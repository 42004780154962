var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row form-row"},[_c('div',{staticClass:"col-xs-12"},[_c('h3',{staticClass:"text-center"},[_vm._v("\n            Deine Heldenskills\n        ")]),_vm._v(" "),_c('p',{staticClass:"subtitle"},[_vm._v("\n          Wähle Technologien aus, die du schon kannst oder lernen willst. So kannst du deinen Fortschritt verfolgen.\n        ")]),_vm._v(" "),_c('div',{staticClass:"col-md-offset-2 col-xs-12 col-md-8"},[_c('Transition',{attrs:{"name":"fade"}},[(!_vm.contentReady)?_c('LoadingIndicatorComponent'):_vm._e()],1),_vm._v(" "),_c('Transition',{attrs:{"name":"fade"}},[(_vm.contentReady)?_c('div',[_c('TransitionGroup',{staticClass:"list-unstyled technologies",attrs:{"name":"chip","tag":"ul","duration":{ enter: _vm.technologyEnterTime, leave: _vm.technologyLeaveTime }}},_vm._l((_vm.selectableTechnologies),function(value){return _c('li',{key:value.id,staticClass:"technology"},[_c('input',{ref:value.id,refInFor:true,staticClass:"technology__checkbox",attrs:{"type":"checkbox","id":value.id,"name":value.id},domProps:{"value":value.id},on:{"change":function($event){return _vm.onTechnologyChange($event, value.id)}}}),_vm._v(" "),_c('label',{staticClass:"technology__label",class:{
                                  'bg-tec-python': value.name === 'python',
                                  'bg-tec-dockerfile': value.name === 'dockerfile',
                                  'bg-tec-java': value.name === 'java',
                                  'bg-tec-javascript': value.name === 'javascript',
                                  'bg-tec-csharp': value.name === 'csharp',
                                  'bg-tec-cpp': value.name === 'cpp',
                                  'bg-tec-php': value.name === 'php',
                              },attrs:{"for":value.id}},[_c('i',{staticClass:"fa fa-code"}),_vm._v(" "),_c('span',{staticClass:"technology__name"},[_vm._v(_vm._s(value.displayName))])])])}),0),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTechnologies.length > 0),expression:"selectedTechnologies.length > 0"}],staticClass:"selected-technologies"},[_c('p',[_vm._v("Ausgewählt")]),_vm._v(" "),_c('TransitionGroup',{staticClass:"list-unstyled technologies",attrs:{"name":"chip","tag":"ul","duration":{ enter: _vm.technologyEnterTime, leave: _vm.technologyLeaveTime }}},_vm._l((_vm.selectedTechnologies),function(value){return _c('li',{key:value.id,staticClass:"technology"},[_c('input',{ref:value.id,refInFor:true,staticClass:"technology__checkbox",attrs:{"type":"checkbox","id":value.id,"name":value.id,"checked":""},domProps:{"value":value.id},on:{"change":function($event){return _vm.onTechnologyChange($event, value.id)}}}),_vm._v(" "),_c('label',{staticClass:"technology__label",class:{
                                  'bg-tec-python': value.name === 'python',
                                  'bg-tec-dockerfile': value.name === 'dockerfile',
                                  'bg-tec-java': value.name === 'java',
                                  'bg-tec-javascript': value.name === 'javascript',
                                  'bg-tec-csharp': value.name === 'csharp',
                                  'bg-tec-cpp': value.name === 'cpp',
                                  'bg-tec-php': value.name === 'php',
                              },attrs:{"for":value.id}},[_c('i',{staticClass:"fa fa-code"}),_vm._v(" "),_c('span',{staticClass:"technology__name"},[_vm._v(_vm._s(value.displayName))])])])}),0)],1),_vm._v(" "),_c('TechnologyTagInputComponent',{attrs:{"available-tec":_vm.typeaheadTechnologies,"existing-technologies":_vm.typeaheadTechnologies},on:{"tag-selected":_vm.onTagSelected}})],1):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }