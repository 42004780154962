<template>
    <div class="loading-indicator-component">
        <span class="loading-spinner loading-spinner-big loading-spinner-green"></span>
        <span class="sr-only">Loading...</span>
    </div>
</template>

<script>

export default {
    name: "LoadingIndicatorComponent",
}
</script>

<style lang="scss" scoped>
.loading-indicator-component {
    text-align: center;
}
</style>