<template>
    <div class="panel  error-panel col-lg-6 col-lg-offset-3 col-sm-12 col-xs-12">
        <div class="panel-body">
            <img src="/assets/icons/search-code.svg" alt="An icon with a browser and a magnifying lens" style="width: 100px; display: block; margin: 1rem auto;"/>
            <h3 class="text-center" style="margin-top: 3rem"><strong>{{ message }}</strong></h3>
             <hr style="margin: 10px auto; padding: 0; width: 60%; ">
            <h4 v-if="isError" class="text-center">Da ist etwas schief gelaufen :(</h4>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ErrorComponent",
        props: {
            message: {type: [String], default: ""},
            callToAction: {type: [String], default: ""},
            isError: {type: [Boolean], default: false},
            icon: {type: [String], default: ""},
        },
    }
</script>

<style scoped>

</style>
