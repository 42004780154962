import ProvisionerServiceStub from "./ProvisionerServiceStub";
import ProvisionerServiceNetwork from "./ProvisionerServiceNetwork";

export default class ProvisionerService {
    constructor(auth) {
        this.authService = auth;
    }

    getWorkspaceUrl(challengeId) {
        if (typeof window.PROVISIONER_API_URL === "undefined" || window.PROVISIONER_API_URL === "") {
            console.info("window.PROVISIONER_API_URL is not set! Using Stub data!");
            return new ProvisionerServiceStub(this.authService).getWorkspaceUrl(challengeId);
        }
        return new ProvisionerServiceNetwork(this.authService).getWorkspaceUrl(challengeId);
    }
}
