<template>
    <div class="job-wizard-section">
        <p class="job-wizard-section__title" v-if="title !== ''">{{ title }}</p>
        <p class="job-wizard-section__subtitle" v-if="subtitle !== ''">{{ subtitle }}</p>
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: "JobWizardSection",
    components: {},
    props: {
        title: {
            type: String,
        },
        subtitle: {
            type: String
        }

    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>

<style lang="scss" scoped>

.job-wizard-section {
    padding: 2rem 1.4rem;

    &__title {
        font-size: 2.4rem;
        line-height: 2.6rem;
        font-weight: 300;
        margin: 0 0 4px 0;
        letter-spacing: 0.1rem;
    }

    &__subtitle {
        margin: 0;
        font-size: 1.6rem;
        line-height: 2.1rem;
        font-style: italic;
        font-weight: 300;
    }
}

@media only screen and (min-width: 768px) {
    .job-wizard-section {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }
}

</style>