<template>
    <svg width="110" height="116" viewBox="0 0 110 116" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_55)">
            <path
                d="M27.4464 23.75C27.4464 28.595 30.2289 32.775 34.2702 34.7985L38.1505 40.9165C38.8982 42.0945 40.6018 42.0945 41.3495 40.9165L44.4821 35.9765V55.1C44.4821 55.6225 44.0563 56.05 43.5357 56.05H12.3036V6.65C12.3036 5.605 13.1554 4.75 14.1964 4.75H42.5893C43.6304 4.75 44.4821 5.605 44.4821 6.65V12.35C43.0246 11.742 41.4252 11.4 39.75 11.4C32.9546 11.4 27.4464 16.929 27.4464 23.75Z"
                fill="#FFDF00"/>
            <path
                d="M38.3247 11.4893C37.86 11.4352 37.3896 11.4 36.9107 11.4C30.1154 11.4 24.6071 16.929 24.6071 23.75C24.6071 28.595 27.3896 32.775 31.4309 34.7985L35.3113 40.9165C36.0069 42.0119 37.5193 42.0755 38.3304 41.1331C38.2698 41.0628 38.2026 40.9992 38.1505 40.9165L34.2702 34.7985C30.2289 32.775 27.4464 28.595 27.4464 23.75C27.4464 17.4135 32.2022 12.199 38.3247 11.4893Z"
                fill="#FEC108"/>
            <path
                d="M41.6429 55.1C41.6429 55.6225 41.217 56.05 40.6964 56.05H43.5357C44.0563 56.05 44.4821 55.6225 44.4821 55.1V35.9765L41.6429 40.4539V55.1Z"
                fill="#FEC108"/>
            <path
                d="M42.5893 4.75H39.75C40.7911 4.75 41.6429 5.605 41.6429 6.65V11.5624C42.6309 11.7173 43.5859 11.9766 44.4821 12.35V6.65C44.4821 5.605 43.6304 4.75 42.5893 4.75Z"
                fill="#FEC108"/>
            <path
                d="M41.6429 2.85V4.75H15.1429V2.85C15.1429 1.805 15.9946 0.949997 17.0357 0.949997H39.75C40.7911 0.949997 41.6429 1.805 41.6429 2.85Z"
                fill="#FF9801"/>
            <path
                d="M39.75 0.949997H36.9107C37.9518 0.949997 38.8036 1.805 38.8036 2.85V4.75H41.6429V2.85C41.6429 1.805 40.7911 0.949997 39.75 0.949997Z"
                fill="#F57C00"/>
            <path
                d="M25.5536 42.75H31.2321C32.277 42.75 33.125 43.6012 33.125 44.65V56.05H23.6607V44.65C23.6607 43.6012 24.5087 42.75 25.5536 42.75Z"
                fill="#A0522D"/>
            <path
                d="M20.8214 32.3H18.9286C17.8832 32.3 17.0357 33.1507 17.0357 34.2V36.1C17.0357 37.1493 17.8832 38 18.9286 38H20.8214C21.8668 38 22.7143 37.1493 22.7143 36.1V34.2C22.7143 33.1507 21.8668 32.3 20.8214 32.3Z"
                fill="#02A9F4"/>
            <path
                d="M20.8214 20.9H18.9286C17.8832 20.9 17.0357 21.7507 17.0357 22.8V24.7C17.0357 25.7493 17.8832 26.6 18.9286 26.6H20.8214C21.8668 26.6 22.7143 25.7493 22.7143 24.7V22.8C22.7143 21.7507 21.8668 20.9 20.8214 20.9Z"
                fill="#02A9F4"/>
            <path
                d="M20.8214 9.5H18.9286C17.8832 9.5 17.0357 10.3507 17.0357 11.4V13.3C17.0357 14.3493 17.8832 15.2 18.9286 15.2H20.8214C21.8668 15.2 22.7143 14.3493 22.7143 13.3V11.4C22.7143 10.3507 21.8668 9.5 20.8214 9.5Z"
                fill="#02A9F4"/>
            <path
                d="M12.3036 18.05V56.05H1.89286C1.37232 56.05 0.94643 55.6225 0.94643 55.1V19.95C0.94643 18.905 1.79822 18.05 2.83929 18.05H12.3036Z"
                fill="#FFDF00"/>
            <path d="M12.3036 18.05H9.46429V56.05H12.3036V18.05Z" fill="#FEC108"/>
            <path d="M12.3036 14.25V18.05H2.83929V16.15C2.83929 15.105 3.69107 14.25 4.73214 14.25H12.3036Z"
                  fill="#FF9801"/>
            <path d="M12.3036 14.25H9.46429V18.05H12.3036V14.25Z" fill="#F57C00"/>
            <path
                d="M52.0536 23.75C52.0536 28.595 49.2711 32.775 45.2298 34.7985L44.4821 35.9765L41.3495 40.9165C40.6018 42.0945 38.8982 42.0945 38.1505 40.9165L34.2702 34.7985C30.2289 32.775 27.4464 28.595 27.4464 23.75C27.4464 16.929 32.9546 11.4 39.75 11.4C41.4252 11.4 43.0246 11.742 44.4821 12.35C48.9304 14.212 52.0536 18.6105 52.0536 23.75Z"
                fill="#F44335"/>
            <path
                d="M44.4821 12.35C43.0246 11.742 41.4252 11.4 39.75 11.4C39.2673 11.4 38.7932 11.4352 38.3247 11.4893C39.4859 11.6242 40.6018 11.9159 41.6429 12.35C46.0911 14.212 49.2143 18.6105 49.2143 23.75C49.2143 28.595 46.4318 32.775 42.3905 34.7985L41.6429 35.9765L38.5102 40.9165C38.4581 40.9992 38.3909 41.0619 38.3304 41.1331C39.1414 42.0755 40.6538 42.0128 41.3495 40.9165L44.4821 35.9765L45.2298 34.7985C49.2711 32.775 52.0536 28.595 52.0536 23.75C52.0536 18.6105 48.9304 14.212 44.4821 12.35Z"
                fill="#C81E1E"/>
            <path
                d="M39.75 30.4C43.4089 30.4 46.375 27.4227 46.375 23.75C46.375 20.0773 43.4089 17.1 39.75 17.1C36.0911 17.1 33.125 20.0773 33.125 23.75C33.125 27.4227 36.0911 30.4 39.75 30.4Z"
                fill="#F5F5F5"/>
            <path
                d="M39.75 17.1C39.2626 17.1 38.7884 17.156 38.3304 17.2567C41.3059 17.9094 43.5357 20.5665 43.5357 23.75C43.5357 26.9334 41.3059 29.5906 38.3304 30.2432C38.7884 30.3439 39.2626 30.4 39.75 30.4C43.4089 30.4 46.375 27.4227 46.375 23.75C46.375 20.0773 43.4089 17.1 39.75 17.1Z"
                fill="#CFD8DC"/>
            <path
                d="M23.6607 36.1V34.2C23.6607 32.6287 22.3868 31.35 20.8214 31.35H18.9286C17.3632 31.35 16.0893 32.6287 16.0893 34.2V36.1C16.0893 37.6713 17.3632 38.95 18.9286 38.95H20.8214C22.3868 38.95 23.6607 37.6713 23.6607 36.1ZM17.9821 36.1V34.2C17.9821 33.6756 18.4071 33.25 18.9286 33.25H20.8214C21.3429 33.25 21.7679 33.6756 21.7679 34.2V36.1C21.7679 36.6244 21.3429 37.05 20.8214 37.05H18.9286C18.4071 37.05 17.9821 36.6244 17.9821 36.1Z"
                fill="black"/>
            <path
                d="M18.9286 27.55H20.8214C22.3868 27.55 23.6607 26.2713 23.6607 24.7V22.8C23.6607 21.2287 22.3868 19.95 20.8214 19.95H18.9286C17.3632 19.95 16.0893 21.2287 16.0893 22.8V24.7C16.0893 26.2713 17.3632 27.55 18.9286 27.55ZM17.9821 22.8C17.9821 22.2756 18.4071 21.85 18.9286 21.85H20.8214C21.3429 21.85 21.7679 22.2756 21.7679 22.8V24.7C21.7679 25.2244 21.3429 25.65 20.8214 25.65H18.9286C18.4071 25.65 17.9821 25.2244 17.9821 24.7V22.8Z"
                fill="black"/>
            <path
                d="M18.9286 16.15H20.8214C22.3868 16.15 23.6607 14.8713 23.6607 13.3V11.4C23.6607 9.8287 22.3868 8.55 20.8214 8.55H18.9286C17.3632 8.55 16.0893 9.8287 16.0893 11.4V13.3C16.0893 14.8713 17.3632 16.15 18.9286 16.15ZM17.9821 11.4C17.9821 10.8756 18.4071 10.45 18.9286 10.45H20.8214C21.3429 10.45 21.7679 10.8756 21.7679 11.4V13.3C21.7679 13.8244 21.3429 14.25 20.8214 14.25H18.9286C18.4071 14.25 17.9821 13.8244 17.9821 13.3V11.4Z"
                fill="black"/>
            <path
                d="M1.89286 17.2748C0.794054 17.6681 0 18.7131 0 19.95V55.1C0 56.1478 0.848946 57 1.89286 57H43.5357C44.5796 57 45.4286 56.1478 45.4286 55.1V36.252L45.8904 35.5234C50.2828 33.2139 53 28.728 53 23.7491C53 18.4566 49.8976 13.8909 45.4286 11.7506V6.65C45.4286 5.0787 44.1547 3.8 42.5893 3.8V2.85C42.5893 1.2787 41.3154 0 39.75 0H17.0357C15.4703 0 14.1964 1.2787 14.1964 2.85V3.8C12.631 3.8 11.3571 5.0787 11.3571 6.65V13.3H4.73214C3.16675 13.3 1.89286 14.5787 1.89286 16.15V17.2748ZM1.89286 19.95C1.89286 19.4256 2.3178 19 2.83929 19H11.3571V55.1H1.89286V19.95ZM24.6071 55.1V44.65C24.6071 44.1256 25.0321 43.7 25.5536 43.7H31.2321C31.7536 43.7 32.1786 44.1256 32.1786 44.65V55.1H24.6071ZM51.1071 23.75C51.1071 28.0924 48.6928 31.9998 44.8077 33.9454C44.6534 34.0223 44.5238 34.1392 44.432 34.2846L40.5497 40.4092C40.3718 40.6885 40.0813 40.8491 39.7509 40.8491C39.4206 40.8491 39.1301 40.6885 38.9531 40.4092L35.0699 34.2846C34.9772 34.1392 34.8475 34.0223 34.6942 33.9454C30.8081 31.9988 28.3938 28.0924 28.3938 23.75C28.3938 17.4638 33.4884 12.35 39.7509 12.35C46.0135 12.35 51.1081 17.4638 51.1081 23.75H51.1071ZM16.0893 2.85C16.0893 2.3256 16.5142 1.9 17.0357 1.9H39.75C40.2715 1.9 40.6964 2.3256 40.6964 2.85V3.8H16.0893V2.85ZM13.25 6.65C13.25 6.1256 13.6749 5.7 14.1964 5.7H42.5893C43.1108 5.7 43.5357 6.1256 43.5357 6.65V11.0105C42.3347 10.6505 41.0655 10.45 39.75 10.45C32.4436 10.45 26.5 16.416 26.5 23.75C26.5 28.728 29.2172 33.2149 33.6105 35.5243L37.3555 41.4314C37.8799 42.2569 38.7752 42.7509 39.75 42.7509C40.7258 42.7509 41.6211 42.2569 42.1454 41.4314L43.5357 39.2388V55.1009H34.0714V44.6509C34.0714 43.0796 32.7975 41.8009 31.2321 41.8009H25.5536C23.9882 41.8009 22.7143 43.0796 22.7143 44.6509V55.1009H13.25V6.65ZM4.73214 15.2H11.3571V17.1H3.78571V16.15C3.78571 15.6256 4.21066 15.2 4.73214 15.2Z"
                fill="black"/>
            <path
                d="M32.1786 23.75C32.1786 27.9405 35.5753 31.35 39.75 31.35C43.9247 31.35 47.3214 27.9405 47.3214 23.75C47.3214 19.5596 43.9247 16.15 39.75 16.15C35.5753 16.15 32.1786 19.5596 32.1786 23.75ZM45.4286 23.75C45.4286 26.8936 42.8817 29.45 39.75 29.45C36.6183 29.45 34.0714 26.8936 34.0714 23.75C34.0714 20.6065 36.6183 18.05 39.75 18.05C42.8817 18.05 45.4286 20.6065 45.4286 23.75Z"
                fill="black"/>
        </g>
        <g clip-path="url(#clip1_2_55)">
            <path
                d="M109.05 106.377V109.264C109.05 112.453 106.498 115.038 103.35 115.038H59.65C56.502 115.038 53.95 112.453 53.95 109.264V106.377C53.95 105.846 54.3753 105.415 54.9 105.415H72V107.34C72 108.934 73.276 110.226 74.85 110.226H88.15C89.724 110.226 91 108.934 91 107.34V105.415H108.1C108.625 105.415 109.05 105.846 109.05 106.377Z"
                fill="#BFBFBF"/>
            <path
                d="M108.1 105.415H105.25C105.775 105.415 106.2 105.846 106.2 106.377V109.264C106.2 112.453 103.648 115.038 100.5 115.038H103.35C106.498 115.038 109.05 112.453 109.05 109.264V106.377C109.05 105.846 108.625 105.415 108.1 105.415Z"
                fill="#808080"/>
            <path
                d="M91 105.415V107.34C91 108.934 89.724 110.226 88.15 110.226H74.85C73.276 110.226 72 108.934 72 107.34V105.415H91Z"
                fill="#BFBFBF"/>
            <path
                d="M88.15 105.415V107.34C88.15 108.934 86.874 110.226 85.3 110.226H88.15C89.724 110.226 91 108.934 91 107.34V105.415H88.15Z"
                fill="#808080"/>
            <path
                d="M103.35 65.9623H59.65C57.5513 65.9623 55.85 67.6855 55.85 69.8113V105.415H107.15V69.8113C107.15 67.6855 105.449 65.9623 103.35 65.9623ZM92.4155 86.497C91.71 87.2836 90.5106 87.348 89.727 86.6413L89.1 86.0736V94.8302C89.1 95.3616 88.6747 95.7925 88.15 95.7925H74.85C74.3253 95.7925 73.9 95.3616 73.9 94.8302V86.0736L73.273 86.6413C72.7673 87.102 72.0574 87.2544 71.4106 87.0411C70.7639 86.8278 70.2787 86.2813 70.1376 85.6073C69.9966 84.9334 70.2213 84.2344 70.727 83.7738L80.227 75.1134C80.9527 74.4589 82.0473 74.4589 82.773 75.1134L86.25 78.2792V76.5472C86.25 76.0157 86.6753 75.5849 87.2 75.5849H89.1C89.6247 75.5849 90.05 76.0157 90.05 76.5472V81.7434L92.273 83.7738C93.0532 84.4867 93.1169 85.7045 92.4155 86.497Z"
                fill="#FFDC00"/>
            <path
                d="M103.35 65.9623H100.5C102.599 65.9623 104.3 67.6855 104.3 69.8113V105.415H107.15V69.8113C107.15 67.6855 105.449 65.9623 103.35 65.9623Z"
                fill="#FF8401"/>
            <path
                d="M90.05 76.5472V81.7434L86.25 78.2792V76.5472C86.25 76.0157 86.6753 75.5849 87.2 75.5849H89.1C89.6247 75.5849 90.05 76.0157 90.05 76.5472Z"
                fill="#FC4012"/>
            <path
                d="M89.1 86.0736V94.8302C89.1 95.3616 88.6747 95.7925 88.15 95.7925H84.35V90.0189C84.3469 88.9573 83.498 88.0975 82.45 88.0943H80.55C79.502 88.0975 78.6531 88.9573 78.65 90.0189V95.7925H74.85C74.3253 95.7925 73.9 95.3616 73.9 94.8302V86.0736L81.5 79.1357L89.1 86.0736Z"
                fill="white"/>
            <path
                d="M92.4155 86.497C91.71 87.2836 90.5106 87.348 89.727 86.6413L89.1 86.0736L81.5 79.1357L73.9 86.0736L73.273 86.6413C72.7673 87.102 72.0574 87.2544 71.4106 87.0411C70.7639 86.8278 70.2787 86.2813 70.1376 85.6073C69.9966 84.9334 70.2213 84.2344 70.727 83.7738L80.227 75.1134C80.9527 74.4589 82.0473 74.4589 82.773 75.1134L86.25 78.2792L90.05 81.7434L92.273 83.7738C93.0532 84.4867 93.1169 85.7045 92.4155 86.497Z"
                fill="#FC4012"/>
            <path
                d="M84.35 90.0189V95.7925H78.65V90.0189C78.6531 88.9573 79.502 88.0975 80.55 88.0943H82.45C83.498 88.0975 84.3469 88.9573 84.35 90.0189Z"
                fill="#FC4012"/>
            <path
                d="M56.8 109.264V106.377C56.8 105.846 57.2253 105.415 57.75 105.415H58.7V69.8113C58.7 67.6855 60.4013 65.9623 62.5 65.9623H59.65C57.5513 65.9623 55.85 67.6855 55.85 69.8113V105.415H54.9C54.3753 105.415 53.95 105.846 53.95 106.377V109.264C53.95 112.453 56.502 115.038 59.65 115.038H62.5C59.352 115.038 56.8 112.453 56.8 109.264Z"
                fill="white"/>
            <path d="M56.8 69.8113H54.9V77.5094H56.8V69.8113Z" fill="white"/>
            <path
                d="M108.1 104.453V69.8113C108.097 67.1554 105.972 65.0032 103.35 65H59.65C57.0279 65.0032 54.9031 67.1554 54.9 69.8113C54.9 70.3428 55.3253 70.7736 55.85 70.7736C56.3747 70.7736 56.8 70.3428 56.8 69.8113C56.8 68.217 58.076 66.9245 59.65 66.9245H103.35C104.924 66.9245 106.2 68.217 106.2 69.8113V104.453H56.8V77.5094C56.8 76.978 56.3747 76.5472 55.85 76.5472C55.3253 76.5472 54.9 76.978 54.9 77.5094V104.453C53.8507 104.453 53 105.314 53 106.377V109.264C53.0042 112.983 55.979 115.996 59.65 116H103.35C107.021 115.996 109.996 112.983 110 109.264V106.377C110 105.314 109.149 104.453 108.1 104.453ZM90.05 106.377V107.34C90.05 108.403 89.1993 109.264 88.15 109.264H74.85C73.8007 109.264 72.95 108.403 72.95 107.34V106.377H90.05ZM108.1 109.264C108.097 111.92 105.972 114.072 103.35 114.075H59.65C57.0279 114.072 54.9031 111.92 54.9 109.264V106.377H71.05V107.34C71.05 109.465 72.7513 111.189 74.85 111.189H88.15C90.2487 111.189 91.95 109.465 91.95 107.34V106.377H108.1V109.264Z"
                fill="black"/>
            <path
                d="M55.85 74.6226C56.3747 74.6226 56.8 74.1918 56.8 73.6604C56.8 73.1289 56.3747 72.6981 55.85 72.6981C55.3253 72.6981 54.9 73.1289 54.9 73.6604C54.9 74.1918 55.3253 74.6226 55.85 74.6226Z"
                fill="black"/>
            <path
                d="M67.25 108.302H64.4C63.8753 108.302 63.45 108.733 63.45 109.264C63.45 109.796 63.8753 110.226 64.4 110.226H67.25C67.7747 110.226 68.2 109.796 68.2 109.264C68.2 108.733 67.7747 108.302 67.25 108.302Z"
                fill="black"/>
            <path
                d="M58.7 110.226C59.2247 110.226 59.65 109.796 59.65 109.264C59.65 108.733 59.2247 108.302 58.7 108.302C58.1753 108.302 57.75 108.733 57.75 109.264C57.75 109.796 58.1753 110.226 58.7 110.226Z"
                fill="black"/>
            <path
                d="M61.55 110.226C62.0747 110.226 62.5 109.796 62.5 109.264C62.5 108.733 62.0747 108.302 61.55 108.302C61.0253 108.302 60.6 108.733 60.6 109.264C60.6 109.796 61.0253 110.226 61.55 110.226Z"
                fill="black"/>
            <path
                d="M59.65 72.6981C59.9019 72.6981 60.1435 72.5966 60.3216 72.4162L62.2216 70.4916C62.5817 70.1141 62.5765 69.5139 62.2101 69.1427C61.8436 68.7715 61.2511 68.7663 60.8783 69.131L58.9783 71.0555C58.7067 71.3307 58.6255 71.7445 58.7725 72.1041C58.9195 72.4636 59.2658 72.698 59.65 72.6981Z"
                fill="black"/>
            <path
                d="M58.9784 77.2275C59.3493 77.6031 59.9507 77.6031 60.3216 77.2275L66.9716 70.4916C67.3317 70.1141 67.3265 69.5139 66.9601 69.1427C66.5936 68.7715 66.0011 68.7663 65.6284 69.131L58.9784 75.8669C58.6075 76.2426 58.6075 76.8517 58.9784 77.2275Z"
                fill="black"/>
            <path
                d="M89.1 74.6226H87.2C86.297 74.6225 85.5186 75.2661 85.338 76.1623L83.4067 74.4023C82.3246 73.4092 80.6754 73.4092 79.5934 74.4023L70.0934 83.0627C69.2343 83.8433 68.9249 85.0717 69.3097 86.1738C69.6944 87.2759 70.6974 88.0342 71.8499 88.0943C71.905 88.0943 71.9525 88.0943 72 88.0943C72.324 88.0934 72.6454 88.0357 72.95 87.924V94.8302C72.95 95.8931 73.8007 96.7547 74.85 96.7547H88.15C89.1993 96.7547 90.05 95.8931 90.05 94.8302V87.9105C91.1337 88.2789 92.3298 87.9788 93.1185 87.1407C94.171 85.9558 94.0761 84.1311 92.9067 83.0646L91 81.3238V76.5472C91 75.4843 90.1493 74.6226 89.1 74.6226ZM87.2 76.5472H89.1V79.5918L87.2 77.8597V76.5472ZM79.6 94.8302V90.0189C79.6 89.4874 80.0253 89.0566 80.55 89.0566H82.45C82.9747 89.0566 83.4 89.4874 83.4 90.0189V94.8302H79.6ZM85.3 94.8302V90.0189C85.3 88.4245 84.024 87.1321 82.45 87.1321H80.55C78.976 87.1321 77.7 88.4245 77.7 90.0189V94.8302H74.85V86.4931L81.5 80.4309L88.15 86.4931V94.8302H85.3ZM91.95 85.1565C91.9635 85.4115 91.8763 85.6614 91.7078 85.8513C91.35 86.2323 90.7605 86.2636 90.3654 85.9225L82.1365 78.4168C81.7752 78.0874 81.2267 78.0874 80.8654 78.4168L72.6356 85.9225C72.3843 86.1596 72.0262 86.241 71.6991 86.1352C71.3719 86.0294 71.1266 85.753 71.0574 85.4123C70.9882 85.0716 71.106 84.7196 71.3654 84.4916L80.8654 75.8312C81.2266 75.5024 81.7744 75.5024 82.1356 75.8312L91.6356 84.4916C91.8244 84.6614 91.9378 84.9011 91.95 85.1565Z"
                fill="black"/>
        </g>
        <line x1="11.9155" y1="102.964" x2="97.9155" y2="12.9637" stroke="black" stroke-width="3"/>
        <defs>
            <clipPath id="clip0_2_55">
                <rect width="53" height="57" fill="white"/>
            </clipPath>
            <clipPath id="clip1_2_55">
                <rect width="57" height="51" fill="white" transform="translate(53 65)"/>
            </clipPath>
        </defs>
    </svg>


</template>
<script>
export default {
    name: "HybridIcon"
}
</script>