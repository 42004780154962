export class RewardListResult {
    constructor() {
        this.rewards = [];
        this.error = null;
        this.loaded = false;
    }
}

export class UserCoinsResult {
    constructor() {
        this.coins = 0;
        this.error = null;
        this.loaded = false;
    }
}

export class ExchangeResult {
    constructor() {
        this.error = null;
        this.loaded = false;
    }
}


export class RedeemCouponCodeResult {
    constructor() {
        this.apiResult = null;
        this.error = null;
        this.loaded = false;
    }
}

export class DonationResult {
    constructor() {
        this.apiResult = {};
        this.error = null;
        this.loaded = false;
    }
}

export class Donate {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}

export class DonationLeaderBoardResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
        this.pagination = {};
    }
}