import { MockResponse } from "../Utilities";
import {
    ActiveValuesResult,
    CompanyListResult,
    PublicSponsoringInformationResult,
    SubmissionStateResult,
    SponsoringInformationDetailsResult,
    VacancyRatingResult,
    VacancyRatingCommentsResult,
    VacancyListResult,
    TechnologyListResult,
    VacanciesTechnologyListResult,
    VacanciesResult,
    VacancyGoogleForJobsResult,
} from "./CompanyServiceResponses";

export class CompanyServiceStub {
    constructor(auth) {
        this.authService = auth;
    }

    getAllCompanies(
        search,
        hasChallenge,
        hasJobs,
        technologies,
        languages,
        latitude,
        longitude,
        searchRadius,
        sortIndex
    ) {
        return this.getCompanyList(
            1,
            search,
            hasChallenge,
            hasJobs,
            technologies,
            languages,
            latitude,
            longitude,
            searchRadius,
            sortIndex
        );
    }

    getCompanyList(
        pageID = 1,
        search,
        hasChallenge,
        hasJobs,
        technologies,
        languages,
        latitude,
        longitude,
        searchRadius,
        sortIndex
    ) {
        let result = new CompanyListResult();
        setTimeout(function () {
            result.apiData = [{
                "id": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                "name": "EntwicklerHeld GmbH",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "entwicklerheld-gmbh",
                "slogan": "EntwicklerHeld bringt Nachwuchsentwickler und regionale Unternehmen zusammen! Entwickler haben die Möglichkeit, ihr Können auf unserer Plattform durch Lösen von verschiedensten Coding-Challenges zu beweisen. ",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{
                    "id": 1,
                    "name": "Django",
                    "label": "Django (Python)",
                    "language": "Python"
                }, {"id": 2, "name": "Spring Boot", "label": "Spring Boot (Java)", "language": "Java"}],
                "locations": [
                    {"lat": 51.025678, "lng": 13.72232, "city": "Dresden"},
                    {"lat": 51.025688, "lng": 13.72232, "city": "Dresden"},
                    {"lat": 51.025668, "lng": 13.72232, "city": "Berlin"},
                    {"lat": 51.025679, "lng": 13.72232, "city": "Hamburg"},
                    {"lat": 51.025676, "lng": 13.72232, "city": "Chemnitz"}
                ],
                "statisticsUpsale":true,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "",
                "logoColor": "",
                "sponsoredChallengeIds": [
                    'e918f361-abe7-4187-a89a-8be94bd4f64b',
                    '9c778212-5cf0-4b5d-b2b5-49bea7eb1d53',
                    'e6458744-d7bf-4fa1-8689-ef1643937339',
                ],
                "has100PercentRemoteOption":false,
            }, {
                "id": "29bdf748-dd1c-48a4-8a0a-54753843cdb2",
                "name": "Kontext E GmbH",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "kontext-e-gmbh",
                "slogan": "Wir leben Digitalisierung.",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{
                    "id": 2,
                    "name": "Spring Boot",
                    "label": "Spring Boot (Java)",
                    "language": "Java"
                }, {"id": 7, "name": ".NET", "label": ".NET (C#)", "language": "C#"}],
                "locations": [
                    {"lat": 51.0476, "lng": 13.737647, "city": "Berlin"},
                    {"lat": 51.025659, "lng": 13.72232, "city": "Hamburg"},
                    {"lat": 51.025669, "lng": 13.72232, "city": "Hamburg"},
                ],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "",
                "logoColor": "",
                "has100PercentRemoteOption":true,
            }, {
                "id": "fba086e3-9747-49e7-a86e-9222bbe97cd6",
                "name": "Senorics GmbH",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "senorics-gmbh",
                "slogan": "Senorics ist ein Start-up und eine Ausgründung aus der TU Dresden. Wir bietet technologische Herausforderungen und neues, angewandtes Wissen in kurzer Zeit. Wir leben flache Hierarchien und flexible Arbeitszeiten, ermöglichen Selbstverantwortung, steile Lernkurven und Entwicklungschancen.",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{"id": 26, "name": "", "label": "C++", "language": "C++"}, {
                    "id": 50,
                    "name": "Android",
                    "label": "Android (Java)",
                    "language": "Java"
                }, {
                    "id": 51,
                    "name": "Sharepoint Framework",
                    "label": "Sharepoint Framework (JavaScript)",
                    "language": "JavaScript"
                }],
                "locations": [{"lat": 51.025848, "lng": 13.725692, "city": "Dresden"}],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "stepstone",
                "logoColor": "#ffd300",
                "has100PercentRemoteOption":true,
            }, {
                "id": "801989e9-77e3-498f-a22c-c1cf5aefbaf9",
                "name": "itelligence",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "itelligence",
                "slogan": "Lass uns gemeinsam die digitale Revolution gestalten: Wir lieben Digitalisierung! Lass uns gemeinsam die digitale Revolution gestalte Digitalisierung",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{"id": 19, "name": "", "label": "Python", "language": "Python"}, {
                    "id": 21,
                    "name": "SAP S/4 HANA",
                    "label": "SAP S/4 HANA (ABAP)",
                    "language": "ABAP"
                }, {"id": 20, "name": "SAP UI5", "label": "SAP UI5 (JavaScript)", "language": "JavaScript"}],
                "locations": [{"lat": 51.040898, "lng": 13.780976, "city": "Hamburg"}],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "stackoverflow",
                "logoColor": "#179442",
                "has100PercentRemoteOption":false,
            }, {
                "id": "225229b1-9f7a-400f-8cc1-9dd89b5f4cb6",
                "name": "itemis AG",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "itemis-ag",
                "slogan": "Die itemis AG ist ein unabhängiges IT-Beratungsunternehmen und Anbieter von Produkten und Dienstleistungen für effiziente Softwareentwicklungsprojekte. Kernkompetenz ist die modellbasierte Softwareentwicklung (MDSD).",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{"id": 26, "name": "", "label": "C++", "language": "C++"}, {
                    "id": 28,
                    "name": "Spring",
                    "label": "Spring (Java)",
                    "language": "Java"
                }, {"id": 35, "name": "Angular", "label": "Angular (JavaScript)", "language": "JavaScript"}, {
                    "id": 36,
                    "name": "Jakarta EE",
                    "label": "Jakarta EE (Java)",
                    "language": "Java"
                }, {"id": 37, "name": "Eclipse RCP", "label": "Eclipse RCP (Java)", "language": "Java"}, {
                    "id": 38,
                    "name": "EMF",
                    "label": "EMF (Java)",
                    "language": "Java"
                }, {"id": 39, "name": "React", "label": "React (JavaScript)", "language": "JavaScript"}],
                "locations": [{"lat": 51.346139, "lng": 12.39053, "city": "Hamburg"}],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "",
                "logoColor": "",
                "has100PercentRemoteOption":false,
            }, {
                "id": "cbf68420-7d96-42ff-aeab-34fb866151f9",
                "name": "voice INTER connect GmbH",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "voice-inter-connect-gmbh",
                "slogan": "Die voice INTER connect GmbH wurde 2001 von Studenten aus dem Institut für Akustik und Sprachkommunikation der TU Dresden ausgegründet.\r\n\r\nSprachsteuerung, Intercom, VoIP, HMI, Industrie 4.0 - das sind die Bereiche, in denen wir innovative Produkte entwickeln und auf den Kunden zugeschnittene Dienstleistungen anbieten.",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{"id": 29, "name": ".NET", "label": ".NET (C++)", "language": "C++"}, {
                    "id": 30,
                    "name": "Qt",
                    "label": "Qt (C++)",
                    "language": "C++"
                }, {"id": 31, "name": ".NET", "label": ".NET (C)", "language": "C"}],
                "locations": [{"lat": 51.045491, "lng": 13.722392, "city": "Köln"}],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "",
                "logoColor": "",
                "has100PercentRemoteOption":false,
            }, {
                 "id": "6e4cfa45-846d-432a-bc10-c46016d69645",
                "name": "Softwareforen Leipzig GmbH",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "softwareforen-leipzig-gmbh",
                "slogan": "Neben der Ausrichtung von Premium-Veranstaltungen sind wir als Partner in IT-Projekten aktiv und optimieren mit maßgeschneiderten Softwarelösungen die Geschäftsprozesse unserer Kunden aus unterschiedlichsten Branchen. ",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{
                    "id": 2,
                    "name": "Spring Boot",
                    "label": "Spring Boot (Java)",
                    "language": "Java"
                }, {"id": 8, "name": "", "label": "JavaScript", "language": "JavaScript"}, {
                    "id": 28,
                    "name": "Spring",
                    "label": "Spring (Java)",
                    "language": "Java"
                }, {"id": 34, "name": "Polymer", "label": "Polymer (JavaScript)", "language": "JavaScript"}, {
                    "id": 35,
                    "name": "Angular",
                    "label": "Angular (JavaScript)",
                    "language": "JavaScript"
                }],
                "locations": [{"lat": 51.342576, "lng": 12.373566, "city": "Leipzig"}],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "",
                "logoColor": "",
                "has100PercentRemoteOption":false,
            }, {
                "id": "41fff9dc-0a39-4009-abc7-df5cbb613567",
                "name": "SALT Solutions AG",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "salt-solutions-ag",
                "slogan": "Die SALT Solutions AG mit rund 500 Mitarbeitern ist ein unabhängiger, international\r\nagierender IT-Dienstleister an den Standorten München, Würzburg, Dresden und Stuttgart.",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{"id": 11, "name": "", "label": "Java", "language": "Java"}, {
                    "id": 12,
                    "name": "JEE",
                    "label": "JEE (Java)",
                    "language": "Java"
                }, {"id": 13, "name": "iOS", "label": "iOS (Objective-C)", "language": "Objective-C"}, {
                    "id": 14,
                    "name": "iOS",
                    "label": "iOS (Swift)",
                    "language": "Swift"
                }, {"id": 7, "name": ".NET", "label": ".NET (C#)", "language": "C#"}, {
                    "id": 23,
                    "name": "",
                    "label": "SQL",
                    "language": "SQL"
                }, {"id": 25, "name": "IBM Cognos TM1", "label": "IBM Cognos TM1", "language": ""}, {
                    "id": 26,
                    "name": "",
                    "label": "C++",
                    "language": "C++"
                }, {"id": 16, "name": "SAP Hybris", "label": "SAP Hybris", "language": ""}, {
                    "id": 15,
                    "name": "Hadoop",
                    "label": "Hadoop",
                    "language": ""
                }, {"id": 17, "name": "IBM", "label": "IBM", "language": ""}],
                "locations": [{"lat": 51.069231, "lng": 13.782894, "city": "Dresden"}],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "",
                "logoColor": "",
                "has100PercentRemoteOption":false,
            }, {
                "id": "6b914674-83f0-439d-8ba3-8e57d2fa9127",
                "name": "Cloud&Heat",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "cloudheat",
                "slogan": "Ein Team von mehr als 90 Kollegen arbeitet an dieser Vision täglich interdisziplinär und auf kurzen Wegen Hand in Hand. ",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{
                    "id": 5,
                    "name": "Ansible",
                    "label": "Ansible (Python)",
                    "language": "Python"
                }, {"id": 52, "name": "OpenStack", "label": "OpenStack (Python)", "language": "Python"}, {
                    "id": 53,
                    "name": "Chef",
                    "label": "Chef (Ruby)",
                    "language": "Ruby"
                }],
                "locations": [{"lat": 51.080074, "lng": 13.76216, "city": "Dresden"}],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "",
                "logoColor": "",
                "has100PercentRemoteOption":false,
            }, {
                 "id": "5b2da6ee-a67f-4d24-afa9-96860984812e",
                "name": "e-dox AG",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "e-dox-ag",
                "slogan": "Die e-dox AG ist langjähriger Partner des Xerox® Konzerns und führender Servicedienstleister für\r\nManaged Print Services & Dokumentenmanagement in Mitteldeutschland mit Sitz in Leipzig.",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{
                    "id": 2,
                    "name": "Spring Boot",
                    "label": "Spring Boot (Java)",
                    "language": "Java"
                }, {"id": 49, "name": "jQuery", "label": "jQuery (JavaScript)", "language": "JavaScript"}],
                "locations": [{"lat": 51.36486, "lng": 12.39941, "city": "Dresden"}],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "",
                "logoColor": "",
                "has100PercentRemoteOption":false,
            }, {
                "id": "9b7d75a7-451f-4901-83a7-4aae6c0a3fbe",
                "name": "Cyface",
                "isCustomer": true,
                "isFreeCustomer": false,
                "slug": "cyface",
                "slogan": "Wir sind Cyface, ein Startup aus Dresden und entwickeln Software zur Analyse von Straßen- und Verkehrsdaten. ",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{"id": 6, "name": "Docker", "label": "Docker", "language": "dockerfile"}, {
                    "id": 8,
                    "name": "",
                    "label": "JavaScript",
                    "language": "JavaScript"
                }, {"id": 62, "name": "TypeScript", "label": "TypeScript", "language": ""}, {
                    "id": 63,
                    "name": "GeoServer",
                    "label": "GeoServer (Java)",
                    "language": "Java"
                }],
                "locations": [{"lat": 51.051814, "lng": 13.728889, "city": "Dresden"}],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "",
                "logoColor": "",
                "has100PercentRemoteOption":false,
            }, {
                 "id": "56f263cd-17cc-401c-bb78-ebbda95c1ce1",
                "name": "descript GmbH",
                "isCustomer": true,
                "isFreeCustomer": true,
                "slug": "descript-gmbh",
                "slogan": "descript wurde vor 14 Jahren von Studenten der (Medien-)Informatik an der TU Dresden gegründet. Wir entwickeln web-basierte Software, Mobile Apps und Internetauftritte für kleine und mittlere Unternehmen, Institutionen sowie Städte und Gemeinden. ",
                "logo": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "heroImage": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                },
                "logoIcon": {
                    "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                    "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                },
                "technologies": [{"id": 1, "name": "Django", "label": "Django (Python)", "language": "Python"}],
                "locations": [{"lat": 51.052265, "lng": 13.723624, "city": "Dresden"}],
                "statisticsUpsale":false,
                "valuesUpsale":false,
                "videoUpsale":false,
                "importSource": "",
                "logoColor": "",
                "has100PercentRemoteOption":false,
            }, {
                     "id": "e839cbf2-6f5a-4063-a1f7-c1471049e432",
                    "name": "DevBoost GmbH",
                    "isCustomer": true,
                    "isFreeCustomer": false,
                    "slug": "devboost-gmbh",
                    "slogan": "DevBoost steht für Qualität und Effizienz in der Softwareentwicklung. Wir entwickeln hochwertige Softwarelösungen auf Basis moderner Technologien und Cloud-Plattformen. ",
                    "logo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "heroImage": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                    },
                    "logoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "technologies": [{
                        "id": 2,
                        "name": "Spring Boot",
                        "label": "Spring Boot (Java)",
                        "language": "Java"
                    }, {"id": 7, "name": ".NET", "label": ".NET (C#)", "language": "C#"}],
                    "locations": [{"lat": 51.032799, "lng": 13.72072, "city": "Dresden"}],
                    "statisticsUpsale":false,
                    "valuesUpsale":false,
                    "videoUpsale":false,
                    "importSource": "",
                    "logoColor": "",
                    "has100PercentRemoteOption":false,
                }, {
                     "id": "fd7a69c5-2397-4abf-a0c2-a9ffc2114629",
                    "name": "3D Interaction Technologies GmbH",
                    "isCustomer": true,
                    "isFreeCustomer": false,
                    "slug": "3d-interaction-technologies-gmbh",
                    "slogan": "Wir sind ein junges und erfolgreiches Unternehmen, das Hightech- und Design-Firmen mit interaktiven Präsentationen ihrer Produkte ausstattet. Mit unseren Lösungen helfen wir, die innovativen Ansätze namhafter Kunden  spielerisch und begreifbar zu erleben. ",
                    "logo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "heroImage": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                    },
                    "logoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "technologies": [{"id": 7, "name": ".NET", "label": ".NET (C#)", "language": "C#"}, {
                        "id": 8,
                        "name": "",
                        "label": "JavaScript",
                        "language": "JavaScript"
                    }, {"id": 39, "name": "React", "label": "React (JavaScript)", "language": "JavaScript"}, {
                        "id": 69,
                        "name": "Unity3D",
                        "label": "Unity3D (C#)",
                        "language": "C#"
                    }, {
                        "id": 70,
                        "name": "PlayCanvas",
                        "label": "PlayCanvas (JavaScript)",
                        "language": "JavaScript"
                    }, {"id": 71, "name": "ThreeJS", "label": "ThreeJS (JavaScript)", "language": "JavaScript"}, {
                        "id": 72,
                        "name": "BabylonJS",
                        "label": "BabylonJS (JavaScript)",
                        "language": "JavaScript"
                    }, {"id": 73, "name": "NodeJS", "label": "NodeJS (JavaScript)", "language": "JavaScript"}, {
                        "id": 74,
                        "name": "Meteor",
                        "label": "Meteor (JavaScript)",
                        "language": "JavaScript"
                    }],
                    "locations": [{"lat": 51.059656, "lng": 13.785812, "city": "Dresden"}],
                    "statisticsUpsale":false,
                    "valuesUpsale":false,
                    "videoUpsale":false,
                    "importSource": "",
                    "logoColor": "",
                    "has100PercentRemoteOption":false,
                }, {
                    "id": "99a82520-54ad-401d-8b1c-eb85990ff1fa",
                    "name": "profi.com AG business solutions",
                    "isCustomer": true,
                    "isFreeCustomer": false,
                    "slug": "proficom-ag-business-solutions",
                    "slogan": "Als mittelständisches IT-Unternehmen vertrauen wir auf unsere 70 Mitarbeiter, die den Slogan „WE MAKE IT WORK\" lebendig werden lassen. \r\n\r\nWir beraten in den Geschäftsfeldern Quality, Cloud & Security. ",
                    "logo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "heroImage": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                    },
                    "logoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "technologies": [{"id": 32, "name": "MicroFocus", "label": "MicroFocus", "language": ""}, {
                        "id": 33,
                        "name": "Selenium",
                        "label": "Selenium",
                        "language": ""
                    }, {"id": 45, "name": "Softwaretest", "label": "Softwaretest", "language": ""}, {
                        "id": 46,
                        "name": "Testautomatisierung",
                        "label": "Testautomatisierung",
                        "language": ""
                    }, {
                        "id": 47,
                        "name": "Last- und Performancetest",
                        "label": "Last- und Performancetest",
                        "language": ""
                    }, {
                        "id": 48,
                        "name": "Cloud Automation (AWS, GCP, Azure)",
                        "label": "Cloud Automation (AWS, GCP, Azure)",
                        "language": ""
                    }],
                    "locations": [{"lat": 51.04334, "lng": 13.7727, "city": "Dresden"}],
                    "statisticsUpsale":false,
                    "valuesUpsale":false,
                    "videoUpsale":false,
                    "importSource": "",
                    "logoColor": "",
                    "has100PercentRemoteOption":false,
                }, {
                     "id": "16d9344c-5ffa-44ad-b325-2011c7c04120",
                    "name": "TraceTronic GmbH",
                    "isCustomer": true,
                    "isFreeCustomer": false,
                    "slug": "tracetronic-gmbh",
                    "slogan": "In unseren agilen Teams aus Ingenieuren, Mathematikern und Informatikern unterstützen wir unsere Kunden im gesamten Hardware- und Softwareentwicklungsprozess tatkräftig - von der Erhebung der Anforderung, über die Systemanalyse bis hin zur Implementierung und Durchführung von Tests. ",
                    "logo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "heroImage": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                    },
                    "logoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "technologies": [{"id": 11, "name": "", "label": "Java", "language": "Java"}, {
                        "id": 19,
                        "name": "",
                        "label": "Python",
                        "language": "Python"
                    }, {"id": 6, "name": "Docker", "label": "Docker", "language": "dockerfile"}, {
                        "id": 54,
                        "name": "",
                        "label": "C",
                        "language": "C"
                    }, {"id": 55, "name": "Jenkins", "label": "Jenkins", "language": ""}, {
                        "id": 56,
                        "name": "Git",
                        "label": "Git",
                        "language": ""
                    }, {"id": 57, "name": "", "label": "MatLAB", "language": "MatLAB"}],
                    "locations": [{"lat": 51.008797, "lng": 13.693465, "city": "Dresden"}],
                    "statisticsUpsale":false,
                    "valuesUpsale":false,
                    "videoUpsale":false,
                    "importSource": "",
                    "logoColor": "",
                    "has100PercentRemoteOption":false,
                }, {
                    "id": "6df126f4-fa50-4bbe-82f1-7cd936158337",
                    "name": "FERCHAU Engineering GmbH, Dresden",
                    "isCustomer": true,
                    "isFreeCustomer": false,
                    "slug": "ferchau-engineering-gmbh-dresden",
                    "slogan": "Die Theorie hast Du langsam aber sicher auf dem Kasten und möchtest jetzt endlich in der Praxis zeigen, was in Dir steckt? Bei FERCHAU musst Du dich nicht auf eine spezielle Branche festlegen. ",
                    "logo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "heroImage": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                    },
                    "logoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "technologies": [{"id": 9, "name": "", "label": "HTML5", "language": "HTML5"}, {
                        "id": 10,
                        "name": "",
                        "label": "CSS3",
                        "language": "CSS3"
                    }, {"id": 11, "name": "", "label": "Java", "language": "Java"}, {
                        "id": 22,
                        "name": "",
                        "label": "PHP",
                        "language": "PHP"
                    }, {"id": 8, "name": "", "label": "JavaScript", "language": "JavaScript"}, {
                        "id": 75,
                        "name": "TYPO3",
                        "label": "TYPO3",
                        "language": ""
                    }, {"id": 76, "name": "C#", "label": "C#", "language": ""}],
                    "locations": [{"lat": 51.0756, "lng": 13.686793, "city": "Dresden"}],
                    "statisticsUpsale":false,
                    "valuesUpsale":false,
                    "videoUpsale":false,
                    "importSource": "",
                    "logoColor": "",
                    "has100PercentRemoteOption":false,
                },
                {
                    "datePosted": "2020-05-27",
                    "id": "419c75b8-970e-4b4b-8e30-d8e70361532d",
                    "name": "Craftnote - Handwerker App (myCraftnote Digital GmbH)",
                    "isCustomer": true,
                    "isFreeCustomer": true,
                    "slug": "craftnote-handwerker-app-mycraftnote-digital-gmbh",
                    "slogan": "We, the Craftnote team, are pursuing the following vision: From 2021, the Craftnote App will support all those involved on every second construction site in German-speaking countries. ",
                    "logo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "heroImage": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                    },
                    "logoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-e81e9c41-5eb3-40a8-b171-fc1ec787ef8b.png"
                    },
                    "technologies": [{"id": 9, "name": "", "label": "HTML5", "language": "HTML5"}, {
                        "id": 10,
                        "name": "",
                        "label": "CSS3",
                        "language": "CSS3"
                    }, {"id": 11, "name": "", "label": "Java", "language": "Java"}, {
                        "id": 14,
                        "name": "iOS",
                        "label": "iOS (Swift)",
                        "language": "Swift"
                    }, {"id": 35, "name": "Angular", "label": "Angular (JavaScript)", "language": "JavaScript"}, {
                        "id": 62,
                        "name": "TypeScript",
                        "label": "TypeScript",
                        "language": ""
                    }, {"id": 73, "name": "NodeJS", "label": "NodeJS (JavaScript)", "language": "JavaScript"}, {
                        "id": 80,
                        "name": "Kotlin",
                        "label": "Kotlin",
                        "language": ""
                    }],
                    "locations": [{"lat": 52.503724, "lng": 13.454852, "city": "Dresden"}],
                    "statisticsUpsale":false,
                    "valuesUpsale":false,
                    "videoUpsale":false,
                    "importSource": "",
                    "logoColor": "",
                    "has100PercentRemoteOption":false,
                }, {
                    "id": "company_id_1",
                    "name": "Edition VC",
                    "isCustomer": false,
                    "isFreeCustomer": false,
                    "slug": "edition-vc",
                    "aboutUs": "",
                    "slogan": "",
                    "logo": "",
                    "logoIcon": "",
                    "technologies": [],
                    "locations": [{"lat": 48.025678, "lng": 13.72232, "city": "Dresden"}],
                    "statisticsUpsale":false,
                    "valuesUpsale":false,
                    "videoUpsale":false,
                    "importSource": "",
                    "logoColor": "",
                    "has100PercentRemoteOption":false,
                }, {
                    "id": "company_id_2",
                    "name": "Attacke Ventures",
                    "isCustomer": false,
                    "isFreeCustomer": false,
                    "slug": "edition-vc",
                    "aboutUs": "",
                    "slogan": "",
                    "logo": "",
                    "logoIcon": "",
                    "technologies": [],
                    "locations": [{"lat": 51.025678, "lng": 10.72232, "city": "Dresden"}],
                    "statisticsUpsale":false,
                    "valuesUpsale":false,
                    "videoUpsale":false,
                    "importSource": "",
                    "logoColor": "",
                    "has100PercentRemoteOption":false,
                }];
            result.pagination = {"pageID": pageID, "numPages": "3", "totalCount": 200};
            if (sortIndex === 1) {
                result.apiData.sort(function(company1, company2){
                    return company1["name"].localeCompare(company2["name"]);
                });
            }
            else if (sortIndex === 2) {
                result.apiData.sort(function(company1, company2){
                    return company2["name"].localeCompare(company1["name"]);
                });
            }
            if (hasChallenge) {
                result.apiData = result.apiData.filter(
                    (data) => data.sponsoredChallengeIds && data.sponsoredChallengeIds.length !== 0
                );
            }
            result.loaded = true;
        }, 200);
        return result;
    }

    getPublicSponsoringInformation(challengeIds, tracking) {
        let result = new PublicSponsoringInformationResult();
        setTimeout(function () {
            result.apiData = [
                {
                    "name": "EntwicklerHeld GmbH",
                    "sponsoredChallengeId": "",
                    "challengeId": "5fb64776-ec46-48ae-ae04-b9c77949eb80",
                    "logo": "https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png",
                    "logoIcon": "https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png",
                },
                {
                    "name": "EntwicklerHeld GmbH",
                    "sponsoredChallengeId": "",
                    "challengeId": "e918f361-abe7-4187-a89a-8be94bd4f64b",
                    "logo": "https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png",
                    "logoIcon": "https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png",
                },
                {
                    "name": "EntwicklerHeld GmbH",
                    "sponsoredChallengeId": "",
                    "challengeId": "2617136e-97da-4842-bbba-9c21e888e6e4",
                    "logo": "https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png",
                    "logoIcon": "https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png",
                },
                {
                    "name": "EntwicklerHeld GmbH",
                    "sponsoredChallengeId": "",
                    "challengeId": "ea2d5525-8d19-4db1-912c-6fbc5b0825ab",
                    "logo": "https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png",
                    "logoIcon": "https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png",
                },
                {
                    "name": "EntwicklerHeld GmbH",
                    "sponsoredChallengeId": "",
                    "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                    "logo": "https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png",
                    "logoIcon": "https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png",
                },
            ];
            result.loaded = true;
        }, 100);
        return result;
    }

    getSponsoringInformationDetails(challengeId) {
        let result = new SponsoringInformationDetailsResult();
        setTimeout(function () {
            result.apiData = {
                'type': 'POOL',
                'companyId': 'c25bfe23-e621-465e-911c-0c6c81e2496c',
                'companySlug': 'entwicklerheld-gmbh',
                'logo': 'https://production-company-media-storage.s3.amazonaws.com/eh-logo-developer-small.png',
                'name': 'EntwicklerHeld GmbH',
                'reward': 250,
                'twitterHandle': "EntwicklerHeld"
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    getSubmissionState(challengeId) {
        let result = new SubmissionStateResult();

        setTimeout(function () {
            result.loaded = true;
            result.error = null;
            result.apiData = {
                'state': 'open'
            };
        }, 100);

        return result;
    }

    getCompanyProfile(companyId, tracking) {
        let data = {
            "companyId": "c25bfe23-e621-465e-911c-0c6c81e2496c",
            "profileId": 1,
            "statisticsUpsale": true,
            "valuesUpsale": true,
            "videoUpsale": true,
            "profile": {
                "slogan": "Du möchtest die Plattform in einem jungen Team aus Freunden mitgestalten? Dann bist du bei EntwicklerHeld genau richtig!",
                "aboutUs": "EntwicklerHeld bringt Nachwuchsentwickler und regionale Unternehmen zusammen! Entwickler haben die Möglichkeit, ihr Können auf unserer Plattform durch Lösen von verschiedensten Coding-Challenges zu beweisen. Viele dieser Challenges werden von regionalen Unternehmen gesponsert, die nach Entwicklern suchen. Aus den Teilnehmern ihrer Challenges suchen sich die Unternehmen die besten Entwickler aus und können diese direkt kontaktieren.",
                "logo": "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_entwicklerheld-gmbh/eh-logo-short.png",
                "careerPage": "https://entwicklerheld.de",
                "logoIcon": "",
                "name": "EntwicklerHeld",
                "heroImage": "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_entwicklerheld-gmbh/FEL_2178.jpg",
                "video": "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_entwicklerheld-gmbh/uploaded_2020-03-27_081525.665691.mp4",
                "sliderImage1": "https://production-company-media-storage.s3.amazonaws.com/52555150_582514055548953_6264070124584566784_o.jpg",
                "sliderImage2": "https://production-company-media-storage.s3.amazonaws.com/ew-44_1.jpg",
                "sliderImage3": "https://production-company-media-storage.s3.amazonaws.com/philipp_jakob.jpg",
                "importSource": "",
                "logoColor": "#FFD022",
                "remoteChoices": [
                    {"name": "Pro Team unterschiedlich", "nameCode": "REMOTE_PER_TEAM"},
                    {"name": "0%", "nameCode": "REMOTE_0"},
                    {"name": "25%", "nameCode": "REMOTE_25"},
                    {"name": "50%", "nameCode": "REMOTE_50"},
                    {"name": "75%", "nameCode": "REMOTE_75"},
                    {"name": "100%", "nameCode": "REMOTE_100"},
                ],
                "remoteOption": "REMOTE_100",
                "has100PercentRemoteOption": true
            },
            "values": [{
                "nameCode": "FLEXIBLE_WORKING_ENV",
                "headline": "Remote Friendly",
                "description": "Unser Firmensitz ist in Dresden. Wir haben ein wunderschönes Büro im Dresdner Süden. Doch das bedeutet nicht, dass jeder umziehen muss, der bei uns arbeitet. Fast die Hälfte unseres Teams arbeitet zumindest zeitweise von Zuhause, aus Co-Working Spaces oder vom Café um die Ecke. Die Kommunikation verläuft über Slack und Skype. Täglich reden wir eine viertel Stunde über den vergangenen Tag und unsere Ziele für den kommenden. Unsere Remote-Mitarbeiter sind dort natürlich auch dabei - über Videotelefonie.",
                "image": ""
            }, {
                "nameCode": "TECHNICAL_BACKGROUND",
                "headline": "Im Herzen Software-Entwickler",
                "description": "Unser Gründerteam besteht fast ausschließlich aus Software-Entwicklern, die sowohl für unsere Nutzer-Zielgruppe, als auch für die technischen Hintergründe ein tiefes Verständnis mitbringen. Jeder von ihnen ist bei der Entwicklung der Plattform dabei und hat vollen Überblick über alle Features. Daher wird dir jeder im Team offene, technische Fragen beantworten können.",
                "image": ""
            }, {
                "nameCode": "B2C",
                "headline": "Make Something People Want",
                "description": "Unser Hauptziel besteht darin, die Plattform für unsere User immer weiter zu verbessern. Seien es neue Features, spannende Challenges oder Bugfixes. Denn zufriedene Nutzer sind unser größter Antrieb. Transparenz ist uns wichtig. Daher teilen wir unseren Weg mit euch über unsere News-Seite und Social Media. Wir freuen uns über jedes Feedback von unseren Usern und beantworten so gut wie alle Anfragen.\r\nTrotz unserer klaren Vision steht die Planung der Features immer unter dem Standpunkt: „Make something people want“.",
                "image": ""
            }, {
                "nameCode": "START_UP",
                "headline": "Hands-On",
                "description": "In einem Startup zu arbeiten, das bedeutet manchmal, dass vielleicht noch nicht alles perfekt und rund läuft. Gleichzeitig ist es aber auch eine Chance auf echte Mitbestimmung in einem kleinen, aber wachsendem Team. In unserem Team haben fast alle Gründer auch einen technischen Hintergrund und ein tiefgreifendes Verständnis der Entwickler-Szene. Sie haben das Unternehmen als Freunde gegründet und möchten weiterhin in einem freundschaftlichen Team mit regelmäßigen Team-Events und flachen Hierarchien arbeiten. Es gibt keine Einteilung in Abteilungen, wir leben nach der „Hands-On“-Mentalität - jeder packt mit an! In unserem Team ist man von Anfang an dabei und kann Verantwortung übernehmen, ohne Angst vor Fehlern haben zu müssen. Durch unsere große Aufgabenvielfalt ist jeder Tag anders und wertvolle Erfahrungen sind schnell gesammelt.",
                "image": ""
            }, {
                "nameCode": "B2B",
                "headline": "B2B",
                "description": "EntwicklerHeld würde nicht funktionieren, hätten wir nicht so spannende Unternehmen auf der Plattform. Passende Software-Entwickler zu finden, ist nicht so leicht. Durch unsere Plattform wird es Unternehmen leicht gemacht, Entwickler in ihrer Region und in unternehmensspezifischen Frameworks zu testen und so herauszufinden, wie viel Erfahrungen ein Entwickler bereits hat. Dies ist eine neue und innovative Möglichkeit für Unternehmen, passende Entwickler zu finden, aber auch ihre Sichtbarkeit unter Entwicklern zu erhöhen. Unternehmen sponsern daher Coding-Challenges und finden dadurch passende Entwickler für ihr Team.",
                "image": ""
            }, {
                "nameCode": "PRODUCT_DRIVEN",
                "headline": "Product Driven",
                "description": "Unsere Plattform ist unser Baby. Seit Anfang an gibt es eine starke, klar kommunizierte Vision vom Produkt, an der wir immer weiter feilen und arbeiten. Jeder im Team lebt für diese Idee, jeder steht hinter unserem Produkt. Und jeder im Team legt seinen Fokus auf das Produkt, um eine wunderbare Plattform zu kreieren, die unsere User lieben. Unsere Vision ist es, die EntwicklerHeld Plattform zu DER Spielwiese für Softwareentwickler zu machen. Dafür sitzen wir jeden Tag an der Planung neuer Features und deren Umsetzung sowie neuen herausfordernden Challenges und Bugfixes. Die Plattform jeden Tag ein Stückchen zu verbessern, das ist unser Ziel.",
                "image": ""
            }],
            "valueChoices": [{
                "nameCode": "AGILE_METHODS",
                "name": "Agile Arbeitsmethoden",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/AGILE_METHODS.png"
            }, {
                "nameCode": "APPRENTICESHIPS",
                "name": "Ausbildungsplätze",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/APPRENTICESHIPS.png"
            }, {
                "nameCode": "B2B",
                "name": "B2B",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/B2B.png"
            }, {
                "nameCode": "B2C",
                "name": "B2C",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/B2C.png"
            }, {
                "nameCode": "COMMUNITY_SUPPORT",
                "name": "Engagement für Communities",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/COMMUNITY_SUPPORT.png"
            }, {
                "nameCode": "CONTINUOUS_DELIVERY",
                "name": "Continuous Delivery",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/CONTINUOUS_DELIVERY.png"
            }, {
                "nameCode": "CUSTOMER_FIRST",
                "name": "Der Kunde ist König",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/CUSTOMER_FIRST.png"
            }, {
                "nameCode": "DATA_DRIVEN",
                "name": "Data Driven",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/DATA_DRIVEN.png"
            }, {
                "nameCode": "DESIGN_DRIVEN",
                "name": "Design Driven",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/DESIGN_DRIVEN.png"
            }, {
                "nameCode": "DIVERSITY",
                "name": "Diversität",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/DIVERSITY.png"
            }, {
                "nameCode": "EFFECTIVE_MEETING_CULTURE",
                "name": "Effektive Meetingkultur",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/EFFECTIVE_MEETING_CULTURE.png"
            }, {
                "nameCode": "ENGINEERING_DRIVEN",
                "name": "Engineering Driven",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/ENGINEERING_DRIVEN.png"
            }, {
                "nameCode": "FAILURE_CULTURE",
                "name": "Konstruktive Fehlerkultur",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/FAILURE_CULTURE.png"
            }, {
                "nameCode": "FAMILY_FRIENDLY",
                "name": "Familienfreundlichkeit",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/FAMILY_FRIENDLY.png"
            }, {
                "nameCode": "FEEDBACK",
                "name": "Feedbackkultur",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/FEEDBACK.png"
            }, {
                "nameCode": "FLAT_HIERARCHIES",
                "name": "Flache Hierarchien",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/FLAT_HIERARCHIES.png"
            }, {
                "nameCode": "FLEXIBLE_WORKING_ENV",
                "name": "Flexible Arbeitsumgebung",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/FLEXIBLE_WORKING_ENV.png"
            }, {
                "nameCode": "HEART_FOR_JUNIOR_DEVS",
                "name": "Herz für Juniorentwickler",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/HEART_FOR_JUNIOR_DEVS.png"
            }, {
                "nameCode": "HIGH_QUALITY_CODEBASE",
                "name": "Hochqualitative Codebase",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/HIGH_QUALITY_CODEBASE.png"
            }, {
                "nameCode": "INCLUSION",
                "name": "Inklusion",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/INCLUSION.png"
            }, {
                "nameCode": "MODERN_OFFICES",
                "name": "Moderne Arbeitsplätze",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/MODERN_OFFICES.png"
            }, {
                "nameCode": "OPEN_COMMUNICATION",
                "name": "Transparente Kommunikation",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/OPEN_COMMUNICATION.png"
            }, {
                "nameCode": "OPEN_SOURCE_CONTRIBUTOR",
                "name": "Open Source Contributor",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/OPEN_SOURCE_CONTRIBUTOR.png"
            }, {
                "nameCode": "PAIR_PROGRAMMING",
                "name": "Pair Programming",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/PAIR_PROGRAMMING.png"
            }, {
                "nameCode": "PERSONAL_DEVELOPMENT",
                "name": "Persönliche Weiterentwicklung",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/PERSONAL_DEVELOPMENT.png"
            }, {
                "nameCode": "PRODUCT_DRIVEN",
                "name": "Product Driven",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/PRODUCT_DRIVEN.png"
            }, {
                "nameCode": "SELF_FUNDED",
                "name": "Eigenfinanziert",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/SELF_FUNDED.png"
            }, {
                "nameCode": "START_UP",
                "name": "Startup",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/START_UP.png"
            }, {
                "nameCode": "TEAM_EVENTS",
                "name": "Team Events",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/TEAM_EVENTS.png"
            }, {
                "nameCode": "TEAM_LUNCH",
                "name": "Gemeinsames Mittagessen",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/TEAM_LUNCH.png"
            }, {
                "nameCode": "TEAM_ORIENTATION",
                "name": "Teamorientierung",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/TEAM_ORIENTATION.png"
            }, {
                "nameCode": "TECHNICAL_BACKGROUND",
                "name": "Gründer mit technischem Hintergrund",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/TECHNICAL_BACKGROUND.png"
            }, {
                "nameCode": "TEST_DRIVEN",
                "name": "Test Driven",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/TEST_DRIVEN.png"
            }, {
                "nameCode": "TRAININGS",
                "name": "Fortbildung",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/TRAININGS.png"
            }, {
                "nameCode": "WORK_LIFE_BALANCE",
                "name": "Work-Life-Balance",
                "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/WORK_LIFE_BALANCE.png"
            }],
            "locations": [{
                "address": "Tübinger Str. 10 (RoboLAB)",
                "zipCode": "01189",
                "city": "Dresden",
                "country": "Deutschland",
                "latitude": 51.01193,
                "longitude": 13.70157
            },{
                "address": "Diesterwegstraße 8",
                "zipCode": "06128",
                "city": "Halle",
                "country": "Deutschland",
                "latitude": 51.458290,
                "longitude": 11.951500
            },{
                "address": "Willy-Brandt-Platz (Leipzig Hbf)",
                "zipCode": "04109",
                "city": "Leipzig",
                "country": "Deutschland",
                "latitude": 51.344109,
                "longitude": 12.383280
            }],
            "technologies": [
                {
                    "id": 1,
                    "name": "Django",
                    "label": "Django (Python)",
                    "logo": "https://production-company-media-storage.s3.amazonaws.com/django.png",
                    "language": "Python"
                },
                {
                    "id": 2,
                    "name": "Kubernetes",
                    "label": "Kubernetes",
                    "logo": "https://production-company-media-storage.s3.amazonaws.com/js-logo.png",
                    "language": ""
                },
                {
                    "id": 3,
                    "name": "Spring Boot",
                    "label": "Spring Boot (Java)",
                    "language": "Java"
                },
                {
                    "id": 4,
                    "name": "Angular",
                    "label": "Angular (JavaScript)",
                    "language": "JavaScript"
                },
                {
                    "id": 5,
                    "name": "SAP S/4 HANA",
                    "label": "SAP S/4 HANA (ABAP)",
                    "language": "ABAP"
                }
            ],
            "socialMediaLinks": [{
                "platform": "facebook",
                "link": "https://www.facebook.com/EntwicklerHeld"
            }, {"platform": "twitter", "link": "https://twitter.com/EntwicklerHeld"}, {
                "platform": "instagram",
                "link": "https://www.instagram.com/entwicklerheld"
            }, {"platform": "website", "link": "https://entwicklerheld.de"}, {
                "platform": "xing",
                "link": "https://www.xing.com/companies/entwicklerheldgmbh"
            }, {"platform": "linkedin", "link": "https://www.linkedin.com/company/entwicklerheld/"
            }, {"platform": "spotify", "link": "https://open.spotify.com/show/2doGAbH8hfnuOYSWCtZRJh"
            }, {"platform": "kununu", "link": "https://www.kununu.com/de/entwicklerheld"
            }, {"platform": "youtube", "link": "https://www.youtube.com/@entwicklerheld3626"},
            ],
            "socialMediaChoices": [{"platform": "facebook", "label": "Facebook"}, {
                "platform": "twitter",
                "label": "Twitter"
            }, {"platform": "github", "label": "GitHub"}, {
                "platform": "instagram",
                "label": "Instagram"
            }, {"platform": "xing", "label": "Xing"}, {
                "platform": "linkedin",
                "label": "LinkedIn"
            }, {"platform": "website", "label": "Website"}, {"platform": "spotify", "label": "Spotify"}, {"platform": "kununu", "label": "Kununu"}, , {"platform": "youtube", "label": "YouTube"}],
            "statistics": [{"nameCode": "PETS", "value": "8"}, {
                "nameCode": "USERS",
                "value": "17.000"
            }, {"nameCode": "AVERAGE_AGE", "value": "30"}, {
                "nameCode": "EMPLOYEES",
                "value": "10"
            }, {"nameCode": "DAILY_COFFEE", "value": "18"}, {"nameCode": "DAILY_EMOJIS", "value": "24"}],
            "statisticChoices": [{
                "nameCode": "AVERAGE_AGE",
                "name": "Durchschnittsalter",
                "icon": "birthday-cake"
            }, {
                "nameCode": "AVERAGE_CODE_COVERAGE",
                "name": "Code Coverage",
                "icon": "check-circle"
            }, {
                "nameCode": "AVERAGE_TEAM_SIZE",
                "name": "Durchschnittliche Teamgröße",
                "icon": "arrows-alt"
            }, {
                "nameCode": "CUSTOMERS",
                "name": "Glückliche Kunden",
                "icon": "thumbs-o-up"
            }, {
                "nameCode": "DAILY_COFFEE",
                "name": "Tassen Kaffee pro Tag",
                "icon": "coffee"
            }, {
                "nameCode": "DAILY_COMMITS",
                "name": "Commits pro Tag",
                "icon": "code-fork"
            }, {
                "nameCode": "DAILY_EMOJIS",
                "name": "Benutzte Emojis pro Tag",
                "icon": "smile-o"
            }, {
                "nameCode": "EMPLOYEES",
                "name": "Mitarbeiter",
                "icon": "sitemap"
            }, {
                "nameCode": "FINISHED_PROJECTS",
                "name": "Abgeschlossene Projekte",
                "icon": "trophy"
            }, {
                "nameCode": "INTERNSHIPS",
                "name": "Ausbildungsplätze",
                "icon": "graduation-cap"
            }, {
                "nameCode": "JUNIOR_DEVELOPERS",
                "name": "Junior-Entwickler",
                "icon": "child"
            }, {
                "nameCode": "LINES_OF_CODE",
                "name": "Lines of Code",
                "icon": "file-text-o"
            }, {"nameCode": "LINUX_USER", "name": "Linux-Nutzer in %", "icon": "linux"}, {
                "nameCode": "MAC_USER",
                "name": "MAC-Nutzer in %",
                "icon": "apple"
            }, {"nameCode": "PETS", "name": "Haustiere", "icon": "paw"}, {
                "nameCode": "PROGRAMMING_LANGUAGES",
                "name": "Verschiedene Programmiersprachen",
                "icon": "keyboard-o"
            }, {
                "nameCode": "SPOKEN_LANGUAGES",
                "name": "Gesprochene Sprachen",
                "icon": "language"
            }, {
                "nameCode": "SUPPORTED_COMMUNITIES",
                "name": "Unterstützte Communities",
                "icon": "heart-o"
            }, {"nameCode": "USERS", "name": "Zufriedene Nutzer", "icon": "users"}, {
                "nameCode": "WINDOWS_USER",
                "name": "Windows-Nutzer in %",
                "icon": "windows"
            }, {
                "nameCode": "YEARLY_CHOCOLATE",
                "name": "Schokoladenkonsum pro Jahr in kg",
                "icon": "th"
            }, {
                "nameCode": "YEARLY_CONFERENCES",
                "name": "Besuchte Konferenzen pro Jahr",
                "icon": "slideshare"
            }, {"nameCode": "YEARLY_TEAM_EVENTS", "name": "Teamevents pro Jahr", "icon": "music"}],
            "maxUploadSizeImage": 20971520,
            "maxUploadSizeVideo": 300000000,
            "importSource": "",
            "logoColor": "#FFD022",
        };

        return new MockResponse().respond(data);
    }

    getVacancyList(
        pageID = 1,
        search,
        hasChallenge,
        hasRemote,
        technologies,
        languages,
        latitude,
        longitude,
        searchRadius,
        ratingIndex,
        sortIndex
    ) {
        let result = new VacancyListResult();
        setTimeout(function () {
            result.apiData = [
                {
                    "vacancyDatePosted": "2020-05-10",
                    "vacancyId": "3d8c8ab2-513d-4d58-a56a-763454292fb5",
                    "vacancyTitle": "Software Engineer (m/w/d)",
                    "vacancySlug": "software-engineer-m-w-d",
                    "vacancyRemoteJob": true,
                    "vacancySalaryMinimum": 10000.0,
                    "vacancySalaryMaximum": 30000.0,
                    "vacancySalaryCurrency": "CHF",
                    "vacancyEmploymentType": "Vollzeit",
                    "vacanyTechnologies": [
                        {
                        "id": 1,
                        "name": "Django",
                        "label": "Django (Python)",
                        "language": "Python"
                        },
                        {"id": 2, "name": "Spring Boot", "label": "Spring Boot (Java)", "language": "Java"}
                    ],
                    "vacancyLocations": [
                        {"lat": 51.025678, "lng": 13.72232, "city": "Dresden"},
                        {"lat": 51.025678, "lng": 13.72232, "city": "Berlin"},
                        {"lat": 51.025678, "lng": 13.72232, "city": "Hamburg"},
                        {"lat": 51.025678, "lng": 13.72232, "city": "Chemnitz"}
                    ],
                    "companyId": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                    "companyName": "EntwicklerHeld GmbH",
                    "companySlug": "entwicklerheld-gmbh",
                    "companyLogo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                    },
                    "companyHeroImage": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                    },
                    "companyLogoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                    },
                    "companyIsCustomer": true,
                    "companyIsFreeCustomer": false,
                    "companyStatisticsUpsale":true,
                    "companyValuesUpsale":false,
                    "companyVideoUpsale":false,
                    "vacancySource": "stepstone",
                    "companyLogoColor": "#B00AF3",
                    "sponsoredChallengeIds": [
                        'e918f361-abe7-4187-a89a-8be94bd4f64b',
                        '9c778212-5cf0-4b5d-b2b5-49bea7eb1d53',
                        'e6458744-d7bf-4fa1-8689-ef1643937339',
                    ],
                    "vacancyShortLinkCode": "eh-full-stack"
                },
                {
                    "vacancyDatePosted": "2020-06-10",
                    "vacancyId": "3d8c8ab2-513d-4d58-a56a-763454292fb0",
                    "vacancyTitle": "Product owner (m/w/d)",
                    "vacancySlug": "product-owner-m-w-d",
                    "vacancyRemoteJob": true,
                    "vacancySalaryMinimum": 50000.0,
                    "vacancySalaryMaximum": 80000.0,
                    "vacancySalaryCurrency": "EUR",
                    "vacancyEmploymentType": "Freelance",
                    "vacanyTechnologies": [],
                    "vacancyLocations": [
                        {"lat": 51.025678, "lng": 13.72232, "city": "Dresden"},
                        {"lat": 51.025678, "lng": 13.72232, "city": "Berlin"},
                        {"lat": 51.025678, "lng": 13.72232, "city": "Hamburg"},
                        {"lat": 51.025678, "lng": 13.72232, "city": "Chemnitz"}
                    ],
                    "companyId": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                    "companyName": "EntwicklerHeld GmbH",
                    "companySlug": "queo",
                    "companyLogo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                    },
                    "companyHeroImage": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                    },
                    "companyLogoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                    },
                    "companyIsCustomer": true,
                    "companyIsFreeCustomer": false,
                    "companyStatisticsUpsale":true,
                    "companyValuesUpsale":false,
                    "companyVideoUpsale":false,
                    "vacancySource": "",
                    "companyLogoColor": "",
                    "sponsoredChallengeIds": [
                        'e918f361-abe7-4187-a89a-8be94bd4f64b',
                        '9c778212-5cf0-4b5d-b2b5-49bea7eb1d53',
                        'e6458744-d7bf-4fa1-8689-ef1643937339',
                        '58c45208-c9cc-474e-94d4-ced187b077e0',
                    ],
                    "vacancyShortLinkCode": "eh-full-stack"
                },
                {
                    "vacancyDatePosted": "2020-08-10",
                    "vacancyId": "3d8c8ab2-513d-4d58-a56a-763454292fb2",
                    "vacancyTitle": "Software Tester (m/w/d)",
                    "vacancySlug": "software-tester-m-w-d",
                    "vacancyRemoteJob": true,
                    "vacancyEmploymentType": "Vollzeit",
                    "vacanyTechnologies": [
                        {
                        "id": 1,
                        "name": "Django",
                        "label": "Django (Python)",
                        "language": "Python"
                        },
                        {"id": 2, "name": "Spring Boot", "label": "Spring Boot (Java)", "language": "Java"}
                    ],
                    "vacancyLocations": [
                        {"lat": 51.025678, "lng": 13.72232, "city": "Dresden"},
                    ],
                    "companyId": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                    "companyName": "EntwicklerHeld GmbH",
                    "companySlug": "entwicklerheld-gmbh",
                    "companyLogo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                    },
                    "companyHeroImage": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                    },
                    "companyLogoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                    },
                    "companyIsCustomer": true,
                    "companyIsFreeCustomer": false,
                    "companyStatisticsUpsale":true,
                    "companyValuesUpsale":true,
                    "companyVideoUpsale":false,
                    "vacancySource": "",
                    "companyLogoColor": "",
                    "vacancyShortLinkCode": "eh-full-stack"
                },
                {
                    "vacancyDatePosted": "2020-07-10",
                    "vacancyId": "3d8c8ab2-513d-4d58-a56a-763454292fb1",
                    "vacancyTitle": "Software Architect (m/w/d)",
                    "vacancySlug": "software-architect-m-w-d",
                    "vacancyRemoteJob": false,
                    "vacancyEmploymentType": "Vollzeit",
                    "vacanyTechnologies": [
                        {
                        "id": 1,
                        "name": "Django",
                        "label": "Django (Python)",
                        "language": "Python"
                        },
                        {"id": 2, "name": "Spring Boot", "label": "Spring Boot (Java)", "language": "Java"}
                    ],
                    "vacancyLocations": [
                        {"lat": 51.025678, "lng": 13.72232, "city": "Dresden"},
                        {"lat": 51.025678, "lng": 13.72232, "city": "Berlin"},
                    ],
                    "companyId": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                    "companyName": "EntwicklerHeld GmbH",
                    "companySlug": "entwicklerheld-gmbh",
                    "companyLogo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                    },
                    "companyHeroImage": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/local/entwicklerheld-gmbh-01de5a59-dac7-47a8-b35b-fc695ff006e0.jpg"
                    },
                    "companyLogoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                    },
                    "companyIsCustomer": true,
                    "companyIsFreeCustomer": false,
                    "companyStatisticsUpsale":true,
                    "companyValuesUpsale":false,
                    "companyVideoUpsale":false,
                    "vacancySource": "",
                    "companyLogoColor": "blue",
                },
            ];
            result.pagination = {"pageID": pageID, "numPages": "3", "totalCount": 200};
            if (sortIndex === 1) {
                result.apiData.sort(function(job1, job2){
                    return job1["vacancyDatePosted"].localeCompare(job2["vacancyDatePosted"]);
                });
            }
            else {
                result.apiData.sort(function(job1, job2){
                    return job2["vacancyDatePosted"].localeCompare(job1["vacancyDatePosted"]);
                });
            }
            if (hasChallenge) {
                result.apiData = result.apiData.filter(
                    (data) => data.sponsoredChallengeIds && data.sponsoredChallengeIds.length !== 0
                );
            }
            result.loaded = true;
        }, 100);
        return result;
    }

    getCompanyVacanciesByLocations(
        search,
        hasChallenge,
        hasRemote,
        technologies,
        languages,
        latitude,
        longitude,
        searchRadius,
        ratingIndex,
        sortIndex
    ) {
        let result = new VacancyListResult();
        setTimeout(function () {
            result.apiData = [
              {
                "companyId": "79542349-bba2-430f-a0ad-63bf75b8869c",
                "companyName": "Neuschäfer Bachmann AG & Co. KG",
                "companySlug": "neuschafer-bachmann-ag-co-kg",
                "companyLogo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                },
                "companyHeroImage": "",
                "companyLogoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                },
                "companyIsCustomer": true,
                "companyIsFreeCustomer": false,
                "companyStatisticsUpsale": false,
                "companyValuesUpsale": false,
                "companyVideoUpsale": false,
                "companyLogoColor": "",
                "vacancyLocations": [
                  {
                    "location": {
                      "lat": 51.4416,
                      "lng": 13.92859,
                      "zipCode": "74725",
                      "city": "Eilenburg",
                      "country": "Deutschland",
                      "address": "Wilmsengasse 157"
                    },
                    "technologies": [
                      {
                        "id": 11,
                        "name": "Kubernetes",
                        "label": "Kubernetes (GoLang)",
                        "language": "GoLang",
                        "logo": ""
                      },
                      {
                        "id": 2,
                        "name": "Spring Boot",
                        "label": "Spring Boot (Java)",
                        "language": "Java",
                        "logo": ""
                      },
                      {
                        "id": 6,
                        "name": "Vue.js",
                        "label": "Vue.js (JavaScript)",
                        "language": "JavaScript",
                        "logo": ""
                      }
                    ],
                    "count": 3
                  }
                ]
              },
              {
                "companyId": "e64e32be-493a-44e2-9468-b0c46e777dc7",
                "companyName": "Hamann GmbH",
                "companySlug": "hamann-gmbh",
                "companyLogo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                },
                "companyHeroImage": "",
                "companyLogoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                },
                "companyIsCustomer": true,
                "companyIsFreeCustomer": false,
                "companyStatisticsUpsale": false,
                "companyValuesUpsale": false,
                "companyVideoUpsale": false,
                "companyLogoColor": "",
                "vacancyLocations": [
                  {
                    "location": {
                      "lat": 51.980599999999995,
                      "lng": 14.46959,
                      "zipCode": "59188",
                      "city": "Eckernförde",
                      "country": "Deutschland",
                      "address": "Heinstr. 9/7"
                    },
                    "technologies": [
                      {
                        "id": 9,
                        "name": "Ansible",
                        "label": "Ansible (Python)",
                        "language": "Python",
                        "logo": ""
                      },
                      {
                        "id": 2,
                        "name": "Spring Boot",
                        "label": "Spring Boot (Java)",
                        "language": "Java",
                        "logo": ""
                      }
                    ],
                    "count": 2
                  }
                ]
              },
              {
                "companyId": "0d3f5fd8-c481-45ae-b900-971540912c9e",
                "companyName": "Wulff",
                "companySlug": "wulff",
                "companyLogo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                },
                "companyHeroImage": "",
                "companyLogoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                },
                "companyIsCustomer": true,
                "companyIsFreeCustomer": false,
                "companyStatisticsUpsale": false,
                "companyValuesUpsale": false,
                "companyVideoUpsale": false,
                "companyLogoColor": "",
                "vacancyLocations": [
                  {
                    "location": {
                      "lat": 51.2416,
                      "lng": 14.686589999999999,
                      "zipCode": "91524",
                      "city": "Sonneberg",
                      "country": "Deutschland",
                      "address": "Truppallee 42"
                    },
                    "technologies": [
                      {
                        "id": 3,
                        "name": "Angular",
                        "label": "Angular (JavaScript)",
                        "language": "JavaScript",
                        "logo": ""
                      },
                      {
                        "id": 11,
                        "name": "Kubernetes",
                        "label": "Kubernetes (GoLang)",
                        "language": "GoLang",
                        "logo": ""
                      },
                      {
                        "id": 10,
                        "name": "Typo3",
                        "label": "Typo3 (PHP)",
                        "language": "PHP",
                        "logo": ""
                      }
                    ],
                    "count": 1
                  }
                ]
              },
              {
                "companyId": "d7e81199-85e3-4030-b2ba-929137e419f9",
                "companyName": "Nerger",
                "companySlug": "nerger",
                "companyLogo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                },
                "companyHeroImage": "",
                "companyLogoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                },
                "companyIsCustomer": true,
                "companyIsFreeCustomer": false,
                "companyStatisticsUpsale": false,
                "companyValuesUpsale": false,
                "companyVideoUpsale": false,
                "companyLogoColor": "",
                "vacancyLocations": [
                  {
                    "location": {
                      "lat": 51.7446,
                      "lng": 13.96659,
                      "zipCode": "73116",
                      "city": "Zeulenroda",
                      "country": "Deutschland",
                      "address": "Ebertring 45"
                    },
                    "technologies": [
                      {
                        "id": 4,
                        "name": "Open UI 5",
                        "label": "Open UI 5 (JavaScript)",
                        "language": "JavaScript",
                        "logo": ""
                      },
                      {
                        "id": 5,
                        "name": "React",
                        "label": "React (JavaScript)",
                        "language": "JavaScript",
                        "logo": ""
                      }
                    ],
                    "count": 1
                  }
                ]
              },
              {
                "companyId": "fe706635-0bd9-4d42-b554-19a0f991f034",
                "companyName": "Stroh Fliegner Stiftung & Co. KGaA",
                "companySlug": "stroh-fliegner-stiftung-co-kgaa",
                "companyLogo": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                },
                "companyHeroImage": "",
                "companyLogoIcon": {
                        "baseUrl": "https://res.cloudinary.com/dspi5zyaa/image/upload",
                        "url": "eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png"
                },
                "companyIsCustomer": true,
                "companyIsFreeCustomer": false,
                "companyStatisticsUpsale": false,
                "companyValuesUpsale": false,
                "companyVideoUpsale": false,
                "companyLogoColor": "",
                "vacancyLocations": [
                  {
                    "location": {
                      "lat": 51.2846,
                      "lng": 13.86459,
                      "zipCode": "01421",
                      "city": "Gardelegen",
                      "country": "Deutschland",
                      "address": "Weißplatz 8"
                    },
                    "technologies": [
                      {
                        "id": 1,
                        "name": "Django",
                        "label": "Django (python)",
                        "language": "python",
                        "logo": ""
                      },
                      {
                        "id": 2,
                        "name": "Spring Boot",
                        "label": "Spring Boot (Java)",
                        "language": "Java",
                        "logo": ""
                      }
                    ],
                    "count": 1
                  }
                ]
              }
            ];
        result.loaded = true;
        }, 1000 + Math.random() * 1000);
        return result;
    }

    getVacancies(companyId, pageId = 1, jobsPerPage=6) {
        let result = new VacanciesResult();
        setTimeout(function () {
            result.apiData = [
                {
                    "id": "3d8c8ab2-513d-4d58-a56a-763454292fb0",
                    "title": "Junior-Developer",
                    "employmentType": "Vollzeit",
                    "onSiteLocations": ["Dresden", "Leipzig"],
                    "isRemoteJob": false,
                    "technologies": [
                        {
                            "id": "3d8c8ab2-513d-4d58-a56a-763454292fb0",
                            "name": "Django",
                            "language": "Python",
                            "label": "Django (Python)",
                        },
                    ],
                    "vacancyShortLinkCode": "eh-full-stack"
                },
                {
                    "id": "456c8ab2-513d-4d58-a56a-763454292000",
                    "title": "Senior-Developer",
                    "slug": "senior-developer",
                    "employmentType": "Vollzeit",
                    "onSiteLocations": ["Dresden"],
                    "isRemoteJob": false,
                    "technologies": [
                        {
                            "id": "3d8c8ab2-513d-4d58-a56a-763454292fb0",
                            "name": "Django",
                            "language": "Python",
                            "label": "Django (Python)",
                        },
                        {
                            "id": "f02aaf03-5154-4e8e-997d-80685a812866",
                            "name": "Flask",
                            "language": "Python",
                            "label": "Flask (Python)",
                        },
                    ],
                    "vacancyShortLinkCode": "eh-full-stack"
                },
                {
                    "id": "11c8ab2-513d-4d58-a56a-763454292333",
                    "title": "Senior-Developer",
                    "slug": "senior-developer",
                    "employmentType": "Teilzeit",
                    "onSiteLocations": ["Leipzig"],
                    "isRemoteJob": false,
                    "technologies": [
                        {
                            "id": "2d465394-720f-4d90-bf8b-4e788707e214",
                            "name": "Spring Boot",
                            "language": "Java",
                            "label": "Spring Boot (Java)",
                        },
                        {
                            "id": "78465394-720f-4d90-bf8b-4e788707e298",
                            "name": "",
                            "language": "JavaScript",
                            "label": "JavaScript",
                        },
                    ],
                    "vacancyShortLinkCode": "eh-full-stack"
                },
                {
                    "id": "3d8c8ab2-513d-4d58-a56a-763454292fb0",
                    "title": "Junior-Developer",
                    "employmentType": "Vollzeit",
                    "onSiteLocations": ["Dresden", "Leipzig"],
                    "isRemoteJob": false,
                    "technologies": [
                        {
                            "id": "3d8c8ab2-513d-4d58-a56a-763454292fb0",
                            "name": "Django",
                            "language": "Python",
                            "label": "Django (Python)",
                        },
                    ],
                    "vacancyShortLinkCode": "eh-full-stack"
                },
                {
                    "id": "456c8ab2-513d-4d58-a56a-763454292000",
                    "title": "Senior-Developer",
                    "slug": "senior-developer",
                    "employmentType": "Vollzeit",
                    "onSiteLocations": ["Dresden"],
                    "isRemoteJob": false,
                    "technologies": [
                        {
                            "id": "3d8c8ab2-513d-4d58-a56a-763454292fb0",
                            "name": "Django",
                            "language": "Python",
                            "label": "Django (Python)",
                        },
                        {
                            "id": "f02aaf03-5154-4e8e-997d-80685a812866",
                            "name": "Flask",
                            "language": "Python",
                            "label": "Flask (Python)",
                        },
                    ],
                    "vacancyShortLinkCode": "eh-full-stack"
                },
                {
                    "id": "11c8ab2-513d-4d58-a56a-763454292333",
                    "title": "Senior-Developer",
                    "slug": "senior-developer",
                    "employmentType": "Teilzeit",
                    "onSiteLocations": ["Leipzig"],
                    "isRemoteJob": false,
                    "technologies": [
                        {
                            "id": "2d465394-720f-4d90-bf8b-4e788707e214",
                            "name": "Spring Boot",
                            "language": "Java",
                            "label": "Spring Boot (Java)",
                        },
                        {
                            "id": "78465394-720f-4d90-bf8b-4e788707e298",
                            "name": "",
                            "language": "JavaScript",
                            "label": "JavaScript",
                        },
                    ],
                    "vacancyShortLinkCode": "eh-full-stack"
                },
            ];
            result.loaded = true;
            result.pagination = {"pageID": pageId, "numPages": 2, "totalCount": 9};
        }, 5000);
        return result;
    }

    getVacancy(vacancySlugOrId) {
        let data = {
            "vacancyId": "vacancy-1123",
            "@context": "https://schema.org/",
            "@type": "JobPosting",
            "datePosted": "2020-01-27",
            "title": "Junior Full Stack Developer (m/w/d)",
            "description": "Vorbereitung und Durchführung von Maßnahmen der operativen Einsatz- und Ermittlungsunterstützung einschließlich<br><ul><li>der Maßnahmen der Telekommunikationsüberwachung / informationstechnischen Überwachung der Entwicklung von Softwarekomponenten und von Methoden zur Lösung von informationstechnischen Problemstellungen</li><li>der Analyse von Kommunikations-/ Netzwerkdaten</li><li>der Betreuung der jeweiligen Fachanwendungen sowie der Durchführung von Systemkontrollen und Tests</li></ul>",
            "technologies": [
                {
                    "id": 1,
                    "name": "",
                    "label": "Java",
                    "programmingLanguage": "Java",
                },
                {
                    "id": 2,
                    "name": "",
                    "label": "Django (Python)",
                    "programmingLanguage": "Python",
                },
                {
                    "id": 3,
                    "name": "",
                    "label": "JavaScript",
                    "programmingLanguage": "JavaScript",
                },
                {
                    "id": 4,
                    "name": "",
                    "label": "Spring (Java)",
                    "programmingLanguage": "Java",
                },
            ],
            "baseSalary": {
                "@type": "MonetaryAmount",
                "currency": "CHF",
                "value": {
                    "@type": "QuantitativeValue",
                    "minValue": 10000.0,
                    "maxValue": 30000.0,
                    "unitText": "YEAR",
                },
            },
            "employmentType": "FULL_TIME",
            "validThrough": "2020-03-31",
            "hiringOrganization": {
                "@type": "Organization",
                "name": "EntwicklerHeld GmbH",
                "sameAs": "https://example.org",
                "logo": "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_technica-engineering/TechnicaEngineering_Logo_4C-Gradient_RGB_300ppi.png",
                "slug": "entwicklerheld-gmbh",
            },
            "jobLocation": [
                {
                    "@type": "Place",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "Tübinger Str 10",
                        "addressLocality": "Dresden",
                        "postalCode": "01189",
                        "addressCountry": "Germany",
                    },
                },
                {
                    "@type": "Place",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "Petersteinweg 14",
                        "addressLocality": "Leipzig",
                        "postalCode": "04107",
                        "addressCountry": "Germany",
                    },
                },
            ],
            "applicantLocationRequirements": {"@type": "Country", "name": "USA"},
            "jobLocationType": "TELECOMMUTE",
            "importSource": "stepstone",
            "logoColor": "#2997D0"
        };
        return new MockResponse().respond(data);
    }

    getVacancyGoogleForJobsJson(vacancyId) {
        let result = new VacancyGoogleForJobsResult();
        setTimeout(function () {
            result.apiData = {
                "@context": "https://schema.org/",
                "@type": "JobPosting",
                "datePosted": "2020-01-27",
                "title": "Data Analyst (m/w/d)",
                "description": "Vorbereitung und Durchführung von Maßnahmen der operativen Einsatz- und Ermittlungsunterstützung einschließlich<br><ul><li>der Maßnahmen der Telekommunikationsüberwachung / informationstechnischen Überwachung der Entwicklung von Softwarekomponenten und von Methoden zur Lösung von informationstechnischen Problemstellungen</li><li>der Analyse von Kommunikations-/ Netzwerkdaten</li><li>der Betreuung der jeweiligen Fachanwendungen sowie der Durchführung von Systemkontrollen und Tests</li></ul>",
                "baseSalary": {
                    "@type": "MonetaryAmount",
                    "currency": "CHF",
                    "value": {
                        "@type": "QuantitativeValue",
                        "minValue": 10000.0,
                        "maxValue": 30000.0,
                        "unitText": "YEAR",
                    },
                },
                "employmentType": "FULL_TIME",
                "validThrough": "2020-03-31",
                "hiringOrganization": {
                    "@type": "Organization",
                    "name": "EntwicklerHeld GmbH",
                    "sameAs": "https://example.org",
                    "logo": "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_technica-engineering/TechnicaEngineering_Logo_4C-Gradient_RGB_300ppi.png",
                },
                "jobLocation": [
                    {
                        "@type": "Place",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Tübinger Str 10",
                            "addressLocality": "Dresden",
                            "postalCode": "01189",
                            "addressCountry": "Germany",
                        },
                    },
                    {
                        "@type": "Place",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Petersteinweg 14",
                            "addressLocality": "Leipzig",
                            "postalCode": "04107",
                            "addressCountry": "Germany",
                        },
                    },
                ],
                "applicantLocationRequirements": {"@type": "Country", "name": "USA"},
                "jobLocationType": "TELECOMMUTE",
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    getTechnologyList() {
        let result = new TechnologyListResult();
        setTimeout(function () {
            result.apiData = {
                "technologies": [
                    {
                        "id": "3d8c8ab2-513d-4d58-a56a-763454292fb0",
                        "name": "Django",
                        "language": "Python",
                        "label": "Django (Python)",
                    },
                    {
                        "id": "f02aaf03-5154-4e8e-997d-80685a812866",
                        "name": "Flask",
                        "language": "Python",
                        "label": "Flask (Python)",
                    },
                    {
                        "id": "2d465394-720f-4d90-bf8b-4e788707e214",
                        "name": "Spring Boot",
                        "language": "Java",
                        "label": "Spring Boot (Java)",
                    },
                ],
                "programmingLanguages": ["Java", "JavaScript", "Python", "Ada", "Haskell", "C", "C++", "C#", "Cobol"],
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    getVacanciesTechnologyList() {
        let result = new VacanciesTechnologyListResult();
        setTimeout(function () {
            result.apiData = {
                "technologies": [
                    {
                        "id": "3d8c8ab2-513d-4d58-a56a-763454292fb0",
                        "name": "Django",
                        "language": "Python",
                        "label": "Django (Python)",
                    },
                    {
                        "id": "f02aaf03-5154-4e8e-997d-80685a812866",
                        "name": "Flask",
                        "language": "Python",
                        "label": "Flask (Python)",
                    },
                    {
                        "id": "2d465394-720f-4d90-bf8b-4e788707e214",
                        "name": "Spring Boot",
                        "language": "Java",
                        "label": "Spring Boot (Java)",
                    },
                ],
                "programmingLanguages": ["Java", "JavaScript", "Python", "Ada", "Haskell", "C", "C++", "C#", "Cobol"],
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    getActiveValues() {
        let result = new ActiveValuesResult();
        setTimeout(function () {
            result.apiData = [
                {
                    "nameCode": "AGILE_METHODS",
                    "label": "Agile Arbeitsmethoden",
                    "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/AGILE_METHODS.png"
                },
                {
                    "nameCode": "APPRENTICESHIPS",
                    "label": "Ausbildungsplätze",
                    "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/APPRENTICESHIPS.png"
                },
                {
                    "nameCode": "COMMUNITY_SUPPORT",
                    "label": "Engagement für Communities",
                    "icon": "https://res.cloudinary.com/dspi5zyaa/image/upload/eh-platform/company_static/profile_icons/COMMUNITY_SUPPORT.png"
                }
            ];
            result.loaded = true;
        }, 100);
        return result;
    }

    getVacancyRating(vacancyId) {
        let result = new VacancyRatingResult();
        setTimeout(function () {
            result.apiData = {
                // set empty ratingId to see sliders
                "ratingId": "6d063e11-b9b1-4827-ba02-77cc86685b96",
                "created": "2021-06-04T16:03:20.243533Z",
                "userProfession": "Student*in, Azubi",
                "userRegion": "Hessen",
                "userHasJobInterest": false,
                "totalRatings": 17,
                "totalComments": 15,
                "overallRating": {
                    "id": "total",
                    "label": {
                        "start": "schlecht",
                        "end": "perfekt",
                    },
                    "userValue": 3,
                    "median": 1,
                },
                "categories": [
                    {
                        "id": "casual_serious_rating",
                        "label": {
                            "start": "locker",
                            "end": "ernst",
                        },
                        "userValue": 2,
                        "median": 2,
                    },
                    {
                        "id": "traditional_modern_rating",
                        "label": {
                            "start": "traditionell",
                            "end": "modern",
                        },
                        "userValue": 1,
                        "median": 3,
                    },
                    {
                        "id": "short_long_rating",
                        "label": {
                            "start": "zu kurz",
                            "end": "zu lang",
                        },
                        "userValue": 2,
                        "median": 4,
                    },
                    {
                        "id": "proven_innovative_rating",
                        "label": {
                            "start": "bewährter Stack",
                            "end": "innovativer Stack",
                        },
                        "userValue": 3,
                        "median": 5,
                    },
                    {
                        "id": "standard_extra_rating",
                        "label": {
                            "start": "gewöhnlich",
                            "end": "ausgefallen",
                        },
                        "userValue": 5,
                        "median": 8,
                    },
                ],
                "tags": [
                    {
                        "id": "tag1",
                        "label": "angemessen",
                        "isSelected": true,
                        "icon": "",
                        "ratingShare": 0.598756,
                    },
                    {
                        "id": "tag2",
                        "label": "klare Anforderungen",
                        "isSelected": false,
                        "icon": "fa fa-thumbs-up",
                        "ratingShare": 0.8,
                    },
                    {
                        "id": "tag3",
                        "label": "Anforderungen unklar",
                        "isSelected": true,
                        "icon": "fa fa-thumbs-down",
                        "ratingShare": 0.2,
                    },
                    {
                        "id": "tag4",
                        "label": "spannend",
                        "isSelected": false,
                        "ratingShare": 0.6,
                    },
                    {
                        "id": "tag5",
                        "label": "transparent",
                        "isSelected": false,
                        "ratingShare": 0.3,
                    },
                    {
                        "id": "tag6",
                        "label": "undurchsichtig",
                        "isSelected": false,
                        "ratingShare": 0.1,
                    },
                    {
                        "id": "tag7",
                        "label": "unattraktiv",
                        "isSelected": false,
                    },
                    {
                        "id": "tag8",
                        "label": "Tech spannend",
                        "isSelected": false,
                    },
                    {
                        "id": "tag9",
                        "label": "Tech veraltet",
                        "isSelected": false,
                    },
                ],
                "comment":  {
                    "isPublic": false,
                    "content": "Toll! Sowas möchte ich auch mal machen!",
                },
            };
            result.loaded = true;
        }, 2500);
        return result;
    }

    postVacancyRating(vacancyId, ratingData) {
        let result = new VacancyRatingResult();
        setTimeout(function () {
            result.apiData = ratingData;
            // set ratingId to see results
            result.apiData.ratingId = "testrating123";
            // set random values on response
            result.apiData.categories.forEach((category) => {
                category.mean = Math.round(Math.random() * 100) % 9 + 1;
            });
            result.apiData.overallRating.mean = Math.round(Math.random() * 100) % 9 + 1;
            result.apiData.tags.forEach((tag) => {
                tag.ratingShare = Math.random() > 0.5 ? Math.random() : 0.0;
            });
            result.loaded = true;
        }, 2500);
        return result;
    }

    getVacancyRatingComments(vacancyId) {
        let result = new VacancyRatingCommentsResult();
        setTimeout(function() {
            result.apiData = [
                {
                    'ratingId': '45aacfd8-f9bd-495b-881f-d66a1afd76fb',
                    'created': '2021-06-04T18:03:26.181805Z',
                    'userProfession': 'Anderes',
                    'userRegion': 'Hessen',
                    'overallRating': 3,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Stop pass low step. Forget vote across way. Again vote movie shoulder third marriage.',
                    },
                },
                {
                    'ratingId': '9be763c4-9aeb-4bc9-84a0-48591369901f',
                    'created': '2021-06-04T18:03:26.233202Z',
                    'userProfession': 'Freelancer',
                    'userRegion': 'Baden-Württemberg',
                    'overallRating': 1,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Street walk war car.',
                    },
                },
                {
                    'ratingId': '388a7cf6-7502-457c-83c9-96cf4e87dcef',
                    'created': '2021-06-04T18:03:26.239600Z',
                    'userProfession': 'Freelancer',
                    'userRegion': 'Brandenburg',
                    'overallRating': 6,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'System how notice. Program president game the. Tough shoulder discussion technology local.',
                    },
                },
                {
                    'ratingId': '3cf01794-7024-45a2-809b-5e9e48c76c64',
                    'created': '2021-06-04T18:03:26.245626Z',
                    'userProfession': 'Anderes',
                    'userRegion': 'Bremen',
                    'overallRating': 5,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Police sister subject on place president. Market institution determine Mr score. Should just business side size onto fact.',
                    },
                },
                {
                    'ratingId': 'b8d2c0be-13a0-41b7-ac2b-887752710b2f',
                    'created': '2021-06-04T18:03:26.251405Z',
                    'userProfession': 'Schüler/in',
                    'userRegion': 'Baden-Württemberg',
                    'overallRating': 2,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Yet purpose anyone try make include. Force late firm study visit. Identify sometimes consider mean.',
                    },
                },
                {
                    'ratingId': 'fbad127d-0d38-44e2-8468-6dec68359e15',
                    'created': '2021-06-04T18:03:26.257264Z',
                    'userProfession': 'Freelancer',
                    'userRegion': 'Baden-Württemberg',
                    'overallRating': 5,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Enter each general this more woman. Recognize more your but.',
                    },
                },
                {
                    'ratingId': 'a8b57c79-9682-43f4-be15-033b21e2d3f8',
                    'created': '2021-06-04T18:03:26.262762Z',
                    'userProfession': 'Absolvent/in',
                    'userRegion': 'Hessen',
                    'overallRating': 5,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Wonder should firm front indeed. Ability cell respond talk performance.',
                    },
                },
                {
                    'ratingId': '8eaf838d-096f-4411-8c8d-7c6b3f85c1be',
                    'created': '2021-06-04T18:03:26.187357Z',
                    'userProfession': 'Junior-Entwickler/in',
                    'userRegion': 'Schweiz',
                    'overallRating': 8,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Cost try in every town bank. Your black thought guess sign feel section.',
                    },
                },
                {
                    'ratingId': '5b792f8a-831e-4b73-b7e8-fd9a49d5c4be',
                    'created': '2021-06-04T18:03:26.192823Z',
                    'userProfession': 'Junior-Entwickler/in',
                    'userRegion': 'Niedersachsen',
                    'overallRating': 5,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Training but make candidate science. Past half sit thousand TV vote land. Still let thing option main matter.',
                    },
                },
                {
                    'ratingId': '97fc591c-8e41-41bf-bc44-d3caae48d7d3',
                    'created': '2021-06-04T18:03:26.197926Z',
                    'userProfession': 'Absolvent/in',
                    'userRegion': 'Andere',
                    'overallRating': 1,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Compare that growth detail customer total white. Hotel yet establish medical piece religious amount add. Back improve ground director write.',
                    },
                },
                {
                    'ratingId': '907d676f-6e6a-45d9-b34c-9dd66a8cb18e',
                    'created': '2021-06-04T18:03:26.204249Z',
                    'userProfession': 'Senior-Entwickler/in',
                    'userRegion': 'Baden-Württemberg',
                    'overallRating': 3,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Paper girl garden either. Become trade address away community front.',
                    },
                },
                {
                    'ratingId': '28689ce0-6665-4e40-9c52-add0e9b7d229',
                    'created': '2021-06-04T18:03:26.210271Z',
                    'userProfession': 'Senior-Entwickler/in',
                    'userRegion': 'Nordrhein-Westfalen',
                    'overallRating': 9,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Cold safe thought sell now work. Top discover man student result our feel.',
                    },
                },
                {
                    'ratingId': '3eba5a8c-3054-49af-ada0-fec8d10d15d2',
                    'created': '2021-06-04T18:03:26.216123Z',
                    'userProfession': 'Schüler/in',
                    'userRegion': 'Berlin',
                    'overallRating': 7,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Defense level time minute. Tax four image all learn. Often here sure this six.',
                    },
                },
                {
                    'ratingId': 'd383a028-d37a-4b1a-adb5-a7781675047b',
                    'created': '2021-06-04T18:03:26.221609Z',
                    'userProfession': 'Student/in',
                    'userRegion': 'Niedersachsen',
                    'overallRating': 4,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Event team after about forget. Well shoulder scientist answer although.',
                    },
                },
                {
                    'ratingId': '7399eed6-e6ad-4a32-b36c-553d5b052ae8',
                    'created': '2021-06-04T18:03:26.227734Z',
                    'userProfession': 'Junior-Entwickler/in',
                    'userRegion': 'Schweiz',
                    'overallRating': 5,
                    'comment':
                    {
                        'isPublic': true,
                        'content': 'Clear wind join agency outside only research. Individual finally there project daughter station health character. Cause mother article what. Training bad respond skill do up relationship.',
                    },
                },
            ];
            result.loaded = true;
        }, 2500);
        return result;
    }
}
