export class HistoryReport {
    constructor() {
        this.history_report_data = {};
        this.error = null;
        this.loaded = false;
    }
}

export class PercentagePosition {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class AnalysisReport {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}