import io from 'socket.io-client';

import {ChangedFileResult, FileResult, FilesResult, SetActiveResult} from "./WorkspaceServiceResponses";

function responseHandler(response) {
    if (response.status !== 200) {
        let errorMessage;
        try {
            let responseBody = response.body;
            let json = JSON.parse(responseBody);
            errorMessage = json.message
        } catch (err) {
            errorMessage = "Error occurred with status " + response.status
        }
        throw new Error(errorMessage);
    }
    return response.json();
}


export default class WorkspaceServiceNetwork {

    constructor(auth) {
        this.authService = auth;
    }

    setActive(workspaceUrl) {
        let result = new SetActiveResult();
        fetch(workspaceUrl + "/set_active", {
            method: 'POST',
            headers: this.authService.getAuthorizationHeader()
        })
        .then(responseHandler)
        .then((json) => {
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getFiles(workspaceUrl, taskId) {
        let result = new FilesResult();
        fetch(workspaceUrl + "/task/" + taskId + "/files", {headers: this.authService.getAuthorizationHeader()})
        .then(responseHandler)
        .then((json) => {
            result.apiData = json;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getFileContent(workspaceUrl, taskId, filePath) {
        let result = new FileResult();
        fetch(workspaceUrl + "/task/" + taskId + "/files/" + filePath, {headers: this.authService.getAuthorizationHeader()})
        .then(responseHandler)
        .then((json) => {
            result.apiData = json;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    changeFileContent(workspaceUrl, taskId, filePath, content) {
        let result = new ChangedFileResult();
        fetch(workspaceUrl + "/task/" + taskId + "/files/" + filePath,
            {
                headers: this.authService.getAuthorizationHeader(),
                method: "POST",
                body: JSON.stringify({content: content})
            }
        )
        .then(responseHandler)
        .then((json) => {
            result.apiData = json;
            result.loaded = true
        }).catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getEventSocket(workspaceUrl) {
        console.log("Connect to socket: "+workspaceUrl)
        return io(workspaceUrl);
    }
}
