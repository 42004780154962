export class ActivityResult {
    constructor() {
        this.error = null;
        this.apiResult = [];
        this.loaded = false;
    }
}

export class EasterChallenge2021FinishedResult {
    constructor() {
        this.error = null;
        this.apiResult = {};
        this.loaded = false;
    }
}

export class ExperienceResult {
    constructor() {
        this.error = null;
        this.apiResult = [];
        this.loaded = false;
    }
}

export class LanguageProgress {
    constructor() {
        this.error = null;
        this.apiResult = [];
        this.loaded = false;
    }
}

export class LevelLeaderBoardResult {
    constructor() {
        this.apiData = {};
        this.loaded = false;
        this.error = null;
        this.pagination = {};
    }
}

export class ExperienceHistory {
    constructor() {
        this.apiData = [];
        this.loaded = false;
        this.error = null;
        this.pagination = {};
    }
}

export class Achievements{
    constructor(){
        this.apiData = {};
        this.loaded = false;
        this.error = null;
    }
}

export class AchievementLeaderBoardResult {
    constructor(){
        this.apiData = [];
        this.loaded = false;
        this.error = null
        this.pagination = {};
    }
}

export class SkillScoreForUserResult {
    constructor(){
        this.apiData = [];
        this.loaded = false;
        this.error = null;
    }
}