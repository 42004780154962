import {
    ResumeResult,
    CreateEducationResult,
    CreateWorkResult,
    UpdateWorkResult,
    UpdateEducationResult,
    DeleteWorkResult,
    DeleteEducationResult
} from "./ResumeServiceResponses";


export default class ResumeServiceStub {

    constructor(auth) {
        this.authService = auth;
    }

    getResume() {
        let result = new ResumeResult();

        setTimeout(function () {
            result.apiData = {
                'resumeId': '7f377532-e64d-467e-a45b-45feb452a570',
                'userId': 'auth0|5a99736aae9f9b5ee5acdbd8',
                'userLevel': 0,
                'solvedChallenges': {},
                'employmentTypes': {
                    'FULL_TIME': 'Vollzeit',
                    'PART_TIME': 'Teilzeit',
                    'CONTRACTOR': 'Freelancer',
                    'TEMPORARY': 'Zeitarbeit',
                    'INTERN': 'Praktikum',
                    'APPRENTICESHIP': 'Ausbildung',
                    'VOLUNTEER': 'Ehrenamt',
                    'PER_DIEM': 'Tagesarbeitsplatz',
                    'OTHER': 'Anders'
                },
                'educationTypes': {
                    'SECONDARY_SCHOOL': 'Mittlere Reife',
                    'HIGHER_EDUCATION': 'Hochschulreife',
                    'APPRENTICESHIP': 'Ausbildung',
                    'BACHELOR': 'Bachelor',
                    'MASTER': 'Master',
                    'DIPLOMA': 'Diplom',
                    'DOCTORAL_DEGREE': 'Doktor'
                },
                'basics': {
                    'name': 'Michelle Ellis',
                    'label': '',
                    'image': 'https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:74b9ff,f_png/v1597653561/eh-platform/user_avatars/31-031_Bulldog-Retro.svg',
                    'email': 'hallo@entwicklerheld.de',
                    'phone': '+49 (0)176 50860663',
                    'url': 'https://entwicklerheld.de/',
                    'summary': '',
                    'location': {
                        'address': 'Tübinger Str. 10',
                        'postalCode': '01189',
                        'city': 'Dresden',
                        'countryCode': 'DE',
                        'region': 'Sachsen'
                    },
                    'profiles': [{
                        'network': 'LinkedIn',
                        'username': 'entwicklerheld',
                        'url': 'https://www.linkedin.com/company/entwicklerheld/'
                    }, {
                        'network': 'Twitter',
                        'username': '@EntwicklerHeld',
                        'url': 'https://twitter.com/EntwicklerHeld'
                    }]
                },
                'work': [{
                    'name': 'Brown, Boyd and Brown',
                    'location': '',
                    'description': '',
                    'position': 'Herbalist',
                    'url': '',
                    'startDate': '2013-10-14',
                    'endDate': '',
                    'summary': '',
                    'highlights': ['', ''],
                    'type': 'INTERN',
                    'technologies': ["c4d6db85-daf3-474e-9c02-c848f54e723d", "7e529e4d-2f8d-4ee8-a203-e5cd9d2a5f56"],
                    'workId': '58d69b0d-4b8b-4164-b23d-b0d805dfaa9b',
                }, {
                    'name': 'Schultz Inc',
                    'location': '',
                    'description': '',
                    'position': 'Ergonomist',
                    'url': '',
                    'startDate': '2008-09-10',
                    'endDate': '2013-01-27',
                    'summary': '',
                    'highlights': ['', ''],
                    'type': 'PER_DIEM',
                    'technologies': [],
                    'workId': '8d07aeb6-5f81-4cd0-b68c-add6b80011fa',
                }, {
                    'name': 'Russo, Quinn and Conley',
                    'location': '',
                    'description': '',
                    'position': 'Network engineer',
                    'url': '',
                    'startDate': '2002-03-10',
                    'endDate': '2017-11-10',
                    'summary': '',
                    'highlights': ['', ''],
                    'type': 'PER_DIEM',
                    'technologies': [],
                    'workId': '2ba63e55-00f5-4463-881b-f1dce5a31eb6',
                }, {
                    'name': 'Morgan and Sons',
                    'location': '',
                    'description': '',
                    'position': 'Theme park manager',
                    'url': '',
                    'startDate': '2018-03-05',
                    'endDate': '2008-08-11',
                    'summary': 'Technologien: ABAP, SAP UI5<br>Bachelorarbeit Thema: “Prototypische Entwicklung eines Alexa‐Skills mit Anbindung an ein SAP ERP System zur Optimierung eines Geschäftsprozesses”',
                    'highlights': ['', ''],
                    'type': 'INTERN',
                    'technologies': [],
                    'workId': '43bdd210-a09c-4140-97b1-dc167d4c4ce9',
                }, {
                    'name': 'Roberts, Carroll and Brown',
                    'location': '',
                    'description': '',
                    'position': 'Biochemist, clinical',
                    'url': '',
                    'startDate': '2009-08-31',
                    'endDate': '2011-01-29',
                    'summary': '',
                    'highlights': ['', ''],
                    'type': 'VOLUNTEER',
                    'technologies': [],
                    'workId': '83b8c4e7-f5cf-4c49-af82-0b95b86a40f5',
                }],
                'education': [{
                    'institution': 'Albert Einstein Gymnasium',
                    'url': '',
                    'area': 'Wirtschaft',
                    'studyType': 'HIGHER_EDUCATION',
                    'startDate': '2003-07-05',
                    'endDate': '2015-07-05',
                    'gpa': '',
                    'courses': ['', ''],
                    'technologies': ["a7ec2776-713f-4005-9f91-0b4eb488fca1"],
                    'educationId': 'e7cb42d2-056d-4f66-bcde-e57c43ab5752',
                }, {
                    'institution': 'Technische Universität Dresden',
                    'url': '',
                    'area': 'Computer Science',
                    'studyType': 'MASTER',
                    'startDate': '2013-08-29',
                    'endDate': '2019-07-13',
                    'gpa': '',
                    'courses': ['', ''],
                    'technologies': [],
                    'educationId': '8c0bb0a3-cb05-463e-a4a2-40685d977f17',
                }],
                'skills': [{'name': 'ABAP', 'level': '25%', 'keywords': ['verified']}, {
                    'name': 'Java',
                    'level': '65%',
                    'keywords': ['verified']
                }, {'name': 'JavaScript', 'level': '35%', 'keywords': ['verified']}, {
                    'name': 'CSS',
                    'level': '25%',
                    'keywords': ['verified']
                }, {'name': 'HTML', 'level': '45%', 'keywords': ['verified']}]
            };
            result.loaded = true;
        }, 200);
        return result;
    }

    createWork(data) {
        let result = new CreateWorkResult();

        console.log("Stub Request to createWork with:", data);

        setTimeout(function () {
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    createEducation(data) {
        let result = new CreateEducationResult();

        console.log("Stub Request to createEducation with:", data);

        setTimeout(function () {
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    updateWork(workId, data) {
        let result = new UpdateWorkResult();

        console.log("Stub Request to updateWork with:", data);

        setTimeout(function () {
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    updateEducation(educationId, data) {
        let result = new UpdateEducationResult();

        console.log("Stub Request to updateEducation with:", data);

        setTimeout(function () {
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    deleteWork(workId) {
        let result = new DeleteWorkResult();

        console.log("Stub Request to deleteWork for id: ", workId);

        setTimeout(function () {
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    deleteEducation(educationId) {
        let result = new DeleteEducationResult();

        console.log("Stub Request to deleteEducation for id: ", educationId);

        setTimeout(function () {
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }
}
