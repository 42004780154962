<template>
    <div class="row form-row">
        <div class="col-xs-12">
            <h3 class="text-center">
                Perfekt <b>{{information.name}}</b>, Du hast es geschafft!
            </h3>
            <div class="content">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="avatar-wrapper">
                            <div class="avatar" :style="{backgroundColor: '#'+information.selectedColor}">
                                <img :src="information.selectedImage">
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <table class="information">
                            <tr class="information-element">
                                <td>
                                    <img class="information-icon" :src="professionIcons[information.selectedProfession]">
                                </td>
                                <td>
                                    {{displayProfession[information.selectedProfession]}}
                                </td>
                            </tr>
                            <tr class="information-element">
                                <td>
                                     <img class="information-icon" src="https://polylith-public-files.s3.eu-central-1.amazonaws.com/Web+Development+-+Retro+(1).svg">
                                </td>
                                <td>
                                     {{information.workExperience}} Jahre
                                </td>
                            </tr>
                            <tr class="information-element" v-if="information.technologies && information.technologies.length !== 0">
                                <td></td>
                                <td>
                                  <div class="technologies">
                                    <span v-for="(value) in information.technologies" :key="value.id"
                                      class="technology">
                                      <label class="technology__label"
                                             :class="{
                                              'bg-tec-python': value.name === 'python',
                                              'bg-tec-dockerfile': value.name === 'dockerfile',
                                              'bg-tec-java': value.name === 'java',
                                              'bg-tec-javascript': value.name === 'javascript',
                                              'bg-tec-csharp': value.name === 'csharp',
                                              'bg-tec-cpp': value.name === 'cpp',
                                              'bg-tec-php': value.name === 'php',
                                          }">
                                          <i class="fa fa-code"></i>
                                          <span class="technology__name">{{ value.displayName }}</span>
                                      </label>
                                    </span>
                                  </div>
                                </td>
                            </tr>
                            <tr class="information-element" v-if="information.location">
                                <td>
                                     <img class="information-icon" src="https://polylith-public-files.s3.eu-central-1.amazonaws.com/Location+Pin+-+Retro.svg">
                                </td>
                                <td>
                                  {{information.location.address}}
                                </td>
                            </tr>
                            <tr class="information-element" v-if="information.selectedJobInterest !== 'no-job' && information.selectedJobInterest !== 'no-studies'">
                                <td>
                                    <img class="information-icon" :src="jobInterestIcons[information.selectedJobInterest]">
                                </td>
                                <td>
                                    {{displayJobInterest[information.selectedJobInterest]}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="newsletter">
                       <input type="checkbox" id="newsletter" v-model="newsletterApproval">
                      <label for="newsletter">Ich will über neue Challenges und Features auf der Plattform per Mail informiert werden.</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CompletionComponent",
        props: {
            information: {
                type: Object,
                default: {},
            },
        },
        data() {
            return {
                professionIcons: {
                    'pupil': 'https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605664/eh-platform/icons/038_School_-_Retro.svg',
                    'student/trainee': 'https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605662/eh-platform/icons/031_University_-_Retro.svg',
                    'professional/academics': 'https://res.cloudinary.com/dspi5zyaa/image/upload/v1598606266/eh-platform/icons/Condo_Building_1_-_Retro.svg',
                },
                displayProfession: {
                    'pupil': 'Schüler*in',
                    'student/trainee': 'Student*in / Azubi',
                    'professional/academics': 'Professional',
                },
                jobInterestIcons: {
                    'job': 'https://res.cloudinary.com/dspi5zyaa/image/upload/v1598604845/eh-platform/icons/002_Job_Search_-_Retro.svg',
                    'offers': 'https://res.cloudinary.com/dspi5zyaa/image/upload/v1598604673/eh-platform/icons/096_Emails_-_Retro.svg',
                    'freelancer': 'https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605040/eh-platform/icons/029_3D_Model_-_Retro.svg',
                    'no-job': 'https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605043/eh-platform/icons/032_No_Waiting_-_Retro.svg',
                    'studies': 'https://res.cloudinary.com/dspi5zyaa/image/upload/v1598606549/eh-platform/icons/047_Programmer_-_Retro.svg',
                    'maybe': 'https://res.cloudinary.com/dspi5zyaa/image/upload/v1598606549/eh-platform/icons/043_Browser_-_Retro.svg',
                    'no-studies': 'https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605043/eh-platform/icons/032_No_Waiting_-_Retro.svg',
                },
                displayJobInterest: {
                    'job': 'Auf Job- oder Praktikumssuche',
                    'offers': 'Offen für Job- und Praktikaangebote',
                    'freelancer': 'Freelancer auf Projektsuche',
                    'no-job': 'Nicht auf Jobsuche',
                    'studies': 'Interesse an einer IT-Ausbildung',
                    'maybe': 'Interesse am Coden',
                    'no-studies': 'Kein IT-Interesse',
                },
                newsletterApproval: true,
            }
        },
        validations: {
            newsletterApproval: {
            },
            form: ["newsletterApproval"],
        },
        methods: {
          validate() {
                this.$v.form.$touch();
                let isValid = !this.$v.form.$invalid;
                this.$emit("on-validate", this.$data, isValid);
                return isValid;
            },
        }
    }
</script>

<style scoped>
    h3 {
        color: #4c4c4c;
        margin: 5rem 0;
        line-height: 1.4em;
    }

    p {
        margin-top: 1rem;
        font-size: 15px;
        color: #999999;
    }

    table {
        margin-bottom: 1rem;
    }

    table, td {
        border: 1px solid transparent;
    }

    td {
        padding: 1rem;
        font-size: 16px;
    }

    .content {
        width: 100%;
    }

    .avatar-wrapper {
        width: 25rem;
        height: 25rem;
        margin-left: auto;
        margin-right: auto;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .avatar {
        animation-delay: 100ms;
        width: 100%;
        height: 100%;
        animation-fill-mode: both;
        background-color: #00bf8f;
        border-radius: 99%;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: box-shadow, transform;
        transition-property: box-shadow, transform;
        display: flex;
        justify-content: center;
        -webkit-transition: transform 0.3s ease-in-out !important;
    }

    .avatar img {
        height: 65%;
        z-index: 10;
        transform: translateY(25%);
    }

    .information {
        list-style: none;
        font-size: 20px;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .information-element {
        padding: 2rem;
        color: #999999;
    }

    .information-icon {
        width: 4rem;
        margin-right: 2rem;
    }

    .newsletter {
        text-align: center;
        color: #4c4c4c;
        font-size: 14px;
        cursor: pointer;
    }

    input[type="checkbox"] {
        display: none;
    }

    input[type="checkbox"] + label::before {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border: 2px solid #08AB99;
        background-color: #fff;
        display: block;
        content: "";
        float: left;
        margin-right: 10px;
    }

    input[type="checkbox"]:checked + label::before {
        box-shadow: inset 0 0 0 3px #fff;
        background-color: #08AB99;
    }

    label {
        font-weight: normal;
        font-size: 80%;
        text-align: left;
    }

    @media (max-width: 767px) {
        td {
            padding: 0.5rem
        }

        table {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

        .avatar-wrapper {
            height: 10rem;
            width: 10rem;
            margin-left: auto;
            margin-right: auto;
        }

        h3 {
            margin: 1.5rem 0;
        }

       .information-icon{
            width: 3.6rem;
        }
    }

    .technology {
      display: inline-block;
    }

    .technology .technology__label {
          position: relative;
          font-family: "Source Code Pro", sans-serif;
          color: white;
          overflow: hidden;
          padding: 1px 12px 4px 12px;
          margin-bottom: 8px;
          border-radius: 50px;
          background-color: #08AB99;
          margin-right: 0.5rem;
          line-height: 1;
          box-shadow: 0 4px 7px rgba(13, 51, 85, 0.2);
          display: inline-block;
    }

    .technology::before {
        content: none;
    }

    .technology .fa {
        position: absolute;
        bottom: -5%;
        left: -4%;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.25);
    }

    .technology .technology__name {
        font-size: 11px;
        text-transform: uppercase;
    }

    .bg-tec-python {
        background-color: #3c6382 !important;
        color: white !important;
    }

    .bg-tec-dockerfile,
    .bg-tec-docker {
        background-color: #2C90DE !important;
        color: white !important;
    }

    .bg-tec-java {
        background-color: #fa983a !important;
        color: white !important;
    }

    .bg-tec-javascript {
        background-color: #f6b93b !important;
        color: white !important;
    }

    .bg-tec-csharp {
        background-color: #803788 !important;
        color: white !important;
    }

    .bg-tec-cpp {
        background-color: #e55039 !important;
        color: white !important;
    }

    .bg-tec-php {
        background-color: #6a89cc !important;
        color: white !important;
    }

    @media (min-width: 768px){
        h3 {
            margin: 1.5rem 0;
        }

        p {
          font-size: 120%;
        }

        label {
          font-size: 100%;
        }

        .avatar-wrapper {
          width: 20rem;
          height: 20rem;

          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
     }

</style>