import {findGetParameter} from "./Utilities";
import {Auth0Lock, Auth0LockPasswordless} from "auth0-lock";
import {isEmpty} from "lodash";
import {services} from "../main";
import {eventBus} from "../main";

export default class AuthenticationService {

  constructor(router, store) {
    this.router = router;
    this.nextRoute = null;
    let redirect = false;
    let showSignupFirst = findGetParameter('showSignUpFirst');
    let auth0Redirect = findGetParameter('auth0Redirect');
    if (showSignupFirst !== null || auth0Redirect !== null) {
      redirect = true
    }
      if (window.WHITELABEL) {
          this.auth = new Auth0LockPasswordless(
              window.WHITELABEL_AUTH0_CLIENT_ID,
              'auth.platform.entwicklerheld.de',
              {
                  configurationBaseUrl: "https://cdn.eu.auth0.com",
                  container: "eh-login-container",
                  language: "de",
                  auth: {
                      redirectUrl: window.BASE_URL + "/callback?auth0Redirect=1",
                      autoParseHash: true,
                      responseType: "token id_token",
                      audience: "https://polylith-io.eu.auth0.com/userinfo",
                      redirect: redirect,
                  },
                  rememberLastLogin: false,
                  autoclose: false,
                  languageDictionary: {
                      passwordlessEmailInstructions: '',
                      emailInputPlaceholder: "E-Mail",
                      passwordInputPlaceholder: "Passwort",
                      title: "",
                      signUpTerms: '',
                  },
              }
          );
      } else {
        this.auth = new Auth0Lock("qYcxMFaJrwnaCU9JDngvj-aFM-CbML1F", "auth.platform.entwicklerheld.de", {
            configurationBaseUrl: "https://cdn.eu.auth0.com",
            auth: {
                redirectUrl: window.BASE_URL + "/callback?auth0Redirect=1",
                audience: "https://polylith-io.eu.auth0.com/userinfo",
                responseType: "token id_token",
                params: {scope: "openid email profile"},
                redirect: redirect,
                autoParseHash: true,
            },
            autoclose: false,
            container: "eh-login-container",
            language: "de",
            rememberLastLogin: true,
            mustAcceptTerms: true,
            languageDictionary: {
                emailInputPlaceholder: "E-Mail",
                passwordInputPlaceholder: "Passwort",
                title: "Willkommen zurück!",
                signUpTitle: "Jetzt registrieren!",
                signUpTerms: 'Ich stimme den <a target="_blank" href="https://entwicklerheld.de/data_privacy/">Datenrichtlinien</a> und den <a target="_blank" href="https://entwicklerheld.de/terms_of_use/">Nutzungsbedingungen</a> zu.',
            },
            theme: {
                logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1638188273/eh-platform/logos/eh_logo_full_green_new_svg.svg",
            },
        });
    }
    this.auth.on("authenticated", (authResult) => {
      if (!(authResult && authResult.accessToken && authResult.idToken)) {
        return;
      }
      if (!isEmpty(services.facebookAnalytics)) {
        services.facebookAnalytics.sendRegistrationCompletionEvent();
      }
      this.setSession(authResult);

      if (auth0Redirect === null) {
        setTimeout(() => {
         this.auth.hide();
        }, 1500);
      }
      else {
       this.auth.hide();
       this.nextRoute = {fullPath: '/dashboard'}
      }

      if (this.nextRoute) {
        this.router.push(this.nextRoute.fullPath);
      }

      this.nextRoute = null;
    });

    this.store = store;
    this.saveTrackingDataInLocalStorage();
  }

  parseJwt(token) {
    if (!token) {
      return {};
    }
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(base64));
  }

  setSession(authResult) {
    let idTokenPayload = this.parseJwt(authResult.idToken);
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expiresAt', idTokenPayload.exp);
    localStorage.setItem('id_token_payload', JSON.stringify(idTokenPayload));

    setTimeout(()=> {
      this.store.commit("setAuthentication", true);
    }, 1000);

    // this timeout is for a better look and feel for the finished onboarding animation -> confetti
    setTimeout(()=> {
      eventBus.$emit("userLoggedIn");
    }, 2000);

  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  getIDToken() {
    return localStorage.getItem('id_token');
  }

  getIDTokenPayload() {
    return JSON.parse(localStorage.getItem('id_token_payload'));
  }

  getExpireAt() {
    return JSON.parse(localStorage.getItem('expiresAt'));
  }

  login(next) {
    if (findGetParameter('showSignUpFirst') !== null) {
      this.signUp(next);
      return;
    }

    this.nextRoute = next;
    this.auth.show({
      allowSignUp: true,
    });
  }

  signUp(next = null) {
    this.auth.show({
      allowLogin: false,
    });
    this.nextRoute = next
  }

  logout() {
    // Clear access token and ID token from local storage
    AuthenticationService.deleteLocalStorage();
    this.store.commit("setAuthentication", false);
    this.router.push({path: "/"});
  }

  static deleteLocalStorage() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_token_payload');
    localStorage.removeItem('expiresAt');
  }

  isAuthenticated() {
    let isAuthenticated = Date.now() < this.getExpireAt() * 1000;
    this.store.commit("setAuthentication", isAuthenticated);
    if (isAuthenticated === false) {
      AuthenticationService.deleteLocalStorage();
    }
    return isAuthenticated;
  }

  getAuthorizationHeader() {
    let headers = new Headers({"Content-Type": "application/json"});
    if (this.getIDToken() !== null && this.getIDToken() !== "") {
      headers.append("Authorization", "Bearer " + this.getIDToken());
    }
    return headers;
  }

  getUserID() {
    return this.getIDTokenPayload().sub;
  }

  saveTrackingDataInLocalStorage() {
    let utmCampaign = findGetParameter("utm_campaign");
    let utmSource = findGetParameter("utm_source");
    let utmMedium = findGetParameter("utm_medium");
    const trackingData = {
          utm_campaign: utmCampaign,
          utm_source: utmSource,
          utm_medium: utmMedium,
      }

      if (utmMedium === null && utmCampaign === null && utmSource === null) {
        return;
      }

      localStorage.setItem("campaignData", JSON.stringify(trackingData));
  }

  getTrackingData() {
    return JSON.parse(localStorage.getItem("campaignData"));
  }
}
