import CampusChallengeServiceNetwork from "./CampusChallengeServiceNetwork";
import {CampusChallengeServiceStub} from "./CampusChallengeServiceStub";

export default class CampusChallengeService {
    constructor(auth) {
        this.authService = auth;
    }

    getUserContribution() {
        if (typeof window.CAMPUS_CHALLENGE_API_URL === "undefined" || window.CAMPUS_CHALLENGE_API_URL === "") {
            console.info("window.CAMPUS_CHALLENGE_API_URL is not set! Using Stub data!");
            return new CampusChallengeServiceStub(this.authService).getUserContribution();
        }
        return new CampusChallengeServiceNetwork(this.authService).getUserContribution();
    }

    getRankingList() {
        if (typeof window.CAMPUS_CHALLENGE_API_URL === "undefined" || window.CAMPUS_CHALLENGE_API_URL === "") {
            console.info("window.CAMPUS_CHALLENGE_API_URL is not set! Using Stub data!");
            return new CampusChallengeServiceStub(this.authService).getRankingList();
        }
        return new CampusChallengeServiceNetwork(this.authService).getRankingList();
    }

    getActiveCampusList() {
        if (typeof window.CAMPUS_CHALLENGE_API_URL === "undefined" || window.CAMPUS_CHALLENGE_API_URL === "") {
            console.info("window.CAMPUS_CHALLENGE_API_URL is not set! Using Stub data!");
            return new CampusChallengeServiceStub(this.authService).getActiveCampusList();
        }
        return new CampusChallengeServiceNetwork(this.authService).getActiveCampusList();
    }

}
