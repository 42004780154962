<template>
    <div ref="sidebar" class="sidebar" v-bind:class="{'sidebar_vip':isVip || isCustomerView}">
        <!-- Close button (mobile devices) -->
        <div class="sidebar__close" @click="toggleSidebar()">
            <img src="../assets/img/close.svg" alt="Close sidebar">
        </div>
        <!-- Sidebar user -->
        <mini-profile></mini-profile>
        <!-- Sidebar user nav -->
        <nav v-if="isAuthenticated" class="sidebar-user__nav">
            <ul class="sidebar__nav">
                <li v-if="!isCustomerFlag">
                    <router-link :to="{ name: 'user-profile'}" href="#">
                        <i class="sidebar-icon dev-user"></i>
                        <span class="link-title">Einstellungen</span>
                    </router-link>
                </li>
                <li v-else>
                    <router-link :to="{ name: 'user-profile'}" href="#">
                        <i class="sidebar-icon dev-user"></i>
                        <span class="link-title">Einstellungen</span>
                    </router-link>
                </li>
                <li>
                    <a href="#" @click="logout()">
                        <i class="sidebar-icon dev-logout"></i>
                        <span class="link-title">Logout</span>
                    </a>
                </li>
            </ul>
        </nav>
        <div v-if="isAuthenticated" @click="toggleProfile()" class="text-center collapse-bar"><i
                class="sidebar-icon"
                :class="{'dev-chevron-down': !collapsedProfile, 'dev-chevron-up':collapsedProfile}"></i></div>
        <!-- Sidebar nav -->
        <div class="sidebar-scrollable">
            <ul class="sidebar__nav">
                <li v-if="isCustomerFlag && !isCustomerView" class="sidebar-nav__heading">Firmenbereich</li>
                <li v-if="isCustomerFlag && !isCustomerView" @click="isCustomerView = true">
                    <a href="https://corporate.platform.entwicklerheld.de">
                        <i class="sidebar-icon dev-arrow-circle-right"></i>
                        <span class="link-title">Zum Firmenbereich</span>
                    </a>
                </li>
                <li class="sidebar-nav__heading">Challenges</li>
                <li v-if="!isCustomerView">
                    <router-link :to="{ name: 'challenge-list', query: { challengeFilterStateKey: 'all',} }">
                        <i class="sidebar-icon dev-terminal-box"></i>
                        <span class="link-title">Challenges</span>
                    </router-link>
                </li>
                <li v-if="isAuthenticated && !isCustomerView" title="Du hast aktuell keine gestarteten Challenges">
                    <a v-if="!hasPausedChallenges" class="a-disabled">
                        <i class="sidebar-icon dev-laptop"></i>
                        <span class="link-title">Gestartete Challenges</span>
                    </a>
                    <router-link v-if="hasPausedChallenges" :to="{ name: 'challenge-list', query: { challengeFilterStateKey: 'started', } }">
                        <i class="sidebar-icon dev-laptop"></i>
                        <span class="link-title">Gestartete Challenges</span>
                    </router-link>
                </li>
                <li v-if="isAuthenticated && !isCustomerView">
                    <router-link v-if="hasSolvedChallenges" :to="{ name: 'challenge-list', query: { challengeFilterStateKey: 'solved', } }">
                        <i class="sidebar-icon dev-circle-check"></i>
                        <span class="link-title">Abgeschlossene Challenges</span>
                    </router-link>
                </li>
                <li class="sidebar-nav__heading">Plattform</li>
                <li v-if="isCustomerFlag && isCustomerView" @click="isCustomerView = false">
                    <router-link :to="{ name: 'challenge-list'}">
                        <i class="sidebar-icon dev-arrow-circle-left"></i>
                        <span class="link-title">Zum Userbereich</span>
                    </router-link>
                </li>
                <li v-if="!isCustomerView">
                    <router-link :to="{ name: 'dashboard'}">
                        <i class="sidebar-icon dev-dashboard"></i>
                        <span class="link-title">Dashboard</span>
                    </router-link>
                </li>
                <li class="news-link" @click="sendAllPostsReadRequest" v-if="!isCustomerView">
                    <router-link :to="{ name: 'news'}">
                        <i class="sidebar-icon dev-newspaper"></i>
                        News
                        <span v-if="!setAllPostsReadResult.loaded && unreadPostsCount && unreadPostsCount>9"
                              class="badge">
                            9+
                        </span>
                        <span v-if="!setAllPostsReadResult.loaded && unreadPostsCount && unreadPostsCount<=9"
                              class="badge">
                            {{ unreadPostsCount }}
                        </span>
                    </router-link>
                </li>
                 <li v-if="!isCustomerView">
                    <router-link :to="{ name: 'leader-board'}">
                        <i class="sidebar-icon dev-trophy"></i>
                        <span class="link-title">Ranglisten</span>
                    </router-link>
                </li>
                <li v-if="!isCustomerView">
                    <router-link :to="{ name: 'company-list'}">
                        <i class="sidebar-icon dev-building"></i>
                        <span class="link-title">Unternehmen</span>
                    </router-link>
                </li>
                <li v-if="!isCustomerView">
                    <router-link :to="{ name: 'vacancy-list'}">
                        <i class="sidebar-icon dev-briefcase"></i>
                        <span class="link-title">Jobs</span>
                    </router-link>
                </li>
                <li v-if="!isCustomerView">
                    <router-link :to="{ name: 'reward-list'}">
                        <i class="sidebar-icon dev-gift"></i>
                        <span class="link-title">Belohnungen</span>
                    </router-link>
                </li>
                <li v-if="isCreator && !isCustomerView">
                    <router-link :to="{ name: 'creators-lounge'}">
                        <i class="sidebar-icon dev-lightbuld"></i>
                        <span class="link-title">CreatorsLounge</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div id="impress-footer">
            <div class="text-center">
                <a href="https://entwicklerheld.de/impress" target="_blank" rel="noopener noreferrer">Impressum</a>
                <img src="/assets/img/eh-logo.png" alt="EntwicklerHeld logo">
                <a href="https://entwicklerheld.de/data_privacy" target="_blank"
                   rel="noopener noreferrer">Datenschutz</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {eventBus} from "../main";
    import {mapState} from 'vuex';
    import MiniProfile from './user/MiniProfileComponent.vue';
    import PerfectScrollbar from 'perfect-scrollbar';
    import 'perfect-scrollbar/css/perfect-scrollbar.css'
    import {services} from "../main";
    import {findGetParameter} from "../services/Utilities";

    function initializePerfectScrollbar(this_) {
        const ps = new PerfectScrollbar('.sidebar-scrollable', {
            suppressScrollX: true,
        });
    }

    // copied from here: https://stackoverflow.com/a/38552302/10949964
    function parseJwt(token) {
        if (!token) {
            return;
        }
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
    }

    export default {
        name: 'sidebar',
        props: {
            isVip: {type: Boolean, default: false},
            isCreator: {type: Boolean, default: false},
        },
        components: {
            MiniProfile
        },
        computed: {
            hasSolvedChallenges() {
                if (this.acceptedChallengeGroupsRequest.loaded && !this.acceptedChallengeGroupsRequest.error) {
                    for (let challengeGroup of this.acceptedChallengeGroupsRequest.apiData) {
                        for (let challenge of challengeGroup.challenges) {
                            if (challenge.isSolved === true) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            },
            hasPausedChallenges() {
                if (this.acceptedChallengeGroupsRequest.loaded && !this.acceptedChallengeGroupsRequest.error) {
                    for (let challengeGroup of this.acceptedChallengeGroupsRequest.apiData) {
                        for (let challenge of challengeGroup.challenges) {
                            if (challenge.isSolved === false) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            },
            ...mapState([
                'isAuthenticated',
            ])
        },
        data() {
            return {
                open_tasks: [],
                error: null,
                isCustomerFlag: false,
                isCustomerView: false,
                showWelcomeModalNav: false,
                setAllPostsReadResult: {loaded: false, error: null},
                unreadPostsResult: {loaded: false, error: null},
                unreadPostsCount: 0,
                collapsedProfile: false,
                acceptedChallengeGroupsRequest: {loaded: false, error: null},
            }
        },
        methods: {
            getUrl: function (identifier) {
                return "#/task/" + identifier + "/edit/";
            },
            logout: () => services.auth.logout(),
            toggleSidebar: function () {
                $(".wrapper").toggleClass("alt");
                eventBus.$emit("sideBarToggle");
                return false;
            },
            closeSidebar: function () {
                const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                if ($(".wrapper").hasClass("alt") && viewWidth < 1024) {
                    $(".wrapper").toggleClass("alt");
                    eventBus.$emit("sideBarToggle");
                }
            },
            isMobile: function () {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            isCustomer: function () {
                let idToken = services.auth.getIDToken();
                let decodedIdToken = parseJwt(idToken);
                if (!decodedIdToken) {
                    this.isCustomerFlag = false;
                    return;
                }
                let roles = decodedIdToken['https://entwicklerheld.de/roles'];
                if (!roles) {
                    this.isCustomerFlag = false;
                    return;
                }

                this.isCustomerFlag = roles.includes("Customer");
            },
            openWelcomeModal() {
                eventBus.$emit('openWelcomeModal');
            },
            sendAllPostsReadRequest() {
                this.setAllPostsReadResult = services.newsService.setAllPostsRead();
            },
            toggleProfile: function () {
                $(".sidebar-user__nav").slideToggle(300, function () {
                    //ps.update();
                });
                this.collapsedProfile = !this.collapsedProfile;
            }
        },
        mounted() {
            this.acceptedChallengeGroupsRequest = this.challengeGroupsRequest = services.taskManagementService.getChallengeGroups(true);
            this.isCustomer();
            this.$nextTick(() => {
                initializePerfectScrollbar(this);
            });
            eventBus.$on("newsOpened", function () {
                this.sendAllPostsReadRequest();
            }.bind(this));
            eventBus.$on("challenge-accepted", () => {
                this.acceptedChallengeGroupsRequest = this.challengeGroupsRequest = services.taskManagementService.getChallengeGroups(true);
            });
            eventBus.$on("challenge-submitted", () => {
                this.acceptedChallengeGroupsRequest = this.challengeGroupsRequest = services.taskManagementService.getChallengeGroups(true);
            });
        },
        updated() {
            this.$nextTick(function () {
                initializePerfectScrollbar(this)
            });
            if (this.isAuthenticated) {
                this.unreadPostsResult = services.newsService.getUnreadPosts();
            }
        },
        watch: {
            isAuthenticated: function () {
                initializePerfectScrollbar(this);
                this.isCustomer();
            },
            'unreadPostsResult.apiData': function () {
                if (this.unreadPostsResult.loaded && !this.unreadPostsResult.error) {
                    this.unreadPostsCount = this.unreadPostsResult.apiData.unreadPosts
                }
            }
        }
    }
</script>

<style scoped>
    li a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    @supports (-moz-appearance:none) {
        li a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: baseline;
        }
    }

    body > .wrapper .sidebar.sidebar_vip:before {
        filter: grayscale(1) brightness(50%);
    }

    body > .wrapper .sidebar_vip {
        background: #5c5c5c !important;
    }

    .sidebar {
        display: flex !important;
        flex-direction: column;
        transition: all 1.5s ease;
    }

    .news-link {
        position: relative;
    }

    .badge {
        color: whitesmoke;
        text-align: center;
        top: 2px;
        background-color: #398b7d;
        border-radius: 1em;
        margin-left: 8px;
        min-width: 2em;
        height: 1em;
        line-height: 1em;
        padding: 2px;
        box-sizing: content-box;
        font-weight: 600;
    }

    .fa {
        display: inline-block;
        width: 20%;
    }

    .link-title {
        display: inline-block;
        width: 80%;
    }

    .a-disabled {
        cursor: default;
        opacity: 0.4;
        text-decoration: none;
    }

    #impress-footer {
        margin-top: auto;
        padding: 10px 22px;
        position: fixed;
        bottom: 0;
    }

    #impress-footer a {
        color: whitesmoke;
    }

    #impress-footer img {
        width: 25px;
    }

    .sidebar-scrollable {
        overflow: hidden;
        position: relative;
    }

    .collapse-bar {
        color: white;
        background-color: rgba(0, 0, 0, 0.2);
        font-size: 1.0rem;
        position: relative;
    }

    .collapse-bar i {
        padding: 15px 0;
        border-radius: 10px;
        width: 100%;
        bottom: 0;
        position: absolute;
        left: 0;
    }

    .collapse-bar:hover {
        cursor: pointer;
    }

    .sidebar__nav {
        margin-bottom: 2rem;
    }

    .sidebar-icon {
      margin-right: 1.8rem;
      font-size: 2rem;
    }
</style>
