import {APIResponse, responseHandler} from "../Utilities";
import {
    CodeComments,
    CommentForCodeComment, RandomSolution, RankTheCodeUser,
    RatedSolutions,
    RatingPost,
    Ratings,
    ChallengeStatistics,
    ChallengeSolutions,
    ReceivedSolutionRatings, Solution,
    OtherSolutionRatings, CommentForSolutionRating
} from "./RankTheCodeServiceResponses";

export default class RankTheCodeServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    getOtherSolutionRatings(taskId, view, pageId) {
        let result = new OtherSolutionRatings();
        let url = window.RANK_THE_CODE_API_URL + "/api/solution/get_other_solution_ratings/?";

        if (taskId) {
            url += "taskId=" + taskId;
        }

        if (view) {
            url += "&view=" + view;
        }

        if (pageId) {
            url += "&pageID=" + pageId;
        }

        fetch(url, {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET"
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
                if ("pagination" in json) {
                    result.pagination = json.pagination;
                }
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getOtherSolutionRatingsById(solutionId) {
        let result = new OtherSolutionRatings();
        let url = window.RANK_THE_CODE_API_URL + "/api/solution/get_other_solution_ratings_by_id/?solutionId=" + solutionId;
        fetch(url, {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET"
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getSolutionsForChallenge(challengeId) {
        let result = new ChallengeSolutions();
        fetch(window.RANK_THE_CODE_API_URL + "/api/solution/" + challengeId + "/challenge_solutions/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getSolutionsOfUserForChallenge(challengeId, public_user_id) {
        let result = new ChallengeSolutions();
        fetch(window.RANK_THE_CODE_API_URL + "/api/solution/" + challengeId + "/challenge_solutions/" + public_user_id, {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getChallengeStatistics(challengeId) {
        let result = new ChallengeStatistics();
        fetch(window.RANK_THE_CODE_API_URL + "/api/solution/" + challengeId + "/challenge_statistics/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    postRating(solutionId, rate, comment) {
        let result = new RatingPost();
        let postData = {
            solutionId: solutionId,
            rate: rate,
            comment: comment,
        };
        fetch(window.RANK_THE_CODE_API_URL + "/api/solution/" + solutionId + "/solution_rating/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify(postData)
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getSolutionRatings(solutionId) {
        let result = new Ratings();
        fetch(window.RANK_THE_CODE_API_URL + "/api/solution/" + solutionId + "/solution_rating/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getCodeComments(solutionId) {
        let result = new CodeComments();
        fetch(window.RANK_THE_CODE_API_URL + "/api/solution/" + solutionId + "/code_comment/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    postCodeComment(solutionId, range, judgement, comment) {
        let result = new CodeComments();

        const postData = {
            range: range,
            judgement: judgement,
            comment: comment
        };

        fetch(window.RANK_THE_CODE_API_URL + "/api/solution/" + solutionId + "/code_comment/create_code_comment/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify(postData)
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    postCommentForCodeComment(codeCommentId, text) {
        let result = new CommentForCodeComment();

        const postData = {
            text: text,
        };

        fetch(window.RANK_THE_CODE_API_URL + "/api/code_comment/" + codeCommentId + "/comment/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify(postData)
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    postCommentForSolutionRating(solutionRatingId, text) {
        let result = new CommentForSolutionRating();

        const postData = {
            text: text,
        };

        fetch(window.RANK_THE_CODE_API_URL + "/api/solution_rating/" + solutionRatingId + "/comment/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify(postData)
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getRatedSolutions() {
        let result = new RatedSolutions();

        fetch(window.RANK_THE_CODE_API_URL + "/api/get_rated_solutions/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    receivedRatedSolutions() {
        let result = new ReceivedSolutionRatings();

        fetch(window.RANK_THE_CODE_API_URL + "/api/received_rated_solutions/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getRandomSolution(challengeId, excludeAlreadyRated) {
        let result = new RandomSolution();
        let url = window.RANK_THE_CODE_API_URL + "/api/get_random_solution/?";
        const searchParams = {};
        if (challengeId) {
           searchParams['challengeId'] = challengeId
        }
        // Attention it is not possible to set it explicit to true
        if (excludeAlreadyRated) {
            searchParams['excludeAlreadyRated'] = true;
        }
        url += new URLSearchParams(searchParams).toString();

        fetch(url, {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getRandomSolutionPromise(challengeId, excludeAlreadyRated) {
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let url = window.RANK_THE_CODE_API_URL + "/api/get_random_solution/?";
                    if (challengeId) {
                        url = url + `challengeId=${challengeId}`;
                    }
                    if (excludeAlreadyRated) {
                        url = url + `&excludeAlreadyRated=true`;
                    }
                    let apiFetch = await fetch(url, {
                        headers: this.authService.getAuthorizationHeader(),
                    });

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        );
        return result;
    }

    getSolution(solutionId) {
        let result = new Solution();
        fetch(window.RANK_THE_CODE_API_URL + "/api/solution/" + solutionId + "/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getRankTheCodeUser() {
        let result = new RankTheCodeUser();
        fetch(window.RANK_THE_CODE_API_URL + "/api/rank_the_code_user/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    createRankTheCodeUser() {
        let result = new RankTheCodeUser();
        fetch(window.RANK_THE_CODE_API_URL + "/api/rank_the_code_user/create_user/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }
}
