import {findGetParameter} from "../Utilities";
import {services} from "../../main";

export default class FaceBookAnalyticsService {
    constructor() {
        //this.userComesFromAdvertising = findGetParameter("utm_campaign")? true : false;
        // if (this.userComesFromAdvertising) {
            this.initializePixel();
        //}
    }

    initializePixel() {
        !function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ?
                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
                'https://connect.facebook.net/en_US/fbevents.js');
        this.fbq = fbq;
        this.fbq('init', '799687760396338');
    }

    sendRegistrationCompletionEvent() {
        // if (this.userComesFromAdvertising) {
            this.fbq('track', 'CompleteRegistration', {
                value: 0.00,
                currency: 'USD'
            });
        //}
    }
    sendSolvedChallengeEvent() {
        // if (this.userComesFromAdvertising) {
            this.fbq('track', 'SolvedChallenge', {
                value: 0.00,
                currency: 'USD'
            });
        //}
    }
    sendViewJobOffer() {
        // if (this.userComesFromAdvertising) {
            this.fbq('track', 'ViewJobOffer', {
                value: 0.00,
                currency: 'USD'
            });
        //}
    }
    sendShowApplyJobOffer() {
        // if (this.userComesFromAdvertising) {
        this.fbq('track', 'ShowApplyJobOffer', {
            value: 0.00,
            currency: 'USD'
        });
        //}
    }
    sendApplyJobOffer() {
        // if (this.userComesFromAdvertising) {
        this.fbq('track', 'ApplyJobOffer', {
            value: 0.00,
            currency: 'USD'
        });
        //}
    }
}
