import {NotificationServiceStub} from "./NotificationServiceStub";
import {NotificationServiceNetwork} from "./NotificationServiceNetwork";

export default class NotificationService {
    constructor(auth) {
        this.authService = auth;
    }

    setSubscriptionState(subscribed, notificationType) {
        if (typeof window.NOTIFICATION_API_URL === "undefined" || window.NOTIFICATION_API_URL === "") {
            console.info("window.NOTIFICATION_API_URL is not set! Using Stub data!");
            return new NotificationServiceStub(this.authService).setSubscriptionState(subscribed, notificationType);
        }
        return new NotificationServiceNetwork(this.authService).setSubscriptionState(subscribed, notificationType);
    }

    getSubscriptionState() {
        if (typeof window.NOTIFICATION_API_URL === "undefined" || window.NOTIFICATION_API_URL === "") {
            console.info("window.NOTIFICATION_API_URL is not set! Using Stub data!");
            return new NotificationServiceStub(this.authService).getSubscriptionState();
        }
        return new NotificationServiceNetwork(this.authService).getSubscriptionState();
    }

    getPlatformNotifications(pageNumber, pageSize) {
        if (typeof window.NOTIFICATION_API_URL === "undefined" || window.NOTIFICATION_API_URL === "") {
            console.info("window.NOTIFICATION_API_URL is not set! Using Stub data!");
            return new NotificationServiceStub(this.authService).getPlatformNotifications(pageNumber, pageSize);
        }
        return new NotificationServiceNetwork(this.authService).getPlatformNotifications(pageNumber, pageSize);
    }

    setPlatformNotificationsAsRead(notificationId) {
        if (typeof window.NOTIFICATION_API_URL === "undefined" || window.NOTIFICATION_API_URL === "") {
            console.info("window.NOTIFICATION_API_URL is not set! Using Stub data!");
            return new NotificationServiceStub(this.authService).setPlatformNotificationsAsRead(notificationId);
        }
        return new NotificationServiceNetwork(this.authService).setPlatformNotificationsAsRead(notificationId);
    }

    setPlatformNotificationsAsSeen() {
        if (typeof window.NOTIFICATION_API_URL === "undefined" || window.NOTIFICATION_API_URL === "") {
            console.info("window.NOTIFICATION_API_URL is not set! Using Stub data!");
            return new NotificationServiceStub(this.authService).setPlatformNotificationsAsSeen();
        }
        return new NotificationServiceNetwork(this.authService).setPlatformNotificationsAsSeen();
    }

}
