import {responseHandler, APIResponse} from "../Utilities";
import {
    ActiveValuesResult,
    CompanyListResult,
    CompanyProfileResult,
    SponsoringInformationDetailsResult,
    SubmissionStateResult,
    VacancyResult,
    VacancyRatingResult,
    VacancyRatingCommentsResult,
    TechnologyListResult,
    VacanciesResult,
    VacancyListResult,
    VacancyGoogleForJobsResult,
    PublicSponsoringInformationResult,
    CompanyVacancyLocationsListResult,

} from "./CompanyServiceResponses";

export class CompanyServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    buildCompanySearchQuery(
        pageID,
        search,
        hasChallenge,
        hasJobs,
        has100PercentRemoteOption,
        technologies,
        languages,
        values,
        latitude,
        longitude,
        searchRadius,
        sortIndex
    ) {
        let technologiesString = "";
        for (const technology of technologies) {
            technologiesString += technology.id + ","
        }

        let languagesString = "";
        for (let language of languages) {
            languagesString += language + ","
        }

        let valuesString = "";
        for (let value of values) {
            valuesString += value.nameCode + ","
        }

        let queryObject = {
            search: search,
            has_sponsored_challenge: hasChallenge,
            has_jobs: hasJobs,
            has_100_percent_remote_option: has100PercentRemoteOption,
            technologies: technologiesString,
            programming_languages: languagesString,
            values: valuesString,
        };
        if (pageID > 0) {
            queryObject.pageID = pageID;
        }
        if (searchRadius) {
            queryObject.latitude = latitude;
            queryObject.longitude = longitude;
            queryObject.max_radius = searchRadius;
        }

        if (sortIndex) {
            queryObject.sort_index = sortIndex;
        }

        return new URLSearchParams(queryObject).toString();
    }


    getAllCompanies(
        search,
        hasChallenge,
        hasJobs,
        has100PercentRemoteOption,
        technologies,
        languages,
        values,
        latitude,
        longitude,
        searchRadius,
        sortIndex
    ) {
        let result = new CompanyListResult();
        let queryString = this.buildCompanySearchQuery(
            0,
            search,
            hasChallenge,
            hasJobs,
            has100PercentRemoteOption,
            technologies,
            languages,
            values,
            latitude,
            longitude,
            searchRadius,
            sortIndex
        );

        fetch(window.COMPANY_API_URL + "/api/company/all/?" + queryString, {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getCompanyList(
        pageID = 1,
        search,
        hasChallenge,
        hasJobs,
        has100PercentRemoteOption,
        technologies,
        languages,
        values,
        latitude,
        longitude,
        searchRadius,
        sortIndex
    ) {
        let result = new CompanyListResult();
        let queryString = this.buildCompanySearchQuery(
            pageID,
            search,
            hasChallenge,
            hasJobs,
            has100PercentRemoteOption,
            technologies,
            languages,
            values,
            latitude,
            longitude,
            searchRadius,
            sortIndex
        );

        fetch(window.COMPANY_API_URL + "/api/company/?" + queryString, {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
                if ("pagination" in json) {
                    result.pagination = json.pagination;
                }
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }


    getPublicSponsoringInformation(challengeIds, tracking) {
        let result = new PublicSponsoringInformationResult();

        const queryParams = new URLSearchParams({});
        if (tracking) {
            queryParams.append("stats", "");
        }

        fetch(window.COMPANY_API_URL + "/api/challenge/public_sponsoring_information/?" + queryParams, {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify({challengeIds: challengeIds})
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getSponsoringInformationDetails(challengeId, tracking) {
        let result = new SponsoringInformationDetailsResult();

        const queryParams = new URLSearchParams({});
        if (tracking) {
            queryParams.append("stats", "");
        }

        fetch(window.COMPANY_API_URL + "/api/challenge/" + challengeId + "/sponsoring_information_details/?" + queryParams, {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
            ReferrerPolicy: "origin-when-cross-origin"
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getSubmissionState(challengeId) {
        let result = new SubmissionStateResult();
        fetch(COMPANY_API_URL + "/api/challenge/" + challengeId + "/sponsored_lead/submission_state/", {
            headers: this.authService.getAuthorizationHeader(),
        })
            .then(responseHandler)
            .then((json) => {
                result.loaded = true;
                result.apiData = json.data;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getCompanyProfile(companyId, tracking) {
        const queryParams = new URLSearchParams({});
        if (tracking) {
            queryParams.append("stats", "");
        }
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let apiFetch = await fetch(window.COMPANY_API_URL + "/api/profiles/" + companyId + "/?" + queryParams, {
                        headers: this.authService.getAuthorizationHeader(),
                    });

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        );
        return result;
    }

    buildVacancySearchQuery(
        pageID = 1,
        search,
        hasChallenge,
        hasRemote,
        technologies,
        languages,
        latitude,
        longitude,
        searchRadius,
        ratingIndex,
        sortIndex
    ) {
        let result = new VacancyListResult();

        let technologiesString = "";
        for (const technology of technologies) {
            technologiesString += technology.id + ","
        }

        let languagesString = "";
        for (let language of languages) {
            languagesString += language + ","
        }

        let queryObject = {
            search: search,
            has_sponsored_challenge: hasChallenge,
            has_remote: hasRemote,
            technologies: technologiesString,
            programming_languages: languagesString
        };
        if (pageID) {
            queryObject.pageID = pageID;
        }

        if (searchRadius) {
            queryObject.latitude = latitude;
            queryObject.longitude = longitude;
            queryObject.max_radius = searchRadius;
        }

        if (ratingIndex) {
            queryObject.rating_index = ratingIndex;
        }

        if (sortIndex) {
            queryObject.sort_index = sortIndex;
        }

        return new URLSearchParams(queryObject).toString();
    }

    getVacancyList(
        pageID = 1,
        search,
        hasChallenge,
        hasRemote,
        technologies,
        languages,
        latitude,
        longitude,
        searchRadius,
        ratingIndex,
        sortIndex
    ) {
        let result = new VacancyListResult();
        let queryString = this.buildVacancySearchQuery(
            pageID,
            search,
            hasChallenge,
            hasRemote,
            technologies,
            languages,
            latitude,
            longitude,
            searchRadius,
            ratingIndex,
            sortIndex
        );

        fetch(window.COMPANY_API_URL + "/api/profile/vacancy/?" + queryString, {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
                if ("pagination" in json) {
                    result.pagination = json.pagination;
                }
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getCompanyVacanciesByLocations(
        search,
        hasChallenge,
        hasRemote,
        technologies,
        languages,
        latitude,
        longitude,
        searchRadius,
        ratingIndex,
        sortIndex
    ) {
        let result = new CompanyVacancyLocationsListResult();
        let queryString = this.buildVacancySearchQuery(
            0,
            search,
            hasChallenge,
            hasRemote,
            technologies,
            languages,
            latitude,
            longitude,
            searchRadius,
            ratingIndex,
            sortIndex
        );
        fetch(window.COMPANY_API_URL + "/api/profile/vacancy/all_by_locations/?" + queryString, {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getVacancy(vacancySlugOrId) {
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let apiFetch = await fetch(window.COMPANY_API_URL + "/api/profiles/vacancy/" + vacancySlugOrId + "/", {
                        headers: this.authService.getAuthorizationHeader(),
                    });

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        );
        return result;
    }

    getVacancyGoogleForJobsJson(vacancyId) {
        let result = new VacancyGoogleForJobsResult();
        fetch(window.COMPANY_API_URL + "/api/profiles/vacancy/" + vacancyId + "/google_for_jobs_json/", {
            headers: this.authService.getAuthorizationHeader(),
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getVacancies(companyIdOrSlug, pageID, jobsPerPage) {
        let paginationQuery = "?pageID=" + pageID.toString() + "&entriesPerPage=" + jobsPerPage.toString()
        let result = new VacanciesResult();
        fetch(window.COMPANY_API_URL + "/api/profile/" + companyIdOrSlug + "/vacancy/" + paginationQuery, {
            headers: this.authService.getAuthorizationHeader(),
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
                if ("pagination" in json) {
                    result.pagination = json.pagination;
                }
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getActiveValues() {
        let result = new ActiveValuesResult();
        fetch(window.COMPANY_API_URL + "/api/profile/value/all_active/", {
            headers: this.authService.getAuthorizationHeader(),
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getTechnologyList() {
        let result = new TechnologyListResult();
        fetch(window.COMPANY_API_URL + "/api/technology/", {
            headers: this.authService.getAuthorizationHeader(),
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getVacanciesTechnologyList() {
        let result = new TechnologyListResult();
        fetch(window.COMPANY_API_URL + "/api/profile/vacancy/technology/", {
            headers: this.authService.getAuthorizationHeader(),
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getVacancyRating(vacancyId) {
        let result = new VacancyRatingResult();
        fetch(window.COMPANY_API_URL + "/api/rating/vacancy/" + vacancyId + "/", {
            headers: this.authService.getAuthorizationHeader(),
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getVacancyRatingComments(vacancyId) {
        let result = new VacancyRatingCommentsResult();
        fetch(window.COMPANY_API_URL + "/api/rating/vacancy/" + vacancyId + "/comments/", {
            headers: this.authService.getAuthorizationHeader(),
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    postVacancyRating(vacancyId, ratingData) {
        let result = new VacancyRatingResult();
        fetch(window.COMPANY_API_URL + "/api/rating/vacancy/" + vacancyId + "/rate/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify(ratingData)
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }
}
