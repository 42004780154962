<template>
    <div class="row">
        <div class="col-xs-12 col-sm-offset-1 col-sm-10">
            <div class="content">
                <h1 class="text-center">Willkommen<span v-if="displayName"> zurück</span><span v-if="!displayName"> auf EntwicklerHeld</span><span
                        v-if="displayName">, {{displayName}}</span></h1>
                <h3 v-if="!displayName" class="subtitle">Hier bist du richtig!</h3>
                <p v-if="displayName" class="subtitle">Wir haben das <strong>Onboarding</strong> neu gestaltet und das
                    wollen wir dir
                    nicht vorenthalten :-) Aber keine Sorge, <strong>20 Sekunden</strong> und du bist damit durch! Und
                    falls du dich
                    schon mal gefragt hast, warum es dir bei uns so gut gefällt, hier sind vermutlich einige <strong>Gründe</strong>:
                </p>
                <div class="row">
                    <div class="col-xs-12 col-md-6 col-lg-4">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front">
                                    <i class="dev-laptop" aria-hidden="true"></i>
                                    <h4>Coden & Spaß haben</h4>
                                </div>
                                <div class="card-back">
                                    <p>Es warten spannende und knifflige <strong>Challenges</strong> auf dich. Du
                                        zeigst, was du drauf
                                        hast!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-4">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front">
                                    <i class="dev-code-slash" aria-hidden="true"></i>
                                    <h4>Technologien ausprobieren<br>&<br>Skills verbessern</h4>
                                </div>
                                <div class="card-back">
                                    <p>Wir lieben <strong>Technologien</strong> und das wollen wir mit dir teilen.
                                        Probiere Challenges in
                                        verschiedenen Sprachen oder Frameworks einfach mal aus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-4">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front">
                                    <i class="dev-building-3" aria-hidden="true"></i>
                                    <h4>Tech-Unternehmen kennenlernen<br>&<br>Traumjob finden</h4>
                                </div>
                                <div class="card-back">
                                    <p>Weisst du, welche <strong>Tech-Unternehmen</strong> in deiner Nähe coole Jobs
                                        anbieten? Hier
                                        erfährst du es.<br>Und das Beste: Du bestimmst die Regeln!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-4">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front">
                                    <i class="dev-trophy" aria-hidden="true"></i>
                                    <h4>Erster auf dem Leaderbord werden</h4>
                                </div>
                                <div class="card-back">
                                    <p>Erkämpfe dir den <strong>1. Platz</strong> in den Ranglisten und erhalte Badges
                                        und Achievements
                                        für dein Können!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-4">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front">
                                    <i class="dev-gift"></i>
                                    <h4>Coins verdienen<br>&<br>Gutscheine abfassen</h4>
                                </div>
                                <div class="card-back">
                                    <p>Für deine Anstrengungen rollt der Taler und du kannst sie in unserem Shop gegen
                                        <strong>Gutscheine</strong> tauschen oder für eine gute Sache spenden!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-4">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front">
                                    <i class="dev-hammer" aria-hidden="true"></i>
                                    <h4>Community<br>&<br>Challenges selber bauen</h4>
                                </div>
                                <div class="card-back">
                                    <p>Werde aktiv in der <strong>EntwicklerHeld-Community</strong>! Denk dir z.B.
                                        selbst mal eine
                                        Challenge aus. Wer knackt deinen Code?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 v-if="!displayName" class="text-center">Jetzt nur noch schnell Profil anlegen und direkt
                    loscoden!</h3>
                <h3 v-if="displayName" class="text-center"><strong>20 Sekunden</strong> ... ab jetzt!</h3>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WelcomeComponent",
        props: {
            displayName: {
                type: String,
                default: "",
            },
        },
        data() {
            return {}
        },
        methods: {
            validate() {
                this.$emit("on-validate", "", true);
                return true;
            },
        }
    }
</script>

<style scoped>
    h1 {
        font-weight: bold;
    }

    h3 {
        margin: 3rem 0;
        line-height: 1.4em;
    }

    h4 {
        color: gray;
    }


    p {
        color: #999999;
        text-align: center;
    }

    .subtitle {
        margin-bottom: 2rem;
        text-align: center;
    }

    .content {
        width: 100%;
    }

    .card {
        background-color: transparent;
        border-radius: 10px;
        width: auto;
        height: 200px;
        margin-bottom: 18px;
    }

    .card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }

    .card:hover .card-inner {
        transform: rotateY(180deg);
    }

    .card-inner .card-front h4, .card-inner .card-front i {
        opacity: 1;
        transition: opacity ease 0.3s;
    }

    .card:hover .card-inner .card-front h4, .card:hover .card-inner .card-front i {
        opacity: 0;
    }

    .card:hover {
      cursor: default;
    }

    .card-front, .card-back {
        padding: 2rem;
        position: absolute;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: opacity ease 1ms;
    }

    .card-front {
        background: #08AB991A;
    }

    .card-front::before,
    .card-front::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border-color: transparent;
        border-style: solid;
    }

    .card-front::before {
        border-width: 1.5em;
        border-right-color: #08AB99;
        border-top-color: #08AB99;
    }

    .card-front::after {
        border-radius: 0.4em;
        border-width: 1.35em;
        border-right-color: #08AB99;
        border-top-color: #08AB99;
    }

    .card-front i {
        font-size: 4rem;
        color: #08AB99;
    }

    .card-back {
        background-color: #08AB991A;
        transform: rotateY(180deg);
        border-radius: 10px;
    }

    @media (min-width: 768px) {
        h3 {
            margin: 1.5rem 0;
        }

        p {
            font-size: 120%;
        }
    }
</style>