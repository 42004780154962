import {Cache, responseHandler} from "../Utilities";
import {
    RedeemCouponCodeResult,
    RewardListResult,
    UserCoinsResult,
    ExchangeResult,
    DonationResult, Donate, DonationLeaderBoardResult,
} from "./RewardServiceResponses";

const cache = new Cache();


function getFromCacheIfThere(withoutCache, key, result) {
    if (withoutCache) {
        cache.pushToCache(key, null);
    }

    let valueFromCache = cache.getValueFromCache(key);
    if (valueFromCache) {
        return valueFromCache;
    }

    cache.pushToCache(key, result);
    return null;
}

export class RewardServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    getAvailableRewards() {
        let result = new RewardListResult();
        fetch(window.REWARD_API_URL + "/api/reward/",
            {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.rewards = json;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getUserCoins(withoutCache = false, valueBefore = 0) {
        let key = "user-coins";
        let result = new UserCoinsResult();

        let valueFromCache = getFromCacheIfThere(withoutCache, key, result);
        if (valueFromCache) {
            return valueFromCache;
        }

        result.coins = valueBefore;

        fetch(window.REWARD_API_URL + "/api/coin/", {
            headers: this.authService.getAuthorizationHeader()
        })
            .then(responseHandler)
            .then((json) => {
                result.coins = json.coins;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    exchangeReward(rewardId, email, userAddress) {
        let result = new ExchangeResult();
        fetch(window.REWARD_API_URL + "/api/reward/" + rewardId + "/", {
            method: 'POST',
            body: JSON.stringify({email: email, userAddress: userAddress}),
            headers: this.authService.getAuthorizationHeader()
        })
            .then(responseHandler)
            .then((json) => {
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    redeemCouponCode(couponCode) {
        let result = new RedeemCouponCodeResult();
        fetch(window.REWARD_API_URL + "/api/coupon/redeem_code/", {
            method: 'POST',
            headers: this.authService.getAuthorizationHeader(),
            body: JSON.stringify({couponCode: couponCode})
        })
        .then((response) => {
            return response.json()
        })
        .then((json) => {
            result.loaded = true;
            if (json.error) {
                result.error = json.error;
            }
            if (json.data) {
                result.apiResult = json.data;
            }
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = {message: "Ein unerwarteter Fehler ist aufgetreten, bitte wenden sie sich an den Support"};
        });
        return result;
    }

    getDonations() {
        let result = new DonationResult();
        fetch(window.REWARD_API_URL + "/public_api/donations/",
            {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiResult = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    donate(coins) {
        let result = new Donate();
        fetch(window.REWARD_API_URL + "/api/donations/donate/", {
            method: 'POST',
            headers: this.authService.getAuthorizationHeader(),
            body: JSON.stringify({coins: coins})
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getDonationLeaderBoard(pageId) {
        let url = window.REWARD_API_URL + "/public_api/donations/leaderboard/";
        if(pageId !== null){
            url += "?pageID=" + pageId;
        }
        let result = new DonationLeaderBoardResult();
        fetch(url, {
            method: 'GET',
            headers: this.authService.getAuthorizationHeader(),
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
            if ("pagination" in json) {
                result.pagination = json.pagination;
            }
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }
}
