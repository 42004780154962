<template>
    <div class="row form-row">
        <div class="col-xs-12">
            <h3 class="text-center">
                {{name}}, wie viel professionelle Erfahrung hast du in der IT?
            </h3>
            <div class="binary-tiles">
                <div v-if="workExperience===10" class="binary-tile-more blink-animation"
                     :class="workExperience===10 ? 'more-binary-tiles' : ''"></div>
                <div v-if="workExperience===10" class="binary-tile-more blink-animation"
                     :class="workExperience===10 ? 'more-binary-tiles' : ''"></div>

                <div class="binary-tile" :class="{'is-one': getValueForBinaryTile[0], 'blink-animation': workExperience===10}"
                     id="tile1"></div>
                <div class="binary-tile" :class="{'is-one': getValueForBinaryTile[1], 'blink-animation': workExperience===10}"
                     id="tile2"></div>
                <div class="binary-tile" :class="{'is-one': getValueForBinaryTile[2], 'blink-animation': workExperience===10}"
                     id="tile3"></div>
                <div class="binary-tile" :class="{'is-one': getValueForBinaryTile[3], 'blink-animation': workExperience===10}"
                     id="tile4"></div>

                <div v-if="workExperience===10" class="binary-tile-more blink-animation"
                     :class="workExperience===10 ? 'more-binary-tiles' : ''"></div>
                <div v-if="workExperience===10" class="binary-tile-more blink-animation"
                     :class="workExperience===10 ? 'more-binary-tiles' : ''"></div>
            </div>
            <div class="col-md-offset-3 col-xs-12 col-md-6">
                <div class="d-flex justify-content-center my-4">
                    <div class="slidecontainer">
                        <vue-slider
                                v-model="workExperience"
                                :process="process"
                                :process-style="{backgroundColor: '#08AB99'}"
                                :dot-style="{backgroundColor: '#08AB99', boxShadow: 'none'}"
                                :tooltip-style="{backgroundColor: '#08AB99', borderColor: '#08AB99'}"
                                :marks="marks"
                                :tooltip="'always'"
                                :min="0"
                                :height="8"
                                :max="10"
                                :tooltip-formatter="formatter"
                                :dotSize="25"
                                :dotOptions="{focusStyle: {boxShadow: 'none'}}"
                                :railStyle="{backgroundColor: '#F2F7FD', height: '8px', borderRadius: '4px'}"
                        >
                        </vue-slider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/dist-css/vue-slider-component.css'
    import 'vue-slider-component/theme/default.css'

    export default {
        name: "WorkExperienceForm",
        components: {
            VueSlider,
        },
        data() {
            return{
                workExperience: 0,
                process: dotsPos =>[[0, dotsPos[0]]],
                formatter: function(value) {
                    if (value < 10) {
                        return value + " Jahre"
                    }
                    return "10+ jahre"
                },
                marks: {
                    '0': '0',
                    '10': '10+',
                },
                isOne: [false, false, false, false],
            }
        },
        props: {
            name: {
                type: String,
                default: "EntwicklerHeld",
            },
            nextFunction: {}
        },
        validations: {
            workExperience: {
                required,
            },
            form: ["workExperience"],
        },
        computed: {
            getValueForBinaryTile () {
                let number = this.padWithZeroes(this.workExperience.toString(2)).split('');
                let isOne = [false, false, false, false];
                for(let index = 0; index < number.length; index++){
                    isOne[index] = number[index]==="1";
                }
                return isOne;
            },
        },
        methods: {
            validate() {
                this.$v.form.$touch();
                let isValid = !this.$v.form.$invalid;
                this.$emit("on-validate", this.$data, isValid);
                return isValid;
            },
            padWithZeroes(number) {
                let binaryString = ''+ number;
                if(binaryString.length === 1) {
                    return '000' + number;
                }
                if(binaryString.length === 2) {
                    return '00' + number;
                }
                if(binaryString.length === 3) {
                    return '0' + number;
                }
                return binaryString;
            },
        }
    }
</script>

<style scoped>
     :root {
        --animation-delay: 0.1s;
    }

    h3 {
        color: #4c4c4c;
        margin: 5rem 0;
        line-height: 1.4em;
    }

    .slidecontainer {
        margin-top: 3rem;
        margin-bottom: 7rem;
    }

    .binary-tiles {
        justify-content: center;
        margin-bottom: 4rem;
        display: flex;
        flex-direction: row;
        margin-top: 5rem;
    }

    .binary-tile, .binary-tile-more {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        height: 50px;
        width: 50px;
        border-radius: 15px;
        background-color: #08AB99;
        opacity: 0.5;
        transition: all 0.5s ease-in;
    }

    .is-one {
        opacity: 1;
    }

    .more-binary-tiles {
        animation-name: binaryTileAnimation;
        animation-duration: 0.25s;
    }

    .blink-animation {
        animation-name: blinkAnimation;
        animation-duration: 0.5s;
        animation-direction:  alternate;
        animation-iteration-count: infinite !important;

    }

     @keyframes binaryTileAnimation {
        from {
            transform: scale(0);
        }

        to {
            transform: scale(1);
        }
    }

    @keyframes blinkAnimation {
        from {
            opacity: 0.5;
        }
        to {
            opacity: 1;
        }
    }

    @media (min-width: 768px){
        h3 {
            margin: 1.5rem 0;
        }
        p {
          font-size: 120%;
        }
    }

    @media (max-width: 768px) {
        .slidecontainer {
          margin-top: 3rem;
          margin-bottom: 2rem;
        }
      }
</style>