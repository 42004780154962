import {responseHandler} from '../Utilities.js';
import {ProvisionResult} from "./ProvisionerServiceResponses";

export default class ProvisionerServiceNetwork {

    constructor(auth) {
        this.authService = auth;
    }

    getWorkspaceUrl(challengeId) {
        let result = new ProvisionResult();
        fetch(window.PROVISIONER_API_URL + "/api/task/" + challengeId + "/provision_info/",
            {headers: this.authService.getAuthorizationHeader()}
        )
        .then(responseHandler)
        .then((json) => {
            result.loaded = true;
            result.apiData = json;
        }).catch((error) => {
            if (error.status !== 404) {
                console.error(error);
            }
            result.loaded = true;
            result.error = error;
        });
        return result;
    }
}
