export class CompanyListResult {
    constructor() {
        this.error = null;
        this.apiData = [];
        this.loaded = false;
    }
}

export class PublicSponsoringInformationResult {
    constructor() {
        this.error = null;
        this.apiData = [];
        this.loaded = false;
    }
}

export class SponsoringInformationDetailsResult {
    constructor() {
        this.error = null;
        this.apiData = {};
        this.loaded = false;
    }
}

export class SubmissionStateResult {
    constructor() {
        this.error = null;
        this.loaded = false;
        this.apiData = {};
    }
}

export class CompanyProfileResult {
    constructor() {
      this.error = null;
      this.loaded = false;
      this.apiData = {};
    }
}

export class CompanyVacancyLocationsListResult {
    constructor() {
        this.error = null;
        this.apiData = [];
        this.loaded = false;
    }
}

export class VacancyListResult {
    constructor() {
        this.error = null;
        this.apiData = [];
        this.loaded = false;
    }
}

export class VacancyResult {
    constructor() {
      this.error = null;
      this.loaded = false;
      this.apiData = {};
    }
}

export class VacancyRatingResult {
    constructor() {
      this.error = null;
      this.loaded = false;
      this.apiData = {};
    }
}

export class ActiveValuesResult {
    constructor() {
      this.error = null;
      this.loaded = false;
      this.apiData = {};
    }
}

export class VacancyRatingCommentsResult {
    constructor() {
      this.error = null;
      this.loaded = false;
      this.apiData = {};
    }
}

export class VacancyGoogleForJobsResult {
    constructor() {
      this.error = null;
      this.loaded = false;
      this.apiData = {};
    }
}

export class VacanciesResult {
    constructor() {
      this.error = null;
      this.loaded = false;
      this.apiData = {};
    }
}

export class TechnologyListResult {
    constructor() {
      this.error = null;
      this.loaded = false;
      this.apiData = {};
    }
}

export class VacanciesTechnologyListResult {
    constructor() {
      this.error = null;
      this.loaded = false;
      this.apiData = {};
    }
}
