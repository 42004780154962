<template>
    <span>
        <input class="technology-checkbox"
               type="checkbox"
               :value="inputValue"
               :id="inputId"
               :checked="checked"
               :name="inputName"
               v-model="model"
        />
        <label class="challenge-group-languages" :key="inputValue" :for="inputId"
               :class="{
                'bg-tec-python': tecName === 'python',
                'bg-tec-dockerfile': tecName === 'dockerfile',
                'bg-tec-java': tecName === 'java',
                'bg-tec-javascript': tecName === 'javascript',
                'bg-tec-csharp': tecName === 'csharp',
                'bg-tec-cpp': tecName === 'cpp',
                'bg-tec-php': tecName === 'php',
                'inactive': !checked,
                'isNotMobile': !isMobile(),
            }">
            <i class="fa fa-code"></i>
            <span class="technology__name">{{ label }}</span>
        </label>
    </span>
</template>

<script>
export default {
    name: "TechnologyItem",
    components: {},
    props: {
        label: String,
        inputValue: Array,
        tecName: String,
        inputName: String,
        inputId: String,
        checked: Boolean
    },
    data() {
        return {
            id: null,
            isSelected: false
        }
    },
    computed: {
        model: {
            get() {return this.inputValue},
            set(newValue) { this.$emit('input', newValue) }
        }
    },
    mounted() {
        this.id = this._uid
    },
    methods: {
        isMobile: function () {
            return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        handleInput (event) {
            this.$emit('input', event.target.value)
        }
    },
}
</script>

<style scoped>
form input[type="checkbox"] + label.challenge-group-languages {
    font-family: "Source Code Pro", sans-serif;
    color: #08AB99;
    overflow: hidden;
    padding: 3px 20px;
    margin-bottom: 8px;
    border-radius: 100px;
    background-color: white;
    margin-right: 1rem;
    line-height: 1.6;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 4px 15px rgba(13, 51, 85, 0.2);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
    user-select: None;
    display: inline-block;
}

form input[type="checkbox"] + label.challenge-group-languages:before {
    content: none;
}

.challenge-group-languages > .fa {
    position: absolute;
    bottom: -20%;
    left: -8%;
    font-size: 28px;
    color: rgba(255, 255, 255, 0.15);
}

.technology__name {
    font-size: 11px;
}

.technology-checkbox {
    display: none;
}

.technology-checkbox:checked ~ .challenge-group-languages {
    color: white;
    background-color: #08AB99;
}

.technology-checkbox:checked ~ .bg-tec-python {
    background-color: #3c6382 !important;
    color: white !important;
}

.technology-checkbox:checked ~ .bg-tec-dockerfile,
.technology-checkbox:checked ~ .bg-tec-docker {
    background-color: #2C90DE !important;
    color: white !important;
}

.technology-checkbox:checked ~ .bg-tec-java {
    background-color: #fa983a !important;
    color: white !important;
}

.technology-checkbox:checked ~ .bg-tec-javascript {
    background-color: #f6b93b !important;
    color: white !important;
}

.technology-checkbox:checked ~ .bg-tec-csharp {
    background-color: #803788 !important;
    color: white !important;
}

.technology-checkbox:checked ~ .bg-tec-cpp {
    background-color: #e55039 !important;
    color: white !important;
}

.technology-checkbox:checked ~ .bg-tec-php {
    background-color: #6a89cc !important;
    color: white !important;
}

@media only screen and (min-width: 992px) {
    form input[type="checkbox"] + label.challenge-group-languages:hover {
        cursor: pointer;
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    form input[type="checkbox"] + label.challenge-group-languages:hover {
        color: white;
        background-color: #08AB99;
    }

    form input[type="checkbox"] + label.challenge-group-languages.bg-tec-python:hover {
        background-color: #3c6382 !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.challenge-group-languages.bg-tec-dockerfile:hover,
    form input[type="checkbox"] + label.challenge-group-languages.bg-tec-docker:hover {
        background-color: #2C90DE !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.challenge-group-languages.bg-tec-java:hover {
        background-color: #fa983a !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.challenge-group-languages.bg-tec-javascript:hover {
        background-color: #f6b93b !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.challenge-group-languages.bg-tec-csharp:hover {
        background-color: #803788 !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.challenge-group-languages.bg-tec-cpp:hover {
        background-color: #e55039 !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.challenge-group-languages.bg-tec-php:hover {
        background-color: #6a89cc !important;
        color: white !important;
    }

}

</style>