export class ResumeResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class CreateWorkResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class CreateEducationResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class UpdateWorkResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class UpdateEducationResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class DeleteWorkResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class DeleteEducationResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}