export class GetChannelsResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class GetChannelDetailsResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class SendMessageResult {
    constructor() {
        this.error = null;
        this.apiData = {};
        this.loaded = false;
    }
}

export class SaveDraftMessageResult {
    constructor() {
        this.error = null;
        this.apiData = {};
        this.loaded = false;
    }
}

export class CloseChatResult {
    constructor() {
        this.error = null;
        this.apiData = {};
        this.loaded = false;
    }
}