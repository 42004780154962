import {FlowServiceStub} from "./FlowServiceStub";
import {FlowServiceNetwork} from "./FlowServiceNetwork";

export default class FlowService {
    constructor(auth) {
        this.authService = auth;
    }

    getFlowContent(taskId) {
        if (typeof window.FLOW_API_URL === "undefined" || window.FLOW_API_URL === "") {
            console.info("window.FLOW_API_URL is not set! Using Stub data!");
            return new FlowServiceStub(this.authService).getFlowContent(taskId);
        }
        return new FlowServiceNetwork(this.authService).getFlowContent(taskId);
    }

    runTest(campaignId, taskId, data) {
        if (typeof window.FLOW_API_URL === "undefined" || window.FLOW_API_URL === "") {
            console.info("window.FLOW_API_URL is not set! Using Stub data!");
            return new FlowServiceStub(this.authService).runTest(campaignId, taskId, data);
        }
        return new FlowServiceNetwork(this.authService).runTest(campaignId, taskId, data);
    }

    getTestStatus(taskId) {
        if (typeof window.FLOW_API_URL === "undefined" || window.FLOW_API_URL === "") {
            console.info("window.FLOW_API_URL is not set! Using Stub data!");
            return new FlowServiceStub(this.authService).getTestStatus(taskId);
        }
        return new FlowServiceNetwork(this.authService).getTestStatus(taskId);
    }

}

