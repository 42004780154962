export class RatingPost {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class Ratings {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}

export class CodeComments {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}

export class CodeCommentPostResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class CommentForCodeComment {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class CommentForSolutionRating {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class RatedSolutions {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}

export class ReceivedSolutionRatings {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}

export class Solution {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class RankTheCodeUser {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class RandomSolution {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class ChallengeStatistics {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class ChallengeSolutions {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class OtherSolutionRatings {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}
