export default class GoogleAnalyticsService {
    constructor(gtag) {
        this.$gtag = gtag;
    }

    sendEvent(category, action, label) {
        this.$gtag.event(action, {
            'event_category': category,
            'event_label': label,
        })
    }

    setUserId(userId) {
        this.$gtag.set({'user_id': userId});
    }

    sendPageView(route) {
        this.$gtag.pageview(route);
    }
}
