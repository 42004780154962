<template>
    <div class="row form-row">
        <div class="col-xs-12">
            <h3 class="text-center">
                Wähle einen Avatar
            </h3>
            <p class="subtitle">Der ist für alle deine Inkognito-Missionen auf der Plattform hilfreich.</p>
            <div class="col-md-offset-2 col-xs-12 col-md-8">
                <div class="avatar-selector">
                    <div class="avatars">
                        <a class="avatar-wrapper"
                           @click="selectAvatar(selectedImageList[0], selectedColorList[0])"
                           :class="{selectedAvatarAnimation: !finished && selectedImage && selectedImage === selectedImageList[0], 'isDisabled': selectedImage}">
                            <div class="avatar"
                                 :class="{avatarAnimation:showAnimation,
                                    selectedAvatarAnimationOther: selectedImage && selectedImage !== selectedImageList[0]}"
                                 :style="{backgroundColor: selectedColorList[0]}">
                                <img v-if="selectedImageList" :src="selectedImageList[0]">
                            </div>
                        </a>
                        <a class="avatar-wrapper" @click="selectAvatar(selectedImageList[1], selectedColorList[1])"
                           :class="{selectedAvatarAnimation: selectedImage && selectedImage === selectedImageList[1], 'isDisabled': selectedImage}" >
                            <div class="avatar"
                                 :class="{avatarAnimation:showAnimation,
                                    selectedAvatarAnimationOther: selectedImage && selectedImage !== selectedImageList[1]}"
                                 :style="{backgroundColor: selectedColorList[1]}">
                                <img v-if="selectedImageList" :src="selectedImageList[1]">
                            </div>
                        </a>
                        <a class="avatar-wrapper" @click="selectAvatar(selectedImageList[2], selectedColorList[2])"
                           :class="{selectedAvatarAnimation: selectedImage && selectedImage === selectedImageList[2], 'isDisabled': selectedImage}">
                            <div class="avatar"
                                 :class="{avatarAnimation:showAnimation,
                                    selectedAvatarAnimationOther: selectedImage && selectedImage !== selectedImageList[2]}"
                                 :style="{backgroundColor: selectedColorList[2]}">
                                <img v-if="selectedImageList" :src="selectedImageList[2]">
                            </div>
                        </a>
                        <a class="avatar-wrapper" @click="selectAvatar(selectedImageList[3], selectedColorList[3])"
                           :class="{selectedAvatarAnimation: selectedImage && selectedImage === selectedImageList[3], 'isDisabled': selectedImage}">
                            <div class="avatar"
                                 :class="{avatarAnimation:showAnimation,
                                    selectedAvatarAnimationOther: selectedImage && selectedImage !== selectedImageList[3]}"
                                 :style="{backgroundColor: selectedColorList[3]}">
                                <img v-if="selectedImageList" :src="selectedImageList[3]">
                            </div>
                        </a>
                        <a class="avatar-wrapper" @click="selectAvatar(selectedImageList[4], selectedColorList[4])"
                           :class="{selectedAvatarAnimation: selectedImage && selectedImage === selectedImageList[4], 'isDisabled': selectedImage}">
                            <div class="avatar"
                                 :class="{avatarAnimation:showAnimation,
                                    selectedAvatarAnimationOther: selectedImage && selectedImage !== selectedImageList[4]}"
                                 :style="{backgroundColor: selectedColorList[4]}">
                                <img v-if="selectedImageList" :src="selectedImageList[4]">
                            </div>
                        </a>
                        <a class="avatar-wrapper" @click="selectAvatar(selectedImageList[5], selectedColorList[5])"
                           :class="{selectedAvatarAnimation: selectedImage && selectedImage === selectedImageList[5], 'isDisabled': selectedImage}">
                            <div class="avatar"
                                 :class="{avatarAnimation:showAnimation,
                                    selectedAvatarAnimationOther: selectedImage && selectedImage !== selectedImageList[5]}"
                                 :style="{backgroundColor: selectedColorList[5]}">
                                <img v-if="selectedImageList" :src="selectedImageList[5]">
                            </div>
                        </a>
                        <a class="avatar-wrapper" @click="selectAvatar(selectedImageList[6], selectedColorList[6])"
                           :class="{selectedAvatarAnimation: selectedImage && selectedImage === selectedImageList[6], 'isDisabled': selectedImage}">
                            <div class="avatar"
                                 :class="{avatarAnimation:showAnimation,
                                    selectedAvatarAnimationOther: selectedImage && selectedImage !== selectedImageList[6]}"
                                 :style="{backgroundColor: selectedColorList[6]}">
                                <img v-if="selectedImageList" :src="selectedImageList[6]">
                            </div>
                        </a>
                    </div>

                    <div class="new-avatar-button">
                        <button v-on:click="counter += 1" class="generateAvatar" v-if="!selectedImage"
                                @click="generate()"><i
                                class="dev-repeat"></i> Neue Avatare generieren
                        </button>
                        <button v-on:click="counter += 1" class="generateAvatar" v-if="selectedImage"
                                @click="generateNew()"><i
                                class="dev-repeat"></i> Doch anderen Avatar wählen
                        </button>
                    </div>
                  <p v-if="!selectedImage">
                    <span v-if="counter>=5 && counter < 10">Macht wohl Spaß?</span>
                    <span v-if="counter>=10 && counter < 15">Unser Ziel war es, einen blitzschnellen Registrierungsprozess zu entwickeln. Hat wohl nicht so geklappt, oder?</span>
                    <span v-if="counter>=15 && counter < 20">Du willst dich nicht entscheiden? Du kannst deinen Avatar auch später noch ändern.</span>
                    <span v-if="counter>=20">😴😴😴</span>
                  </p>
                  <p>
                    <span v-if="$v.selectedImage.$error && !$v.selectedImage.required" class="error">Du musst hier genau einen Avatar auswählen.</span>
                  </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as avatarList from "../../avatarlist";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "AvatarForm",
        props: {
            name: {
                type: String,
                default: "EntwicklerHeld",
            },
            nextFunction: {}
        },
        data() {
            return {
                counter: 0,
                selectedImageList: null,
                showAnimation: true,
                colors: [
                    '#ffeaa7',
                    '#fdcb6e',
                    '#00b894',
                    '#55efc4',
                    '#81ecec',
                    '#00cec9',
                    '#fab1a0',
                    '#e17055',
                    '#ff7675',
                    '#d63031',
                    '#74b9ff',
                    '#0984e3',
                    '#a29bfe',
                    '#6c5ce7',
                    '#dfe6e9',
                    '#b2bec3',
                    '#636e72',
                    '#2d3436',
                ],
                selectedColorList: null,
                selectedImage: null,
                selectedColor: null,
                finished: false
            }
        },
        validations: {
            selectedImage: {
                required
            },
            selectedColor: {
                required
            },
            form: ["selectedImage", "selectedColor"],
        },
        methods: {
            randomAvatarList(items) {
                return [
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)]
                ];
            },
            randomColorList(items) {
                return [
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)],
                    items[Math.floor(Math.random() * items.length)]
                ];
            },
            generate() {
                this.showAnimation = false;
                this.selectedImageList = [
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[0],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[1],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[2],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[3],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[4],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[5],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[6],
                ];
                this.selectedColorList = this.randomColorList(this.colors);
                setTimeout(() => {
                    this.showAnimation = true;
                }, 0)

            },
            generateNew() {
                this.selectedImage = null;
                this.showAnimation = false;
                this.selectedImageList = [
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[0],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[1],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[2],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[3],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[4],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[5],
                    "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[6],
                ];
                this.selectedColorList = this.randomColorList(this.colors);
                setTimeout(() => {
                    this.showAnimation = true;
                }, 0)

            },
            validate() {
                this.$v.form.$touch();
                let isValid = !this.$v.form.$invalid;
                this.$emit("on-validate", this.$data, isValid);
                return isValid;
            },
            selectAvatar(selectedImage, selectedColor) {
                if (this.selectedImage !== null) {
                    return;
                }
                this.selectedImage = selectedImage;
                this.selectedColor = selectedColor.substring(1);
                this.$v.selectedImage.$touch()
                this.$v.selectedColor.$touch()
                setTimeout(() => {
                    this.nextFunction()
                }, 1000);
                this.counter = 0;
            }
        },
        created() {
            this.selectedImageList = [
                "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[0],
                "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[1],
                "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[2],
                "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[3],
                "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[4],
                "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[5],
                "https://res.cloudinary.com/dspi5zyaa/image/upload/v1597653561/eh-platform/user_avatars/" + this.randomAvatarList(avatarList.avatars)[6],
            ];
            this.selectedColorList = [
                this.randomColorList(this.colors)[0],
                this.randomColorList(this.colors)[1],
                this.randomColorList(this.colors)[2],
                this.randomColorList(this.colors)[3],
                this.randomColorList(this.colors)[4],
                this.randomColorList(this.colors)[5],
                this.randomColorList(this.colors)[6]
            ];
        }
    }
</script>

<style scoped>
    h3 {
        color: #4c4c4c;
        line-height: 1.4em;
        margin: 1.5rem 0;
    }

    p {
        margin-top: 1rem;
        color: #999999;
        text-align: center;
    }

    .error {
        display: block;
        color: #e74c3c;
        font-size: 12px;
        margin: 1rem 0 0 0;
        text-align: center;
    }

    .avatar-selector {
        width: 100%;
        text-align: center;
    }

    .avatars {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 41rem;
        position: relative;
    }

    .avatar-wrapper {
        width: 12rem;
        height: 12rem;
        position: absolute;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .avatar-wrapper:nth-child(1) {
        transform: translate(0.0rem, -14.25rem);
    }

    .avatar-wrapper:nth-child(2) {
        transform: translate(-12.5rem, -7.25rem);
    }

    .avatar-wrapper:nth-child(3) {
        transform: translate(12.5rem, -7.25rem);
    }

    .avatar-wrapper:nth-child(4) {
        transform: translate(0.0rem, 0.00rem);
    }

    .avatar-wrapper:nth-child(5) {
        transform: translate(-12.5rem, 7.25rem);
    }

    .avatar-wrapper:nth-child(6) {
        transform: translate(12.5rem, 7.25rem);
    }

    .avatar-wrapper:nth-child(7) {
        transform: translate(0.0rem, 14.25rem);
    }

    .avatar {
        animation-delay: 100ms;
        width: 100%;
        height: 100%;
        animation-fill-mode: both;
        background-color: #00bf8f;
        border-radius: 99px;
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: box-shadow, transform;
        transition-property: box-shadow, transform;
        display: flex;
        justify-content: center;
        -webkit-transition: transform 0.3s ease-in-out !important;
    }

    .hideAvatar {
        opacity: 0;
    }

    .avatarAnimation {
        animation-name: profilePictureAnimation;
        animation-duration: 1s;
    }

    .selectedAvatarAnimation {
        animation-name: selectedAvatarGetBig;
        animation-fill-mode: forwards;
        animation-duration: 1s;
    }

    .selectedAvatarAnimationOther {
        animation-name: selectedAvatarFadeOut;
        animation-duration: 0.5s;
    }

    .avatar-wrapper:nth-child(1) > .avatarAnimation {
        animation-delay: 0.0s;
    }

    .avatar-wrapper:nth-child(2) > .avatarAnimation {
        animation-delay: 0.05s;
    }

    .avatar-wrapper:nth-child(3) > .avatarAnimation {
        animation-delay: 0.1s;
    }

    .avatar-wrapper:nth-child(4) > .avatarAnimation {
        animation-delay: 0.15s;
    }

    .avatar-wrapper:nth-child(5) > .avatarAnimation {
        animation-delay: 0.2s;
    }

    .avatar-wrapper:nth-child(6) > .avatarAnimation {
        animation-delay: 0.25s;
    }

    .avatar-wrapper:nth-child(7) > .avatarAnimation {
        animation-delay: 0.3s;
    }

    .avatar img {
        height: 65%;
        z-index: 10;
        transform: translateY(25%);
    }

    .avatar:hover {
        cursor: pointer;
        box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.5) !important;
        /*-webkit-transform: scale(1.1) !important;*/
        /*transform: scale(1.1) !important;*/
    }

    .avatar:checked {
        transform: scale(1.1) !important;
        border: 1px solid red;
    }

    .generateAvatar {
        border: none;
        background-color: transparent;
        color: grey;
        -webkit-transition: transform 0.3s ease-in-out;
    }

    .generateAvatar:hover {
        transform: scale(1.1) !important;
    }

    .generateAvatar:focus {
        outline: none;
    }

    @keyframes selectedAvatarGetBig {
        to {
            /*top: 50%;
            left: 50%;*/
            transform: translate(-0%, -0%) scale(2);
        }
    }

    @keyframes selectedAvatarFadeOut {
        to {
            opacity: 0;
        }
    }

    @keyframes profilePictureAnimation {
        from {
            transform: scale(0);
            opacity: 0;
        }

        to {
            transform: scale(1);
            opacity: 1;
        }
    }

    a.isDisabled, .isDisabled .avatar, .isDisabled .avatar:hover{
        cursor: default;
        box-shadow: none !important;
    }

    @media (min-width: 768px){
        p {
          font-size: 120%;
        }
        .new-avatar-button {
          font-size: 120%;
          margin-top: 2rem;
        }
    }

    @media (max-width: 767px) {
      .avatars {
        height: 30rem;
      }

      .vue-form-wizard .wizard-tab-content {
        padding: 10px 20px 10px;
      }

      .avatar-wrapper {
        width: 8rem;
        height: 8rem;
      }

      .avatar-wrapper:nth-child(1) {
        transform: translate(0.0rem, -9.25rem);
      }

      .avatar-wrapper:nth-child(2) {
        transform: translate(-8.5rem, -4.75rem);
      }

      .avatar-wrapper:nth-child(3) {
        transform: translate(8.5rem, -4.75rem);
      }

      .avatar-wrapper:nth-child(4) {
        transform: translate(0.0rem, 0.00rem);
      }

      .avatar-wrapper:nth-child(5) {
        transform: translate(-8.5rem, 4.75rem);
      }

      .avatar-wrapper:nth-child(6) {
        transform: translate(8.5rem, 4.75rem);
      }

      .avatar-wrapper:nth-child(7) {
        transform: translate(0.0rem, 9.25rem);
      }

      .new-avatar-button {
        margin-top: 0;
      }
    }
</style>