<template>
    <div>
        <div :class='{"in": openSwitch, "open": openSwitch}' class="modal fade donate-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body" style="padding: 4rem;">
                        <div class="claimer" style="margin-left: 50%; transform: translateX(-50%); text-align: center">
                            <p style="color: #e74c3c; font-family: 'Source Code Pro-Bold' !important; margin-top: 5px; font-size: 3rem; margin-bottom: 3px;">Coins spenden</p>
                            <p class="desktop-info" style="font-size: 1.6rem;">
                              Du kannst deine Coins für einen wohltätigen Zweck spenden. Wir wandeln deine Coins in Euro um und spenden den Betrag an die Aktion Deutschland Hilft. Mehr Informationen findest du unter <a href="https://stayhomeandcode.de/" target="_blank">https://stayhomeandcode.de/</a>.
                                <br/><br/>Aktuelle Aktion:
                                <strong><a href="https://www.aktion-deutschland-hilft.de/de/hilfseinsaetze/nothilfe-ukraine/" target="_blank" rel="noopener noreferrer">Nothilfe Ukraine</a></strong>
                                <strong
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Mit deiner Spende unterstützt du das Projekt 'Nothilfe Ukraine' der Aktion Deutschland Hilft."
                                >
                                    <i style="margin-left: 0.3rem;" class="dev-circle-info"></i>
                                </strong>
                            </p>
                            <div v-if="!donationRequest" class="mobile-additional-info" style="font-size: 0.7em; min-height: 50px;">
                                <div>
                                    <div id="entwicklerherz-total-donations">
                                        <p class="entwicklerherz-digit">Aktueller Gesamtbetrag: {{totalDonations}}
                                            <br>Dein Beitrag: {{ donationByUser }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="donationRequest && !donationRequest.loaded" style="text-align: center"><span
                                class="loading-spinner loading-spinner-big"></span></div>
                        <div v-if="donationRequest && donationRequest.loaded && !donationRequest.error"
                             style="font-size: 2rem; text-align: center; margin-top: 2rem;">Danke, du bist toll!
                        </div>
                        <div v-if="donationRequest && donationRequest.loaded && donationRequest.error"
                             style="font-size: 2rem; text-align: center; margin-top: 2rem;">Es ist leider ein Fehler
                            aufgetreten! Bitte versuch es später noch einmal!
                        </div>

                        <div v-if="!userCoinsResult.loaded || !coinExchangeRate.loaded"
                             style="text-align: center"><span
                                class="loading-spinner loading-spinner-big"></span>
                        </div>
                        <div v-if="!donationRequest && userCoinsResult.loaded && !userCoinsResult.error && coinExchangeRate.loaded && !coinExchangeRate.error">
                            <div>
                                <div class="slidecontainer">
                                    <i class="dev-heart min-value-icon"></i>
                                    <input type="range" min="1" :max="userCoinsResult.coins" v-model="coinsToDonate"
                                           class="slider" id="myRange">
                                    <img src="/assets/icons/eherz-new-color.png" class="max-value-icon"/>
                                </div>
                                <p style="color: #e74c3c; font-family: 'Source Sans Pro' !important; font-size: 3rem; text-align: center;">
                                    {{coinsToDonate}} Coins = {{donationInEuro}} €
                                </p>
                            </div>
                        </div>

                        <p style="text-align: center; margin-top: 1rem;">
                            <button v-if="isAuthenticated && isEntwicklerHerzFeatureFlag && (!donationRequest || donationRequest.error)" @click="closeHandler"
                               class="btn btn-secondary btn-margin">
                              Abbrechen
                            </button>
                            <button v-if="isAuthenticated && isEntwicklerHerzFeatureFlag && !donationRequest && userCoinsResult.loaded && !userCoinsResult.error && coinExchangeRate.loaded && !coinExchangeRate.error && userCoinsResult.coins > 0"
                               @click="donate()"
                               class="btn btn-entwicklerherz btn-margin">
                                <i class="dev-heart">&nbsp;</i>Spenden
                            </button>
                            <button v-if="isAuthenticated && isEntwicklerHerzFeatureFlag && donationRequest && donationRequest.loaded && !donationRequest.error"
                               @click="closeHandler"
                               class="btn btn-entwicklerherz btn-margin">
                                <i class="dev-heart">&nbsp;</i>Nice
                            </button>


                        </p>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->

        </div><!-- /.modal -->
        <div v-if="openSwitch" class="modal-backdrop fade in"></div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {eventBus} from "../../main";
    import {services} from "../../main"

    export default {
        name: "DonationModal",
        computed: {
            totalDonations: function () {
                if (!this.donatedCoins.loaded) {
                    return this.totalDonationsBefore;
                }
                let totalDonations = (this.donatedCoins.apiResult.donatedCoins * this.coinExchangeRate.apiResult.exchangeRate).toFixed(2).toString().replace(".", ",") + " €";
                this.totalDonationsBefore = totalDonations;
                return totalDonations;
            },
            donationByUser: function () {
                if (!this.donatedCoins.loaded) {
                    return this.totalDonationByUserBefore;
                }
                let totalDonationsByUser = (this.donatedCoins.apiResult.donatedCoinsByUser * this.coinExchangeRate.apiResult.exchangeRate).toFixed(2).toString().replace(".", ",") + " €";
                this.totalDonationByUserBefore = totalDonationsByUser;
                return totalDonationsByUser;
            },
            donationInEuro: function () {
                return (this.coinsToDonate * this.coinExchangeRate.apiResult.exchangeRate).toFixed(2).toString().replace(".", ",");
            },
            isEntwicklerHerzFeatureFlag: function () {
                return window.ENTWICKLER_HERZ_FEATURE_FLAG;
            },
            ...mapState([
                'isAuthenticated',
            ])
        },
        data() {
            return {
                openSwitch: false,
                donatedCoins: {loaded: false, error: null},
                userCoinsResult: {loaded: false, error: null},
                coinExchangeRate: {loaded: false, error: null},
                coinsToDonate: 0,
                donationRequest: null,
                totalDonationsBefore: "?? €",
                totalDonationByUserBefore: "?? €",
            }
        },
        mounted() {
            eventBus.$on("open-donate-modal", () => {
                this.openDonationModal();
            })
        },
        updated() {
            this.$nextTick(function () {
                let tooltips = $('[data-toggle="tooltip"]');
                tooltips.tooltip('destroy');
                tooltips.tooltip();
            })
        },
        methods: {
            getRoute: function () {
                this.$route
            },
            logout: () => services.auth.logout(),
            login: function () {
                services.auth.login(this.$route)
            },
            openDonationModal: function () {
                this.donatedCoins = services.rewardService.getDonations()
                this.userCoinsResult = services.rewardService.getUserCoins();
                this.coinExchangeRate = services.entwicklerHerzService.getCoinExchangeRate();
                this.openSwitch = true;
            },
            donate: function () {
                this.donationRequest = services.rewardService.donate(parseInt(this.coinsToDonate));
            },
            closeHandler: function () {
                this.donationRequest = null;
                this.openSwitch = false;
                this.totalDonationsBefore= "?? €";
                this.totalDonationByUserBefore= "?? €";
            }
        },
        watch: {
            "userCoinsResult.loaded": function () {
                if (!this.userCoinsResult.loaded || this.userCoinsResult.error) {
                    return;
                }

                this.coinsToDonate = Math.floor(this.userCoinsResult.coins / 2)
            },
            "donationRequest.loaded": function () {
                if (!this.donationRequest.loaded || this.donationRequest.error) {
                    return;
                }
                this.donatedCoins = services.rewardService.getDonations();
                eventBus.$emit('user-coins-changed');
                eventBus.$emit('coins-donated');
            }
        }
    }
</script>

<style scoped>
    .slidecontainer {
        margin: 0 auto;
        width: 90%; /* Width of the outside container */
        margin-top: 3rem;
        margin-bottom: 2rem;
        text-align: center;
    }

    .slider {
        display: inline-block;
        -webkit-appearance: none;
        width: 70%;
        height: 15px;
        border-radius: 5px;
        background: #F2F7FD;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #E74C3C;
        cursor: pointer;
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #E74C3C;
        cursor: pointer;
    }

    .exchange-rate:hover {
        cursor: pointer;
    }

    .donate-modal {
        z-index: 1000000;
        margin-top: 4rem;
    }

    .min-value-icon {
        width: 54px;
        font-size: 2.5rem;
        color: #E74C3C;
        margin-right: 1rem;
        text-align: right
    }

    .max-value-icon {
        display: inline-block;
        height: 50px;
        margin-left: 1rem;
        margin-top: -1rem
    }

    @media (max-width: 602px) {
        .max-value-icon {
            height: 30px;
        }

        .min-value-icon {
            width: 20px;
            font-size: 2.2rem;
        }
    }

    @media (max-width: 395px) {
        .max-value-icon {
            height: 20.5px;
        }

        .min-value-icon {
            width: 10px;
            font-size: 1.8rem;
        }
    }

    .open {
        display: block;
    }

    .claimer {
        display: inline-block;
        margin-left: 20px;
        font-size: 1.3rem;
        width: 100%;
    }

    .mobile-additional-info {
        display: block;
    }

    @media (min-width: 825px) {
        .mobile-additional-info {
            display: none;
        }
    }
</style>
