import io from 'socket.io-client';

export class NotificationsServiceNetwork {

  constructor() {
  }

  getPlatformNotificationsServiceSocket() {
    return io(window.NOTIFICATIONS_API_URL);
  }

   getUserServiceSocket() {
    return io(window.NOTIFICATIONS_API_URL);
  }

  getAchievementNotificationsServiceSocket() {
    return io(window.NOTIFICATIONS_API_URL);
  }

  getLevelUpNotificationsServiceSocket() {
    return io(window.NOTIFICATIONS_API_URL);
  }

  getExperienceUpdateNotificationsServiceSocket() {
    return io(window.NOTIFICATIONS_API_URL);
  }

  getSkillScoreNotificationsServiceSocket() {
    return io(window.NOTIFICATIONS_API_URL);
  }

  getNewMessageNotificationSocket() {
    return io(window.NOTIFICATIONS_API_URL);
  }

}
