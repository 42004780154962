import {responseHandler} from "../Utilities";
import {
    ActivityResult,
    ExperienceHistory,
    ExperienceResult,
    LevelLeaderBoardResult,
    Achievements,
    AchievementLeaderBoardResult,
    SkillScoreForUserResult,
    EasterChallenge2021FinishedResult,
} from "./GamificationServiceResponses";

export class GamificationServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    getActivity(userId) {
        let result = new ActivityResult();

        fetch(window.GAMIFICATION_API_URL + "/public_api/activity/" + userId + "/")
            .then(responseHandler)
            .then((json) => {
                result.apiResult = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getAchievements(userId) {
        let result = new Achievements();

        fetch(window.GAMIFICATION_API_URL + "/public_api/achievement/" + userId + "/")
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getExperienceInformation() {
        let result = new ExperienceResult();

        fetch(window.GAMIFICATION_API_URL + "/api/experience/", {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiResult = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getExperienceInformationForUserId(publicUserId) {
        let result = new ExperienceResult();

        fetch(window.GAMIFICATION_API_URL + "/public_api/experience/" + publicUserId + "/")
            .then(responseHandler)
            .then((json) => {
                result.apiResult = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getLanguageProgress(userId) {
        let result = new ExperienceResult();
        fetch(window.GAMIFICATION_API_URL + "/api/language_progress/" + userId + "/", {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiResult = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getLevelLeaderBoard(pageId = 0) {
        let url = window.GAMIFICATION_API_URL + "/api/level_leaderboard/?pageID=" + pageId;
        let result = new LevelLeaderBoardResult();
        fetch(url, {
            method: 'GET',
            headers: this.authService.getAuthorizationHeader()
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
            if ("pagination" in json) {
                result.pagination = json.pagination;
            }
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getAchievementLeaderBoard(pageId = 0) {
        let url = window.GAMIFICATION_API_URL + "/api/achievement_leaderboard/?pageID=" + pageId;
        let result = new AchievementLeaderBoardResult();
        fetch(url, {
            method: 'GET',
            headers: this.authService.getAuthorizationHeader()
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
            if ("pagination" in json) {
                result.pagination = json.pagination;
            }
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getExperienceHistory(pageID = 1) {
        let result = new ExperienceHistory();
        fetch(window.GAMIFICATION_API_URL + "/api/experience_history/" + "?pageID=" + pageID, {headers: this.authService.getAuthorizationHeader()}) //Add pageID as get parameter
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
                if ("pagination" in json) {
                    result.pagination = json.pagination;
                }
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getSkillScoreForUser(publicUserId) {
        let result = new SkillScoreForUserResult();
        fetch(window.GAMIFICATION_API_URL + "/public_api/skill_score/" + publicUserId + "/")
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    triggerEasterChallenge2021Finished() {
        let result = new EasterChallenge2021FinishedResult();
        fetch(window.GAMIFICATION_API_URL + "/api/trigger_happy_easter_2021_finished/", {
            method: 'POST',
            headers: this.authService.getAuthorizationHeader()
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

}
