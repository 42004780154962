import {findGetParameter} from "../Utilities";

export default class FaceBookAnalyticsService {
    constructor() {
        this.userComesFromAdvertising = findGetParameter("utm_campaign") ? true : false;
        if (this.userComesFromAdvertising) {
            this.initializePixel();
        }
    }

    initializePixel() {
        (function (e, t, n) {
            if (e.snaptr) return;
            var a = e.snaptr = function () {
                a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments)
            };
            a.queue = [];
            var s = 'script';
            let r = t.createElement(s);
            r.async = !0;
            r.src = n;
            var u = t.getElementsByTagName(s)[0];
            u.parentNode.insertBefore(r, u);
        })(window, document,
            'https://sc-static.net/scevent.min.js');
        this.snaptr = snaptr;
        this.snaptr('init', 'cdddb1b9-208a-4d04-9a99-dc6586491d8f');
    }

    sendRegistrationCompletionEvent() {
        if (this.userComesFromAdvertising) {
            this.snaptr('track', 'SIGN_UP');
        }
    }
}
