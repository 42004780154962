<template>
    <div class="input-wrapper">
        <div class="ratio-wrapper">
            <div class="location-option">

                <input class="location-option__radio"
                       type="radio"
                       name="mydreamcompany-location"
                       :id="id"
                       :value="inputValue"
                       :data-name="label"
                       :checked="checked"
                       @input="handleInput($event)"/>
                <label :for="id" class="location-option__icon">
                    <slot></slot>
                    <span class="location-option__label">{{ label }}</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import OfficeIcon from "./icons/OfficeIcon";
import RemoteIcon from "./icons/RemoteIcon";
import HybridIcon from "./icons/HybridIcon";

// read more:
// https://paulund.co.uk/use-v-model-on-custom-vue-component
// https://www.digitalocean.com/community/tutorials/how-to-add-v-model-support-to-custom-vue-js-components
export default {
    name: "LocationItem",
    components: {HybridIcon, RemoteIcon, OfficeIcon},
    props: ['label', 'inputValue', 'checked'],
    data() {
        return {
            id: null,
        }
    },
    mounted() {
        this.id = this._uid
    },
    methods: {
        handleInput(event) {
            this.$emit('input', event.target.value)
        }
    },
}
</script>

<style lang="scss" scoped>

form input[type="radio"].location-option__radio + label:before {
    content: none;
}

.input-wrapper {
    width: 100%;
    max-width: 164px;
    margin: 1%;
    text-align: center;
}

.ratio-wrapper {
    padding-bottom: 100%;
    width: 100%;
    position: relative;
}

.location-option {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 8px;
    overflow: hidden;

    &__radio {
        display: none;

        &:hover,
        &:checked {
            & + .location-option__icon {
                filter: grayscale(0);
                transition: filter .4s ease-out;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    background: var(--button-background);
                    top: -50%;
                    left: -50%;
                    border: none;
                    border-radius: 50%;
                    @extend .scale-in-center;
                }

                .location-option__label {
                    color: var(--button-color);
                }
            }
        }
    }

    &__icon {
        filter: grayscale(1);
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }

        & > svg {
            width: auto;
            height: 100%;
            max-height: 59px;
        }
    }

    &__label {
        margin-top: 1.6rem;
        display: block;
    }
}

.scale-in-center {
    -webkit-animation: scale-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-19 11:27:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(4);
        transform: scale(4);
        opacity: 1;
    }
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(4);
        transform: scale(4);
        opacity: 1;
    }
}


</style>