import WorkspaceServiceStub from "./WorkspaceServiceStub";
import WorkspaceServiceNetwork from "./WorkspaceNetworkService";

export default class WorkspaceService {

    constructor(auth) {
        this.authService = auth;
    }

    setActive(workspaceUrl) {
        if (typeof window.USE_WORKSPACE_STUB !== undefined && window.USE_WORKSPACE_STUB !== "") {
            console.info("window.USE_WORKSPACE_STUB is set! Using Stub data!");
            return new WorkspaceServiceStub(this.authService).setActive(workspaceUrl);
        }
        return new WorkspaceServiceNetwork(this.authService).setActive(workspaceUrl);
    }

    getFiles(workspaceUrl, taskId) {
        if (typeof window.USE_WORKSPACE_STUB !== undefined && window.USE_WORKSPACE_STUB !== "") {
            console.info("window.USE_WORKSPACE_STUB is set! Using Stub data!");
            return new WorkspaceServiceStub(this.authService).getFiles(workspaceUrl, taskId);
        }
        return new WorkspaceServiceNetwork(this.authService).getFiles(workspaceUrl, taskId);
    }

    getFileContent(workspaceUrl, taskId, filePath) {
        if (typeof window.USE_WORKSPACE_STUB !== undefined && window.USE_WORKSPACE_STUB !== "") {
            console.info("window.USE_WORKSPACE_STUB is set! Using Stub data!");
            return new WorkspaceServiceStub(this.authService).getFileContent(workspaceUrl, taskId, filePath);
        }
        return new WorkspaceServiceNetwork(this.authService).getFileContent(workspaceUrl, taskId, filePath);
    }

    changeFileContent(workspaceUrl, taskId, filePath, content) {
        if (typeof window.USE_WORKSPACE_STUB !== undefined && window.USE_WORKSPACE_STUB !== "") {
            console.info("window.USE_WORKSPACE_STUB is set! Using Stub data!");
            return new WorkspaceServiceStub(this.authService).changeFileContent(workspaceUrl, taskId, filePath, content);
        }
        return new WorkspaceServiceNetwork(this.authService).changeFileContent(workspaceUrl, taskId, filePath, content);
    }

    getEventSocket(workspaceUrl) {
        if (typeof window.USE_WORKSPACE_STUB !== undefined && window.USE_WORKSPACE_STUB !== "") {
            console.info("window.USE_WORKSPACE_STUB is set! Using Stub data!");
            return new WorkspaceServiceStub(this.authService).getEventSocket(workspaceUrl);
        }
        return new WorkspaceServiceNetwork(this.authService).getEventSocket(workspaceUrl);
    }
}
