import {
    AcceptChallengeResult,
    ChallengeDetails,
    ChallengeGroupDetails,
    ChallengeGroupList,
    ChallengeList,
    RunnTests,
    SolvedChallengesResult,
    SponsoredChallengesList,
    SubmitChallengeResult,
    SubmitTask,
    TaskList
} from "./TaskManagementServiceResponses";

import {responseHandler, APIResponse} from "../Utilities";

export class TaskManagementServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    accceptChallenge(challengeId, rewardOption, userUuid) {
        let result = new AcceptChallengeResult();
        fetch(window.TASK_API_URL + '/api/campaign/' + challengeId + '/accept/', {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify({reward_option: rewardOption, user_uuid: userUuid})
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    submitChallenge(challengeId) {
        let result = new SubmitChallengeResult();
        fetch(window.TASK_API_URL + '/api/campaign/' + challengeId + '/submit/', {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST"
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getChallengeDetails(challengeIdOrSlug, region) {
        let result = new ChallengeDetails();
        let queryParams = '';
        if (region) {
            queryParams = '?region=' + region;
        }
        fetch(window.TASK_API_URL + "/api/campaign/" + challengeIdOrSlug + "/details/" + queryParams,
            {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.challenge_data = json;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getChallengeList(accepted, region) {
        let result = new ChallengeList();
        let queryParams = '';
        if (accepted) {
            queryParams = '?accepted=true';
        }
        if (region) {
            queryParams = '?region=' + region;
        }
        fetch(window.TASK_API_URL + "/api/campaign/" + queryParams, {
                headers: this.authService.getAuthorizationHeader()
            }
        )
            .then(responseHandler)
            .then((json) => {
                result.challengeListData = json;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    submitTask(taskId, commitHash) {
        let result = new SubmitTask();
        let data = {
            "commitHash": commitHash,
        };

        fetch(window.TASK_API_URL + "/api/campaigntask/" + taskId + "/submit/", {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
                body: JSON.stringify(data)
            }
        )
        .then(responseHandler)
        .then((json) => {
            result.apiData = json;
            result.loaded = true;
        })
        .catch((error) => {
            console.warn(error);
            result.error = error;
            result.loaded = true;
        });
        return result;
    }

    getDataOfMultipleChallenges(challengeIds){
        let data = {
            "challengeIds": challengeIds,
        };

        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let apiFetch = await fetch(window.TASK_API_URL + "/api/campaign/get_data_for_campaign_ids/", {
                        headers: this.authService.getAuthorizationHeader(),
                        method: 'POST',
                        body: JSON.stringify(data)
                    }
                );

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        );
        return result;
    }

    getSolvedChallenge(userId){
        let result = new SolvedChallengesResult();

        fetch(window.TASK_API_URL + "/public_api/user/" + userId + "/solved_challenges/")
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.warn(error);
            result.error = error;
            result.loaded = true;
        });
        return result;
    }

    getDataOfMultipleChallengeTasks(challengeTaskIds){
        let result = new TaskList();
        let data = {
            "challengeTaskIds": challengeTaskIds,
        };

        fetch(window.TASK_API_URL + "/api/challengetask/get_data_for_challenge_task_ids/", {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
                body: JSON.stringify(data)
            }
        )
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.warn(error);
            result.error = error;
            result.loaded = true;
        });
        return result;
    }

    runnTests(taskId, code) {
        let result = new RunnTests();
        let data = {
            "code": code,
        };

        fetch(window.TASK_API_URL + `/api/challengetask/${taskId}/runn_tests/`, {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
                body: JSON.stringify(data)
            }
        )
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.warn(error);
            result.error = error;
            result.loaded = true;
        });

        return result;
    }

    getChallengeGroupDetails(groupIdOrSlug) {
        let result = new ChallengeGroupDetails();

        fetch(window.TASK_API_URL + "/api/challenge_group/" + groupIdOrSlug + "/", {
                headers: this.authService.getAuthorizationHeader()
            }
        )
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getChallengeGroups(accepted) {
        let result = new ChallengeGroupList();
        let queryParams = '';
        if (typeof accepted !== 'undefined') {
            queryParams = `?accepted=${accepted.toString()}`;
        }

        fetch(window.TASK_API_URL + "/api/challenge_group/" + queryParams, {
                headers: this.authService.getAuthorizationHeader()
            }
        )
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }
}
