<template>
    <div id="dailyRewardDropModal" v-if="isVisible">
        <div class="modal open" role="dialog"
             aria-labelledby="dailyRewardDropModal" tabindex="-1">
            <div class="daily-reward-drop-modal modal-dialog gummy-scaling" :class="{'scale-out-hor-center': closing}"
                 role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="text-center">
                            <div class="reward-modal-heading">
                                <i class="hidden-xs dev-star"></i>
                                &nbsp;REWARD DROP&nbsp;
                                <i class="hidden-xs dev-star"></i></div>
                        </div>
                        <div class="text-center">
                            <div class="logo-circle jello-vertical">
                                <img class="reward-icon"
                                     src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1667911865/eh-platform/rewards/smashicons_customer_loyalty_reward-points-35-icon-yellow.svg">
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row text-center">
                            <div class="reward-drop-banner">
                                <a class="reward-drop-banner-link" :href="displayedCompany.selectedImage.link + '?redirect_only=true'" :ping="displayedCompany.selectedImage.link + '?user_id=' + services.auth.getUserID()"
                                   :target="displayedCompany.selectedImage.target">
                                    <div class="more-about external-link">Mehr erfahren <i
                                        class="dev-external-link"></i></div>
                                    <img v-if="isVisible"
                                         :src="displayedCompany.selectedImage.image">
                                </a>
                            </div>
                            <div class="reward-drop-gift-wrapper row">
                                <div class="col-xs-12 reward-drop-company">
                                    Für den täglichen Login schenkt dir <strong>{{
                                        displayedCompany.selectedImage.name
                                    }}</strong>:
                                </div>
                                <div class="col-xs-12 reward-box-container">
                                    <div class="reward-box">
                                        <div class="reward-svg-box">
                                            <img
                                                src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1638874965/eh-platform/icons/smashicons_business_rich-icon-148-retro.svg"
                                                alt="An icon for coins"
                                                class="coin-bag-icon"/>
                                        </div>
                                        <p class="text-right coin-amount">
                                            10
                                            <img class="coin-icon" src="/assets/icons/coins.svg"
                                                 alt="A coin icon">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center">
                            <div class="button-wrapper">
                                <button class="btn btn-big-cta btn-reward-drop" @click="close">
                                    <span>Danke!</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-background">
                    <img class="reward-modal-background scale-up"
                         src="https://res.cloudinary.com/dspi5zyaa/image/upload/q_auto,w_2400/v1667905155/eh-platform/rewards/coins-bg.png">
                </div>
            </div>
            <div @click="close()" class="modal-backdrop fade-in" :class="{'fade-out': closing}"></div>
        </div>

    </div>
</template>

<script>
import {makeBackgroundNotScrollable, makeBackgroundScrollable} from "../../services/Utilities";
import {eventBus, services} from "../../main";

export default {
    name: "DailyRewardDropModal",
    computed: {
        services() {
            return services
        }
    },
    props: {
        isVisible: {type: [Boolean], default: false},
    },
    data() {
        return {
            closing: false,
            displayedCompany: {
                selectedImage: {},
                bannerImages: [
                    {
                        name: "Rheinwerk - JavaScript: Dynamisch in jeder Hinsicht",
                        image: `https://company.platform.entwicklerheld.de/link/banner-rheinwerk-javascript-11-24-quer`,
                        link: "https://company.platform.entwicklerheld.de/link/banner-rheinwerk-javascript-11-24-link",
                        target: "_blank"
                    },
                    {
                        name: "Rheinwerk - Kotlin: Saubere und klare Syntax mit Kotlin",
                        image: `https://company.platform.entwicklerheld.de/link/banner-rheinwerk-kotlin-11-24-quer`,
                        link: "https://company.platform.entwicklerheld.de/link/banner-rheinwerk-kotlin-11-24-link",
                        target: "_blank"
                    },
                    {
                        name: "Rheinwerk - PHP: Dynamische Webseiten entwickeln ohne Vorwissen",
                        image: `https://company.platform.entwicklerheld.de/link/banner-rheinwerk-php-11-24-quer`,
                        link: "https://company.platform.entwicklerheld.de/link/banner-rheinwerk-php-11-24-link",
                        target: "_blank"
                    },
                    {
                        name: "Rheinwerk - Python: Übersichtlich, einfach und mit großem Potenzial",
                        image: `https://company.platform.entwicklerheld.de/link/banner-rheinwerk-python-11-24-quer`,
                        link: "https://company.platform.entwicklerheld.de/link/banner-rheinwerk-python-11-24-link",
                        target: "_blank"
                    }
                ]
            }
        }
    },
    created() {
        this.displayedCompany.selectedImage = this.getRandomImage();
    },
    destroyed() {
        document.removeEventListener('keydown', this.keyDownHandler);
        makeBackgroundScrollable();
    },
    methods: {
        keyDownHandler: function (event) {
            // escape key
            if (event.key === "Escape") {
                this.close();
            }
        },
        close() {
            this.closing = true;
            document.removeEventListener('keydown', this.keyDownHandler);
            makeBackgroundScrollable();
            setTimeout(() => {
                this.$emit('close');
                this.closing = false;
                eventBus.$emit('user-coins-changed');

            }, 800);
        },
        getRandomImage() {
            return this.displayedCompany.bannerImages[Math.floor(Math.random() * this.displayedCompany.bannerImages.length)];
        }
    },
    watch: {
        isVisible: function (newVal) {
            if (newVal) {
                makeBackgroundNotScrollable();
                document.addEventListener('keydown', this.keyDownHandler);
            }
        }
    }
}
</script>

<style scoped>
.open {
    display: block;
}

.daily-reward-drop-modal {
    z-index: 2200;
    position: relative;
}

.external-link {
    text-shadow: 1px 1px 2px black;
}

.modal-dialog {
    width: 800px;
    margin-top: 8rem;
}

.reward-modal-heading {
    margin-top: 1rem;
    letter-spacing: .35rem;
    color: white;
    font-size: 3rem;
    font-weight: bold;
}

.modal-header {
    margin-bottom: 4rem;
}

.logo-circle {
    margin: 2rem auto -6rem auto;
    background-color: white;
    border-radius: 99px;
    width: 100px;
    height: 100px;
    padding: 1rem;
    line-height: 75px;
}

.modal-content {
    z-index: 2;
}

.modal-body {
    padding-bottom: 2rem;
}

.reward-box {
    height: 50px;
    background: #F8FBFECC;
    border-radius: 4px;
    transition: all 0.1s ease-out;
    cursor: default;
    width: 225px;
    margin: 1rem auto;
}

.reward-svg-box {
    background: #F2F7FD;
    height: 100%;
    width: 50px;
    border-radius: 4px 0 0 4px;
    float: left;
}

.coin-bag-icon {
    height: 100%;
    padding: 7px;
}

.coin-amount {
    display: inline-block;
    float: right;
    line-height: 50px;
    font-size: 2.5rem;
    margin-right: 1rem;
    color: #606060;
}

.coin-icon {
    height: 2rem;
    margin-top: -5px;
}


@media (max-width: 900px) {
    .modal-dialog {
        width: 100%;
        margin: 6rem 0 0 0;
    }
}

.modal-header {
    padding: 2rem;
    justify-content: center;
    background: linear-gradient(110.16deg, var(--sidebar-second-background) 3.84%, var(--sidebar-background) 93.74%) !important;
    border-radius: 10px 10px 0 0;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
}

.button-wrapper .btn-big-cta {
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
    border: none;
    padding: .75rem 4rem;
    transform: perspective(1px) translateZ(0);
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    z-index: 1;
}

.reward-drop-banner {
    position: relative;
    transition: all 0.3s ease-in-out;
}

.reward-drop-banner img {
    padding-top: 20px;
    width: 100%;
}

.reward-drop-banner:hover {
    transform: scale(1.05);
}

.reward-drop-banner:hover .more-about {
    text-decoration: underline;
}

.more-about {
    position: absolute;
    right: 0;
    color: whitesmoke;
    text-transform: uppercase;
    padding-right: 1rem;
}

.reward-drop-gift-wrapper {
    margin: 3rem;
    font-size: 1.8rem;
    height: 50px;
}

.reward-modal-background {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 800px;
}

.btn-reward-drop {
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .reward-drop-company {
        display: block;
    }

    .reward-drop-gift-wrapper {
        height: 100%;
        display: block;
    }

    .btn-reward-drop {
        margin-top: 0;
    }
}

/* animations */

/* modal entrance animation */

.gummy-scaling {
    -webkit-animation: gummy-scaling 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: gummy-scaling 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes gummy-scaling {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    50% {
        -webkit-transform: scaleX(1.3);
        transform: scaleX(1.3);
    }
    65% {
        -webkit-transform: scaleX(0.7);
        transform: scaleX(0.7);
    }
    80% {
        -webkit-transform: scaleX(1.2);
        transform: scaleX(1.2);
    }
    90% {
        -webkit-transform: scaleX(0.9);
        transform: scaleX(0.9);
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@keyframes gummy-scaling {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    10% {
        -webkit-transform: scaleX(1.3);
        transform: scaleX(1.3);
    }
    25% {
        -webkit-transform: scaleX(0.7);
        transform: scaleX(0.7);
    }
    50% {
        -webkit-transform: scaleX(1.2);
        transform: scaleX(1.2);
    }
    75% {
        -webkit-transform: scaleX(0.9);
        transform: scaleX(0.9);
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

/* modal exit animation */

.scale-out-hor-center {
    -webkit-animation: scale-out-hor-center 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
    animation: scale-out-hor-center 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-27 15:40:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-hor-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-hor-center {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }
}

@keyframes scale-out-hor-center {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }
}

/* fade in modal backdrop */

.fade-in {
    -webkit-animation: fade-in 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-27 14:55:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

/* fadeout modal backdrop */

.fade-out {
    -webkit-animation: fade-out 0.25s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-out 0.25s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-27 14:55:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-out {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

/* reward icon animation */

.jello-vertical {
    -webkit-animation: jello-vertical 0.9s 0.25s both;
    animation: jello-vertical 0.9s 0.25s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-8 9:16:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-vertical
 * ----------------------------------------
 */
@-webkit-keyframes jello-vertical {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    40% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    50% {
        -webkit-transform: scale3d(0.85, 1.15, 1);
        transform: scale3d(0.85, 1.15, 1);
    }
    65% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    75% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes jello-vertical {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    40% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    50% {
        -webkit-transform: scale3d(0.85, 1.15, 1);
        transform: scale3d(0.85, 1.15, 1);
    }
    65% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    75% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

/*  coins background animation */

.scale-up {
    -webkit-animation: scale-up 7s 0.75s cubic-bezier(.04, .84, .4, .96) both;
    animation: scale-up 7s 0.75s cubic-bezier(.04, .84, .4, .96) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-8 11:50:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */
@-webkit-keyframes scale-up {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.75;
    }
    25% {
        opacity: 0.75;
    }
    100% {
        -webkit-transform: scale(3);
        transform: scale(3);
        opacity: 0;
    }
}

@keyframes scale-up {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.75;
    }
    25% {
        opacity: 0.75;
    }
    100% {
        -webkit-transform: scale(3);
        transform: scale(3);
        opacity: 0;
    }
}
</style>
