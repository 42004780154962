<template>
    <div>
        <div class="level">{{experienceInformationResult.apiResult.level}}</div>
        <LevelUpComponent v-if="levelUpAnimation" :new-level="''+experienceInformationResult.apiResult.level" :finishedHandler="deactivateLevelUpAnimation" />
    </div>
</template>

<script>
    import {services} from "../../main.js"
    import {mapState} from 'vuex';
    import {eventBus} from "../../main";
    import io from 'socket.io-client';
    import LevelUpComponent from "./LevelUpComponent";

    export default {
        name: "level",
        components: {LevelUpComponent},
        data() {
            return {
                experienceInformationResult: services.gamificationService.getExperienceInformation(),
                socket: null,
                levelUpAnimation: false,
            }
        },
        computed: mapState([
            'isAuthenticated',
        ]),
        mounted() {
            if (this.isAuthenticated) {
                this.createNotificationConnection();
            }
        },
        watch: {
            isAuthenticated() {
                if (!this.isAuthenticated) {
                    return;
                }
                this.apiResult = services.gamificationService.getExperienceInformation();
                this.createNotificationConnection();
            },
        },
        methods: {
            createNotificationConnection: function () {
                const userID = services.auth.getUserID();
                this.socket = services.notificationsService.getLevelUpNotificationsServiceSocket();
                this.socket.on(`notifications_gamification_${userID}_level-up`, (data) => {
                    let json = JSON.parse(data);
                    this.experienceInformationResult.apiResult.level = json.levelAfterUpdate;
                    setTimeout(() => this.levelUpAnimation = true, 1800);
                });
            },
            deactivateLevelUpAnimation: function() {
                this.levelUpAnimation = false;
            },
        },
    }
</script>

<style scoped>
.level {
    border-radius: 50%;
    background: rgb(37,129,196); /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgb(44, 153, 232) 0%, rgb(61, 190, 189) 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgb(44, 153, 232) 0%, rgb(61, 190, 189) 99%);
    background: linear-gradient(135deg,  rgb(44, 153, 232) 0%, rgb(61, 190, 189) 99%);
    height: 1.5rem;
    width: 1.5rem;
    color: white;
    font-weight: bolder;
    text-align: center;
}


</style>
