import {QualityServiceStub} from "./QualityServiceStub";
import {QualityServiceNetwork} from "./QualityServiceNetwork";

export default class QualityService {
    constructor(auth) {
        this.authService = auth;
    }

    getHistoryReport(challengeId, userId, file, task) {
        if (typeof window.QUALITY_API_URL === "undefined" || window.QUALITY_API_URL === "") {
            console.info("window.QUALITY_API_URL is not set! Using Stub data!");
            return new QualityServiceStub(this.authService).getHistoryReport(challengeId, userId, file, task);
        }
        return new QualityServiceNetwork(this.authService).getHistoryReport(challengeId, userId, file, task);
    }

    getPercentagePosition(challengeId) {
        if (typeof window.QUALITY_API_URL === "undefined" || window.QUALITY_API_URL === "") {
            console.info("window.QUALITY_API_URL is not set! Using Stub data!");
            return new QualityServiceStub(this.authService).getPercentagePosition(challengeId);
        }
        return new QualityServiceNetwork(this.authService).getPercentagePosition(challengeId);
    }

    getAnalysisReport(challengeId){
        if (typeof window.QUALITY_API_URL === "undefined" || window.QUALITY_API_URL === "") {
            console.info("window.QUALITY_API_URL is not set! Using Stub data!");
            return new QualityServiceStub(this.authService).getAnalysisReport(challengeId);
        }
        return new QualityServiceNetwork(this.authService).getAnalysisReport(challengeId);
    }
}
