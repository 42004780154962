import { data } from "jquery";
import { APIResponse, MockResponse } from "../Utilities";
import {ApplyToJobOfferResponse, CheckIfJobOfferExistsResponse, InteractResult} from "./InteractsServiceResponse";

export class InteractsServiceStub {

    constructor(auth) {
        this.authService = auth;
    }

    checkIfJobOfferExists(vacancyIdFromCompanyService) {
        let result = new CheckIfJobOfferExistsResponse();
        setTimeout(() => {
            result.loaded = true;
            result.apiData = {
                jobOfferId: "3d8c8ab2-513d-4d58-a56a-763454292fb5",
                userAlreadyApplied: false,
            }
        }, 1000)
        return result;
    }

    cachePreChallengeInteract(challengeId, language, userTokenId, region, profession) {
        let result = new InteractResult();
        setTimeout(() => {
            result.loaded = true;
            result.apiData = {};
        }, 5000)
        return result;
    }

    applyToJobOffer(vacancyIdFromCompanyService) {
        let result = new ApplyToJobOfferResponse();
        setTimeout(() => {
            result.loaded = true;
            result.apiData = {};
        }, 300)
        return result;
    }

    addWorkSampleToCompanyId(companyId, challengeId) {
        console.log(`Added Worksample with vacancyId ${companyId} and challengeId ${challengeId}`);
        return new MockResponse().respond({});
    }

    getSharedWorksamples(companyId) {
        let data = ["cc4d10a7-e4ec-4d1e-b613-5ce7d5b11d9e"];
        return new MockResponse().respond(data);
    }

    getInteractResult(challengeId, language, userTokenId, region, profession) {
        let result = new InteractResult();
        if (userTokenId == null) {
            setTimeout(() => {
                result.loaded = true;
                result.html = `
<html style="height: 100%">
<head>
    <script
            src="https://code.jquery.com/jquery-3.4.1.min.js"
            integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
            crossorigin="anonymous"></script>
    <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" rel="stylesheet"
          integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
            integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
            crossorigin="anonymous"></script>
    <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet">
</head>
<body style="height: 100%">
<div style="display: flex; justify-content: center; align-items: center; height: 100%; max-height: 100%; flex-direction: column;">

    <div class="text-center">
        <p><img src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1611131281/eh-platform/icons/smashicons_super_heroes_hero-outfit-icon-23-retro.svg" style="max-width: 75px; width: 100%;"></p>
        <h2>BEREIT FÜR EINE HELDENHAFTE MISSION?</h2>
        <p>
            Löse diese <strong>Challenge</strong> und mache Unternehmen auf dich aufmerksam!
        </p>
    </div>

    
</div>
</body>
<style>
    h2 {
        font-size: 1.4rem;
        font-weight: bold;
    }
    body {
        font-family: "Open Sans", sans-serif;
    }
</style>
</html>`;

            }, 300)
            return result;
        }
        setTimeout(() => {
            result.loaded = true;
            result.html = `
<html style="height: 100%"><head>
    <script src="https://code.jquery.com/jquery-3.4.1.min.js" integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=" crossorigin="anonymous"></script>
    <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>
    <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet">
</head>
<body style="height: 100%;" data-new-gr-c-s-check-loaded="14.982.0" data-gr-ext-installed="">
<div style="display: flex; justify-content: center; align-items: center; height: 100%; max-height: 100%; flex-direction: column;">

    
        <div class="text-center">
            
        </div>
        
            <h5 class="text-center" style="font-weight: 600;">Deine ausgewählte Firma</h5>
            
                <div style="text-align: center; margin-bottom: 1.5rem;">
                    <a style="color: black;" target="_blank" href="https://platform.entwicklerheld.de/company/715d7171-7f0a-414e-9199-716e563ca62c">
                        <img class="img-responsive" src="https://production-interacts-media-storage.s3.amazonaws.com/logo-softwareone.png" style="width: 70%; margin-top: 0.5rem;"></a>
                </div>
            
        
    </div>

<style>
    h2 {
        font-size: 1.4rem;
        font-weight: bold;
    }
    body {
        font-family: "Open Sans", sans-serif;
    }
</style>

</body></html>`;

        }, 300)
        return result;
    }

    getJobOfferDetails(jobOfferId) {
        let data;
        if (jobOfferId == "1234") {
            data = {
            id: "1234",
            title: "Full Stack Python Entwickler",
            teaserText:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua",
            location: "Dresden",
            technologies: [
                { name: "Django", programmingLanguage: "Python" },
                { name: "Springboot", programmingLanguage: "Java" },
                { name: "THREE.js", programmingLanguage: "JavaScript" },
            ],
            region: "Sachsen",
            };
        } else {
            data = {
            id: "12345",
            title: "Full Stack Developer PHP/TYPO3 (w/m/d)",
            teaserText:
                "<ul><li><p>Konzeption/Strukturierung von komplexen Aufgabenstellungen&nbsp;</p></li><li><p>Entwicklung von Datenbankstrukturen&nbsp;</p></li><li><p>Umsetzung von dynamischen Inhalten (XHTML, PHP, MySQL, JavaScript, usw.)&nbsp;</p></li><li><p>Von Vorteil: Kenntnisse in PHP Framework/s,&nbsp;jQuery,&nbsp;Laravel&nbsp;und CSS&nbsp;</p></li><li><p>Entwicklung von Anbindungen zu externen Anbietern, wie Google, Bing usw.&nbsp;</p></li></ul>",
            location: "Leipzig",
            technologies: [
                { name: "TYPO3", programmingLanguage: "PHP" },
                { name: "Magento", programmingLanguage: "PHP" },
            ],
            region: "Sachsen",
            };
        }

        return new MockResponse().respond(data);
    }

    applyToCompany(companyId) {
        const data = {

        }
        return new MockResponse().respond(data);
    }

    getJobRequests(companyId) {
        const data = {

        }
        return new MockResponse().respond(data);
    }
}