import {
    ActivityResult,
    ExperienceHistory,
    ExperienceResult,
    LanguageProgress,
    LevelLeaderBoardResult,
    Achievements,
    AchievementLeaderBoardResult,
    SkillScoreForUserResult,
    EasterChallenge2021FinishedResult,
} from "./GamificationServiceResponses";


export class GamificationServiceStub {
    constructor(auth) {
        this.authService = auth;
    }

    getActivity() {
        let result = new ActivityResult();
        setTimeout(function () {
            result.apiResult = getRandomActivityResults();
            result.loaded = true;
        }, 100);
        return result;
    }

    getAchievements(){
        let result = new Achievements();
        setTimeout(function() {
            result.apiData = {
                grouped: [
                    {
                        "title": "Rookie",
                        "score": 10,
                        "description": "Löse deine erste Challenge auf EntwicklerHeld.",
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/TrophyIcons_10_t.PNG",
                        "success": false,
                        "trigger": [{
                            "description": "Challenge gelöst",
                            "limit": 1,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 0,
                            "finished": false
                        }]
                    },
                    {
                        "title": "Welcome",
                        "description": "Register at the Platform",
                        "score": 10,
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/TrophyIcons_21_t.png",
                        "success": false,
                        "trigger": [{
                            "description": "You are registerd",
                            "limit": 0,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 0,
                            "finished": false
                        }]
                    },
                    {
                        "title": "Creator",
                        "description": "Du bist einer der Pioniere der Creators' Lounge",
                        "score": 0,
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/hm_t_01.png",
                        "success": true,
                        "trigger": [{
                            "description": "Erhalte den Creator Status",
                            "limit": 1,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 1,
                            "finished": true
                        }]
                    }, {
                        "title": "EntwicklerHerz",
                        "description": "Du hast bei der #StayHomeAndCode Aktion teilgenommen.",
                        "score": 10,
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/achievement-icons/heart.PNG",
                        "success": true,
                        "trigger": [{
                            "description": "Löse die StayHomeAndCode Challenge oder spende mehr als 5€ über Coins",
                            "limit": 1,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 1,
                            "finished": true
                        }]
                    },
                    {
                        "title": "Das Urteil ist gefallen!",
                        "description": "Du hast in mehr als fünzig Abgaben eine Bewertung in Rank the Code abgegeben.",
                        "score": 10,
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/achievement-icons/scroll1.png",
                        "success": true,
                        "trigger": [{
                            "description": "Bewerte mehr als 50 Lösungen in Rank the Code",
                            "limit": 50,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 50,
                            "finished": true
                        }]
                    },
                    {
                        "title": "High-Performer",
                        "description": "Du hast eine Wahnsinns-Bewertung in Rank the Code für eine deiner Abgaben bekommen.",
                        "score": 30,
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/achievement-icons/order.png",
                        "success": true,
                        "trigger": [{
                            "description": "Erhalte mehr als 4,5 Sterne",
                            "limit": 4.5,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 4.7,
                            "finished": true
                        }, {
                            "description": "Erhalte mehr als 20 Bewertungen ",
                            "limit": 20,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 20,
                            "finished": true
                        }]
                    },
                ],
                ungrouped:[
                    {
                        "title": "Rookie",
                        "score": 10,
                        "description": "Löse deine erste Challenge auf EntwicklerHeld.",
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/TrophyIcons_10_t.PNG",
                        "success": false,
                        "trigger": [{
                            "description": "Challenge gelöst",
                            "limit": 1,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 0,
                            "finished": false
                        }]
                    },
                    {
                        "title": "Sei der schnellste",
                        "score": 10,
                        "description": "Löse eine Challenge auf EntwicklerHeld in unter 10 Minuten",
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/TrophyIcons_10_t.PNG",
                        "success": true,
                        "trigger": [{
                            "description": "Minuten für deine schnellste Bearbeitungszeit",
                            "limit": 10,
                            "operator": "LOWER_THAN_OR_EQUALS",
                            "count": 9,
                            "finished": true
                        }]
                    },
                    {
                        "title": "Welcome",
                        "description": "Register at the Platform",
                        "score": 10,
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/TrophyIcons_21_t.png",
                        "success": false,
                        "trigger": [{
                            "description": "You are registerd",
                            "limit": 0,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 0,
                            "finished": false
                        }]
                    },
                    {
                        "title": "Creator",
                        "description": "Du bist einer der Pioniere der Creators' Lounge",
                        "score": 0,
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/hm_t_01.png",
                        "success": true,
                        "trigger": [{
                            "description": "Erhalte den Creator Status",
                            "limit": 1,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 1,
                            "finished": true
                        }]
                    }, {
                        "title": "EntwicklerHerz",
                        "description": "Du hast bei der #StayHomeAndCode Aktion teilgenommen.",
                        "score": 10,
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/achievement-icons/heart.PNG",
                        "success": true,
                        "trigger": [{
                            "description": "Löse die StayHomeAndCode Challenge oder spende mehr als 5€ über Coins",
                            "limit": 1,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 1,
                            "finished": true
                        }]
                    },
                    {
                        "title": "Das Urteil ist gefallen!",
                        "description": "Du hast in mehr als fünzig Abgaben eine Bewertung in Rank the Code abgegeben.",
                        "score": 10,
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/achievement-icons/scroll1.png",
                        "success": true,
                        "trigger": [{
                            "description": "Bewerte mehr als 50 Lösungen in Rank the Code",
                            "limit": 50,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 50,
                            "finished": true
                        }]
                    },
                    {
                        "title": "High-Performer",
                        "description": "Du hast eine Wahnsinns-Bewertung in Rank the Code für eine deiner Abgaben bekommen.",
                        "score": 30,
                        "icon": "https://polylith-public-files.s3.eu-central-1.amazonaws.com/achievement-icons/order.png",
                        "success": true,
                        "trigger": [{
                            "description": "Erhalte mehr als 4,5 Sterne",
                            "limit": 4.5,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 4.7,
                            "finished": true
                        }, {
                            "description": "Erhalte mehr als 20 Bewertungen ",
                            "limit": 20,
                            "operator": "GREATER_THAN_OR_EQUALS",
                            "count": 20,
                            "finished": true
                        }]
                    },
                ]
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    getExperienceInformation() {
        let result = new ExperienceResult();
        setTimeout(function () {
            result.apiResult = {
                "level": 7,
                "currentExperience": 500,
                "neededNextLevel": 1000,
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    getExperienceInformationForUserId(publicUserId) {
        let result = new ExperienceResult();
        setTimeout(function () {
            result.apiResult = {
                "level": 7,
                "currentExperience": 500,
                "neededNextLevel": 1000,
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    getLanguageProgress() {
        let result = new LanguageProgress();
        setTimeout(function () {
            result.apiResult = {
                "Python": 0.1,
                "Java": 0.5,
                "NumPy": 1.0
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    getLevelLeaderBoard(pageId = 0) {
        let result = new LevelLeaderBoardResult();
        let pos = 43;
        
        if (pageId < 1)
            pageId = Math.ceil(pos/10);
            
        let i = pageId > 1 ? 3 : 0;
        let j = (pageId - 1) * 10;
        
        setTimeout(function () {
            result.apiData = {
                "currentUserPosition": pos,
                "leaderboard": [
                    {
                        "position": 1,
                        "publicUserId": "1",
                        "level": 20,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 2,
                        "publicUserId": "2",
                        "level": 19,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 3,
                        "publicUserId": "3",
                        "level": 18,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 4,
                        "publicUserId": "4",
                        "level": 18,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 5,
                        "publicUserId": "5",
                        "level": 17,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 6,
                        "publicUserId": "6",
                        "level": 16,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 7,
                        "publicUserId": "7",
                        "level": 4,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 8,
                        "publicUserId": "8",
                        "level": 3,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 9,
                        "publicUserId": "9",
                        "level": 2,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 10,
                        "publicUserId": "10",
                        "level": 1,
                        "isCurrentUser": false,
                    }
                ]
            }
            
            if (pageId > 1) {
                while (i < result.apiData.leaderboard.length) {
                    result.apiData.leaderboard[i].position = ++j;
                    result.apiData.leaderboard[i].isCurrentUser = result.apiData.leaderboard[i].position === pos;
                    i++;
                }

                while (result.apiData.leaderboard.length < 13) {
                    result.apiData.leaderboard.push({
                        "position": ++j,
                        "publicUserId": i++,
                        "level": 1,
                        "isCurrentUser": false,
                    });
                }
            }
            
            result.loaded = true;
            result.pagination = {"pageID": pageId, "numPages": 10, "totalCount": 100};
        }, 100);

        return result;
    }

    getAchievementLeaderBoard(pageId = 0) {
        let result = new AchievementLeaderBoardResult();
        let pos = 16;
        
        if (pageId < 1)
            pageId = Math.ceil(pos/10);

        let i = pageId > 1 ? 3 : 0;
        let j = (pageId - 1) * 10;
        
        setTimeout(function () {
            result.apiData = {
                "currentUserPosition": pos,
                "leaderboard": [
                    {
                        "position": 1,
                        "publicUserId": "3",
                        "points": 20,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 2,
                        "publicUserId": "2",
                        "points": 19,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 3,
                        "publicUserId": "1",
                        "points": 18,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 4,
                        "publicUserId": "4",
                        "points": 18,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 5,
                        "publicUserId": "5",
                        "points": 17,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 6,
                        "publicUserId": "6",
                        "points": 16,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 7,
                        "publicUserId": "7",
                        "points": 4,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 8,
                        "publicUserId": "8",
                        "points": 3,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 9,
                        "publicUserId": "9",
                        "points": 2,
                        "isCurrentUser": false,
                    },
                    {
                        "position": 10,
                        "publicUserId": "10",
                        "points": 1,
                        "isCurrentUser": false,
                    },
                ]
            };
            
            if (pageId > 1) {
                while (i < result.apiData.leaderboard.length) {
                    result.apiData.leaderboard[i].position = ++j;
                    result.apiData.leaderboard[i].isCurrentUser = result.apiData.leaderboard[i].position === pos;
                    i++;
                }

                while (result.apiData.leaderboard.length < 13) {
                    result.apiData.leaderboard.push({
                        "position": ++j,
                        "publicUserId": i++,
                        "points": 1,
                        "isCurrentUser": false,
                    });
                }
            }
            
            result.loaded = true;
            result.pagination = {"pageID": pageId, "numPages": 10, "totalCount": 100};
        }, 100);

        return result;
    }

    getExperienceHistory(pageId = 1) {
        let result = new ExperienceHistory();

        setTimeout(function () {
            result.apiData = [
                {
                    "description": "Daily Login",
                    "item": null,
                    "experience": 10,
                    "datetime": "2019-07-25T14:12:20Z"
                },
                {
                    "description": "Stage gelöst",
                    "item": {
                        id: "f0402749-0cee-45ae-9c90-7a6a0360d0f6",
                        type: "challenge_task"
                    },
                    "experience": 100,
                    "datetime": "2019-07-20T14:12:20Z"
                },
                {
                    "description": "Challenge gelöst.",
                    "item": {
                        id: "e918f361-abe7-4187-a89a-8be94bd4f64b",
                        type: "challenge"
                    },
                    "experience": 10,
                    "datetime": "2019-07-24T14:12:20Z"
                },
                {
                    "description": "Challenge gelöst",
                    "item": {id: "58c45208-c9cc-474e-94d4-ced187b077e0", type: "challenge"},
                    "experience": 10,
                    "datetime": "2019-07-23T14:12:20Z"
                },
                {
                    "description": "Daily Login",
                    "item": null,
                    "experience": 10,
                    "datetime": "2019-07-22T14:12:20Z"
                },
                {
                    "description": "Daily Login",
                    "item": null,
                    "experience": 10,
                    "datetime": "2019-07-21T14:12:20Z"
                },
                {
                    "description": "Challenge gelöst",
                    "item": {
                        id: "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                        type: "challenge"
                    },
                    "experience": 1000,
                    "datetime": "2019-07-20T14:12:20Z"
                },
            ];
            result.loaded = true;
            result.pagination = {"pageID": pageId, "numPages": "10",};
        }, 100);
        return result
    }

    getSkillScoreForUser(publicUserId) {
        let result = new SkillScoreForUserResult();
        setTimeout(function () {
            result.apiData = {
                "1b7bba4e-f041-4d04-bca2-45f6ba2c56ed": {
                    "score": 430,
                    "scoreInPercent": 1.19,
                    "scoreUntilNextLevel": 290,
                    "isProfessional": true,
                    "challengeCount": 1,
                    "workItemCount": 1,
                    "educationItemCount": 0
                },
                "246a76b0-920e-43ad-ad0e-ec0cb73356ea": {
                    "score": 120,
                    "scoreInPercent": 0.33,
                    "scoreUntilNextLevel": 240,
                    "isProfessional": false,
                    "challengeCount": 1,
                    "workItemCount": 0,
                    "educationItemCount": 0
                }
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    triggerEasterChallenge2021Finished() {
        let result = new EasterChallenge2021FinishedResult();
        setTimeout(function () {
            result.apiResult = {};
            result.loaded = true;
        }, 100);
        return result;
    }
}

const getRandomActivityResults = () => {
    let activityResults = [];
    let date = new Date();
    let numberOfActivities = Math.floor(Math.random() * 200) + 20;
    while (--numberOfActivities){
        let dateInPast = new Date();
        dateInPast.setDate(date.getDate() - Math.floor(Math.random() * 365));
        activityResults.push({date: dateInPast, count: Math.floor(Math.random() * 5) + 1});
    }
    return activityResults;
};