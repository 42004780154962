<template>
    <div class="level_up_component"
         style="">
        <svg id="el_-dEsohD0b" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 4149 2441">
            <defs>

                <linearGradient id="Unbenannter_Verlauf_7" data-name="Unbenannter Verlauf 7" x1="2286.26" y1="1310.07"
                                x2="1131.41" y2="643.32" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#72c099"></stop>
                    <stop offset="1" stop-color="#319998"></stop>
                </linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-2" x1="2729.69" y1="899.33" x2="2152.26" y2="565.96"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-3" x1="2729.69" y1="1387.43" x2="2152.26" y2="1054.05"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-4" x1="1494.63" y1="651.83" x2="917.21" y2="318.46"
                                gradientTransform="translate(1794.54 1191.43)"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-5" x1="1796.19" y1="1656.72" x2="1218.76" y2="1323.34"
                                gradientTransform="translate(1492.98 674.63)"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-6" x1="1782.45" y1="2834.99" x2="627.61" y2="2168.24"
                                gradientTransform="translate(-401 -581)"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-7" x1="1753.5" y1="411.24" x2="1176.07" y2="77.86"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-8" x1="1753.5" y1="1875.52" x2="1176.07" y2="1542.15"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-9" x1="1265.4" y1="411.24" x2="687.98" y2="77.86"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-10" x1="2270.55" y1="1370.71" x2="1115.7" y2="703.96"
                                gradientTransform="translate(575.19 1371.38)"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-11" x1="3217.78" y1="1875.52" x2="2640.36" y2="1542.15"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-12" x1="3217.78" y1="2363.62" x2="2640.36" y2="2030.24"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-13" x1="2270.55" y1="1858.8" x2="1115.7" y2="1192.05"
                                gradientTransform="translate(87.1 883.29)"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-14" x1="1782.45" y1="-93.58" x2="627.61" y2="-760.33"
                                gradientTransform="translate(2527.57 2347.57)"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-15" x1="3217.78" y1="411.24" x2="2640.36" y2="77.86"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-16" x1="3705.88" y1="1875.52" x2="3128.46" y2="1542.15"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-17" x1="2241.59" y1="2363.62" x2="1664.17" y2="2030.24"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-18" x1="1294.36" y1="394.52" x2="139.51" y2="-272.23"
                                gradientTransform="translate(2527.57 1371.38)"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-19" x1="777.31" y1="411.24" x2="199.88" y2="77.86"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-20" x1="2729.69" y1="411.24" x2="2152.26" y2="77.86"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-21" x1="3705.88" y1="2363.62" x2="3128.46" y2="2030.24"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-22" x1="4193.97" y1="1875.52" x2="3616.55" y2="1542.15"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-23" x1="2270.55" y1="2346.9" x2="1115.7" y2="1680.15"
                                gradientTransform="translate(-401 395.19)"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-24" x1="1782.45" y1="2834.99" x2="627.61" y2="2168.24"
                                gradientTransform="translate(-401 -581)"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-25" x1="266.88" y1="938.01" x2="-21.83" y2="771.32"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-26" x1="266.88" y1="693.96" x2="-21.83" y2="527.28"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-27" x1="266.88" y1="1426.11" x2="-21.83" y2="1259.42"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-28" x1="266.88" y1="1182.06" x2="-21.83" y2="1015.37"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-29" x1="1243.07" y1="1914.2" x2="954.36" y2="1747.51"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-30" x1="1243.07" y1="1670.15" x2="954.36" y2="1503.47"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-31" x1="1731.17" y1="1426.11" x2="1442.45" y2="1259.42"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-32" x1="1243.07" y1="2155.25" x2="954.36" y2="1988.56"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-33" x1="3439.5" y1="1182.06" x2="3150.79" y2="1015.37"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-34" x1="510.93" y1="1915.2" x2="222.22" y2="1748.51"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-35" x1="510.93" y1="1670.15" x2="222.22" y2="1503.47"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-36" x1="999.02" y1="1670.15" x2="710.31" y2="1503.47"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-37" x1="1487.12" y1="1426.11" x2="1198.41" y2="1259.42"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-38" x1="999.02" y1="2156.25" x2="710.31" y2="1989.56"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-39" x1="266.88" y1="1670.15" x2="-21.83" y2="1503.47"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
                <linearGradient id="Unbenannter_Verlauf_7-40" x1="266.88" y1="449.92" x2="-21.83" y2="283.23"
                                xlink:href="#Unbenannter_Verlauf_7"></linearGradient>
            </defs>
            <title>levelup</title>
            <rect x="1220.74" y="488.6" width="976.19" height="976.19" id="el_u2cui9A2Dd"></rect>


            <rect x="315.94" y="1432.52" width="976.19" height="976.19" id="el_xRWWbFpYCF"></rect>
            <rect x="1220.74" y="0.5" width="488.1" height="488.1" id="el_45H8KF5zqk"></rect>
            <rect x="1220.74" y="1464.79" width="488.1" height="488.1" id="el_TV75H91k6p"></rect>
            <rect x="732.64" y="0.5" width="488.1" height="488.1" id="el_cY5yYf76J1"></rect>
            <rect x="1780.22" y="1920.62" width="976.19" height="976.19" id="el_Ut-kYqtXzE"></rect>
            <rect x="2685.02" y="1464.79" width="488.1" height="488.1" id="el_m0k2lJ0uEa"></rect>
            <rect x="2685.02" y="1952.88" width="488.1" height="488.1" id="el_QZS8gsu-_c"></rect>
            <rect x="1292.13" y="1920.62" width="976.19" height="976.19" id="el_RUOhOpltPX"></rect>
            <rect x="3244.51" y="1432.52" width="976.19" height="976.19" id="el__m9WRGFL2H"></rect>
            <rect x="2685.02" y="0.5" width="488.1" height="488.1" id="el_Rcyhwtbnqy"></rect>
            <rect x="3173.12" y="1464.79" width="488.1" height="488.1" id="el_4fQwtgMaMts"></rect>
            <rect x="1708.83" y="1952.88" width="488.1" height="488.1" id="el_GLrfVNaEMNS"></rect>
            <rect x="2756.41" y="944.43" width="976.19" height="976.19" id="el_PY3dzjHJ6DS"></rect>

            <rect x="2196.93" y="0.5" width="488.1" height="488.1" id="el_aSuaiLU0OpY"></rect>
            <rect x="3173.12" y="1952.88" width="488.1" height="488.1" id="el_CRPDrxRhyrj"></rect>
            <rect x="3661.21" y="1464.79" width="488.1" height="488.1" id="el_EXXzDJY7PAr"></rect>
            <rect x="804.03" y="1920.62" width="976.19" height="976.19" id="el_-DQiC3bCz4M"></rect>
            <g id="el_BlPWQGqAJn8_an_oWMPHPClo" data-animator-group="true" data-animator-type="0">
                <text id="el_BlPWQGqAJn8">
                    <tspan id="el_pcKiMXZ_NEO">L</tspan>
                    <tspan x="181.65" y="0" id="el_XX9K2XTEI7x">e</tspan>
                    <tspan x="381.91" y="0" id="el_Q-0Hrc_l-YI">vel</tspan>
                    <tspan x="848.77" y="0" xml:space="preserve" id="el_wNwK9uRspSd"> Up!</tspan>
                </text>
            </g>
            <rect x="315.94" y="1432.52" width="976.19" height="976.19" id="el_7D2E-Fmd5F0"/>
            <g id="el_deimzEZi79Y_an_4ayvROT7A" data-animator-group="true" data-animator-type="0">
                <text id="el_deimzEZi79Y">{{newLevel}}</text>
            </g>
            <rect x="0.5" y="732.64" width="244.05" height="244.05" id="el_vryEdZVtqHm"></rect>
            <rect x="0.5" y="488.6" width="244.05" height="244.05" id="el__eL2obWc0SY"></rect>
            <rect x="0.5" y="1220.74" width="244.05" height="244.05" id="el_k0p1XmIHl6W"></rect>
            <rect x="0.5" y="976.69" width="244.05" height="244.05" id="el_mA74pRE-52D"></rect>
            <rect x="976.69" y="1708.83" width="244.05" height="244.05" id="el_3bh3R3vUl9_"></rect>
            <rect x="976.69" y="1464.79" width="244.05" height="244.05" id="el_FwVB4MSTGp2"></rect>
            <rect x="1464.79" y="1220.74" width="244.05" height="244.05" id="el_YmOuVyf3Ozp"></rect>
            <rect x="976.69" y="1949.88" width="244.05" height="244.05" id="el_l_9uCHxdV0c"></rect>
            <rect x="3173.12" y="976.69" width="244.05" height="244.05" id="el_jaK8ofyrwjf"></rect>
            <rect x="244.55" y="1709.83" width="244.05" height="244.05" id="el_8LauTczFPXS"></rect>
            <rect x="244.55" y="1464.79" width="244.05" height="244.05" id="el_WvhIpQVGFod"></rect>
            <rect x="732.64" y="1464.79" width="244.05" height="244.05" id="el_-_mxtilSX78"></rect>
            <rect x="1220.74" y="1220.74" width="244.05" height="244.05" id="el_rb8REuSCVCX"></rect>
            <rect x="732.64" y="1950.88" width="244.05" height="244.05" id="el_t9SPFkKtCvD"></rect>
            <rect x="0.5" y="1464.79" width="244.05" height="244.05" id="el_n58o70T6RUA"></rect>
            <rect x="0.5" y="244.55" width="244.05" height="244.05" id="el_o55T11qfuZ3"></rect>
        </svg>
    </div>
</template>


<script>
    export default {
        name: "LevelUpComponent",
        data() {
            return {};
        },
        props: {
            newLevel: {type: [String], default: "2"},
            finishedHandler: {type: [Function], default: null},
        },
        created() {
            setTimeout(() => {
                this.finishedHandler();
            }, 4000);
        }
    }
</script>

<style scoped>
    @keyframes background_white {
        0% {
            background: none;
            box-shadow: 0 0 90px 50px rgba(255, 255, 255, 0.0);
        }

        50% {
            background: rgba(255, 255, 255, 0.7);
            box-shadow: 0 0 90px 50px rgba(255, 255, 255, 0.79);
        }
        100% {
            background: none;
            box-shadow: 0 0 90px 50px rgba(255, 255, 255, 0.0);
            display: none;
        }
    }

    .level_up_component {
        animation: background_white 4s linear;
        width: 45%;
        position: fixed;
        top: 25%;
        left: 33%;
        height: auto;
        z-index: 1000;
        padding: 5rem;

    }


    @-webkit-keyframes kf_el_xRWWbFpYCF_an_lB02_92SX {
        12.38% {
            opacity: 0;
        }
        61.90% {
            opacity: 0.05;
        }
        79.05% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_xRWWbFpYCF_an_lB02_92SX {
        12.38% {
            opacity: 0;
        }
        61.90% {
            opacity: 0.05;
        }
        79.05% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_45H8KF5zqk_an_jqxpkI7YF {
        42.38% {
            opacity: 0;
        }
        74.29% {
            opacity: 0.28;
        }
        77.62% {
            opacity: 0.5;
        }
        81.43% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_45H8KF5zqk_an_jqxpkI7YF {
        42.38% {
            opacity: 0;
        }
        74.29% {
            opacity: 0.28;
        }
        77.62% {
            opacity: 0.5;
        }
        81.43% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_TV75H91k6p_an_UtkBbW3NR {
        20.48% {
            opacity: 0;
        }
        51.90% {
            opacity: 0.28;
        }
        68.10% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_TV75H91k6p_an_UtkBbW3NR {
        20.48% {
            opacity: 0;
        }
        51.90% {
            opacity: 0.28;
        }
        68.10% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_cY5yYf76J1_an__fbAbHjpP {
        29.05% {
            opacity: 0;
        }
        37.14% {
            opacity: 0.35;
        }
        48.10% {
            opacity: 0.06;
        }
        78.10% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_cY5yYf76J1_an__fbAbHjpP {
        29.05% {
            opacity: 0;
        }
        37.14% {
            opacity: 0.35;
        }
        48.10% {
            opacity: 0.06;
        }
        78.10% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_Ut-kYqtXzE_an_dMAEueVvg {
        57.62% {
            opacity: 0;
        }
        68.10% {
            opacity: 0.28;
        }
        77.62% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_Ut-kYqtXzE_an_dMAEueVvg {
        57.62% {
            opacity: 0;
        }
        68.10% {
            opacity: 0.28;
        }
        77.62% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_QZS8gsu-_c_an_Ze2sgCcoi {
        8.57% {
            opacity: 0;
        }
        19.05% {
            opacity: 0.28;
        }
        40.48% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_QZS8gsu-_c_an_Ze2sgCcoi {
        8.57% {
            opacity: 0;
        }
        19.05% {
            opacity: 0.28;
        }
        40.48% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_m0k2lJ0uEa_an__KMQLODMD {
        39.52% {
            opacity: 0;
        }
        44.76% {
            opacity: 0.4;
        }
        55.24% {
            opacity: 0.28;
        }
        58.10% {
            opacity: 0.5;
        }
        62.86% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_m0k2lJ0uEa_an__KMQLODMD {
        39.52% {
            opacity: 0;
        }
        44.76% {
            opacity: 0.4;
        }
        55.24% {
            opacity: 0.28;
        }
        58.10% {
            opacity: 0.5;
        }
        62.86% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_CRPDrxRhyrj_an_ljEaZJtaZ {
        35.71% {
            opacity: 0;
        }
        50.95% {
            opacity: 0.09;
        }
        65.71% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_CRPDrxRhyrj_an_ljEaZJtaZ {
        35.71% {
            opacity: 0;
        }
        50.95% {
            opacity: 0.09;
        }
        65.71% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_8LauTczFPXS_an_isb_jCAv7 {
        0% {
            opacity: 0;
        }
        22.38% {
            opacity: 0.03;
        }
        76.19% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_8LauTczFPXS_an_isb_jCAv7 {
        0% {
            opacity: 0;
        }
        22.38% {
            opacity: 0.03;
        }
        76.19% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_YmOuVyf3Ozp_an_tPKtR083g {
        33.33% {
            opacity: 0;
        }
        44.76% {
            opacity: 0.65;
        }
        57.14% {
            opacity: 0.28;
        }
        76.67% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_YmOuVyf3Ozp_an_tPKtR083g {
        33.33% {
            opacity: 0;
        }
        44.76% {
            opacity: 0.65;
        }
        57.14% {
            opacity: 0.28;
        }
        76.67% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_WvhIpQVGFod_an_VsSgl2Uqb {
        0% {
            opacity: 0;
        }
        39.05% {
            opacity: 0.09;
        }
        82.86% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_WvhIpQVGFod_an_VsSgl2Uqb {
        0% {
            opacity: 0;
        }
        39.05% {
            opacity: 0.09;
        }
        82.86% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_-_mxtilSX78_an_Zejsi4W-N {
        3.33% {
            opacity: 0;
        }
        59.05% {
            opacity: 0.09;
        }
        75.71% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_-_mxtilSX78_an_Zejsi4W-N {
        3.33% {
            opacity: 0;
        }
        59.05% {
            opacity: 0.09;
        }
        75.71% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_l_9uCHxdV0c_an_ycvEcNntV {
        8.57% {
            opacity: 0;
        }
        28.10% {
            opacity: 0.6;
        }
        60.48% {
            opacity: 0.12;
        }
        74.29% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_l_9uCHxdV0c_an_ycvEcNntV {
        8.57% {
            opacity: 0;
        }
        28.10% {
            opacity: 0.6;
        }
        60.48% {
            opacity: 0.12;
        }
        74.29% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_t9SPFkKtCvD_an_hJdyKOFZO {
        27.62% {
            opacity: 0;
        }
        44.76% {
            opacity: 0.12;
        }
        69.52% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_t9SPFkKtCvD_an_hJdyKOFZO {
        27.62% {
            opacity: 0;
        }
        44.76% {
            opacity: 0.12;
        }
        69.52% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_GLrfVNaEMNS_an_IrZvDn0QP {
        17.62% {
            opacity: 0;
        }
        37.14% {
            opacity: 0.15;
        }
        62.86% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_GLrfVNaEMNS_an_IrZvDn0QP {
        17.62% {
            opacity: 0;
        }
        37.14% {
            opacity: 0.15;
        }
        62.86% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_EXXzDJY7PAr_an_yMBxbUfIz {
        27.62% {
            opacity: 0;
        }
        48.57% {
            opacity: 0.09;
        }
        75.24% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_EXXzDJY7PAr_an_yMBxbUfIz {
        27.62% {
            opacity: 0;
        }
        48.57% {
            opacity: 0.09;
        }
        75.24% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_4fQwtgMaMts_an_e6H5YFRpE {
        38.57% {
            opacity: 0;
        }
        50% {
            opacity: 0.28;
        }
        59.05% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_4fQwtgMaMts_an_e6H5YFRpE {
        38.57% {
            opacity: 0;
        }
        50% {
            opacity: 0.28;
        }
        59.05% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_aSuaiLU0OpY_an_h4pFjlYMH {
        5.71% {
            opacity: 0;
        }
        57.62% {
            opacity: 0.09;
        }
        70.48% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_aSuaiLU0OpY_an_h4pFjlYMH {
        5.71% {
            opacity: 0;
        }
        57.62% {
            opacity: 0.09;
        }
        70.48% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_BlPWQGqAJn8_an_oWMPHPClo {
        27.14% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, -200px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, -200px);
        }
        41.90% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 0px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 0px);
        }
        51.43% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 0px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 0px);
        }
        70.48% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 200px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 200px);
        }
        0% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, -200px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, -200px);
        }
        100% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 200px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 200px);
        }
    }

    @keyframes kf_el_BlPWQGqAJn8_an_oWMPHPClo {
        27.14% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, -200px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, -200px);
        }
        41.90% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 0px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 0px);
        }
        51.43% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 0px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 0px);
        }
        70.48% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 200px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 200px);
        }
        0% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, -200px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, -200px);
        }
        100% {
            -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 200px);
            transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, 200px);
        }
    }

    @-webkit-keyframes kf_el_deimzEZi79Y_an_4ayvROT7A {
        11.43% {
            -webkit-transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(-30px, 0px);
            transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(-30px, 0px);
        }
        28.57% {
            -webkit-transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(0px, 0px);
            transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(0px, 0px);
        }
        0% {
            -webkit-transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(-30px, 0px);
            transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(-30px, 0px);
        }
        100% {
            -webkit-transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(0px, 0px);
            transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(0px, 0px);
        }
    }

    @keyframes kf_el_deimzEZi79Y_an_4ayvROT7A {
        11.43% {
            -webkit-transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(-30px, 0px);
            transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(-30px, 0px);
        }
        28.57% {
            -webkit-transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(0px, 0px);
            transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(0px, 0px);
        }
        0% {
            -webkit-transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(-30px, 0px);
            transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(-30px, 0px);
        }
        100% {
            -webkit-transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(0px, 0px);
            transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(0px, 0px);
        }
    }

    @-webkit-keyframes kf_el_deimzEZi79Y_an_sA0coVFQn {
        14.29% {
            opacity: 0;
        }
        27.14% {
            opacity: 1;
        }
        65.24% {
            opacity: 1;
        }
        79.52% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_deimzEZi79Y_an_sA0coVFQn {
        14.29% {
            opacity: 0;
        }
        27.14% {
            opacity: 1;
        }
        65.24% {
            opacity: 1;
        }
        79.52% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_vryEdZVtqHm_an_I0yCDtNIt {
        13.33% {
            opacity: 0;
        }
        49.52% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_vryEdZVtqHm_an_I0yCDtNIt {
        13.33% {
            opacity: 0;
        }
        49.52% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el__eL2obWc0SY_an_D6bgqHFML {
        6.67% {
            opacity: 0;
        }
        37.14% {
            opacity: 0.3;
        }
        57.14% {
            opacity: 0.09;
        }
        78.10% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el__eL2obWc0SY_an_D6bgqHFML {
        6.67% {
            opacity: 0;
        }
        37.14% {
            opacity: 0.3;
        }
        57.14% {
            opacity: 0.09;
        }
        78.10% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_Rcyhwtbnqy_an_1vLqcr2T6 {
        11.90% {
            opacity: 0;
        }
        48.10% {
            opacity: 0.28;
        }
        59.52% {
            opacity: 0.06;
        }
        71.90% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_Rcyhwtbnqy_an_1vLqcr2T6 {
        11.90% {
            opacity: 0;
        }
        48.10% {
            opacity: 0.28;
        }
        59.52% {
            opacity: 0.06;
        }
        71.90% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_rb8REuSCVCX_an_JoWIXzyfz {
        48.10% {
            opacity: 0;
        }
        54.76% {
            opacity: 0.1;
        }
        78.10% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_rb8REuSCVCX_an_JoWIXzyfz {
        48.10% {
            opacity: 0;
        }
        54.76% {
            opacity: 0.1;
        }
        78.10% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_7D2E-Fmd5F0_an_znzFHGaVl {
        2.86% {
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        73.33% {
            opacity: 1;
        }
        79.52% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_7D2E-Fmd5F0_an_znzFHGaVl {
        2.86% {
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        73.33% {
            opacity: 1;
        }
        79.52% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_o55T11qfuZ3_an_C476f9_O6 {
        37.62% {
            opacity: 0;
        }
        64.29% {
            opacity: 0.05;
        }
        77.62% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_o55T11qfuZ3_an_C476f9_O6 {
        37.62% {
            opacity: 0;
        }
        64.29% {
            opacity: 0.05;
        }
        77.62% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_3bh3R3vUl9__an_qTDKokGXa {
        47.62% {
            opacity: 0;
        }
        58.10% {
            opacity: 0.4;
        }
        60.95% {
            opacity: 0.28;
        }
        78.10% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_3bh3R3vUl9__an_qTDKokGXa {
        47.62% {
            opacity: 0;
        }
        58.10% {
            opacity: 0.4;
        }
        60.95% {
            opacity: 0.28;
        }
        78.10% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_FwVB4MSTGp2_an_Il7G14vkO {
        21.43% {
            opacity: 0;
        }
        29.52% {
            opacity: 0.09;
        }
        41.90% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_FwVB4MSTGp2_an_Il7G14vkO {
        21.43% {
            opacity: 0;
        }
        29.52% {
            opacity: 0.09;
        }
        41.90% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_mA74pRE-52D_an_igEOsVcIA {
        8.10% {
            opacity: 0;
        }
        13.33% {
            opacity: 0;
        }
        58.10% {
            opacity: 0.12;
        }
        66.19% {
            opacity: 0.014199999999999999;
        }
        70.95% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_mA74pRE-52D_an_igEOsVcIA {
        8.10% {
            opacity: 0;
        }
        13.33% {
            opacity: 0;
        }
        58.10% {
            opacity: 0.12;
        }
        66.19% {
            opacity: 0.014199999999999999;
        }
        70.95% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_k0p1XmIHl6W_an_f13uzSb9a {
        11.90% {
            opacity: 0;
        }
        49.05% {
            opacity: 0.28;
        }
        70.95% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_k0p1XmIHl6W_an_f13uzSb9a {
        11.90% {
            opacity: 0;
        }
        49.05% {
            opacity: 0.28;
        }
        70.95% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_n58o70T6RUA_an_gQhiLQRI7 {
        4.76% {
            opacity: 0;
        }
        47.14% {
            opacity: 0.7;
        }
        52.38% {
            opacity: 0.09;
        }
        65.71% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_n58o70T6RUA_an_gQhiLQRI7 {
        4.76% {
            opacity: 0;
        }
        47.14% {
            opacity: 0.7;
        }
        52.38% {
            opacity: 0.09;
        }
        65.71% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_jaK8ofyrwjf_an_JYssF5c0T {
        1.43% {
            opacity: 0;
        }
        27.14% {
            opacity: 0.06;
        }
        47.14% {
            opacity: 0.5;
        }
        81.43% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_jaK8ofyrwjf_an_JYssF5c0T {
        1.43% {
            opacity: 0;
        }
        27.14% {
            opacity: 0.06;
        }
        47.14% {
            opacity: 0.5;
        }
        81.43% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_RUOhOpltPX_an_JaSUpyolH {
        6.67% {
            opacity: 0;
        }
        16.67% {
            opacity: 0.06;
        }
        30% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_RUOhOpltPX_an_JaSUpyolH {
        6.67% {
            opacity: 0;
        }
        16.67% {
            opacity: 0.06;
        }
        30% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el__m9WRGFL2H_an_g7GTK6r1S {
        8.57% {
            opacity: 0;
        }
        18.10% {
            opacity: 0.28;
        }
        52.38% {
            opacity: 0.25;
        }
        58.57% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el__m9WRGFL2H_an_g7GTK6r1S {
        8.57% {
            opacity: 0;
        }
        18.10% {
            opacity: 0.28;
        }
        52.38% {
            opacity: 0.25;
        }
        58.57% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_PY3dzjHJ6DS_an_mcJUZ1MO_ {
        10.95% {
            opacity: 0;
        }
        44.76% {
            opacity: 0.15;
        }
        66.19% {
            opacity: 0.11;
        }
        73.33% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_PY3dzjHJ6DS_an_mcJUZ1MO_ {
        10.95% {
            opacity: 0;
        }
        44.76% {
            opacity: 0.15;
        }
        66.19% {
            opacity: 0.11;
        }
        73.33% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_-DQiC3bCz4M_an_xh0wwsQaj {
        1.43% {
            opacity: 0;
        }
        23.81% {
            opacity: 0.1;
        }
        54.29% {
            opacity: 0.11;
        }
        70.48% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_-DQiC3bCz4M_an_xh0wwsQaj {
        1.43% {
            opacity: 0;
        }
        23.81% {
            opacity: 0.1;
        }
        54.29% {
            opacity: 0.11;
        }
        70.48% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_u2cui9A2Dd_an_N_tEgzhrn {
        62.38% {
            opacity: 0;
        }
        65.24% {
            opacity: 0.05;
        }
        72.38% {
            opacity: 0.28;
        }
        78.57% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_u2cui9A2Dd_an_N_tEgzhrn {
        62.38% {
            opacity: 0;
        }
        65.24% {
            opacity: 0.05;
        }
        72.38% {
            opacity: 0.28;
        }
        78.57% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes kf_el_BlPWQGqAJn8_an_q7f_MKd7r {
        28.57% {
            opacity: 0;
        }
        39.05% {
            opacity: 1;
        }
        57.62% {
            opacity: 1;
        }
        66.19% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes kf_el_BlPWQGqAJn8_an_q7f_MKd7r {
        28.57% {
            opacity: 0;
        }
        39.05% {
            opacity: 1;
        }
        57.62% {
            opacity: 1;
        }
        66.19% {
            opacity: 0;
        }
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    #el_-dEsohD0b * {
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_u2cui9A2Dd {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_u2cui9A2Dd_an_N_tEgzhrn;
        animation-name: kf_el_u2cui9A2Dd_an_N_tEgzhrn;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_fCjox9i6k2 {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0.28;
        fill: url(#Unbenannter_Verlauf_7-2);
        visibility: hidden;
    }

    #el_nQ6KVzheHI {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0.28;
        fill: url(#Unbenannter_Verlauf_7-3);
        visibility: hidden;
    }

    #el_Vbf8o4UrR_ {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0.28;
        fill: url(#Unbenannter_Verlauf_7-4);
        -webkit-transform: translate(5929.53px, 2409.21px) rotate(180deg);
        transform: translate(5929.53px, 2409.21px) rotate(180deg);
        visibility: hidden;
    }

    #el_bzOpnSGhVp {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0.28;
        fill: url(#Unbenannter_Verlauf_7-5);
        -webkit-transform: translate(764.4px, 4221.2px) rotate(-90deg);
        transform: translate(764.4px, 4221.2px) rotate(-90deg);
        visibility: hidden;
    }

    #el_xRWWbFpYCF {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-6);
        -webkit-transform: translate(2653.26px, 172.66px) rotate(90deg);
        transform: translate(2653.26px, 172.66px) rotate(90deg);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_xRWWbFpYCF_an_lB02_92SX;
        animation-name: kf_el_xRWWbFpYCF_an_lB02_92SX;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_45H8KF5zqk {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-7);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_45H8KF5zqk_an_jqxpkI7YF;
        animation-name: kf_el_45H8KF5zqk_an_jqxpkI7YF;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_TV75H91k6p {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-8);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_TV75H91k6p_an_UtkBbW3NR;
        animation-name: kf_el_TV75H91k6p_an_UtkBbW3NR;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_cY5yYf76J1 {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-9);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_cY5yYf76J1_an__fbAbHjpP;
        animation-name: kf_el_cY5yYf76J1_an__fbAbHjpP;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_Ut-kYqtXzE {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-10);
        -webkit-transform: translate(4605.64px, -803.53px) rotate(90deg);
        transform: translate(4605.64px, -803.53px) rotate(90deg);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_Ut-kYqtXzE_an_dMAEueVvg;
        animation-name: kf_el_Ut-kYqtXzE_an_dMAEueVvg;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_m0k2lJ0uEa {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-11);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_m0k2lJ0uEa_an__KMQLODMD;
        animation-name: kf_el_m0k2lJ0uEa_an__KMQLODMD;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_QZS8gsu-_c {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-12);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_QZS8gsu-_c_an_Ze2sgCcoi;
        animation-name: kf_el_QZS8gsu-_c_an_Ze2sgCcoi;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_RUOhOpltPX {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-13);
        -webkit-transform: translate(4117.55px, -315.44px) rotate(90deg);
        transform: translate(4117.55px, -315.44px) rotate(90deg);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_RUOhOpltPX_an_JaSUpyolH;
        animation-name: kf_el_RUOhOpltPX_an_JaSUpyolH;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el__m9WRGFL2H {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-14);
        -webkit-transform: translate(5581.83px, -2755.91px) rotate(90deg);
        transform: translate(5581.83px, -2755.91px) rotate(90deg);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el__m9WRGFL2H_an_g7GTK6r1S;
        animation-name: kf_el__m9WRGFL2H_an_g7GTK6r1S;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_Rcyhwtbnqy {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-15);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_Rcyhwtbnqy_an_1vLqcr2T6;
        animation-name: kf_el_Rcyhwtbnqy_an_1vLqcr2T6;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_4fQwtgMaMts {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-16);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_4fQwtgMaMts_an_e6H5YFRpE;
        animation-name: kf_el_4fQwtgMaMts_an_e6H5YFRpE;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_GLrfVNaEMNS {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-17);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_GLrfVNaEMNS_an_IrZvDn0QP;
        animation-name: kf_el_GLrfVNaEMNS_an_IrZvDn0QP;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_PY3dzjHJ6DS {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-18);
        -webkit-transform: translate(4605.64px, -2755.91px) rotate(90deg);
        transform: translate(4605.64px, -2755.91px) rotate(90deg);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_PY3dzjHJ6DS_an_mcJUZ1MO_;
        animation-name: kf_el_PY3dzjHJ6DS_an_mcJUZ1MO_;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_HW4TmvuOwKK {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0.15;
        fill: url(#Unbenannter_Verlauf_7-19);
        visibility: hidden;
    }

    #el_aSuaiLU0OpY {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-20);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_aSuaiLU0OpY_an_h4pFjlYMH;
        animation-name: kf_el_aSuaiLU0OpY_an_h4pFjlYMH;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_CRPDrxRhyrj {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-21);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_CRPDrxRhyrj_an_ljEaZJtaZ;
        animation-name: kf_el_CRPDrxRhyrj_an_ljEaZJtaZ;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_EXXzDJY7PAr {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-22);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_EXXzDJY7PAr_an_yMBxbUfIz;
        animation-name: kf_el_EXXzDJY7PAr_an_yMBxbUfIz;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_-DQiC3bCz4M {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-23);
        -webkit-transform: translate(3629.45px, 172.66px) rotate(90deg);
        transform: translate(3629.45px, 172.66px) rotate(90deg);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_-DQiC3bCz4M_an_xh0wwsQaj;
        animation-name: kf_el_-DQiC3bCz4M_an_xh0wwsQaj;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_BlPWQGqAJn8 {
        font-size: 404.57px;
        fill: #1d1d1b;
        font-family: SourceSansPro-Light, Source Sans Pro;
        font-weight: 300;
        -webkit-transform: translate(1304.46px, 1062.05px);
        transform: translate(1304.46px, 1062.05px);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        opacity: 0;
        -webkit-animation-name: kf_el_BlPWQGqAJn8_an_q7f_MKd7r;
        animation-name: kf_el_BlPWQGqAJn8_an_q7f_MKd7r;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_pcKiMXZ_NEO {
        letter-spacing: -0.01em;
    }

    #el_XX9K2XTEI7x {
        letter-spacing: 0.01em;
    }

    #el_wNwK9uRspSd {
        font-family: SourceSansPro-Black, Source Sans Pro;
        font-weight: 800;
        letter-spacing: 0em;
    }

    #el_7D2E-Fmd5F0 {
        stroke: #fff;
        stroke-miterlimit: 10;
        fill: url(#Unbenannter_Verlauf_7-24);
        -webkit-transform: translate(2653.26px, 172.66px) rotate(90deg);
        transform: translate(2653.26px, 172.66px) rotate(90deg);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        opacity: 0;
        -webkit-animation-name: kf_el_7D2E-Fmd5F0_an_znzFHGaVl;
        animation-name: kf_el_7D2E-Fmd5F0_an_znzFHGaVl;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_deimzEZi79Y {
        font-size: 772.36px;
        fill: #f1f8f5;
        font-family: Roboto-Bold, Roboto;
        font-weight: 700;
        -webkit-transform: translate(524.94px, 1238.74px);
        transform: translate(524.94px, 1238.74px);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        opacity: 0;
        -webkit-animation-name: kf_el_deimzEZi79Y_an_sA0coVFQn;
        animation-name: kf_el_deimzEZi79Y_an_sA0coVFQn;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_vryEdZVtqHm {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-25);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_vryEdZVtqHm_an_I0yCDtNIt;
        animation-name: kf_el_vryEdZVtqHm_an_I0yCDtNIt;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el__eL2obWc0SY {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-26);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el__eL2obWc0SY_an_D6bgqHFML;
        animation-name: kf_el__eL2obWc0SY_an_D6bgqHFML;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_k0p1XmIHl6W {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-27);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_k0p1XmIHl6W_an_f13uzSb9a;
        animation-name: kf_el_k0p1XmIHl6W_an_f13uzSb9a;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_mA74pRE-52D {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-28);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_mA74pRE-52D_an_igEOsVcIA;
        animation-name: kf_el_mA74pRE-52D_an_igEOsVcIA;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_3bh3R3vUl9_ {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-29);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_3bh3R3vUl9__an_qTDKokGXa;
        animation-name: kf_el_3bh3R3vUl9__an_qTDKokGXa;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_FwVB4MSTGp2 {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-30);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_FwVB4MSTGp2_an_Il7G14vkO;
        animation-name: kf_el_FwVB4MSTGp2_an_Il7G14vkO;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_YmOuVyf3Ozp {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-31);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_YmOuVyf3Ozp_an_tPKtR083g;
        animation-name: kf_el_YmOuVyf3Ozp_an_tPKtR083g;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_l_9uCHxdV0c {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-32);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_l_9uCHxdV0c_an_ycvEcNntV;
        animation-name: kf_el_l_9uCHxdV0c_an_ycvEcNntV;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_jaK8ofyrwjf {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-33);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_jaK8ofyrwjf_an_JYssF5c0T;
        animation-name: kf_el_jaK8ofyrwjf_an_JYssF5c0T;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_8LauTczFPXS {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-34);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_8LauTczFPXS_an_isb_jCAv7;
        animation-name: kf_el_8LauTczFPXS_an_isb_jCAv7;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_WvhIpQVGFod {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-35);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_WvhIpQVGFod_an_VsSgl2Uqb;
        animation-name: kf_el_WvhIpQVGFod_an_VsSgl2Uqb;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_-_mxtilSX78 {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-36);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_-_mxtilSX78_an_Zejsi4W-N;
        animation-name: kf_el_-_mxtilSX78_an_Zejsi4W-N;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_rb8REuSCVCX {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-37);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_rb8REuSCVCX_an_JoWIXzyfz;
        animation-name: kf_el_rb8REuSCVCX_an_JoWIXzyfz;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_t9SPFkKtCvD {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-38);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_t9SPFkKtCvD_an_hJdyKOFZO;
        animation-name: kf_el_t9SPFkKtCvD_an_hJdyKOFZO;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_n58o70T6RUA {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-39);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_n58o70T6RUA_an_gQhiLQRI7;
        animation-name: kf_el_n58o70T6RUA_an_gQhiLQRI7;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_o55T11qfuZ3 {
        stroke: #fff;
        stroke-miterlimit: 10;
        opacity: 0;
        fill: url(#Unbenannter_Verlauf_7-40);
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-animation-name: kf_el_o55T11qfuZ3_an_C476f9_O6;
        animation-name: kf_el_o55T11qfuZ3_an_C476f9_O6;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_BlPWQGqAJn8_an_wCQa4QsKv {
        -webkit-transform: translate(0px, -15px) scale(1, 1) translate(0px, 15px);
        transform: translate(0px, -15px) scale(1, 1) translate(0px, 15px);
    }

    #el_deimzEZi79Y_an_FIvJLZxtt {
        -webkit-transform: translate(221.5546875px, -299.3125px) scale(0.8, 0.8) translate(-221.5546875px, 299.3125px);
        transform: translate(221.5546875px, -299.3125px) scale(0.8, 0.8) translate(-221.5546875px, 299.3125px);
    }

    #el_deimzEZi79Y_an_4ayvROT7A {
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(-30px, 0px);
        transform: translate(221.5546875px, -299.3125px) translate(-221.5546875px, 299.3125px) translate(-30px, 0px);
        -webkit-animation-name: kf_el_deimzEZi79Y_an_4ayvROT7A;
        animation-name: kf_el_deimzEZi79Y_an_4ayvROT7A;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    }

    #el_BlPWQGqAJn8_an_oWMPHPClo {
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, -200px);
        transform: translate(792.9296875px, -144.15625px) translate(-792.9296875px, 144.15625px) translate(0px, -200px);
        -webkit-animation-name: kf_el_BlPWQGqAJn8_an_oWMPHPClo;
        animation-name: kf_el_BlPWQGqAJn8_an_oWMPHPClo;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }

    #el_BlPWQGqAJn8_an_UMiUtQ_Vb {
        -webkit-transform: translate(792.9296875px, -144.15625px) scale(0.8, 0.8) translate(-792.9296875px, 144.15625px);
        transform: translate(792.9296875px, -144.15625px) scale(0.8, 0.8) translate(-792.9296875px, 144.15625px);
    }
</style>
