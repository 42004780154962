import {ProvisionResult} from "./ProvisionerServiceResponses";

export default class ProvisionerServiceStub {

    constructor(auth) {
        this.authService = auth;
    }

    getWorkspaceUrl(challengeId) {
        let result = new ProvisionResult();

        setTimeout(function () {
            result.apiData = {
                testcontainer_url: "http://tc-123-facebook-1234.pinkpanther.entwicklerheld.de",
                workspace_url: "http://localhost:8080",
                live_server_url: "http://ls-123-facebook-1234.pinkpanther.entwicklerheld.de",
                live_stream_code_url: "http://sddas-asdsaddsa-5345435-dqdssad.pinkpanther.entwicklerheld.de"
            };
            result.loaded = true;
        }, 200);
        return result;
    }
}
