<template>
    <div class="row form-row">
        <div class="col-xs-12 col-md-offset-2 col-md-8">
            <h3 class="text-center">
                Was ist dein Heldenstatus?
            </h3>
            <div class="content">
                <form class="profession-form">
                      <div class="profession-box" @click="selectProfession('pupil')"
                           :class="{'professionSelectedAnimation': selectedProfession === 'pupil',
                                   'professionOtherAnimation': selectedProfession && selectedProfession !== 'pupil',
                                   'profession-focus': selectedProfession === 'pupil'}">
                          <div class="row profession-row">
                              <div class="col-xs-4 col-sm-12">
                                  <img class="profession-icon"
                                       src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605664/eh-platform/icons/038_School_-_Retro.svg">
                                  <p>
                                      <input
                                              class="profession-input input"
                                              type="radio"
                                              id="profession-choice-1"
                                              name="radiobutton"
                                      />
                                  </p >
                              </div>
                              <div class="col-xs-8 col-sm-12 profession-label-col">
                                  <p>Schüler*in</p>
                              </div>
                          </div>
                      </div>
                      <div class="profession-box" @click="selectProfession('student/trainee')"
                           :class="{professionSelectedAnimation: selectedProfession === 'student/trainee',
                                   professionOtherAnimation: selectedProfession && selectedProfession !== 'student/trainee',
                                   'profession-focus': selectedProfession === 'student/trainee'}">
                          <div class="row profession-row">
                              <div class="col-xs-4 col-sm-12">
                                  <img class="profession-icon"
                                       src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605662/eh-platform/icons/031_University_-_Retro.svg">
                                  <p><input
                                          class="profession-input input"
                                          type="radio"
                                          id="profession-choice-2"
                                          name="radiobutton"
                                  /></p>
                              </div>
                              <div class="col-xs-8 col-sm-12 profession-label-col">
                                  <p>Student*in / Azubi</p>
                              </div>
                          </div>
                      </div>
                      <div class="profession-box" @click="selectProfession('professional/academics')"
                           :class="{professionSelectedAnimation: selectedProfession === 'professional/academics',
                                   professionOtherAnimation: selectedProfession && selectedProfession !== 'professional/academics',
                                   'profession-focus': selectedProfession === 'professional/academics'}">
                          <div class="row profession-row">
                              <div class="col-xs-4 col-sm-12">
                                  <img class="profession-icon"
                                       src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1598606266/eh-platform/icons/Condo_Building_1_-_Retro.svg">

                                  <p><input
                                          class="profession-input input"
                                          type="radio"
                                          id="profession-choice-3"
                                          name="radiobutton"
                                  /></p>
                              </div>
                              <div class="col-xs-8 col-sm-12 profession-label-col">
                                  <p>Professional</p>
                              </div>
                          </div>
                      </div>
                </form>
                <p v-if="$v.selectedProfession.$error && !$v.selectedProfession.required" class="error">Du musst eins auswählen. Wähle das aus, was am ehesten auf dich zutrifft.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "ProfessionForm",
        props: {
            name: {
                type: String,
                default: "EntwicklerHeld",
            },
            nextFunction: {}
        },
        data() {
            return {
                selectedProfession: "",
            }
        },
        validations: {
            selectedProfession: {
                required,
            },
            form: ["selectedProfession"],
        },
        methods: {
            validate() {
                this.$v.form.$touch();
                let isValid = !this.$v.form.$invalid;
                this.$emit("on-validate", this.$data, isValid);
                return isValid;
            },
            selectProfession (selectedProfession) {
                this.selectedProfession = selectedProfession;
                setTimeout(this.nextFunction, 500);
            },
        },
    }
</script>

<style scoped>
    h3 {
        color: #4c4c4c;
        line-height: 1.4em;
        margin: 1.5rem 0;
    }

    p {
        margin-top: 1rem;
        font-size: 15px;
        color: #4c4c4c;
    }

    .content{
        width: 100%;
    }

    .input {
        width: 100%;
    }

    .profession-form {
        font-size: 15px;
        display: flex;
        align-items: stretch;
    }

    .profession-form ul{
        list-style: none;
	      margin: 0;
	      padding: 0;
    }

    .profession-form li {
        margin: 0;
        padding: 0;
    }

    .profession-form li label {
        display: block;
        border: 1px solid transparent;
        background-color: #5095881A;
        padding: 1rem 7rem;
        font-size: 15px;
        border-radius: 5px;
        width: 100%;
        cursor: pointer;
    }

    .profession-form label:hover {
        background-color: #50958833;
    }

    .profession-form input:checked ~label,
    .profession-form input:focus ~ label {
        background-color: #08AB9999;
        color: white;
    }

    form input[type="radio"] + label:before {
        content: none;
    }

    .error {
        display: block;
        color: #e74c3c;
        font-size: 12px;
        margin: 1rem 0 0 0;
        text-align: center;
    }

    .profession-icon {
        width: auto;
        display: block;
        margin: 1rem auto;
        filter: grayscale(80%);
        opacity: 0.9;
        max-width: 75px;
    }

    .profession-box {
        flex-direction: row;
        border-radius: 10px;
        background-color: #F2F7FD;
        box-sizing: border-box;
        padding: 3rem 3.5rem 10px;
        border: 2px solid transparent;
        transition: all 0.3s ease-in-out;
        width: 33%;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .profession-box:hover {
        cursor: pointer;
        background-color: #08AB9933;
    }

    .profession-box:focus, .profession-focus {
        border: solid 2px #08AB99;
        background-color: #08AB9933;
    }

    .profession-box:hover img, .profession-focus img {
        filter: grayscale(0%);
    }

    .professionSelectedAnimation {
        animation-name: selectedProfessionGetBig;
        animation-fill-mode: forwards;
        animation-duration: 0.8s;
    }

    .professionOtherAnimation {
        animation-name: selectedProfessionFadeOut;
        animation-duration: 0.5s;
    }

    @keyframes selectedProfessionGetBig {
        to {
            transform: scale(1.1);
        }
    }

    @keyframes selectedProfessionFadeOut {
        to {
            opacity: 0;
        }
    }

    @media (max-width: 767px) {
        .profession-form {
            flex-direction: column;
        }
        .profession-box {
            margin: 0.75rem 0;
            width: 100%;
            padding: 1rem;
        }
        .profession-icon {
            max-width: 50px;
        }
        .profession-label-col {
            margin-top: 1rem;
        }
    }

    @media (min-width: 768px){
        p {
          font-size: 120%;
          text-align: center;
        }
        .profession-form {
          margin-top: 5rem;
        }
    }
</style>