var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row form-row"},[_c('div',{staticClass:"col-xs-12 col-md-offset-2 col-md-8"},[_c('h3',{staticClass:"text-center"},[_vm._v("\n            Was ist dein Heldenstatus?\n        ")]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('form',{staticClass:"profession-form"},[_c('div',{staticClass:"profession-box",class:{'professionSelectedAnimation': _vm.selectedProfession === 'pupil',
                               'professionOtherAnimation': _vm.selectedProfession && _vm.selectedProfession !== 'pupil',
                               'profession-focus': _vm.selectedProfession === 'pupil'},on:{"click":function($event){return _vm.selectProfession('pupil')}}},[_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"profession-box",class:{professionSelectedAnimation: _vm.selectedProfession === 'student/trainee',
                               professionOtherAnimation: _vm.selectedProfession && _vm.selectedProfession !== 'student/trainee',
                               'profession-focus': _vm.selectedProfession === 'student/trainee'},on:{"click":function($event){return _vm.selectProfession('student/trainee')}}},[_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"profession-box",class:{professionSelectedAnimation: _vm.selectedProfession === 'professional/academics',
                               professionOtherAnimation: _vm.selectedProfession && _vm.selectedProfession !== 'professional/academics',
                               'profession-focus': _vm.selectedProfession === 'professional/academics'},on:{"click":function($event){return _vm.selectProfession('professional/academics')}}},[_vm._m(2)])]),_vm._v(" "),(_vm.$v.selectedProfession.$error && !_vm.$v.selectedProfession.required)?_c('p',{staticClass:"error"},[_vm._v("Du musst eins auswählen. Wähle das aus, was am ehesten auf dich zutrifft.")]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row profession-row"},[_c('div',{staticClass:"col-xs-4 col-sm-12"},[_c('img',{staticClass:"profession-icon",attrs:{"src":"https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605664/eh-platform/icons/038_School_-_Retro.svg"}}),_vm._v(" "),_c('p',[_c('input',{staticClass:"profession-input input",attrs:{"type":"radio","id":"profession-choice-1","name":"radiobutton"}})])]),_vm._v(" "),_c('div',{staticClass:"col-xs-8 col-sm-12 profession-label-col"},[_c('p',[_vm._v("Schüler*in")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row profession-row"},[_c('div',{staticClass:"col-xs-4 col-sm-12"},[_c('img',{staticClass:"profession-icon",attrs:{"src":"https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605662/eh-platform/icons/031_University_-_Retro.svg"}}),_vm._v(" "),_c('p',[_c('input',{staticClass:"profession-input input",attrs:{"type":"radio","id":"profession-choice-2","name":"radiobutton"}})])]),_vm._v(" "),_c('div',{staticClass:"col-xs-8 col-sm-12 profession-label-col"},[_c('p',[_vm._v("Student*in / Azubi")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row profession-row"},[_c('div',{staticClass:"col-xs-4 col-sm-12"},[_c('img',{staticClass:"profession-icon",attrs:{"src":"https://res.cloudinary.com/dspi5zyaa/image/upload/v1598606266/eh-platform/icons/Condo_Building_1_-_Retro.svg"}}),_vm._v(" "),_c('p',[_c('input',{staticClass:"profession-input input",attrs:{"type":"radio","id":"profession-choice-3","name":"radiobutton"}})])]),_vm._v(" "),_c('div',{staticClass:"col-xs-8 col-sm-12 profession-label-col"},[_c('p',[_vm._v("Professional")])])])
}]

export { render, staticRenderFns }