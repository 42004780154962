import {responseHandler} from '../Utilities.js';
import {
    NewsListResult,
    PostResult,
    SetAllPostsReadResult,
    UnreadPostsResult,
    VotePostResult,
    VotedPostsResult,
} from "./NewsServiceResponses";

export default class NewsServiceNetwork {

    constructor(auth) {
        this.authService = auth;
    }

    getNewsList() {
        let result = new NewsListResult();
        fetch(window.NEWS_API_URL + "/api/news/",
            {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getPost(postID) {
        let result = new PostResult();
        fetch(window.NEWS_API_URL + "/api/news/" + postID + "/",
            {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getUnreadPosts() {
        let result = new UnreadPostsResult();
        fetch(window.NEWS_API_URL + "/api/news/get_unread_posts/",
            {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }


    setAllPostsRead() {
        let result = new SetAllPostsReadResult();
        fetch(window.NEWS_API_URL + "/api/news/set_all_posts_read/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }


    getVotedPosts() {
        let result = new VotedPostsResult();
        fetch(window.NEWS_API_URL + "/api/news/get_voted_posts/",
            {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }


    votePost(postId) {
        let result = new VotePostResult();
        fetch(window.NEWS_API_URL + "/api/news/" + postId + "/vote/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }
}
