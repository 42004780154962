#<template>
    <div>
       <div class="sidebar__user" v-if="!isAuthenticated" style="min-height: 100px; padding: 30px 1rem; height: 116px;">
            <div style="text-align: center; position: absolute; top: 15px; left: 0; color: white; width: 240px;">
                <p class="text-center">Mach mit und werde ein <br><strong>EntwicklerHeld</strong>!</p>
                <a @click="login()" class="btn btn-lg" style="background: white;"><i
                        class="dev-login">&nbsp;&nbsp;</i>Anmelden
                </a>
            </div>
        </div>
        <div v-if="isAuthenticated && userProfileApiResult.loaded && !userProfileApiResult.error" class="sidebar__user">
            <router-link class="link-to-profile" :to="{name:'dashboard'}">
                <div class="sidebar-user__avatar">
                    <div class="sidebar-user__avatar_image"
                         :style="'background-image: url(\'' + userProfileApiResult.user_profile.profile_picture + '\')'">
                    </div>
                    <LevelComponent class="level-in-profile-image"/>
                </div>
                <a class="sidebar-user__info" id="mini-profile">
                    <h4>{{ userProfileApiResult.user_profile.display_name || "Ein Entwicklerheld" }}</h4>
                </a>
            </router-link>

            <p class="coins">
                <img src="/assets/icons/coin.svg" style="height: 1.0rem"/>
                <UserCoinsComponent :with_icon="false"/>
            </p>
            <ExperienceComponent/>
        </div>
    </div>
</template>

<script>
    import {services} from "../../main.js"
    import {mapState} from 'vuex';
    import UserCoinsComponent from '../rewards/UserCoinsComponent';
    import {eventBus} from "../../main";
    import ExperienceComponent from "../gamification/ExperienceComponent";
    import LevelComponent from "../gamification/LevelComponent";


    export default {
        name: "mini-profile",
        components: {LevelComponent, ExperienceComponent, UserCoinsComponent},
        data() {
            return {
                userProfileApiResult: {loaded: false, error: null},

            }
        },
        computed: mapState([
            'isAuthenticated',
        ]),
        methods: {
            login: function () {
                services.auth.login({fullPath: "/dashboard"})
            },
        },
        watch: {
            isAuthenticated() {
                if(!this.isAuthenticated) {
                    return;
                }
                this.userProfileApiResult = services.userService.getUserProfile()
            },
        },
        mounted() {
            let this_ = this;
            eventBus.$on("user-profile-changed", function () {
                this_.userProfileApiResult = services.userService.getUserProfile();
            });
            if (this.isAuthenticated) {
                this.userProfileApiResult = services.userService.getUserProfile()
            }
        },
    }
</script>

<style scoped>
    .sidebar-user__avatar_image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
    }

    .sidebar-user__avatar {
        border-width: 0;

    }

    .level-in-profile-image {
        position: absolute;
        font-size: 85%;
        top: 65px;
        left: 55px;
    }

    .coins {
        margin: 0;
    }

    .link-to-profile:hover {
        text-decoration: none;
    }
</style>
