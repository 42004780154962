export class SetActiveResult {
    constructor() {
        this.error = null;
        this.loaded = false;
    }
}

export class FilesResult {
    constructor() {
        this.error = null;
        this.loaded = false;
        this.apiData = {};
    }
}

export class FileResult {
    constructor() {
        this.error = null;
        this.loaded = false;
        this.apiData = {};
    }
}

export class ChangedFileResult {
    constructor() {
        this.error = null;
        this.loaded = false;
        this.apiData = {};
    }
}
