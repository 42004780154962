export class CoinsLeaderBoard {
    constructor() {
        this.apiData = {};
        this.loaded = false;
        this.error = null;
        this.pagination = {};
    }
}

export class UserProfilesData {
    constructor() {
        this.userProfilesData = {};
        this.loaded = false;
        this.error = null;
    }
}

export class UserProfileChangeResult {
    constructor() {
        this.error = null;
        this.loaded = false;
    }
}

export class ApplyForBeingACreatorResult {
    constructor() {
        this.error = null;
        this.loaded = false;
    }
}

export class UserProfileResult {
    constructor() {
        this.user_profile = {};
        this.error = null;
        this.loaded = false;
    }
}

export class FinishOnboarding {
    constructor () {
        this.error = null;
        this.loaded = false;
    }
}

export class PublicUserProfileResult {
    constructor () {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}


export class MultiplePublicUserProfileDataResult {
    constructor () {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}

export class UploadCvResult {
    constructor (loaded = false) {
        this.apiData = null;
        this.error = null;
        this.loaded = loaded;
    }
}

export class GetCvResult {
    constructor () {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class SaveJsonResumeResult {
    constructor () {
        this.error = null;
        this.loaded = false;
    }
}

export class SubmitConnectionCode {
    constructor () {
        this.error = null;
        this.loaded = false;
    }

    hasLoaded() {
        return this.loaded && this.error === null;
    }

    hasError() {
        return this.loaded && this.error !== null;
    }
}

export class ListConnections {
    constructor () {
        this.error = null;
        this.loaded = false;
    }

    hasLoaded() {
        return this.loaded && this.error === null;
    }

    hasError() {
        return this.loaded && this.error !== null;
    }
}

export class BusinessNetworkUserInformation {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class XingInformation {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}
export class MyDreamCompanyResult {
    constructor() {
        this.apiData = null;
        this.error = null;
        this.loaded = false;
    }
}
