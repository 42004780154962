import {findGetParameter} from "../Utilities";

export default class LinkedInAnalyticsService {
    constructor() {
        this.userComesFromAdvertising = findGetParameter("utm_campaign") ? true : false;
    }

    sendRegistrationCompletionEvent() {
        if (this.userComesFromAdvertising) {
            var _linkedin_partner_id = "1847153";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);

            (function () {
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript";
                b.async = true;
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);
            })();
        }
    }
}
