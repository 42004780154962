import { render, staticRenderFns } from "./CustomLoginContainer.vue?vue&type=template&id=531bf878&scoped=true"
import script from "./CustomLoginContainer.vue?vue&type=script&lang=js"
export * from "./CustomLoginContainer.vue?vue&type=script&lang=js"
import style0 from "./CustomLoginContainer.vue?vue&type=style&index=0&id=531bf878&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531bf878",
  null
  
)

export default component.exports