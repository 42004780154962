import {responseHandler} from "../Utilities";

import {CoinExchangeRateResult} from "./EntwicklerHerzServiceResponses";

export class EntwicklerHerzServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    getCoinExchangeRate() {
        let result = new CoinExchangeRateResult();
        fetch(window.ENTWICKLERHERZ_API_URL + "/api_noauth/get_coin_exchange_rate/",
            {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiResult = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }
}
