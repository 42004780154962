<template>
    <div class="row form-row">
        <div class="col-xs-12">
            <h3 v-if="!displayName" class="space-bottom text-center">
                Wie sollen wir dich nennen?
            </h3>
            <div v-if="displayName">
              <h3 class="text-center">
                Wir nennen dich weiterhin <strong>{{displayName}}</strong>, oder?
              </h3>
              <p>Sonst trag hier einen neuen Namen ein:</p>
            </div>
            <div class="col-md-offset-3 col-xs-12 col-md-6">
                <div class="content">
                    <input
                            v-model.trim="name"
                            class="nickname-input input"
                            :class="{'has-error': $v.name.$error }"
                            type="text"
                            placeholder="Dein heldenhafter Name"
                            @input="$v.name.$touch()"
                            autofocus
                            @keyup.enter="validate"
                    />
                </div>
                <span v-if="$v.name.$error && !$v.name.required" class="error">Bitte trag deinen Heldennamen ein.</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";

    export default {
        props: {
          displayName: {
                type: String,
                default: "",
            },
        },
        data() {
            return {
                name: this.displayName,
            };
        },
        validations: {
            name: {
                required,
            },
            form: ["name"],
        },
        methods: {
            validate() {
                this.$v.form.$touch();
                let isValid = !this.$v.form.$invalid;
                this.$emit("on-validate", this.$data, isValid);
                return isValid;
            },
        }
    }
</script>

<style scoped>
    ::placeholder {
      color: rgba(76, 76, 76, 0.3);
    }

    .content{
        width: 100%;
    }

    .input {
        width: 100%;
    }

    h3 {
        color: #4c4c4c;
        margin: 5rem 0 0 0;
        line-height: 1.4em;
    }

    p {
        margin-top: 1rem;
        color: #4c4c4c;
        text-align: center;
    }

    .space-bottom {
      margin-bottom: 3rem;
    }

    input {
        border: 1px solid transparent;
        background-color: #F2F7FD;
        padding: 1rem;
        font-size: 15px;
        border-radius: 5px;
    }

    input:focus {
        outline: none;
        border-color: #08AB99;
        transition: all 0.5s ease-in;

    }

    .error {
        display: block;
        color: #e74c3c;
        font-size: 12px;
        margin-top: 0.5rem;
        margin-left: 1rem;
    }

    @media (min-width: 768px) {
      h3 {
        margin: 1.5rem 0;
      }

      p {
        font-size: 120%;
      }
    }
</style>