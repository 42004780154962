import {
    GetTechnologiesResult,
    CreateTechnologyResult, GetUserTechnologiesResult, CreateUserTechnologyResult, RemoveUserTechnologyResult,
    UpdateUserTechnologiesResult,

} from "./TechnologyServiceResponses";


export default class TechnologyServiceStub {

    constructor(auth) {
        this.authService = auth;
    }

    getTechnologies() {
        let result = new GetTechnologiesResult();

        setTimeout(function () {
            result.apiData =[
                {
                    'id': '89b1a831-33fd-4330-a07b-68bb492006ba',
                    'name': 'SpringBoot',
                    'displayName': "Spring Boot",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'springboot.png'
                        },
                    'relatedTechnologies': ["246a76b0-920e-43ad-ad0e-ec0cb73356ea"],
                    "isSupportedByPlatform": false,
                    "isReviewed": true,
                },
                {
                    'id': '6a9f341e-ef1e-4464-a3e8-09c81d81ff01',
                    'name': 'php',
                    'displayName': "PHP",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'php.jpg'
                        },
                    'relatedTechnologies': [],
                    "isSupportedByPlatform": true,
                    "isReviewed": true,

                },
                {
                    'id': '1b7bba4e-f041-4d04-bca2-45f6ba2c56ed',
                    'name': 'python',
                    "displayName": "Python",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'python.jpg'
                        },
                    'relatedTechnologies': [],
                    "isSupportedByPlatform": true,
                    "isReviewed": true,
                },
                {
                    'id': 'ca7bba4e-f041-4d04-bca2-45f6ba2c56dd',
                    'name': 'java',
                    "displayName": "Java",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'java.jpg'
                        },
                    'relatedTechnologies': [],
                    "isSupportedByPlatform": true,
                    "isReviewed": true,
                },
                {
                    'id': 'aq7bba4e-f041-4d04-bca2-45f6ba2c5655',
                    'name': 'cpp',
                    "displayName": "C++",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'cpp.jpg'
                        },
                    'relatedTechnologies': [],
                    "isSupportedByPlatform": true,
                    "isReviewed": true,
                },
                {
                    'id': 'aq7bba4e-f041-4d04-bca2-45f6ba2c5656',
                    'name': 'csharp',
                    "displayName": "C#",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'csharp.jpg'
                        },
                    'relatedTechnologies': [],
                    "isSupportedByPlatform": true,
                    "isReviewed": true,
                },
                {
                    'id': 'a567bba4e-f041-4d04-bca2-45f6ba2c56a3',
                    'name': 'javascript',
                    "displayName": "JavaScript",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'javascript.jpg'
                        },
                    'relatedTechnologies': [],
                    "isSupportedByPlatform": true,
                    "isReviewed": false,
                },
                {
                    'id': 'a7ec2776-713f-4005-9f91-0b4eb488fca1',
                    'name': 'django',
                    "displayName": "Django",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'django.jpg'
                        },
                    'relatedTechnologies': ['452a76b0-920e-43ad-ad0e-ec0cb18532fb'],
                    "isSupportedByPlatform": false,
                    "isReviewed": false,
                },
                {
                    'id': '5b0a3eff-a9f0-4b70-8832-71e5301fa87c',
                    'name': 'kubernetes',
                    "displayName": "Kubernetes",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'kubernetes.jpg'
                        },
                    'relatedTechnologies': ['267a76b0-920e-43ad-ad0e-ec0cb29541aa'],
                    "isSupportedByPlatform": false,
                    "isReviewed": false,
                },
                {
                    'id': '7e529e4d-2f8d-4ee8-a203-e5cd9d2a5f56',
                    'name': 'jira',
                    "displayName": "Jira",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'jira.jpg'
                        },
                    'relatedTechnologies': [],
                    "isSupportedByPlatform": false,
                    "isReviewed": true,
                },
                {
                    'id': 'c4d6db85-daf3-474e-9c02-c848f54e723d',
                    'name': 'net',
                    "displayName": ".NET",
                    'logo':
                        {
                            'baseUrl': 'http://localhost:8000/media/image/upload',
                            'url': 'dotnet.jpg'
                        },
                    'relatedTechnologies': ['145a76b0-920e-43ad-ad0e-ec0cb29573ab', '193b76b0-920e-43ad-ad0e-ec0cb22418af'],
                    "isSupportedByPlatform": false,
                    "isReviewed": true,
                },
            ];
            result.loaded = true;
        }, 200);
        return result;
    }

        getJobWizardTechnologies() {
        let result = new GetTechnologiesResult();

        setTimeout(function () {
            result.apiData =
                [
                    {
                        "id": "575b3a6e-f81b-4c0f-88c1-04fb767a8114",
                        "name": "Blender",
                        "displayName": "Blender",
                        "isSupportedByPlatform": false
                    },
                    {
                        "id": "48f6f493-a97c-47a8-b0e1-3d8a1e8cbf7c",
                        "name": "csharp",
                        "displayName": "C#",
                        "isSupportedByPlatform": true
                    },
                    {
                        "id": "73378133-8ae0-489e-a3cd-f4bc1ec4d1eb",
                        "name": "cpp",
                        "displayName": "C++",
                        "isSupportedByPlatform": true
                    },
                    {
                        "id": "281e2066-a66e-4a7f-ad13-a326b959b526",
                        "name": "django",
                        "displayName": "Django",
                        "isSupportedByPlatform": false
                    },
                    {
                        "id": "e2fd7a20-6845-46b6-a7c2-39f7b3cdbea6",
                        "name": "dockerfile",
                        "displayName": "Docker",
                        "isSupportedByPlatform": true
                    },
                    {
                        "id": "f6054818-7250-4248-a265-0034813172d2",
                        "name": "java",
                        "displayName": "Java",
                        "isSupportedByPlatform": true
                    },
                    {
                        "id": "64df6829-4881-48ba-97c4-f23c68d17426",
                        "name": "javascript",
                        "displayName": "JavaScript",
                        "isSupportedByPlatform": true
                    },
                    {
                        "id": "24f4b501-f7cd-4ef8-ad41-b7f675db4a3d",
                        "name": "php",
                        "displayName": "PHP",
                        "isSupportedByPlatform": true
                    },
                    {
                        "id": "440eaa9a-a217-46b1-b60b-f5496f554331",
                        "name": "python",
                        "displayName": "Python",
                        "isSupportedByPlatform": true
                    }

                ];
            result.loaded = true;
        }, 200);
        return result;
    }

    updateUserTechnologies(technologiesData) {
        let result = new UpdateUserTechnologiesResult();

        console.log("Stub Request to update user technologies");

        setTimeout(function() {
          result.apiData = {
              "technologies": technologiesData,
          };
          result.loaded = true;
        }, 1000 + Math.random() * 1000);
        return result;
    }

    createTechnology(technologyData) {
        let result = new CreateTechnologyResult();

        console.log("Stub Request to suggest technology with data:", technologyData);

        setTimeout(function () {
            result.apiData = {id: "347a3eff-a9f0-4b70-8832-71e5301faaft", name: technologyData.name};
            result.loaded = true;
        }, 200);
        return result;
    }

    getUserTechnologies(publicUserId) {
        let result = new GetUserTechnologiesResult();

        setTimeout(function () {
            result.apiData = {
                technologies: [
                    {
                        'id': '246a76b0-920e-43ad-ad0e-ec0cb73356ea',
                        'name': 'java',
                        "label": "Java",
                        "isSupportedByPlatform": true
                    },
                    {
                        'id': '1b7bba4e-f041-4d04-bca2-45f6ba2c56ed',
                        'name': 'python',
                        "label": "Python",
                        "isSupportedByPlatform": true
                    },
                    {
                        'id': '677bba4e-f041-4d04-bca2-45f6ba2c56ab',
                        'name': 'javascript',
                        "label": "JavaScript",
                        "isSupportedByPlatform": true
                    },
                    {
                        'id': '145a76b0-920e-43ad-ad0e-ec0cb29573ab',
                        'name': 'csharp',
                        "label": "C#",
                        "isSupportedByPlatform": true
                    },

                    {
                        'id': '193b76b0-920e-43ad-ad0e-ec0cb22418af',
                        'name': 'cpp',
                        "label": "C++",
                        "isSupportedByPlatform": true
                    },
                    {
                        'id': '6a9f341e-ef1e-4464-a3e8-09c81d81ff01',
                        'name': 'php',
                        "label": "PHP",
                        "isSupportedByPlatform": true
                    },
                    {
                        'id': 'a7ec2776-713f-4005-9f91-0b4eb488fca1',
                        'name': 'django',
                        "label": "Django",
                        "isSupportedByPlatform": false
                    },
                    {
                        'id': '5b0a3eff-a9f0-4b70-8832-71e5301fa87c',
                        'name': 'kubernetes',
                        "label": "Kubernetes",
                        "isSupportedByPlatform": false
                    },
                ]
            };
            result.loaded = true;
        }, 200);
        return result;
    }

    createUserTechnology(technologyId) {
        let result = new CreateUserTechnologyResult();

        console.log("Stub Request to create user technology with id:", technologyId);

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 200);
        return result;
    }

    removeUserTechnology(technologyId) {
        let result = new RemoveUserTechnologyResult();

        console.log("Stub Request to remove user technology with id:", technologyId);

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 200);
        return result;
    }
}
