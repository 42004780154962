<template>
    <div class="row form-row">
        <div class="col-xs-12">
            <h3 class="text-center">
                Deine Heldenskills
            </h3>
            <p class="subtitle">
              Wähle Technologien aus, die du schon kannst oder lernen willst. So kannst du deinen Fortschritt verfolgen.
            </p>
            <div class="col-md-offset-2 col-xs-12 col-md-8">
              <Transition name="fade">
                <LoadingIndicatorComponent v-if="!contentReady"/>
              </Transition>
              <Transition name="fade">
                <div v-if="contentReady">
                  <TransitionGroup name="chip" tag="ul" class="list-unstyled technologies"
                                   :duration="{ enter: technologyEnterTime, leave: technologyLeaveTime }">
                      <li v-for="(value) in selectableTechnologies" :key="value.id" class="technology">
                          <input class="technology__checkbox"
                                 type="checkbox"
                                 :value="value.id"
                                 :id="value.id"
                                 :name="value.id"
                                 :ref="value.id"
                                 @change="onTechnologyChange($event, value.id)"
                          />
                          <label class="technology__label" :for="value.id"
                                 :class="{
                                      'bg-tec-python': value.name === 'python',
                                      'bg-tec-dockerfile': value.name === 'dockerfile',
                                      'bg-tec-java': value.name === 'java',
                                      'bg-tec-javascript': value.name === 'javascript',
                                      'bg-tec-csharp': value.name === 'csharp',
                                      'bg-tec-cpp': value.name === 'cpp',
                                      'bg-tec-php': value.name === 'php',
                                  }">
                              <i class="fa fa-code"></i>
                              <span class="technology__name">{{ value.displayName }}</span>
                          </label>
                      </li>
                  </TransitionGroup>

                  <div class="selected-technologies" v-show="selectedTechnologies.length > 0">
                      <p>Ausgewählt</p>
                      <TransitionGroup name="chip" tag="ul" class="list-unstyled technologies"
                                       :duration="{ enter: technologyEnterTime, leave: technologyLeaveTime }">
                          <li v-for="(value) in selectedTechnologies" :key="value.id"
                              class="technology">
                              <input class="technology__checkbox"
                                     type="checkbox"
                                     :value="value.id"
                                     :id="value.id"
                                     :name="value.id"
                                     :ref="value.id"
                                     @change="onTechnologyChange($event, value.id)"
                                     checked
                              />
                              <label class="technology__label" :for="value.id"
                                     :class="{
                                      'bg-tec-python': value.name === 'python',
                                      'bg-tec-dockerfile': value.name === 'dockerfile',
                                      'bg-tec-java': value.name === 'java',
                                      'bg-tec-javascript': value.name === 'javascript',
                                      'bg-tec-csharp': value.name === 'csharp',
                                      'bg-tec-cpp': value.name === 'cpp',
                                      'bg-tec-php': value.name === 'php',
                                  }">
                                  <i class="fa fa-code"></i>
                                  <span class="technology__name">{{ value.displayName }}</span>
                              </label>
                          </li>
                      </TransitionGroup>
                  </div>

                  <TechnologyTagInputComponent
                      :available-tec="typeaheadTechnologies"
                      :existing-technologies="typeaheadTechnologies"
                      @tag-selected="onTagSelected"/>
                </div>
              </Transition>
            </div>
        </div>
    </div>
</template>

<script>
    import 'vue-slider-component/dist-css/vue-slider-component.css'
    import 'vue-slider-component/theme/default.css'
    import {services} from "../../main";
    import TechnologyTagInputComponent from "../user/jobwizard/TechnologyTagInputComponent";
    import LoadingIndicatorComponent from "../user/jobwizard/LoadingIndicatorComponent";
    import {required} from "vuelidate/lib/validators";
    import Vue from "vue";

    export default {
        name: "SkillsForm",
        components: {
            TechnologyTagInputComponent,
            LoadingIndicatorComponent
        },
        data() {
            return {
              supportedTechnologiesRequest: {loaded: false, error: null},
              allTechnologiesRequest: {loaded: false, error: null},
              suggestTechnologyRequest: {loaded: false, error: null},
              selectedTechnologies: [],
              selectableTechnologies: [],
              technologyEnterTime: 500,
              technologyLeaveTime: 300,
            };
        },
        validations: {
            form: {
              technologies: {
                required
              },
            },
        },
        computed: {
          contentReady: function () {
            return this.supportedTechnologiesRequest.loaded === true;
          },
          typeaheadTechnologies: function () {
            if (!this.allTechnologiesRequest.loaded || this.allTechnologiesRequest.error) {
              return [];
            }
            let out = this.filterDuplicatedTechnologies(this.allTechnologiesRequest.apiData);
            const outIds = out.map(it => it.displayName.toLowerCase())

            for (const item of this.selectableTechnologies) {
                if (!outIds.includes(item.displayName.toLowerCase())) {
                    out.push(item)
                }
            }

            const selectedTechnologyIdList = this.selectedTechnologies.map(technology => technology.displayName.toLowerCase())
            out = out.filter(technology => !selectedTechnologyIdList.includes(technology.displayName.toLowerCase()))
            out.sort(this.sortDisplayNameAsc);
            return out
          },
        },
        created() {
          this.supportedTechnologiesRequest = services.technologyService.getJobWizardTechnologies();
          this.allTechnologiesRequest = services.technologyService.getTechnologies();
        },
        methods: {
          validate() {
              let isValid = true;
              this.$emit("on-validate", {technologies: this.selectedTechnologies}, isValid);
              return isValid;
          },
          onTagSelected(selectedTechnology) {
            // check if technology has to be created
            if (!selectedTechnology.id) {
                this.suggestTechnologyRequest = services.technologyService.createTechnology({name: selectedTechnology.displayName.trim()});
            } else {
                this.selectedTechnologies.push(selectedTechnology)
                if (this.selectableTechnologies.map(it => it.id).includes(selectedTechnology.id)) {
                    this.removeFromAvailable(selectedTechnology.id);
                }
            }
          },
          onTechnologyChange(event, id) {
            if (event.target.checked) {
                this.moveFromAvailableToSelected(id);
            } else {
                this.moveFromSelectedToAvailable(id);
            }
            this.selectedTechnologies.sort(this.sortDisplayNameAsc);
            this.selectableTechnologies.sort(this.sortDisplayNameAsc);
          },
          addToAvailable(technologyId) {
              const item = this.selectedTechnologies.filter(value => {
                  return value.id === technologyId;
              })[0]
              this.selectableTechnologies.push(item);
          },
          removeFromAvailable(technologyId) {
              this.selectableTechnologies = this.selectableTechnologies.filter(value => {
                  return value.id !== technologyId
              })
          },
          addToSelected(technologyId) {
              const item = this.selectableTechnologies.filter(value => {
                  return value.id === technologyId;
              })[0]
              this.selectedTechnologies.push(item);
          },
          removeFromSelected(technologyId) {
              this.selectedTechnologies = this.selectedTechnologies.filter(value => {
                  return value.id !== technologyId
              })
          },
          moveFromAvailableToSelected(technologyId) {
              this.addToSelected(technologyId);
              this.removeFromAvailable(technologyId);
          },
          moveFromSelectedToAvailable(technologyId) {
              this.addToAvailable(technologyId)
              this.removeFromSelected(technologyId)
          },
          sortDisplayNameAsc(technologyA, technologyB) {
              if (technologyA.displayName.toLowerCase() < technologyB.displayName.toLowerCase()) {
                  return -1;
              }
              if (technologyA.displayName.toLowerCase() > technologyB.displayName.toLowerCase()) {
                  return 1;
              }
              return 0;
          },
          filterDuplicatedTechnologies(technologies) {
            let filteredTechnologies = [];
              let technologyNamesSet = new Set();
              technologies.map(technology => {
                if (!technologyNamesSet.has(technology.displayName)) {
                  filteredTechnologies.push(technology);
                  technologyNamesSet.add(technology.displayName);
                }
              });
              return filteredTechnologies;
          },
        },
        watch: {
          'supportedTechnologiesRequest.loaded': function () {
            if (this.supportedTechnologiesRequest.loaded && !this.supportedTechnologiesRequest.error) {
              let selectableTechnologies = this.filterDuplicatedTechnologies(this.supportedTechnologiesRequest.apiData);
              selectableTechnologies.sort(this.sortDisplayNameAsc);
              this.selectableTechnologies = selectableTechnologies;
            }
            if (this.supportedTechnologiesRequest.loaded && this.supportedTechnologiesRequest.error) {
              Vue.$toast.open({
                  message: 'Beim Laden ist was schief gelaufen. Probiere es nochmal oder schreibe uns über den Feedback-Button.',
                  type: 'error',
                  position: 'bottom',
              });
            }
          },
          'suggestTechnologyRequest.loaded': function () {
            if (this.suggestTechnologyRequest.loaded && !this.suggestTechnologyRequest.error) {
                const technology = this.suggestTechnologyRequest.apiData;
                technology.displayName = technology.name;
                if (!this.selectedTechnologies.map(it => it.id).includes(technology.id)) {
                    this.selectedTechnologies.push(technology);
                }
                this.selectableTechnologies.sort(this.sortDisplayNameAsc);
            }
          },
        },
    }
</script>

<style lang="scss" scoped>
$color-brand-primary: #08AB99;
$dotSizeSmall: 20px;
$dotSizeBig: 32px;

h3 {
  color: #4c4c4c;
  line-height: 1.4em;
  margin: 1.5rem 0;
}

p {
  font-size: 120%;
  margin-top: 1rem;
  color: #999999;
  text-align: center;
}

.subtitle {
  margin-bottom: 2rem;
  text-align: center;
}

.technology {
    display: inline-block;

    &__checkbox {
        display: none;

        & + .technology__label {
            cursor: pointer;
            font-family: "Source Code Pro", sans-serif;
            color: $color-brand-primary;
            overflow: hidden;
            padding: 6px 24px;
            margin-bottom: 8px;
            border-radius: 100px;
            background-color: white;
            margin-right: 1rem;
            line-height: 1.6;
            box-shadow: 0 4px 15px rgba(13, 51, 85, 0.2);
            display: inline-block;

            &::before {
                content: none;
            }
        }

        &:checked,
        &:hover {
            & + .technology__label {
                color: white;
                background-color: $color-brand-primary;
            }

            & + .bg-tec-python {
                background-color: #3c6382 !important;
                color: white !important;
            }

            & + .bg-tec-dockerfile,
            & + .bg-tec-docker {
                background-color: #2C90DE !important;
                color: white !important;
            }

            & + .bg-tec-java {
                background-color: #fa983a !important;
                color: white !important;
            }

            & + .bg-tec-javascript {
                background-color: #f6b93b !important;
                color: white !important;
            }

            & + .bg-tec-csharp {
                background-color: #803788 !important;
                color: white !important;
            }

            & + .bg-tec-cpp {
                background-color: #e55039 !important;
                color: white !important;
            }

            & + .bg-tec-php {
                background-color: #6a89cc !important;
                color: white !important;
            }
        }
    }

    &__label {
        position: relative;

        & > .fa {
            position: absolute;
            bottom: -20%;
            left: -8%;
            font-size: 28px;
            color: rgba(255, 255, 255, 0.15);
        }
    }

    &__name {
        font-size: 14px;
        text-transform: uppercase;
    }
}

.chip-leave-active, .chip-enter-active {
    transition: opacity .3s linear,
    transform .45s ease-in-out;
}

.chip-enter, .chip-leave-to {
    opacity: 0;
}

.chip-enter {
    transform: translateX(-20px);
}

.chip-enter-to {
    transform: translateX(0);
}

.chip-leave-to {
    transform: translateX(20px);
}

.technologies {
    margin: 1.6rem 0 0 0;
}
</style>
