<template>
    <div class="level-bar-container">
        <h5 class="progress-title level-bar-title" style=""><strong>Level {{this.experienceInformationResult.apiResult.level}}</strong> -
            {{kFormatter(this.experienceInformationResult.apiResult.currentExperience)}} / {{kFormatter(this.experienceInformationResult.apiResult.neededNextLevel)}} EXP
            ({{calculateProgressPercent()}}%)</h5>
        <div class="progress level-bar">
            <div class="progress-bar level-bar-progress" :class="{'levelup-bar-animation': isLevelUp}"
                 role="progressbar" aria-valuenow="60" aria-valuemin="0"
                 aria-valuemax="100" :style="{width: calculateProgressPercent()+'%'}">
            </div>
        </div>
        <div class="add-exp" :class="{'add-exp-animation' : showAnimation}" >+{{xpAdded}} XP</div>
    </div>
</template>

<script>
    import {services} from "../../main.js"
    import {mapState} from 'vuex';
    import {eventBus} from "../../main";
    import io from 'socket.io-client';

    export default {
        name: "experience",
        data() {
            return {
                experienceInformationResult: services.gamificationService.getExperienceInformation(),
                socket: null,
                showAnimation: false,
                xpAdded: 0,
                levelBarGlow: false,
                isLevelUp: false,
            }
        },
        computed: mapState([
            'isAuthenticated',
        ]),
        watch: {
            isAuthenticated() {
                if (!this.isAuthenticated) {
                    return;
                }
                this.apiResult = services.gamificationService.getExperienceInformation();
                this.createNotificationConnection();
            },
        },
        mounted() {
            if (this.isAuthenticated) {
                this.createNotificationConnection();
            }
        },
        methods: {
            kFormatter: (num) => {
                if ( num>999999 ) {
                    return Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'M'
                }
                if ( num>9999 ) {
                    return Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k'
                }
                return Math.sign(num)*Math.abs(num);
            },
            calculateProgressPercent: function () {
                return Math.round(this.experienceInformationResult.apiResult.currentExperience / this.experienceInformationResult.apiResult.neededNextLevel * 100);
            },
            createNotificationConnection: function () {
                const userID = services.auth.getUserID();
                this.socket = services.notificationsService.getExperienceUpdateNotificationsServiceSocket();
                this.socket.on(`notifications_gamification_${userID}_xp-update`, (data) => {
                    let json = JSON.parse(data);
                    const currentExperience = json.xpAfterUpdate;
                    const neededExperience = json.xpNeededRelative;

                    setTimeout(() => {
                        this.isLevelUp = this.experienceInformationResult.apiResult.neededNextLevel !== json.xpNeededRelative;
                        if (this.isLevelUp) {
                            setTimeout(() => {
                                // Reset Level up state for EXP bar animation
                                this.isLevelUp = false;
                            }, 3000);
                        }
                        // UGLY: This timeout is needed to make the animations of progress bar and xp added in sync.
                        setTimeout(() => this.experienceInformationResult.apiResult.currentExperience = currentExperience, 1000);
                        setTimeout(() => this.experienceInformationResult.apiResult.neededNextLevel = neededExperience, 1000);
                    }, 1000);

                    this.xpAdded = json.xpAdded;

                    this.showAnimation = true;
                    setTimeout(() => this.showAnimation = false, 6500);

                    this.levelBarGlow = true;
                    setTimeout(() => this.levelBarGlow = false, 3000);
                });
                this.socket.on(`notifications_gamification_${userID}_level-up`, (data) => {
                    let json = JSON.parse(data);
                    const levelAfterUpdate = json.levelAfterUpdate;

                    // UGLY: This timeout is needed to make the animations of progress bar and xp added in sync.
                    setTimeout(() => this.experienceInformationResult.apiResult.level = levelAfterUpdate, 1000);
                });
            }
        }
    }
</script>

<style scoped>
.level-bar {
    border-radius: 99px;
    height: 12px;
}

.level-bar-progress {
    animation: xp-bar-animation2;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transition: all 0.5s ease-in-out;
    #transition-delay: 1s;
    background: rgb(37,129,196); /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgb(44, 153, 232) 0%, rgb(61, 190, 189) 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgb(44, 153, 232) 0%, rgb(61, 190, 189) 99%);
    background: linear-gradient(135deg,  rgb(44, 153, 232) 0%, rgb(61, 190, 189) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2581c4', endColorstr='#319998',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    background: linear-gradient(18deg, #3990cf, #7eb3d2, #5ccea2);
    background-size: 600% 600%;
    -webkit-animation: xp-bar-animation2 31s ease infinite;
    -moz-animation: xp-bar-animation2 31s ease infinite;
    animation: xp-bar-animation2 31s ease infinite;
}

.levelup-bar-animation {
    animation: levelup-bar-animation linear 3s;
}

@-webkit-keyframes xp-bar-animation2 {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes xp-bar-animation2 {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes xp-bar-animation2 {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.level-bar-container:hover .progress-bar {
    filter: brightness(1.2) hue-rotate(-45deg);
}

.level-bar-container:hover .level-bar-title {
    opacity: 1;
    margin-top: 11px;
    margin-bottom: 6px;
    font-size: 10px;
    line-height: 11px;
}

.level-bar-title {
    opacity: 0;
    transition: all 0.2s ease-out;
    transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -ms-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;

    margin-top: 17px;
    margin-bottom: 0;
    text-align: center;
    font-size: 7px;
    line-height: 11px;
}

.add-exp-bar {
    animation: levelup-glow-animation 1.5s linear 1;
    animation-delay: 0.75s;
}

.add-exp-animation {
    background: linear-gradient(90deg, #30847c, #fff, rgba(255, 255, 255, 0.9), #fff, #30847c);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: shine-animation 6s linear 1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.add-exp {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: whitesmoke;
    top: 125px;
    left: 0;
    width: 240px;
    position: absolute;
    opacity: 0;
}

@keyframes shine-animation {
    0% {
        background-position: -500%;
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    25% {
        background-position: -50%;
    }
    70% {
        background-position: 90%;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        background-position: 500%;
    }
}

@keyframes level-up-glow-animation {
    0% {
        filter: inherit;
    }

    25% {
        filter: brightness(1.33) saturate(130%);
    }

    100% {
        filter: inherit;

    }
}

    @keyframes levelup-bar-animation {
        0% {
            opacity: 1;
        }

        30% {
            width: 100%;
            opacity: 1;
            filter: brightness(150%);
        }

        58% {
            width: 100%;
            opacity: 1;
        }

        60% {
            width: 100%;
            opacity: 0;
        }

        70% {
            width: 0 ;
            opacity: 0;
        }

        75% {
            width: 0;
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }



</style>
