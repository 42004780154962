<template>
    <div>
        <input ref="input"
               spellcheck="false" @blur="unselectHandler"
               :class="{'error': showSelectionError}"
               v-on:keydown.down.prevent="selectNextUniversity"
               v-on:keydown.up.prevent="selectPreviousUniversity"
               v-on:keydown.enter.prevent="unselectHandler"
               @click="isUniversityChoiceOpen=true"
               @input="onChangeSearchInput"
               @focus="isUniversityChoiceOpen=true"
               type="text" class="form-control" id="university"
               v-model="university"
               placeholder="Tippe den Namen deiner Hochschule hier ein...">
        <ul ref="selectionList" class="search-results" v-show="isUniversityChoiceOpen">
            <li class="search-result"
                :class="{'selected': universityCode===universityEntry.code}"
                v-for="(universityEntry, i) in searchResults" :key="i"
                @mousedown.prevent="setResult(universityEntry)">
                {{ universityEntry.university }}
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "UniversitySelectionField",
        props: {
            universities: {type: Array, required: true},
            initialUniversityCode: {type: String, default: undefined}
        },
        mounted() {
            this.isMounted = true;
            this.searchResults = this.universities;
             if (typeof this.initialUniversityCode !== 'undefined') {
                let university = this.universities.find(university => university.code === this.initialUniversityCode);
                if (university) {
                    this.university = university.university;
                    this.universityCode = this.initialUniversityCode;
                }
            }
        },
        data() {
            return {
                isUniversityChoiceOpen: false,
                searchResults: [],
                university: "",
                universityCode: "",
                isMounted: false,
            }
        },
        computed: {
            showSelectionError: function () {
                if (!this.isMounted) {
                    return;
                }
                return this.universityCode === ''
                    && this.university.length >= 0
                    && this.isUniversityChoiceOpen === false
                    && this.$refs.input.value !== "";
            }
        },
        methods: {
            scrollToSelection: function () {
                const selectedLi = this.$el.querySelector("li.selected");
                if (selectedLi) {
                    this.$refs.selectionList.scrollTo(selectedLi.offsetTop, selectedLi.offsetTop - selectedLi.clientHeight * 2 - 10);
                }
            },
            selectNextUniversity: function () {
                let indexOfSelectedElement = this.searchResults.findIndex(university => university.code === this.universityCode);
                indexOfSelectedElement += 1;
                if (typeof this.searchResults[indexOfSelectedElement] === "undefined") {
                    return;
                }
                this.universityCode = this.searchResults[indexOfSelectedElement].code;
                this.university = this.searchResults[indexOfSelectedElement].university;
                this.scrollToSelection()
            },
            selectPreviousUniversity: function () {
                let indexOfSelectedElement = this.searchResults.findIndex(university => university.code === this.universityCode);
                indexOfSelectedElement -= 1;
                if (indexOfSelectedElement < 0) {
                    return;
                }
                this.universityCode = this.searchResults[indexOfSelectedElement].code;
                this.university = this.searchResults[indexOfSelectedElement].university;
                this.scrollToSelection()
            },
            checkIfIsValidSelection(university) {
                return this.universities.find(universityEntry => universityEntry.code === university.code);
            },
            onChangeSearchInput() {
                this.isUniversityChoiceOpen = true;
                this.filterUniversities();
                this.universityCode = "";
            },
            filterUniversities() {
                this.searchResults = this.universities.filter(item => {
                    return item.university.toLowerCase().indexOf(this.university.toLowerCase()) > -1
                })
            },
            setResult(university) {
                if (this.checkIfIsValidSelection(university)) {
                    this.university = university.university;
                    this.universityCode = university.code;
                    this.$refs.input.focus();
                    return;
                }
                this.university = "";
                this.universityCode = "";
            },
            unselectHandler() {
                this.isUniversityChoiceOpen = false;
            }
        }
    }
</script>

<style scoped>
    .search-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        height: 120px;
        overflow: auto;
    }

    .search-result {
        list-style: none;
        text-align: left;
        padding: 4px 12px;
        cursor: pointer;
    }

    .error {
        border: 2px solid #c0392b;
    }

    .selected {
        background-color: #08AB99;
        color: whitesmoke;
    }
</style>
