<template>

</template>

<script>
    import {eventBus, services} from "../../main";
    import {mapState} from 'vuex';

    export default {
        name: "RedeemCouponThroughLink",
        data() {
            return {
                redeemApiResult: null
            }
        },
        created() {
            this.redeemCoupon();
        },
        methods: {
            redeemCoupon() {
                let couponCode = this.$route.query.couponCode;
                if (!couponCode) {
                    return;
                }
                this.redeemApiResult = services.rewardService.redeemCouponCode(couponCode);
            }
        },
        computed: mapState(['isAuthenticated']),
        watch: {
            "isAuthenticated": function() {
                this.redeemCoupon();
            },
            "$route.query.couponCode": function () {
                this.redeemCoupon();
            },
            'redeemApiResult.apiResult': function () {
                if (!this.redeemApiResult.loaded) {
                    return;
                }

                if (this.redeemApiResult.error) {
                    return;
                }
                eventBus.$emit("user-coins-changed");
            }
        }
    }
</script>

<style scoped>

</style>
