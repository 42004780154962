import {TaskManagementServiceStub} from "./TaskManagementStubService";
import {TaskManagementServiceNetwork} from "./TaskManagementNetworkService";

export default class TaskManagementService {

    constructor(auth) {
        this.authService = auth;
    }

    accceptChallenge(challengeId, rewardOption, userUuid) {
        if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
            console.info("window.TASK_API_URL is not set! Using Stub data!");
            return new TaskManagementServiceStub(this.authService).accceptChallenge(challengeId, rewardOption, userUuid);
        }
        return new TaskManagementServiceNetwork(this.authService).accceptChallenge(challengeId, rewardOption, userUuid);
    }

    submitChallenge(challengeId) {
        if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
            console.info("window.TASK_API_URL is not set! Using Stub data!");
            return new TaskManagementServiceStub(this.authService).submitChallenge(challengeId);
        }
        return new TaskManagementServiceNetwork(this.authService).submitChallenge(challengeId);
    }

    getChallengeDetails(challengeIdOrSlug, region) {
        if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
            console.info("window.TASK_API_URL is not set! Using Stub data!");
            return new TaskManagementServiceStub(this.authService).getChallengeDetails(challengeIdOrSlug, region);
        }
        return new TaskManagementServiceNetwork(this.authService).getChallengeDetails(challengeIdOrSlug, region);
    }

    submitTask(taskId, commitHash) {
        if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
            console.info("window.TASK_API_URL is not set! Using Stub data!");
            return new TaskManagementServiceStub(this.authService).submitTask(taskId, commitHash);
        }
        return new TaskManagementServiceNetwork(this.authService).submitTask(taskId, commitHash);
    }

    getDataOfMultipleChallenges(challengeIds) {
        if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
            console.info("window.TASK_API_URL is not set! Using Stub data!");
            return new TaskManagementServiceStub(this.authService).getDataOfMultipleChallenges(challengeIds);
        }
        return new TaskManagementServiceNetwork(this.authService).getDataOfMultipleChallenges(challengeIds);
    }

    getSolvedChallenge(userId) {
        if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
            console.info("window.TASK_API_URL is not set! Using Stub data!");
            return new TaskManagementServiceStub(this.authService).getSolvedChallenge(userId);
        }
        return new TaskManagementServiceNetwork(this.authService).getSolvedChallenge(userId);
    }

    getDataOfMultipleChallengeTasks(taskIds) {
        if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
            console.info("window.TASK_API_URL is not set! Using Stub data!");
            return new TaskManagementServiceStub(this.authService).getDataOfMultipleChallengeTasks(taskIds);
        }
        return new TaskManagementServiceNetwork(this.authService).getDataOfMultipleChallengeTasks(taskIds);
    }

    runnTests(taskId, code) {
        if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
            console.info("window.TASK_API_URL is not set! Using Stub data!");
            return new TaskManagementServiceStub(this.authService).runnTests(taskId, code);
        }
        return new TaskManagementServiceNetwork(this.authService).runnTests(taskId, code);
    }

    getChallengeGroupDetails(groupIdOrSlug) {
        if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
            console.info("window.TASK_API_URL is not set! Using Stub data!");
            return new TaskManagementServiceStub(this.authService).getChallengeGroupDetails(groupIdOrSlug);
        }
        return new TaskManagementServiceNetwork(this.authService).getChallengeGroupDetails(groupIdOrSlug);
    }

    getChallengeGroups(accepted) {
        if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
            console.info("window.TASK_API_URL is not set! Using Stub data!");
            return new TaskManagementServiceStub(this.authService).getChallengeGroups(accepted);
        }
        return new TaskManagementServiceNetwork(this.authService).getChallengeGroups(accepted);
    }
}
