import Vue from 'vue'
import Vuex from 'vuex'
import {services} from "../main"

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        currentUnit: null,
        commitHashForCurrentTask: null,
        fileList: null,
        isAuthenticated: false,
    },
    actions: {
        setCommitHash(commit, {taskId, commitHash}) {
            this.commit("setCommitHashForCurrentTask", commitHash);
        },
        updateFileList(commit, fileList) {
            this.commit("setFileList", fileList);
        },
        updateFileContent(commit, {filePath, content}) {
            if (this.state.fileList !== null) {
                this.commit("setContentOfFile", {filePath: filePath, content: content});
            }
        }
    },
    mutations: {
        setCommitHashForCurrentTask(state, commitHash) {
            if (commitHash == null) {
                return;
            }
            state.commitHashForCurrentTask = commitHash;
        },
        setFileList(state, fileList) {
            state.fileList = fileList;
        },
        setContentOfFile(state, {filePath, content}) {
            state.fileList.forEach(function (file) {
                if (file.filePath === filePath) {
                    file.content = content;
                }
            })
        },
        setAuthentication(state, isAuthenticated){
          state.isAuthenticated = isAuthenticated;
        },
    },
    getters: {
        getLastCommitForCurrentTask: (state) => () => {
            return state.commitHashForCurrentTask;
        },
    },
    modules: {}
});

export default store
