import {GamificationServiceStub} from "./GamificationServiceStub";
import {GamificationServiceNetwork} from "./GamificationServiceNetwork";

export default class GamificationService {
    constructor(auth) {
        this.authService = auth;
    }

    getActivity(userId) {
        if (typeof window.GAMIFICATION_API_URL === "undefined" || window.GAMIFICATION_API_URL === "") {
            console.info("window.GAMIFICATION_API_URL is not set! Using Stub data!");
            return new GamificationServiceStub(this.authService).getActivity(userId);
        }
        return new GamificationServiceNetwork(this.authService).getActivity(userId);
    }

    getExperienceInformation() {
        if (typeof window.GAMIFICATION_API_URL === "undefined" || window.GAMIFICATION_API_URL === "") {
            console.info("window.GAMIFICATION_API_URL is not set! Using Stub data!");
            return new GamificationServiceStub(this.authService).getExperienceInformation();
        }
        return new GamificationServiceNetwork(this.authService).getExperienceInformation();
    }

    getExperienceInformationForUserId(publicUserId) {
        if (typeof window.GAMIFICATION_API_URL === "undefined" || window.GAMIFICATION_API_URL === "") {
            console.info("window.GAMIFICATION_API_URL is not set! Using Stub data!");
            return new GamificationServiceStub(this.authService).getExperienceInformationForUserId(publicUserId);
        }
        return new GamificationServiceNetwork(this.authService).getExperienceInformationForUserId(publicUserId);
    }

    getLanguageProgress(userId) {
        if (typeof window.GAMIFICATION_API_URL === "undefined" || window.GAMIFICATION_API_URL === "") {
            console.info("window.GAMIFICATION_API_URL is not set! Using Stub data!");
            return new GamificationServiceStub(this.authService).getLanguageProgress(userId);
        }
        return new GamificationServiceNetwork(this.authService).getLanguageProgress(userId);
    }

    getLevelLeaderBoard(pageId) {
        if (typeof window.GAMIFICATION_API_URL === "undefined" || window.GAMIFICATION_API_URL === "") {
            console.info("window.GAMIFICATION_API_URL is not set! Using Stub data!");
            return new GamificationServiceStub(this.authService).getLevelLeaderBoard(pageId);
        }
        return new GamificationServiceNetwork(this.authService).getLevelLeaderBoard(pageId);
    }

    getExperienceHistory(pageID = 1) {
        if (typeof window.GAMIFICATION_API_URL === "undefined" || window.GAMIFICATION_API_URL === "") {
            console.info("window.GAMIFICATION_API_URL is not set! Using Stub data!");
            return new GamificationServiceStub(this.authService).getExperienceHistory(pageID);
        }
        return new GamificationServiceNetwork(this.authService).getExperienceHistory(pageID);
    }

    getAchievements(userId) {
        if (typeof window.GAMIFICATION_API_URL === "undefined" || window.GAMIFICATION_API_URL === "") {
            console.info("window.GAMIFICATION_API_URL is not set! Using Stub data!");
            return new GamificationServiceStub(this.authService).getAchievements(userId);
        }
        return new GamificationServiceNetwork(this.authService).getAchievements(userId);
    }

    getAchievementLeaderBoard(pageId){
        if (typeof window.GAMIFICATION_API_URL === "undefined" || window.GAMIFICATION_API_URL === "") {
            console.info("window.GAMIFICATION_API_URL is not set! Using Stub data!");
            return new GamificationServiceStub(this.authService).getAchievementLeaderBoard(pageId);
        }
        return new GamificationServiceNetwork(this.authService).getAchievementLeaderBoard(pageId);
    }

    getSkillScoreForUser(publicUserId){
        if (typeof window.GAMIFICATION_API_URL === "undefined" || window.GAMIFICATION_API_URL === "") {
            console.info("window.GAMIFICATION_API_URL is not set! Using Stub data!");
            return new GamificationServiceStub(this.authService).getSkillScoreForUser(publicUserId);
        }
        return new GamificationServiceNetwork(this.authService).getSkillScoreForUser(publicUserId);
    }

    triggerEasterChallenge2021Finished() {
        if (typeof window.GAMIFICATION_API_URL === "undefined" || window.GAMIFICATION_API_URL === "") {
            console.info("window.GAMIFICATION_API_URL is not set! Using Stub data!");
            return new GamificationServiceStub(this.authService).triggerEasterChallenge2021Finished();
        }
        return new GamificationServiceNetwork(this.authService).triggerEasterChallenge2021Finished();
    }

}

