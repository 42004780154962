import {
    ApiError,
    responseHandler
} from "../Utilities";
import {
    FlowResult,
    TestStatusResult
} from "./FlowServiceResponses";


async function flowRunTestResponseHandler(response) {
    let statusMessage = "Error occurred with status " + response.status;
    if (response.status !== 200) {
        let errorMessage;
        try {
            let json = await response.json();
            errorMessage = statusMessage + ": " + json.error;
        }
        catch (err) {
        }
        throw new ApiError(response.status, {}, errorMessage);
    }
    return response.json();
}

export class FlowServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    getFlowContent(taskId) {
        let result = new FlowResult();
        fetch(window.FLOW_API_URL + "/api/task/" + taskId + "/", {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.flow_data = json;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    runTest(campaignId, taskId, data) {
        let result = new TestStatusResult();
        fetch(window.FLOW_API_URL + "/api/campaign/" + campaignId + "/task/" + taskId + "/run_test/", {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
                body: JSON.stringify(data)
            }
        )
        .then(flowRunTestResponseHandler)
        .catch((error) => {
            if (!(error instanceof ApiError)) {
                error = new ApiError(504, {}, error.message)
            }
            if (error.status !== 400) {
                console.error(error);
            }
            result.loaded = true;
            result.error = error;
        })
        .then((json) => {
            result.apiData = json;
            result.loaded = true;
        });
        return result;
    }

    getTestStatus(taskId) {
        let result = new TestStatusResult();
        fetch(window.FLOW_API_URL + "/api/task/" + taskId + "/status/", {headers: this.authService.getAuthorizationHeader()})
        .then(responseHandler)
        .then((json) => {
            result.apiData = json;
            result.loaded = true;
        })
        .catch((error) => {
            if (error.status !== 404) {
                console.error(error);
            }
            result.error = error;
            result.loaded = true;
        });
        return result;
    }
}
