import {responseHandler} from "../Utilities";
import {ActiveCampusList, RankingList, UserContribution} from "./CampusChallengeServiceResponses";

export default class CampusChallengeServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    getUserContribution() {
        let result = new UserContribution();
        fetch(window.CAMPUS_CHALLENGE_API_URL + "/api/user_contribution/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getRankingList() {
        let result = new RankingList();
        fetch(window.CAMPUS_CHALLENGE_API_URL + "/api/ranking_list/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getActiveCampusList() {
        let result = new ActiveCampusList();
        fetch(window.CAMPUS_CHALLENGE_API_URL + "/api/active_campus_list/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET",
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

}
