<template>
   <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="56px" height="60px" viewBox="0 0 56 60" version="1.1">
    <!-- Generator: Sketch 50 (54983) - http://www.bohemiancoding.com/sketch -->
    <title>071 - Office Location</title>
    <desc>Created with Sketch.</desc>
    <defs/>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="071---Office-Location" fill-rule="nonzero">
            <path d="M29,25 C29,30.1 31.94,34.5 36.21,36.63 L40.31,43.07 C41.1,44.31 42.9,44.31 43.69,43.07 L47,37.87 L47,58 C47,58.55 46.55,59 46,59 L13,59 L13,7 C13,5.9 13.9,5 15,5 L45,5 C46.1,5 47,5.9 47,7 L47,13 C45.46,12.36 43.77,12 42,12 C34.82,12 29,17.82 29,25 Z" id="Shape" fill="#FFDF00"/>
            <g id="Group" transform="translate(26.000000, 5.000000)" fill="#FEC108">
                <path d="M14.494,7.094 C14.003,7.037 13.506,7 13,7 C5.82,7 0,12.82 0,20 C0,25.1 2.94,29.5 7.21,31.63 L11.31,38.07 C12.045,39.223 13.643,39.29 14.5,38.298 C14.436,38.224 14.365,38.157 14.31,38.07 L10.21,31.63 C5.94,29.5 3,25.1 3,20 C3,13.33 8.025,7.841 14.494,7.094 Z" id="Shape"/>
                <path d="M18,53 C18,53.55 17.55,54 17,54 L20,54 C20.55,54 21,53.55 21,53 L21,32.87 L18,37.583 L18,53 Z" id="Shape"/>
                <path d="M19,0 L16,0 C17.1,0 18,0.9 18,2 L18,7.171 C19.044,7.334 20.053,7.607 21,8 L21,2 C21,0.9 20.1,0 19,0 Z" id="Shape"/>
            </g>
            <path d="M44,3 L44,5 L16,5 L16,3 C16,1.9 16.9,1 18,1 L42,1 C43.1,1 44,1.9 44,3 Z" id="Shape" fill="#FF9801"/>
            <path d="M42,1 L39,1 C40.1,1 41,1.9 41,3 L41,5 L44,5 L44,3 C44,1.9 43.1,1 42,1 Z" id="Shape" fill="#F57C00"/>
            <path d="M27,45 L33,45 C34.104,45 35,45.896 35,47 L35,59 L25,59 L25,47 C25,45.896 25.896,45 27,45 Z" id="Shape" fill="#A0522D"/>
            <rect id="Rectangle-path" fill="#02A9F4" x="18" y="34" width="6" height="6" rx="2"/>
            <rect id="Rectangle-path" fill="#02A9F4" x="18" y="22" width="6" height="6" rx="2"/>
            <rect id="Rectangle-path" fill="#02A9F4" x="18" y="10" width="6" height="6" rx="2"/>
            <path d="M13,19 L13,59 L2,59 C1.45,59 1,58.55 1,58 L1,21 C1,19.9 1.9,19 3,19 L13,19 Z" id="Shape" fill="#FFDF00"/>
            <rect id="Rectangle-path" fill="#FEC108" x="10" y="19" width="3" height="40"/>
            <path d="M13,15 L13,19 L3,19 L3,17 C3,15.9 3.9,15 5,15 L13,15 Z" id="Shape" fill="#FF9801"/>
            <rect id="Rectangle-path" fill="#F57C00" x="10" y="15" width="3" height="4"/>
            <path d="M55,25 C55,30.1 52.06,34.5 47.79,36.63 L47,37.87 L43.69,43.07 C42.9,44.31 41.1,44.31 40.31,43.07 L36.21,36.63 C31.94,34.5 29,30.1 29,25 C29,17.82 34.82,12 42,12 C43.77,12 45.46,12.36 47,13 C51.7,14.96 55,19.59 55,25 Z" id="Shape" fill="#F44335"/>
            <path d="M47,13 C45.46,12.36 43.77,12 42,12 C41.49,12 40.989,12.037 40.494,12.094 C41.721,12.236 42.9,12.543 44,13 C48.7,14.96 52,19.59 52,25 C52,30.1 49.06,34.5 44.79,36.63 L44,37.87 L40.69,43.07 C40.635,43.157 40.564,43.223 40.5,43.298 C41.357,44.29 42.955,44.224 43.69,43.07 L47,37.87 L47.79,36.63 C52.06,34.5 55,30.1 55,25 C55,19.59 51.7,14.96 47,13 Z" id="Shape" fill="#C81E1E"/>
            <circle id="Oval" fill="#F5F5F5" cx="42" cy="25" r="7"/>
            <path d="M42,18 C41.485,18 40.984,18.059 40.5,18.165 C43.644,18.852 46,21.649 46,25 C46,28.351 43.644,31.148 40.5,31.835 C40.984,31.941 41.485,32 42,32 C45.866,32 49,28.866 49,25 C49,21.134 45.866,18 42,18 Z" id="Shape" fill="#CFD8DC"/>
            <path d="M25,38 L25,36 C25,34.346 23.654,33 22,33 L20,33 C18.346,33 17,34.346 17,36 L17,38 C17,39.654 18.346,41 20,41 L22,41 C23.654,41 25,39.654 25,38 Z M19,38 L19,36 C19,35.448 19.449,35 20,35 L22,35 C22.551,35 23,35.448 23,36 L23,38 C23,38.552 22.551,39 22,39 L20,39 C19.449,39 19,38.552 19,38 Z" id="Shape" fill="#000000"/>
            <path d="M20,29 L22,29 C23.654,29 25,27.654 25,26 L25,24 C25,22.346 23.654,21 22,21 L20,21 C18.346,21 17,22.346 17,24 L17,26 C17,27.654 18.346,29 20,29 Z M19,24 C19,23.448 19.449,23 20,23 L22,23 C22.551,23 23,23.448 23,24 L23,26 C23,26.552 22.551,27 22,27 L20,27 C19.449,27 19,26.552 19,26 L19,24 Z" id="Shape" fill="#000000"/>
            <path d="M20,17 L22,17 C23.654,17 25,15.654 25,14 L25,12 C25,10.346 23.654,9 22,9 L20,9 C18.346,9 17,10.346 17,12 L17,14 C17,15.654 18.346,17 20,17 Z M19,12 C19,11.448 19.449,11 20,11 L22,11 C22.551,11 23,11.448 23,12 L23,14 C23,14.552 22.551,15 22,15 L20,15 C19.449,15 19,14.552 19,14 L19,12 Z" id="Shape" fill="#000000"/>
            <path d="M2,18.184 C0.839,18.598 0,19.698 0,21 L0,58 C0,59.103 0.897,60 2,60 L46,60 C47.103,60 48,59.103 48,58 L48,38.16 L48.488,37.393 C53.129,34.962 56,30.24 56,24.999 C56,19.428 52.722,14.622 48,12.369 L48,7 C48,5.346 46.654,4 45,4 L45,3 C45,1.346 43.654,0 42,0 L18,0 C16.346,0 15,1.346 15,3 L15,4 C13.346,4 12,5.346 12,7 L12,14 L5,14 C3.346,14 2,15.346 2,17 L2,18.184 Z M2,21 C2,20.448 2.449,20 3,20 L12,20 L12,58 L2,58 L2,21 Z M26,58 L26,47 C26,46.448 26.449,46 27,46 L33,46 C33.551,46 34,46.448 34,47 L34,58 L26,58 Z M54,25 C54,29.571 51.449,33.684 47.344,35.732 C47.181,35.813 47.044,35.936 46.947,36.089 L42.845,42.536 C42.657,42.83 42.35,42.999 42.001,42.999 C41.652,42.999 41.345,42.83 41.158,42.536 L37.055,36.089 C36.957,35.936 36.82,35.813 36.658,35.732 C32.552,33.683 30.001,29.571 30.001,25 C30.001,18.383 35.384,13 42.001,13 C48.618,13 54.001,18.383 54.001,25 L54,25 Z M17,3 C17,2.448 17.449,2 18,2 L42,2 C42.551,2 43,2.448 43,3 L43,4 L17,4 L17,3 Z M14,7 C14,6.448 14.449,6 15,6 L45,6 C45.551,6 46,6.448 46,7 L46,11.59 C44.731,11.211 43.39,11 42,11 C34.28,11 28,17.28 28,25 C28,30.24 30.871,34.963 35.513,37.394 L39.47,43.612 C40.024,44.481 40.97,45.001 42,45.001 C43.031,45.001 43.977,44.481 44.531,43.612 L46,41.304 L46,58.001 L36,58.001 L36,47.001 C36,45.347 34.654,44.001 33,44.001 L27,44.001 C25.346,44.001 24,45.347 24,47.001 L24,58.001 L14,58.001 L14,7 Z M5,16 L12,16 L12,18 L4,18 L4,17 C4,16.448 4.449,16 5,16 Z" id="Shape" fill="#000000"/>
            <path d="M34,25 C34,29.411 37.589,33 42,33 C46.411,33 50,29.411 50,25 C50,20.589 46.411,17 42,17 C37.589,17 34,20.589 34,25 Z M48,25 C48,28.309 45.309,31 42,31 C38.691,31 36,28.309 36,25 C36,21.691 38.691,19 42,19 C45.309,19 48,21.691 48,25 Z" id="Shape" fill="#000000"/>
        </g>
    </g>
</svg>

</template>
<script>
export default {
    name: "OfficeIcon"
}
</script>