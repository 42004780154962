<template>
    <div class="technology-tag-input-component">
        <tags-input ref="technologySearch"
                    element-id="tags"
                    v-model="selectedTags"
                    :existing-tags="availableTec"
                    id-field="id"
                    text-field="displayName"
                    :typeahead="true"
                    @tag-added="onTagAdded"
                    @keyup="onSearchInput"
                    placeholder="Suchen/Hinzufügen"
                    :typeahead-hide-discard="true"
                    :existing-technologies="existingTechnologies">
        </tags-input>
            <button class="technology-add-button" @click="addTechnology" v-if="showAddButton" :class="{'show': active }">
                +
            </button>
    </div>
</template>

<script>

export default {
    name: "TechnologyTagInputComponent",
    components: {},
    props: {
        availableTec: Array,
        existingTechnologies: Array
    },
    data() {
        return {
            selectedTags: [],
            showAddButton: false,
            searchValue: '',
            active: false

        }
    },
    computed: {
        matchExactly: function () {
            for (const existingTechnology of this.existingTechnologies) {
                if (this.searchValue.toLowerCase() === existingTechnology.displayName.toLowerCase()) {
                    return true
                }
            }
            return false
        },
    },
    methods: {
        onSearchInput(keyEvent) {
            if (keyEvent.key === 'Enter') {
                // prevent add button animation
                return
            }
            this.searchValue = this.$refs.technologySearch.input
            const newShowButtonState =  !(this.searchValue !== '' && !this.matchExactly) && this.showAddButton

            if (newShowButtonState) {
                setTimeout(()=> {
                    this.showAddButton = false
                }, 400)
            } else {
                this.showAddButton = true
            }
            this.active = this.searchValue !== '' && !this.matchExactly
        },

        onTagAdded(technology) {
            this.$emit("tag-selected", technology)
            this.cleanupAfterAdding()
        },

        addTechnology() {
            this.$emit("tag-selected", {displayName: this.searchValue})
            this.cleanupAfterAdding()
        },
        cleanupAfterAdding() {
            this.$refs.technologySearch.input = ''
            this.showAddButton = false
            this.$refs.technologySearch.clearTags()
        }
    },
}
</script>

<style lang="scss" scoped>
$input-size: 44px;
$color-brand-primary: #08AB99;

::v-deep {
    .tags-input-badge {
        font-family: "Source Code Pro", sans-serif;
        color: $color-brand-primary;
        overflow: hidden;
        padding: 6px 24px;
        margin-bottom: 8px;
        border-radius: 100px;
        background-color: white;
        margin-right: 1rem;
        line-height: 1.6;
        box-shadow: 0 4px 15px rgba(13, 51, 85, 0.2);
        display: inline-block;
        text-transform: uppercase;
        cursor: pointer;


        &.tags-input-badge-selected-default {
            display: none;
        }
    }

    .tags-input input {
        display: block;
        width: 100%;
        padding: 10px 60px 10px 24px;
        border: 1px solid #dbdbdb;
        border-radius: 60px;
        height: $input-size + 4px;
        font-size: 1.6rem;

        &:focus-visible {
            outline-color: $color-brand-primary;
        }
    }

    .typeahead-badges {
        margin-top: 1.5rem;
    }
}

.technology-tag-input-component {
    position: relative;
    margin-top: 2rem;
}

.technology-add-button {
    border-radius: 50%;
    background: $color-brand-primary;
    border: 2px solid #fff;
    color: #fff;
    font-size: 35px;
    line-height: 0;
    position: absolute;
    right: 2px;
    top: 2px;
    width: $input-size;
    height: $input-size;
    @extend .fade-out-left;

    &.show {
        @extend .fade-in-left
    }
}

.fade-out-left {
	-webkit-animation: fade-out-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: fade-out-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.fade-in-left {
	-webkit-animation: fade-in-left 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-left 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2022-9-16 15:51:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-16 15:58:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
}
@keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
}




</style>