<template>
    <div class="wrapper" :class="{'alt': this.$features.WHITELABEL, 'sidebar-invisible': showRegistrationModal}" id="app">
         <CustomLoginContainer></CustomLoginContainer>
         <DailyRewardListener v-if="isAuthenticated" :onboarding-modal-open="showRegistrationModal"/>
         <CookieBarComponent :isVisible="!isCookieConsent" />
        <div v-if="shouldShowRegistrationModal">
            <registration :userProfile="apiResult.user_profile"></registration>
        </div>
        <div v-if="showFeedbackModal">
            <feedback-modal :user-request="apiResult" @close="showFeedbackModal=false"/>
        </div>
        <div v-if="!shouldShowRegistrationModal" :key="forceUpdateCounter">
            <DonationModal/>
            <div>
                <ChatSidebarWrapper v-if="apiResult.loaded && !apiResult.error && !this.$features.WHITELABEL" ref="chat"></ChatSidebarWrapper>
                <sidebar v-if="!this.$features.WHITELABEL" ref="sidebar" :class="{showRegistrationModal: 'sidebar-invisible'}" :isVip="isVip" :isCreator="isCreator"></sidebar>
                <div class="container-fluid">
                    <topbar :isVip="isVip"></topbar>
                    <div class="main-content" @click="closeSideBar(); closeChat();">
                        <transition name="fade" mode="out-in">
                            <router-view></router-view>
                        </transition>
                    </div>

                    <footer-component></footer-component>
                </div>
            </div>
            <job-wizard-component v-if="isJobWizardFeatureFlag" />
            <RedeemCouponThroughLink/>
            <AchievementComponent v-if="isAuthenticated && !this.$features.WHITELABEL"/>
            <feedback-button @click.native="showFeedbackModal=true"/>
        </div>
    </div>
</template>

<script>
    import {eventBus} from "./main.js";
    import FooterComponent from './components/FooterComponent.vue';
    import {mapState} from 'vuex';
    import Registration from './components/user/RegistrationComponent';
    import {services} from './main.js';
    import Sidebar from './components/SidebarComponent.vue';
    import Topbar from './components/TopbarComponent.vue';
    import CookieBarComponent from "./components/CookieBarComponent";
    import RedeemCouponThroughLink from "./components/rewards/RedeemCouponThroughLink";
    import AchievementComponent from "./components/gamification/AchievementNotificationComponent";
    import "confetti-js";
    import DonationModal from "./components/entwicklerherz/DonationModal";
    import {callShortenerLinkForTracking, findGetParameter} from "./services/Utilities";
    import {isEmpty} from 'lodash';
    import {isCookieConsentSet} from "./services/Utilities";
    import ChatSidebarWrapper from "./components/message/ChatSidebarWrapper";
    import WelcomeComponent from "./components/register/WelcomeComponent";
    import FeedbackModal from "./components/FeedbackModal";
    import FeedbackButton from "./components/FeedbackButton";
    import "confetti-js";
    import JobWizardComponent from "./components/user/jobwizard/JobWizardComponent";
    import CustomLoginContainer from "./components/login/CustomLoginContainer";
    import DailyRewardListener from "./components/DailyRewardListener";

    export default {
        name: 'app',
        data() {
            return {
                apiResult: {},
                showRegistrationModal: false,
                isVip: false,
                isCreator: false,
                forceUpdateCounter: 0,
                isCookieConsent: false,
                showFeedbackModal: false,
                showLoginScreen: false,
            }
        },
        components: {
            DailyRewardListener,
            CustomLoginContainer,
            JobWizardComponent,
            FeedbackButton,
            FeedbackModal,
            WelcomeComponent,
            ChatSidebarWrapper,
            CookieBarComponent,
            DonationModal,
            FooterComponent,
            Registration,
            Sidebar,
            Topbar,
            RedeemCouponThroughLink,
            AchievementComponent,
        },
        methods: {
            closeSideBar: function () {
                this.$refs.sidebar.closeSidebar();
            },
            hide: function (event) {
                event.target.style.display = "none";
            },
            closeChat: function () {
                if (this.$refs.chat) {
                    this.$refs.chat.closeChat();
                }
            },
            isMobile: function () {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            updateVipState: function (eventValue) {
                this.isVip = eventValue.isVip;
            },
            forceUpdate: function () {
                this.forceUpdateCounter += 1;
            },
        },
        computed: {
            isJobWizardFeatureFlag: function () {
                return window.JOB_WIZARD_FEATURE_FLAG;
            },
            ...mapState([
                'isAuthenticated',
            ]),
            shouldShowRegistrationModal: function () {
                return !this.$features.WHITELABEL && this.showRegistrationModal
            },
        },
        created() {
            if (this.isAuthenticated) {
              this.apiResult = services.userService.getUserProfile();
            }
            eventBus.$on("closeRegistrationEvent", () => {
              this.showRegistrationModal = false;
              if (this.isAuthenticated) {
                this.$router.push({ name: 'dashboard' });
              }
            });
            // New way for Onboarding: we have to use plain javascript to check the route because $route of vue js is not available here
            const urlGETParams = new URLSearchParams(window.location.search);
            const showOnboardingGETparam = urlGETParams.get('showOnboarding');
            if (showOnboardingGETparam && !this.isAuthenticated) {
              // force to show onboarding modal, if user is not already logged in
              this.showRegistrationModal = true;
            }

            eventBus.$on("finishedCookieSettings", () => {
              this.isCookieConsent = isCookieConsentSet();
              services.initializeTracking();
            });
        },
        updated() {
            if (!isEmpty(services.googleAnalytics)) {
                if (this.isAuthenticated) {
                    services.googleAnalytics.setUserId(services.auth.getIDTokenPayload().sub);
                }
                else {
                    services.googleAnalytics.setUserId('');
                }
            }
            if (!this.isAuthenticated && this.$refs.chat) {
              this.$refs.chat.closeChat();
            }

        },
        watch: {
            'isAuthenticated': function (isAuthenticated) {
                if (isAuthenticated) {
                    this.forceUpdate();
                }
                this.apiResult = services.userService.getUserProfile();
            },
            'apiResult.loaded': function () {
                if (this.apiResult.loaded && !this.apiResult.error) {
                    this.isCreator = this.apiResult.user_profile.is_creator;
                    if (this.isAuthenticated && !this.apiResult.user_profile.completedNewOnboarding) {
                      this.showRegistrationModal = true;
                    }

                    // TODO: remove this after zoll promotion is over
                    if(this.apiResult.user_profile.professionType === 'PUPIL' && localStorage.getItem('promotion')) {
                        let baseUrl = `${localStorage.getItem('promotion')}`;
                        if (baseUrl.includes('zoll')) {
                            // use other link so we can calculate the clicks
                            baseUrl = "https://company.platform.entwicklerheld.de/link/zoll-studium-august-pupil"
                        }
                        const url = `${baseUrl}?user_id=${services.auth.getUserID()}`;
                        callShortenerLinkForTracking(url);
                    }
                    localStorage.removeItem('promotion');
                }
            },
        },
        mounted() {
            // caused ugly bug... that in task edit we cannot run tests with the keys
            //fetchSaveEvent();
            if ($(".smart-alert").length) {

                // Init smart alerts
                let smartAlerts = new SmartAlerts();

                // Generate alerts (ui_alerts.html example)
                $(".smart-alert").each(function () {
                    let alertType = $(this).data("alert-type");
                    let alertContent = $(this).data("alert-content");

                    $(this).click(function () {
                        smartAlerts.generate(alertType, alertContent);
                        return false;
                    });
                });

            }

            $("[data-toggle='collapse']").click(function (e) {
                e.preventDefault();
            });

            eventBus.$on("vipStateEvent", this.updateVipState.bind(this));

            this.isCookieConsent = isCookieConsentSet();
        }
    }
</script>

<style>

    @media screen and (max-width: 480px) {
        .auth0-lock-container {
            height: 100vh; /* Fallback for browsers that do not support Custom Properties */
            width: 100vw !important;
            position: fixed;
        }
        .avatars {
          height: 20rem;
        }
    }

    .auth0-lock.auth0-lock .auth0-lock-header-logo {
        box-sizing: border-box;
        padding-top: 3rem;
    }

    .auth0-lock-tabs-container {
        margin-left: -20px !important;
        margin-right: -20px !important;
    }

    .auth0-lock.auth0-lock .auth0-lock-tabs li {
        width: calc(50%) !important;
    }

    .auth0-lock-form {
        box-sizing: border-box !important;
    }

    .auth0-lock-view-content {
        width: 100% !important;
        max-width: 100% !important;
        overflow: hidden;
    }

    .auth0-lock-body-content {
        display: block !important;

    }

    .auth0-lock-form {
        background: transparent !important;
    }

    .auth0-lock-overlay {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .auth0-lock-last-login-pane {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sidebar-invisible {
        padding-left: 0 !important;
        background-color: white;
    }
</style>
