import {EntwicklerHerzServiceStub} from "./EntwicklerHerzServiceStub";
import {EntwicklerHerzServiceNetwork} from "./EntwicklerHerzServiceNetwork";

export default class EntwicklerHerzService {

    constructor(auth) {
        this.authService = auth;
    }

    getCoinExchangeRate() {
        if (typeof window.ENTWICKLERHERZ_API_URL === "undefined" || window.ENTWICKLERHERZ_API_URL === "") {
            console.info("window.ENTWICKLERHERZ_API_URL is not set! Using Stub data!");
            return new EntwicklerHerzServiceStub(this.authService).getCoinExchangeRate();
        }
        return new EntwicklerHerzServiceNetwork(this.authService).getCoinExchangeRate();
    }
}
