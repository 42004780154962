<template>
    <div>
        <div class="modal modal-component" tabindex="-1" role="dialog" @click="handleClickEvent">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header" v-if="showHeader">
                        <slot name="modal-header">
                            <button type="button" class="close" @click="$emit('close')" aria-label="Close"><span
                                aria-hidden="true">&times;</span>
                            </button>
                            <h4 class="modal-title">{{ modalTitle }}</h4>
                        </slot>
                    </div>
                    <div class="modal-body">
                        <slot name="modal-body">
                            <p>Insert content via &lt;template v-slot:modal-body&gt;...&lt;/template&gt;</p>
                        </slot>
                    </div>

                    <div class="modal-footer" v-if="showFooter">
                        <slot name="modal-footer">
                            <button type="button" class="btn btn-default" @click="$emit('dismiss')">
                                {{ dismissText }}
                            </button>
                            <button type="button"
                                    class="btn btn-primary btn-confirm"
                                    :class="{'loading': isLoading}"
                                    @click="$emit('confirm')"
                                    :disabled="confirmDisabled || isLoading">
                                {{ confirmText }}
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
        <div @click="$emit('close')" class="modal-backdrop"></div>
    </div>
</template>

<script>

import LoadingIndicatorComponent from "./user/jobwizard/LoadingIndicatorComponent";
export default {
    name: "ModalComponent",
    components: {LoadingIndicatorComponent},
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        showFooter: {
            type: Boolean,
            default: true
        },
        modalTitle: {
            type: String,
            default: "Modal Title"
        },
        confirmText: {
            type: String,
            default: "Confirm"
        },
        dismissText: {
            type: String,
            default: "Cancel"
        },
        confirmDisabled: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    methods: {
        handleClickEvent(event) {
            if (event.target.classList.contains("modal")) {
                this.$emit('close')
            }
        },
    },
    watch: {}
}

</script>

<style lang="scss" scoped>

.modal-component {
    &.modal {
        display: block;
    }

    & + .modal-backdrop {
        background: #000000;
        background: linear-gradient(to bottom right, rgba(76, 154, 150, .5) 0%, rgba(171, 197, 211, .5) 100%);
    }
}

.btn-confirm.loading {
    position: relative;
    &:disabled {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: var(--main-color);
            background: linear-gradient(270deg,
                rgba(8, 171, 153, 0.1) 0%,
                rgba(11, 196, 162, 0.65) 45%,
                rgba(11, 196, 162, 0.65) 55%,
                rgba(8, 171, 153, 0.1) 100%);
            background-size: 300% 100%;
            border-radius: 7px;
            @extend .bg-pan-left
        }
    }
}

.bg-pan-left {
	-webkit-animation: bg-pan-left 1.5s ease-in-out infinite alternate both;
	        animation: bg-pan-left 1.5s ease-in-out infinite alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-29 14:11:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bg-pan-left
 * ----------------------------------------
 */
@-webkit-keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

</style>