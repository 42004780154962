<template>
    <div ref="chat" class="chat-component" :class="{ 'close-chat': closing }">
        <!-- Close button -->
        <div class="chat__close" @click="closeChat()">
            <img src="../../assets/img/close.svg" alt="Close Chat" />
        </div>
        <ChatComponent :key="forceRerenderChatComponentKey" v-if="!closed"></ChatComponent>
    </div>
</template>

<script>
import { eventBus, services } from "../../main";
export default {
    name: "ChatSidebarWrapper",
    components: {
        ChatComponent: () => import(/* webpackPrefetch:true, webpackChunkName: "ChatComponent" */ './ChatComponent.vue')
    },
    data() {
        return {
            closed: true,
            closing: true,
            forceRerenderChatComponentKey: 0,
        }
    },
    created() {
        eventBus.$on("chatBoxToggle", () => {
            if ($(".wrapper").hasClass("chat-box")) {
                this.openChat();
            }
        });
        eventBus.$on("chatClose", () => {
            this.closeChat();
        });
        eventBus.$on("chatWasClosed", () => {
            this.forceRerenderChatComponentKey++;
        });
    },
    destroyed() {
        eventBus.$off("chatBoxToggle");
        eventBus.$off("chatClose");
        eventBus.$off("chatWasClosed");
    },
    methods: {
        closeChat: function () {
            let wrapper = $(".wrapper");
            if (wrapper.hasClass("chat-box")) {
                wrapper.toggleClass("chat-box");
                this.closing = true;
                setTimeout(() => {
                    this.closed = true;
                }, 600);

            }
        },
        openChat: function () {
            if (this.currentChannel && this.currentChannel !== "" && this.currentChannel !== null) {
                this.getChannelDetailsRequest =
                    services.messageService.getChannelDetails(
                        this.currentChannel
                    );
            }
            this.closed = false;
            this.closing = false;
        },
    },
};
</script>

<style scoped>
.chat-component {
    transform: translate(0, 0);
    transition: transform 0.35s ease-out, opacity 0.25s ease-out;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    border-width: 0;
    z-index: 401;
}

.close-chat {
    transform: translateX(100%);
    opacity: 0;
}

.chat__close {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.3;
    cursor: pointer;
    z-index: 1000;
}

@media (min-width: 576px) {
    .chat-component {
        max-width: 500px;
    }
}
</style>
