<template>
    <div class="row form-row">
        <div class="col-xs-12">
            <h3 class="text-center">
                Wo ist deine Homebase?
            </h3>
            <p>EntwicklerHeld ist regionalisiert, so kannst du z.B. an Challenges in deiner Region teilnehmen oder Unternehmen in deiner Nähe kennenlernen.</p>
                <div class="location-map">
                    <GmapMap
                            class="gmap"
                            :center="{ lat: googleMapsLatitude, lng: googleMapsLongitude }"
                            :zoom="zoomLevel"
                            :options="{ disableDefaultUI: true, gestureHandling: 'none' }"
                            map-type-id="roadmap"
                    >
                        <GmapMarker
                                v-if="Object.keys(location).length !== 0"
                                :position="{ lat: googleMapsLatitude, lng: googleMapsLongitude }"
                                :clickable="false"
                                :draggable="false"
                        />
                    </GmapMap>
                    <div class="content">
                        <VueGooglePlaces
                                api-key="AIzaSyCVWu7dN6ToxD8ksp17hJ9DOtVkN19jbxY"
                                types="(cities)"
                                @placechanged="onPlaceChanged"
                                @noresult="onPlaceNotFound"
                        >
                            <input
                                    class="location-input input"
                                    type="text"
                                    placeholder="Deine Homebase"
                                    @change="onManualChange"
                                    :class="{'has-error': $v.location.$error }"
                            />
                        </VueGooglePlaces>
                    </div>
                <span v-if="$v.location.$error && !$v.location.required" class="error">Gib den Namen deiner Homebase ein.</span>
            </div>
        </div>
    </div>
</template>

<script>

    import {gmapApi} from "vue2-google-maps";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "LocationForm",
        data() {
            return {
                location: {},
            };
        },
        validations: {
            location: {
                required,
            },
            form: ["location"],
        },
        methods: {
            onManualChange: function (event) {
                // we have to use this @change handler, because :v-model is not working in VueGooglePlaces input
                // we have to reset the location here as the VueGooglePlaces will fill the data if it founds the place
                this.setLocation({});
            },
            setLocation: function (value) {
                this.location = value;
                this.$v.location.$touch();
            },
            onPlaceChanged: function (data) {
                this.setLocation({
                    address: data.formatted_address,
                    city: data.locality,
                    country_verbose: data.country,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    region: data.country_code+"_"+data.administrative_area_level_1,
                    country: data.country_code,
                });
            },
            onPlaceNotFound: function (data) {
                this.setLocation({});
            },
            validate() {
                this.$v.form.$touch();
                let isValid = !this.$v.form.$invalid;
                this.$emit("on-validate", this.$data, isValid);
                return isValid;
            },
        },
        computed: {
            google: gmapApi,
            googleMapsLatitude() {
                if (typeof this.location.latitude !== "undefined") {
                    return this.location.latitude;
                }

                return 51.0;
            },
            googleMapsLongitude() {
                if (typeof this.location.longitude !== "undefined") {
                    return this.location.longitude;
                }

                return 10.2123631;
            },
            zoomLevel() {
                if (typeof this.location.longitude !== "undefined") {
                    return 13;
                }

                return 6;
            },
        }
    }
</script>
<style>
   .vue-map-container .vue-map {
      border-radius: 10px;
    }
</style>

<style scoped>
    .content{
        width: 50%;
        z-index: 600000;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
    }

    .input {
        width: 90%;
    }

    p {
        color: #4c4c4c;
        text-align: center;
    }

    h3 {
        color: #4c4c4c;
        line-height: 1.4em;
        margin: 1.5rem 0;
    }

    input {
        border: 1px solid #08AB99;
        background-color: #F2F7FD;
        padding: 1rem;
        font-size: 15px;
        border-radius: 5px;
    }

    input:focus {
        outline: none;
        border-color: #08AB99;
        transition: all 0.5s ease-in;
    }

    .location-map {
        width: 100%;
        background-color: #08AB991A;
        height: 300px;
        justify-content: center;
        text-align: center;
        position: relative;
        border-radius: 10px;
    }

    .gmap {
        height: 100%;
        filter: brightness(90%);
        cursor: default;
    }


    .has-error {
        border: 1px solid #e74c3c;
    }

    .error {
        display: block;
        color: #e74c3c;
        font-size: 12px;
        margin-top: 0.5rem;
        margin-left: 1rem;
    }

    @media (max-width: 767px) {
        .content {
            width: 90%;
        }
    }

    @media (min-width: 768px){
        p {
          font-size: 120%;
        }
    }

    @media (min-width: 2000px){
        .location-map {
            height: 500px;
        }
    }

</style>