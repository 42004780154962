<template>
    <div id="feedbackModal">
        <div ref="modal" class="modal open fade" @click="handleClickEvent"
             aria-labelledby="tutorialModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="modal-header" style="margin-bottom: 0 !important; padding: 10px;">
                            <h1 class="modal-title text-center">
                                <strong>Feedback Bereich</strong>
                            </h1>
                            <button type="button" class="close" @click="closeModal()" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="content-div">
                            <div class="row">
                                <div class="col-xs-12 col-md-6 text-center">
                                    <div class="box-content">
                                        <div class="fh5co-text">
                                            <i class="dev-arrow-split fh5co-intro-icon"></i>
                                            <h2><strong>Feature</strong> Road<strong>Map</strong></h2>
                                            <p>Du möchtest wissen, welche Features & Verbesserungen in Planung sind?
                                                Dann bist du auf unserem Trello genau richtig.
                                                Hier findest du Ideen die wir haben, konkrete Features die wir planen
                                                und Dinge die wir schon umgesetzt haben.
                                            </p>
                                            <p class="text-center call-to-action-section"><a
                                                    target="_blank"
                                                    href="https://trello.com/b/X2lksvxJ/roadmap-entwicklerheld"
                                                    class="btn btn-primary" style="font-size:1.5rem;">Zur Roadmap</a>
                                            </p>
                                        </div>
                                    </div>
                                      <div class="border-div"></div>
                                </div>
                                <div class="col-xs-12 col-md-6 text-center">
                                    <div class="box-content">
                                        <div class="fh5co-text">
                                            <i class="dev-bug fh5co-intro-icon"></i>
                                            <h2><strong>Bugs</strong> oder <strong>Fragen</strong></h2>
                                            <p>Irgendwas funktioniert nicht richtig oder du kommst bei einer Challenge
                                                nicht weiter?
                                                Schreibe uns gerne über unser Feedback-Formular. Wir versuchen das
                                                Problem so schnell wie möglich zu klären.
                                            </p>
                                            <p class="text-center call-to-action-section"><a
                                                    target="_blank"
                                                    @click="openJiraIssueCollector()"
                                                    class="btn btn-primary" style="font-size:1.5rem;">Zum Formular</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <ul class="social social-circle">
                            <li><a href="https://twitter.com/EntwicklerHeld" target="_blank"
                                   rel="noopener noreferrer"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="https://www.reddit.com/r/EntwicklerHeld/" target="_blank"
                                   rel="noopener noreferrer"><i class="fa fa-reddit"></i></a></li>
                            <li><a href="https://www.facebook.com/EntwicklerHeld/" target="_blank"
                                   rel="noopener noreferrer"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li><a href="https://www.instagram.com/EntwicklerHeld/" target="_blank"
                                   rel="noopener noreferrer"><i class="fa fa-instagram"></i></a>
                            </li>
                            <li><a href="https://www.linkedin.com/company/entwicklerheld/"
                                   target="_blank" rel="noopener noreferrer"><i
                                    class="fa fa-linkedin"></i></a></li>
                            <li><a href="https://www.xing.com/companies/entwicklerheldgmbh"
                                   target="_blank" rel="noopener noreferrer"><i
                                    class="fa fa-xing"></i></a></li>
                            <li><a href="https://www.github.com/polylith/" target="_blank"
                                   rel="noopener noreferrer"><i class="fa fa-github"></i></a></li>
                            <li><a href="https://entwicklerheld.de/support_us/" target="_blank"
                                   rel="noopener noreferrer"><i class="dev-heart"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade in"></div>
    </div>
</template>

<script>
    export default {
        name: "FeedbackModal",
        props: {
          userRequest: {type: Object, default: {loaded: false, error: null}}
        },
        computed: {
          feedbackMailLink: function () {
            let mailBody = "";
            /** logged in */
            if (this.userRequest.loaded && !this.userRequest.error && this.userRequest.user_profile.uuid) {
                mailBody = `&body=Liebes EntwicklerHeld-Team,%0D%0A%0D%0AIch habe folgendes Problem:%0D%0A%0D%0A%0D%0AMeine Public ID: ${this.userRequest.user_profile.uuid }%0D%0AAktuelle Seite: ${this.$router.currentRoute.path}`
            }
            return `mailto:support@entwicklerheld.de?subject=Feedback an EntwicklerHeld${mailBody}`
          }
        },
        mounted() {
            this.$nextTick(function () {
                setTimeout(() => {
                    this.$refs.modal.classList.add("in");
                });
            });
        },
        methods: {
            handleClickEvent(event) {
                if (event.target.classList.contains("modal")) {
                    this.closeModal();
                }
            },
            closeModal() {
                this.$emit("close");
            },
            openJiraIssueCollector() {
              try {
                this.$showJiraIssueCollector();
              }
              catch (e) {
                window.open(this.feedbackMailLink, 'feedback');
              }
            }
        }
    }
</script>

<style scoped>
    .modal-content {
        padding-top: 1.5rem;
    }

    .modal-dialog {
        min-width: 50%;
        pointer-events: none;
    }

    .modal-header {
        margin-bottom: 3.5rem;
    }

    .modal-title {
        margin-bottom: 1.5rem;
    }

    .content-div {
        padding: 1rem;
    }

    .open {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .modal-content {
        pointer-events: auto;
    }

    .modal {
    }

    .close {
        top: 0;
        right: 15px;
        font-size: 4rem;
        position: absolute;
    }

    .close:hover {
        cursor: pointer;
    }

    .box-content {
        padding: 5rem 4rem 2rem 4rem;
        border-radius: 15px;
        opacity: 1;
        z-index: 9;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
    }

    .fh5co-intro-icon {
        font-size: 5rem;
        color: #08AB99;
        margin-bottom: 20px;
        display: block;
    }

    .call-to-action-section {
        margin-top: 2rem;
    }

    .social {
        padding: 0;
        margin: 0 auto;
        display: inline-block;
        position: relative;
        width: 100%;
        list-style: none;
        text-align: center;
    }

    .social li {
        padding: 0;
        display: inline-block;
        font-size: 2rem;
        margin-right: 1rem;
    }

    .border-div {
        border-right: 2px whitesmoke solid;
        height: 70%;
        position: absolute;
        right: 0;
        top: 20%;
    }
    .border-div {
        border-right: 2px whitesmoke solid;
        height: 70%;
        position: absolute;
        right: 0;
        top: 20%;
    }

      @media (max-width: 991px) {
        .border-div {
            border-right: 0;
            border-bottom: 2px whitesmoke solid;
            bottom: -10px;
            width: 70%;
            left: 20%;
            right: auto;
            top: auto;
        }
    }
</style>
