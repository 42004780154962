export class ApplyToJobOfferResponse {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class CheckIfJobOfferExistsResponse {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class InteractResult {
    constructor() {
        this.html = "";
        this.error = null;
        this.loaded = false;
    }
}