<template>
    <a
        v-if="isAuthenticated && getChannelsRequest.loaded"
        href="#"
        class="chat-link btn btn-secondary navbar-btn"
        :class="{ unansweredMessages: unansweredCount > 0 }"
        @click="toggleChatBox()"
    >
        <span v-if="unreadCount > 0">
          <i class="dev-chats-circle chat-icon unread-bubble"></i>
          {{unreadCount}}
        </span>
        <span v-if="unreadCount == 0">
          <i class="dev-chats-circle chat-icon"></i>
        </span>
    </a>
</template>

<script>
import { services, eventBus } from "../../main";
import { mapState } from "vuex";

export default {
    name: "ChatToggleButton",
    components: {},
    data() {
        return {
            channels: [],
            unreadCount: 0,
            unansweredCount: 0,
            getChannelsRequest: { loaded: false, error: null },
            socket: null,
        };
    },
    computed: {
        ...mapState(["isAuthenticated"]),
    },
    watch: {
        "getChannelsRequest.loaded": function () {
            if (this.getChannelsRequest.loaded && !this.getChannelsRequest.error) {
                this.channels = this.getChannelsRequest.apiData;
                this.unreadCount = this.channels.filter((channel) => channel.unreadMessage).length;
                this.unansweredCount = this.channels.filter((channel) => !channel.answeredMessage).length;
            }
        },
    },
    created: function () {
        this.createNotificationConnection();
        if (this.isAuthenticated) {
            this.createNotificationConnection();
            this.getChannelsRequest = services.messageService.getChannels();
        }
        eventBus.$on("updateUnreadCount", (count) => {
            this.unreadCount = count;
        });
        eventBus.$on("allChannelsRead", () => {
            this.getChannelsRequest = services.messageService.getChannels();
        });
        eventBus.$on("chatWasClosed", () => {
             this.getChannelsRequest = services.messageService.getChannels();
        });
        if (this.isAuthenticated) {
            this.getChannelsRequest = services.messageService.getChannels();
        }
    },
    destroyed() {
        eventBus.$off("updateUnreadCount");
        eventBus.$off("allChannelsRead");
        eventBus.$off("chatWasClosed");
    },
    methods: {
        toggleChatBox: function () {
            $(".wrapper").toggleClass("chat-box");
            eventBus.$emit("chatBoxToggle");
            return false;
        },
        createNotificationConnection: function () {
            if (!this.isAuthenticated) {
                return;
            }
            const userID = services.auth.getUserID();
            this.socket = services.notificationsService.getNewMessageNotificationSocket();
            this.socket.on(`notifications_message_${userID}_new-message`, (data) => {
                this.getChannelsRequest = services.messageService.getChannels();
            });
        },
    },
};
</script>

<style scoped>
/* Normal behavior */
a {
    position: relative;
    display: block;
    padding: 10px 15px;
    text-decoration: none;
}

.chat-link {
    text-align: center;
    overflow: visible;
    color: #666;
    text-decoration: none;
    position: relative;
    padding: 8px 10px !important;
}

.unread-bubble::after {
    content: " ";
    display: inline-block;
    position: relative;
    top: -12px;
    right: 4px;
    width: 7px;
    height: 7px;
    line-height: 7px;
    text-align: center;
    border-radius: 50%;
    background-color: #e74c3c;
    font-size: 11px;
    color: white;
}

.unansweredMessages .unread-bubble::after {
  background-color: white;
}

.chat-icon {
  color: #061a2d;
  font-size: 1.8rem;
}

.navbar-btn {
    padding: 8px 10px !important;
    border-radius: 7px;
    transition: opacity 0.5s;
    font-size: 12px !important;
}
/* behavior if unread/unanswered message */

.unansweredMessages {
    text-transform: uppercase;
    background-color: #e74c3c !important;
}

.unansweredMessages:hover {
    cursor: pointer;
    background-color: #D93727 !important;
}

.unansweredMessages i,
.unansweredMessages span {
    color: white;
}
.unansweredMessages i,
.unansweredMessages span {
    vertical-align: middle;
}

.unansweredMessages .chat-icon {
    background-color: inherit;
    display: inline !important;
}
</style>
