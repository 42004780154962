<template>
    <div v-if="isAuthenticated">
        <button id="job-wizard-button" type="button" class="btn btn-primary btn-lg" @click="openModal()">
            <i class="fa fa-magic" aria-hidden="true"></i> <span
            class="visible-sm-inline visible-md-inline visible-lg-inline">Traumfirma finden</span>
        </button>

        <!-- Modal -->
        <transition name="fade">
            <ModalComponent v-if="isModalOpen"
                            modal-title="Jetzt Traumfirma finden!"
                            :show-header="false"
                            :is-loading="submitFormPending"
                            @close="closeModal"
                            @dismiss="closeModal"
                            @confirm="submitForm"
                            dismiss-text="Abbrechen"
                            :confirm-disabled="!contentReady || hasError"
                            confirm-text="Beam me up!">
                <template v-slot:modal-body>

                    <Transition name="fade">
                        <LoadingIndicatorComponent v-if="!contentReady"/>
                    </Transition>

                    <div v-if="!hasError">

                        <!-- ### SALARY SECTION ### -->
                        <Transition name="fade">
                            <JobWizardSection :title="'Geld ist (nicht) alles'"
                                              :subtitle="'Wie ist dein jährliches Wunschgehalt?'" v-show="contentReady">
                                <vue-slider
                                    class="salary-slider"
                                    v-model="form.salary"
                                    :min="minSalary"
                                    :max="maxSalary"
                                    :interval="1000"
                                    :height="3"
                                    :dotSize="20"
                                    :dot-style="{backgroundColor: '#fff', border: '3px solid #08AB99'}"
                                    :railStyle="{backgroundColor: '#F2F7FD', borderRadius: '4px'}"
                                    :process-style="{backgroundColor: '#08AB99'}"
                                    :tooltip="'always'"
                                    :tooltip-placement="'bottom'"
                                    :tooltip-formatter="sliderFormatter"
                                    :tooltip-style="{background: '#08AB99', fontSize: '12px', borderColor: '#08AB99'}"
                                    :contained="true"
                                    @change="showLocation = true"
                                >
                                </vue-slider>
                            </JobWizardSection>
                        </Transition>
                        <!-- ### LOCATION SECTION ### -->
                        <Transition name="fade">
                            <JobWizardSection v-show="showLocation" :title="'Die Welt ist ein Dorf'"
                                              :subtitle="'Wo möchtest du arbeiten?'">
                                <div class="location-selection" v-show="showLocation">
                                    <LocationItem v-for="(value, key) in availableLocations" :key="key"
                                                  :label="value"
                                                  :inputValue="key"
                                                  :checked="key === form.location"
                                                  v-model="form.location">
                                        <OfficeIcon v-if="key === 'office'"/>
                                        <RemoteIcon v-if="key === 'remote'"/>
                                        <HybridIcon v-if="key === 'hybrid'"/>
                                    </LocationItem>
                                </div>
                                <div class="selected-locations"
                                     v-if="form.location === 'office' || form.location === 'hybrid'">
                                    <div class="job-wizard-section__subtitle subtitle">Wo genau?</div>
                                    <div v-for="(location, index) of form.selectedLocations"
                                         class="selected-location-item">
                                        <label class="location-label">{{ location.city }} ({{ location.country }}) <i
                                            @click="removeLocation(index)" class="fa fa-times warning"
                                            aria-hidden="true"></i></label>
                                    </div>
                                    <span v-if="$v.form.selectedLocations.$error" class="error">Gib bitte mindestens einen Standort an.</span>
                                    <LocationInputComponent :error="$v.form.selectedLocations.$error" :selected-locations="form.selectedLocations" @location-changed="addLocation"/>
                                </div>
                                <div v-if="form.location === 'hybrid'" class="how-many-remote-days">
                                    <div class="job-wizard-section__subtitle subtitle">Wie viele Remotetage in der Woche?</div>
                                    <!-- plus minus input  -->
                                    <div class="input-group remote-days">
                                        <span class="input-group-addon" @click="setRemoteDays(form.remoteDays - 1)"><i class="fa fa-minus"
                                                                           aria-hidden="true"></i></span>
                                        <input max="7" min="1" type="number" value="1" class="form-control remote-days-input" @input="changeHandlerRemoteDays" v-model="form.remoteDays">
                                        <span class="input-group-addon" @click="setRemoteDays(form.remoteDays + 1)"><i class="fa fa-plus"
                                                                           aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </JobWizardSection>
                        </Transition>

                        <!-- ### TECHNOLOGY SECTION ### -->
                        <Transition name="fade">
                            <JobWizardSection v-show="showTechnologies && technologiesLoaded && wizardInitialLoaded"
                                              :title="'Auf Messers Schneide'"
                                              :subtitle="'Was für Technologien möchtest du verwenden?'">
                                <TransitionGroup name="chip" tag="ul" class="list-unstyled technologies"
                                                 :duration="{ enter: technologyEnterTime, leave: technologyLeaveTime }">
                                    <li v-for="(value) in selectableTechnologies" :key="value.id" class="technology">
                                        <input class="technology__checkbox"
                                               type="checkbox"
                                               :value="value.id"
                                               :id="value.id"
                                               :name="value.id"
                                               :ref="value.id"
                                               @change="onTechnologyChange($event, value.id)"
                                        />
                                        <label class="technology__label" :for="value.id"
                                               :class="{
                                                    'bg-tec-python': value.name === 'python',
                                                    'bg-tec-dockerfile': value.name === 'dockerfile',
                                                    'bg-tec-java': value.name === 'java',
                                                    'bg-tec-javascript': value.name === 'javascript',
                                                    'bg-tec-csharp': value.name === 'csharp',
                                                    'bg-tec-cpp': value.name === 'cpp',
                                                    'bg-tec-php': value.name === 'php',
                                                }">
                                            <i class="fa fa-code"></i>
                                            <span class="technology__name">{{ value.displayName }}</span>
                                        </label>
                                    </li>
                                </TransitionGroup>

                                <div class="selected-technologies" v-show="selectedTechnologies.length > 0">
                                    <p>Ausgewählt</p>
                                    <TransitionGroup name="chip" tag="ul" class="list-unstyled technologies"
                                                     :duration="{ enter: technologyEnterTime, leave: technologyLeaveTime }">
                                        <li v-for="(value) in selectedTechnologies" :key="value.id"
                                            class="technology">
                                            <input class="technology__checkbox"
                                                   type="checkbox"
                                                   :value="value.id"
                                                   :id="value.id"
                                                   :name="value.id"
                                                   :ref="value.id"
                                                   @change="onTechnologyChange($event, value.id)"
                                                   checked
                                            />
                                            <label class="technology__label" :for="value.id"
                                                   :class="{
                                                    'bg-tec-python': value.name === 'python',
                                                    'bg-tec-dockerfile': value.name === 'dockerfile',
                                                    'bg-tec-java': value.name === 'java',
                                                    'bg-tec-javascript': value.name === 'javascript',
                                                    'bg-tec-csharp': value.name === 'csharp',
                                                    'bg-tec-cpp': value.name === 'cpp',
                                                    'bg-tec-php': value.name === 'php',
                                                }">
                                                <i class="fa fa-code"></i>
                                                <span class="technology__name">{{ value.displayName }}</span>
                                            </label>
                                        </li>
                                    </TransitionGroup>
                                </div>

                                <TechnologyTagInputComponent
                                    :available-tec="typeaheadTechnologies"
                                    :existing-technologies="typeaheadTechnologies"
                                    @tag-selected="onTagSelected"/>
                            </JobWizardSection>
                        </Transition>
                    </div>
                    <div v-if="hasError">
                        <p>Es ist ein <strong>Fehler</strong> beim Laden aufgetreten :(</p>
                        <p><a href="" title="Seite neu laden" @click.prevent="reloadPage"><i
                            class="dev-icon dev-refresh"></i> Seite neu laden</a></p>
                    </div>
                </template> <!-- /.modal-body -->
            </ModalComponent>
        </transition>
    </div>
</template>

<script type="text/javascript">
import TechnologyItem from "./TechnologyItem";
import LocationItem from "./LocationItem";
import {services} from "../../../main";
import TechnologyTagInputComponent from "./TechnologyTagInputComponent";
import VueSlider from 'vue-slider-component'
import RemoteIcon from "./icons/RemoteIcon";
import HybridIcon from "./icons/HybridIcon";
import {makeBackgroundNotScrollable, makeBackgroundScrollable} from "../../../services/Utilities";
import ModalComponent from "../../ModalComponent";
import JobWizardSection from "./JobWizardSection";
import LoadingIndicatorComponent from "./LoadingIndicatorComponent";
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import {mapState} from "vuex";
import {VueGooglePlaces} from 'vue-google-places';
import {gmapApi} from "vue2-google-maps";
import LocationInputComponent from "./LocationInputComponent";
import {required} from "vuelidate/lib/validators";
import OfficeIcon from "./icons/OfficeIcon";

Vue.use(VueToast);

export default {
    name: "JobWizardComponent",
    components: {
        LocationInputComponent,
        LoadingIndicatorComponent,
        JobWizardSection,
        ModalComponent,
        HybridIcon,
        RemoteIcon, OfficeIcon, TechnologyTagInputComponent, LocationItem, TechnologyItem, VueSlider, VueGooglePlaces
    },
    props: {
        userId: {
            type: String,
        }
    },
    data() {
        return {
            isModalOpen: false,
            myDreamCompanyGetRequest: {loaded: false, error: null},
            myDreamCompany: {},
            minSalary: 15000,
            maxSalary: 200000,
            salary: this.minSalary,
            selectedLocation: null,
            showLocation: false,
            showTechnologies: false,
            selectableTechnologiesRequest: {loaded: false, error: null},
            selectableTechnologies: [],
            allTechnologiesRequest: {loaded: false, error: null},
            allTechnologies: [],
            selectedTechnologies: [],
            availableLocations: [],
            state: "INITIAL", //"INITIAL", "SAVING", "SUGGEST_TECHNOLOGY_PENDING"
            myDreamCompanyPostRequest: {loaded: false, error: null},
            form: {
                salary: 15000,
                location: '',
                selectedLocations: [],
                technologies: [],
                remoteDays: 1,
            },
            submitFormPending: false,
            wizardInitialLoaded: false,
            technologyEnterTime: 500,
            technologyLeaveTime: 300,
            sliderFormatter: v => `${this.formatNumber(v)}`,
            suggestTechnologyRequest: {loaded: false, error: null},
            userProfileRequest: {loaded: false, error: null},
        }
    },
    validations() {
        if (this.form.location === 'hybrid' || this.form.location === 'office') {
            return {
                form: {
                    selectedLocations: {
                        required
                    }
                }
            }
        }
        return {
            form: {
                selectedLocations: {}
            }
        }
    },
    computed: {
        userProfileRequestLoaded: function () {
            return this.userProfileRequest.loaded && !this.userProfileRequest.error;
        },
        typeaheadTechnologies: function () {
            let out = this.allTechnologies
            const outIds = out.map(it => it.id)

            for (const item of this.selectableTechnologies) {
                if (!outIds.includes(item.id)) {
                    out.push(item)
                }
            }

            const selectedTechnologyIdList = this.selectedTechnologies.map(technology => technology.id)
            out = out.filter(technology => !selectedTechnologyIdList.includes(technology.id))
            return out
        },
        technologiesLoaded: function () {
            return this.myDreamCompanyGetRequest.loaded && !this.myDreamCompanyGetRequest.error && this.selectableTechnologiesRequest.loaded && !this.selectableTechnologiesRequest.error;
        },
        contentReady: function () {
            return this.myDreamCompanyGetRequest.loaded === true && this.allTechnologiesRequest.loaded === true && this.selectableTechnologiesRequest.loaded === true
        },
        hasError: function () {
            const hasError = this.myDreamCompanyGetRequest.error === true || this.allTechnologiesRequest.error === true || this.selectableTechnologiesRequest.error === true
            if (hasError === true) {
                this.openErrorMessage()
            }
            return hasError
        },
        google: gmapApi,
        ...mapState([
            'isAuthenticated',
        ]),
    },
    created() {
        this.allTechnologiesRequest = services.technologyService.getTechnologies()
        this.getInitialTechnologies();
        this.userProfileRequest = services.userService.getUserProfile();
    },
    mounted() {
        if (this.isAuthenticated) {
            this.openModal()
        }
    },
    methods: {
        changeHandlerRemoteDays: function ( inputEvent ) {
            let newValue = inputEvent.data;
            // check if input is number
            if (isNaN(newValue)) {
                return;
            }
            if(newValue < 1) {
                newValue = 1;
            }
            if(newValue > 7) {
                newValue = 7;
            }
            this.form.remoteDays = newValue;
        },
        setRemoteDays: function (remoteDays) {
          if(remoteDays < 0 || remoteDays > 7) {
              return;
          }
          this.form.remoteDays = remoteDays;
        },
        addLocation(location) {
            // check if location is already selected
            const locationAlreadySelected = this.form.selectedLocations.find(selectedLocation => selectedLocation.longitude === location.longitude && selectedLocation.latitude === location.latitude);
            if (locationAlreadySelected) {
                return;
            }
            this.form.selectedLocations.push(location)
        },
        removeLocation(index) {
            this.form.selectedLocations.splice(index, 1)
        },
        setInitialLocationValue() {
            // only add user home base to selection if myDreamCompany location is not set
            if (this.form.selectedLocations.length < 1 && this.userProfileRequestLoaded && ['office', 'hybrid'].indexOf(this.myDreamCompany.location) === -1) {
                const userHomeBase = this.userProfileRequest.user_profile.location;
                userHomeBase.country = userHomeBase.countryCode;
                this.form.selectedLocations = [userHomeBase];
            }
        },
        formatNumber(number) {
            if (number > this.minSalary) {
                return `ab ${new Intl.NumberFormat().format(number)} €`
            }
            return "Wunschgehalt"
        },
        getInitialTechnologies() {
            this.selectableTechnologiesRequest = services.technologyService.getJobWizardTechnologies()
        },
        submitForm() {
            this.$v.$touch();
            if(this.$v.$invalid) {
                return;
            }
            this.submitFormPending = true;
            this.myDreamCompanyPostRequest = services.userService.postMyDreamCompany(this.form)
        },
        onTechnologyChange(event, id) {
            if (event.target.checked) {
                this.moveFromAvailableToSelected(id)
                this.form.technologies.push(id)
            } else {
                this.moveFromSelectedToAvailable(id)
                this.form.technologies = this.form.technologies.filter(item => item !== id)
            }
        },
        addToAvailable(technologyId) {
            const item = this.selectedTechnologies.filter(value => {
                return value.id === technologyId
            })[0]
            this.selectableTechnologies.push(item)
        },
        removeFromAvailable(technologyId) {
            this.selectableTechnologies = this.selectableTechnologies.filter(value => {
                return value.id !== technologyId
            })
        },
        addToSelected(technologyId) {
            const item = this.selectableTechnologies.filter(value => {
                return value.id === technologyId
            })[0]
            this.selectedTechnologies.push(item)
        },
        removeFromSelected(technologyId) {
            this.selectedTechnologies = this.selectedTechnologies.filter(value => {
                return value.id !== technologyId
            })
        },
        moveFromAvailableToSelected(technologyId) {
            this.addToSelected(technologyId)
            this.removeFromAvailable(technologyId)
        },
        moveFromSelectedToAvailable(technologyId) {
            this.addToAvailable(technologyId)
            this.removeFromSelected(technologyId)
        },
        sortDisplayNameAsc(technologyA, technologyB) {
            if (technologyA.displayName.toLowerCase() < technologyB.displayName.toLowerCase()) {
                return -1;
            }
            if (technologyA.displayName.toLowerCase() > technologyB.displayName.toLowerCase()) {
                return 1;
            }
            return 0;
        },
        mapFormTechnologies() {
            for (let tec of this.form.technologies) {
                this.addToSelected(tec)
                this.removeFromAvailable(tec)
            }
        },
        openModal() {
            this.isModalOpen = true
            makeBackgroundNotScrollable()
        },
        closeModal() {
            this.isModalOpen = false;
            makeBackgroundScrollable()
        },
        onSalaryChange() {
            this.showLocation = true
        },
        onTagSelected(selectedTechnology) {
            // check if technology has to be created
            if (!selectedTechnology.id) {
                this.suggestTechnologyRequest = services.technologyService.createTechnology({name: selectedTechnology.displayName.trim()})
            } else {
                this.selectedTechnologies.push(selectedTechnology)
                if (this.selectableTechnologies.map(it => it.id).includes(selectedTechnology.id)) {
                    this.removeFromAvailable(selectedTechnology.id)
                }
                this.form.technologies.push(selectedTechnology.id)
            }
        },
        openErrorMessage(message = 'Es ist ein Fehler aufgetreten') {
            Vue.$toast.open({
                message: message,
                type: 'error',
                position: 'bottom',
            })
        },
        reloadPage() {
            window.location.reload()
        }
    },
    watch: {
        'selectableTechnologiesRequest.loaded': function (loadingState) {
            if (loadingState === true) {
                if (!this.selectableTechnologiesRequest.error) {
                    this.selectableTechnologies = this.selectableTechnologiesRequest.apiData
                    this.selectableTechnologies.sort(this.sortDisplayNameAsc)

                    // FIXME: wait for technolgies loaded and dream company loaded
                    if (this.selectedTechnologies.length === 0) {
                        this.mapFormTechnologies()
                    }
                }
            }
        },
        'myDreamCompanyGetRequest.loaded': function (isLoaded) {
            if (isLoaded === true) {
                if (!this.myDreamCompanyGetRequest.error) {
                    this.availableLocations = this.myDreamCompanyGetRequest.apiData.availableLocations
                    let data = this.myDreamCompanyGetRequest.apiData
                    this.myDreamCompany = data
                    this.form.salary = data.salary !== null ? data.salary : this.minSalary
                    this.form.location = data.location
                    this.form.technologies = data.technologies !== null ? data.technologies : []
                    if (this.form.location === "hybrid") {
                        this.form.remoteDays = data.remoteDays
                    }
                    if (this.form.location === "office" || this.form.location === "hybrid") {
                        this.form.selectedLocations = data.selectedLocations
                    }

                    if (data.salary !== null || data.location !== null) {
                        this.showLocation = true
                    }
                }
            }
        },
        'form.location': function (newValue) {
            if (newValue !== null) {
                this.showTechnologies = true
            }
            this.setInitialLocationValue();
        },
        'allTechnologiesRequest.loaded': function (newValue) {
            if (newValue === true) {
                if (!this.allTechnologiesRequest.error) {
                    this.allTechnologies = this.allTechnologiesRequest.apiData
                    // start my myDreamCompanyGetRequest after all technologies are fetched
                    this.myDreamCompanyGetRequest = services.userService.getMyDreamCompany()
                }
            }
        },
        technologiesLoaded: function (newValue) {
            if (newValue === true) {
                for (const tec of this.form.technologies) {
                    const tecToAddToSelected = this.allTechnologies.filter(possibleTecToAdd => {
                        return possibleTecToAdd.id === tec
                    })[0]

                    if (tecToAddToSelected && !this.selectedTechnologies.includes(tecToAddToSelected)) {
                        this.selectedTechnologies.push(tecToAddToSelected)
                        this.removeFromAvailable(tec)
                    }
                }
                setTimeout(() => {
                    this.wizardInitialLoaded = true
                }, this.technologyEnterTime)

            }
        },
        'suggestTechnologyRequest.loaded': function (newValue) {
            if (newValue === true && !this.suggestTechnologyRequest.error) {
                const technology = this.suggestTechnologyRequest.apiData
                technology.displayName = technology.name
                if (!this.selectedTechnologies.map(it => it.id).includes(technology.id)) {
                    this.selectedTechnologies.push(technology)
                    this.form.technologies.push(technology.id)
                }
            }
        },
        'myDreamCompanyPostRequest.loaded': function (newValue) {
            if (newValue === true) {
                this.submitFormPending = false
                if (!this.myDreamCompanyPostRequest.error) {
                    this.closeModal()
                    Vue.$toast.open({
                        message: '👍 Deine Eingaben wurden gespeichert.',
                        type: 'success',
                        position: 'bottom',
                    })
                } else {
                    this.openErrorMessage("Beim Absenden ist ein Fehler aufgetreten.")
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$color-brand-primary: #08AB99;
$dotSizeSmall: 20px;
$dotSizeBig: 32px;

#job-wizard-button {
    position: fixed;
    left: 20px;
    bottom: 20px;
    border-radius: 50px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-width: 40px;
    min-height: 40px;
    z-index: 2000;
    box-shadow: 0 8px 24px rgba(13, 51, 85, 0.2);
}

.salary-slider {
    margin: 2rem 0;

    &__value {
        margin-top: 0;
    }
}

.location-label {
    cursor: pointer;
    font-family: "Source Code Pro", sans-serif;
    color: $color-brand-primary;
    overflow: hidden;
    padding: 6px 24px;
    margin-bottom: 8px;
    border-radius: 100px;
    background-color: white;
    margin-right: 1rem;
    line-height: 1.6;
    box-shadow: 0 4px 15px rgba(13, 51, 85, 0.2);
    display: inline-block;
}

.selected-location-item {
    margin-bottom: 1rem;
    display: inline-block;
}

.how-many-remote-days {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.remote-days-input {
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-group-addon:hover {
    cursor: pointer;
}

.technology {
    display: inline-block;

    &__checkbox {
        display: none;

        & + .technology__label {
            cursor: pointer;
            font-family: "Source Code Pro", sans-serif;
            color: $color-brand-primary;
            overflow: hidden;
            padding: 6px 24px;
            margin-bottom: 8px;
            border-radius: 100px;
            background-color: white;
            margin-right: 1rem;
            line-height: 1.6;
            box-shadow: 0 4px 15px rgba(13, 51, 85, 0.2);
            display: inline-block;

            &::before {
                content: none;
            }
        }

        &:checked,
        &:hover {
            & + .technology__label {
                color: white;
                background-color: $color-brand-primary;
            }

            & + .bg-tec-python {
                background-color: #3c6382 !important;
                color: white !important;
            }

            & + .bg-tec-dockerfile,
            & + .bg-tec-docker {
                background-color: #2C90DE !important;
                color: white !important;
            }

            & + .bg-tec-java {
                background-color: #fa983a !important;
                color: white !important;
            }

            & + .bg-tec-javascript {
                background-color: #f6b93b !important;
                color: white !important;
            }

            & + .bg-tec-csharp {
                background-color: #803788 !important;
                color: white !important;
            }

            & + .bg-tec-cpp {
                background-color: #e55039 !important;
                color: white !important;
            }

            & + .bg-tec-php {
                background-color: #6a89cc !important;
                color: white !important;
            }
        }
    }

    &__label {
        position: relative;

        & > .fa {
            position: absolute;
            bottom: -20%;
            left: -8%;
            font-size: 28px;
            color: rgba(255, 255, 255, 0.15);
        }
    }

    &__name {
        font-size: 14px;
        text-transform: uppercase;
    }
}

.chip-leave-active, .chip-enter-active {
    transition: opacity .3s linear,
    transform .45s ease-in-out;
}

.chip-enter, .chip-leave-to {
    opacity: 0;
}

.chip-enter {
    transform: translateX(-20px);
}

.chip-enter-to {
    transform: translateX(0);
}

.chip-leave-to {
    transform: translateX(20px);
}

.subtitle {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.location-selection {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 1rem;
}

.technologies {
    margin: 1.6rem 0 0 0;
}

.input-group.remote-days {
    width: 150px;
}

.salary-slider {
    &__value {
        margin-top: 10px;
        display: block;
    }

    &__dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid $color-brand-primary;
        transition: all .6s ease-out;
    }

    ::v-deep {
        .vue-slider-dot:not(.vue-slider-dot-focus) {
            @extend .pulsate-bkwd;

            &:hover {
                @extend .pulsate-fwd;
            }
        }

        .vue-slider-dot-focus {
            @extend .pulsate-fwd;
        }
    }
}

.pulsate-fwd {
    -webkit-animation: pulsate-fwd 0.5s ease-in-out both;
    animation: pulsate-fwd 0.5s ease-in-out both;
}

.pulsate-bkwd {
    -webkit-animation: pulsate-bkwd 0.5s ease-in-out both;
    animation: pulsate-bkwd 0.5s ease-in-out both;
}

@-webkit-keyframes pulsate-fwd {
    0% {
        width: $dotSizeSmall;
        height: $dotSizeSmall;
    }
    50% {
        width: $dotSizeBig + 5;
        height: $dotSizeBig + 5;
    }
    100% {
        width: $dotSizeBig;
        height: $dotSizeBig;
    }
}

@keyframes pulsate-fwd {
    0% {
        width: $dotSizeSmall;
        height: $dotSizeSmall;
    }
    50% {
        width: $dotSizeBig + 5;
        height: $dotSizeBig + 5;
    }
    100% {
        width: $dotSizeBig;
        height: $dotSizeBig;
    }
}

@-webkit-keyframes pulsate-bkwd {
    0% {
        width: $dotSizeBig;
        height: $dotSizeBig;
    }
    50% {
        width: $dotSizeSmall - 5;
        height: $dotSizeSmall - 5;
    }
    100% {

        width: $dotSizeSmall;
        height: $dotSizeSmall;
    }
}

@keyframes pulsate-bkwd {
    0% {
        width: $dotSizeBig;
        height: $dotSizeBig;
    }
    50% {
        width: $dotSizeSmall - 5;
        height: $dotSizeSmall - 5;
    }
    100% {

        width: $dotSizeSmall;
        height: $dotSizeSmall;
    }
}

@media only screen and (min-width: 992px) {
    form input[type="checkbox"] + label.technology__label:hover {
        cursor: pointer;
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    form input[type="checkbox"] + label.technology__label:hover {
        color: white;
        background-color: $color-brand-primary;
    }

    form input[type="checkbox"] + label.technology__label.bg-tec-python:hover {
        background-color: #3c6382 !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.technology__label.bg-tec-dockerfile:hover,
    form input[type="checkbox"] + label.technology__label.bg-tec-docker:hover {
        background-color: #2C90DE !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.technology__label.bg-tec-java:hover {
        background-color: #fa983a !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.technology__label.bg-tec-javascript:hover {
        background-color: #f6b93b !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.technology__label.bg-tec-csharp:hover {
        background-color: #803788 !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.technology__label.bg-tec-cpp:hover {
        background-color: #e55039 !important;
        color: white !important;
    }

    form input[type="checkbox"] + label.technology__label.bg-tec-php:hover {
        background-color: #6a89cc !important;
        color: white !important;
    }
}

.error {
    display: block;
    color: #e74c3c;
    font-size: 12px;
    margin-top: 0.5rem;
    margin-left: 1rem;
}

.has-error * {
    border: 1px solid #e74c3c !important;
}
</style>