import {
    AnalysisReport,
    HistoryReport,
    PercentagePosition,
} from "./QualityServiceResponses";

export class QualityServiceStub {
    constructor(auth) {
        this.authService = auth;
    }

    getHistoryReport(challengeId, userId, file) {
        let result = new HistoryReport();
        setTimeout(function () {
            result.history_report_data = [
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        // TODO Do something smart here to analyze the program\n        throw new RuntimeException(\"Not implemented yet\");\n    }\n}",
                    "time": "2019-03-04T16:15:19",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000001",
                    "revision": "e16c8e91ee2ae05da6f075f54e7e75535cd3e440"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        \n    }\n}",
                    "time": "2019-03-13T14:37:51",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000002",
                    "revision": "c51049d87a6f492bd10ab7403ad5b0cf1d94cd97"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        r\n    }\n}",
                    "time": "2019-03-13T14:37:52",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000003",
                    "revision": "16c08d8d3d2f1e9dcd8bdd2cfd7a68b59aa1bc64"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        re\n    }\n}",
                    "time": "2019-03-13T14:37:52",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000004",
                    "revision": "2c97153ee0df0b31e932c2d93434201b38f688f3"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        ret\n    }\n}",
                    "time": "2019-03-13T14:37:52",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000005",
                    "revision": "3a208f40c8dab77a83ef8133849cae1c1b34a4e6"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        retu\n    }\n}",
                    "time": "2019-03-13T14:37:52",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000006",
                    "revision": "426a31e80fc54751a443691acd4bf8d060a15cad"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        retur\n    }\n}",
                    "time": "2019-03-13T14:37:52",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000007",
                    "revision": "af6ec14b51086131656312490ef96f4fe4fd9f0f"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        return\n    }\n}",
                    "time": "2019-03-13T14:37:53",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000008",
                    "revision": "b160d6dccc23d54c25b4f2f5026c67d90339cb06"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        return \n    }\n}",
                    "time": "2019-03-13T14:37:53",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000009",
                    "revision": "58de1dd28b5e73694455fe9fa806809c8b8ae4c6"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        return f\n    }\n}",
                    "time": "2019-03-13T14:37:53",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000010",
                    "revision": "c00a4bf046285b3e4b3bacdb19f0c24ef8325214"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        return fa\n    }\n}",
                    "time": "2019-03-13T14:37:53",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000011",
                    "revision": "21d82f101db7c91340857b089aa6bb255dc98ed5"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        return fal\n    }\n}",
                    "time": "2019-03-13T14:37:53",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000012",
                    "revision": "a404aa13878c46a6fbbdc6454c37653399b7c255"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        return fals\n    }\n}",
                    "time": "2019-03-13T14:37:53",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000013",
                    "revision": "29b363fce05162fea6ee1a9654a61c67eddb41b3"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        return false\n    }\n}",
                    "time": "2019-03-13T14:37:53",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000014",
                    "revision": "a93c03b9e9ff3b20270b116434a4899496fcb3e5"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:37:54",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000015",
                    "revision": "c056d045b63962ca47eddc5860ff092d34fbfb89"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        \n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:11",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000016",
                    "revision": "0339d315af32707d30c277561eced3bf411365ca"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        i\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:12",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000017",
                    "revision": "10fd09ef80353cdec8ca29d1f8120bb425e2b3c0"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:12",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000018",
                    "revision": "7e7c1f1346b220695146ff48fb8f42cf8a659e8b"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if \n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:12",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000019",
                    "revision": "1b16a3211afebd8f381670ab316ff433c46bf51f"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if ()\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:13",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000020",
                    "revision": "c6aa3a0206426913fc3898f2077eb91e7188871b"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (v)\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:14",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000021",
                    "revision": "9782a3b0ccb4c6b37dab47c2f0a1e72fe98f38a8"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (va)\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:15",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000022",
                    "revision": "6264762c4a5c68575e9db07d7c89636e15af4aa1"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value)\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:15",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000023",
                    "revision": "d261117df3bd1fa0ce8d6d599b00d7ef674098be"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value )\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:16",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000024",
                    "revision": "bd1d0fec212cdcaca168800577d34442f9202a3f"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value !)\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:16",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000025",
                    "revision": "157c29077c6438402c97934e632e046e11c9b8fe"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value )\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:16",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000026",
                    "revision": "1b08029015b8113fef1972e4698584ab090940e0"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value =)\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:17",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000027",
                    "revision": "e4fbcbff46c48d0c527db34a66a036e468e968e3"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value ==)\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:17",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000028",
                    "revision": "88993f90d7572ff827fc2df75bc74d9720560b14"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == )\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:17",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000029",
                    "revision": "81d663383bb7fbd5a3d56a0d94f61e6e102f27de"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 4)\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:18",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000030",
                    "revision": "74f167e871c11f285c688aab52c7af28d88956eb"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42)\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:18",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000031",
                    "revision": "f59688ad83737e654667b63ff63f2dad31158797"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) \n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:19",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000032",
                    "revision": "8cb7d5d63e7194bd9f445c338302828d2f007499"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {}\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:20",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000033",
                    "revision": "39913286d60e98e0f1fb5d6fa602d5fd1d553306"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            \n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:20",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000034",
                    "revision": "aeadf15eef3b15666ed697d601af2e08dda31e2d"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            r\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:20",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000035",
                    "revision": "44f32a67dd855fc826e0348cee2bba36af9223f0"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            re\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:21",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000036",
                    "revision": "2001a71b142f3a92501dfda3ccc4a51af2c8989f"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            ret\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:21",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000037",
                    "revision": "182d9e65bfc98448359487148edff0b67bf83518"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            retu\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:21",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000038",
                    "revision": "40ffd40c3a382cb860fe8fbd7d9b6305db5d0837"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            retur\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:21",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000039",
                    "revision": "6ef3fdf567e3a7d7621c06066b9cf5e1803dc9b4"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            return\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:21",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000040",
                    "revision": "0e756637a0f6333f1038efa9be5630af6fd68a58"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            return \n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:21",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000041",
                    "revision": "5b9f64687fe82c27bf2cbbd07c496c52d7e6d811"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            return t\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:21",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000042",
                    "revision": "2e24662ac645182fd96b3432c9f9781d6b5f963f"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            return tr\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:22",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000043",
                    "revision": "b1d1f3d2b3da3d6aad78372a03a1babbdf792ec4"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            return tru\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:22",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000044",
                    "revision": "6cdd6d58f44c04060bf07f7ceb74425d4f46fd30"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            return true\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:22",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000045",
                    "revision": "efebb8109c948aeb29b48ddb2f8832ec2c3cf786"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:22",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000046",
                    "revision": "cf98220c0c4ef0c41a87c470f1b11cbbd5485ea4"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        \n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:52",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000047",
                    "revision": "9d4b23215864d683a97cf4f91e09a4fc8178b1b8"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        p\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:54",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000048",
                    "revision": "017ac53e28806733f3c9f3c3369ce57813536f2d"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        pr\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:54",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000049",
                    "revision": "c72e9b797f3fb25f0ed57254210f874fdde8422e"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        pro\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:54",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000050",
                    "revision": "b63fbd4e5e1862fc3bdbdd58fcf36e287747ac8e"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        program\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:55",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000051",
                    "revision": "e15428cc7ec8ead3c65ee105f2edb5124389c075"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        program.\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:38:56",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000052",
                    "revision": "a24aea474ba7bb8d5c62ba2d9f0374686ea9c63a"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        program.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:04",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000053",
                    "revision": "6f715e1e4ae98ac9da3a8145f5b2e87a6a4c003c"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        fprogram.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:07",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000054",
                    "revision": "bcb362a23df17e56f46efacc42ec9b8157c271cc"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        foprogram.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:07",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000055",
                    "revision": "c377ec0faa936828610622d9a7be93e511b83acf"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        forprogram.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:07",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000056",
                    "revision": "9869c0fde49b741d28d876bc023112bcf22611db"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        for program.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:07",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000057",
                    "revision": "cce8e7b89200ac30f2c6d289e9db3482c9e261e9"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        for (program.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:08",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000058",
                    "revision": "7116ab57e16ec26d43223b83474234a5eecf6627"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        for ( program.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:09",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000059",
                    "revision": "b2833a4469f7ddec134b48650daf3dabf438dfa6"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        for (S program.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:10",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000060",
                    "revision": "3a08dcd395f96b444e456ff65d72beef87d75d6b"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        for (St program.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:10",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000061",
                    "revision": "f4330d5f12042abf4b8d6f25d05c07892a70554f"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        for (Sta program.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:10",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000062",
                    "revision": "dbb908e0ae4ee6268b2076e6ba61a06a706b55b8"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        for (Stat program.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:11",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000063",
                    "revision": "f3c591e93b1af58694048b1dfb06ddd2837f33fe"
                },
                {
                    "content": "package de.devboost.entwicklerheldchallenge;\n\nimport de.devboost.entwicklerheldchallenge.model.Assignment;\nimport de.devboost.entwicklerheldchallenge.model.CannotDoMuchProgram;\nimport de.devboost.entwicklerheldchallenge.model.Comparison;\nimport de.devboost.entwicklerheldchallenge.model.ComparisonOperator;\nimport de.devboost.entwicklerheldchallenge.model.Expression;\nimport de.devboost.entwicklerheldchallenge.model.IfStatement;\nimport de.devboost.entwicklerheldchallenge.model.IntegerInput;\nimport de.devboost.entwicklerheldchallenge.model.IntegerLiteral;\nimport de.devboost.entwicklerheldchallenge.model.Statement;\nimport de.devboost.entwicklerheldchallenge.model.VariableReference;\n\npublic class CannotDoMuchProgramAnalyzer {\n\n    public boolean analyze(CannotDoMuchProgram program, String variableName, int value) {\n        for (State program.getStatements\n        if (value == 42) {\n            return true;\n        }\n        return false;\n    }\n}",
                    "time": "2019-03-13T14:39:11",
                    "campaign_id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                    "user_file_index": "google-oauth2|107982076963308370959#src/main/java/de/devboost/entwicklerheldchallenge/CannotDoMuchProgramAnalyzer.java#000000064",
                    "revision": "ebc8b9187d56536ae9df1c2a5e0cdf0e12ff15c8"
                },
            ];
            result.loaded = true;
            // result.error = {error: {message: "Error"}}
        }, 2000);
        return result;
    }

    getPercentagePosition(challengeId) {
        let result = new PercentagePosition();

        setTimeout(function () {
            result.apiData = {
                "percentagePosition": 0.3,
                "isRankOne": false
            };
            result.loaded = true;
        }, 100);

        return result;
    }

    getAnalysisReport(challengeId){
        let result = new AnalysisReport();

        setTimeout(function () {
            result.apiData = {
                time: 3,
                paths: 5,
                codelines: 22,
                commentlines: 0,
                functions: 1,
                doublelines: 0,
                issues: 500,
            }
            result.loaded = true;
        }, 100);

        return result;
    }

}
