import {UserServiceNetwork} from "./UserNetworkService";
import {UserServiceStub} from "./UserStubService";

export default class UserService {

    constructor(auth) {
        this.authService = auth;
    }

    getUserProfile() {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).getUserProfile();
        }
        return new UserServiceNetwork(this.authService).getUserProfile();
    }

    changeUserProfile(data) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).changeUserProfile(data);
        }
        return new UserServiceNetwork(this.authService).changeUserProfile(data);
    }

    getProfileDataOfMultipleUsers(user_ids) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).getProfileDataOfMultipleUsers(user_ids);
        }
        return new UserServiceNetwork(this.authService).getProfileDataOfMultipleUsers(user_ids);
    }

    finishOnboarding(data) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).finishOnboarding(data);
        }
        return new UserServiceNetwork(this.authService).finishOnboarding(data);
    }

    applyForBeingACreator(creatorInterest) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).applyForBeingACreator(creatorInterest);
        }
        return new UserServiceNetwork(this.authService).applyForBeingACreator(creatorInterest);
    }

    getCoinsLeaderBoard(pageId) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).getCoinsLeaderBoard(pageId);
        }
        return new UserServiceNetwork(this.authService).getCoinsLeaderBoard(pageId);
    }

    getPublicUserProfile(userId) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).getPublicUserProfile(userId);
        }
        return new UserServiceNetwork(this.authService).getPublicUserProfile(userId);
    }

    getDataForPublicUserIds(userIds) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).getDataForPublicUserIds(userIds);
        }
        return new UserServiceNetwork(this.authService).getDataForPublicUserIds(userIds);
    }

    uploadCv(cvData) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).uploadCv(cvData);
        }
        return new UserServiceNetwork(this.authService).uploadCv(cvData);
    }
    getCv() {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).getCv();
        }
        return new UserServiceNetwork(this.authService).getCv();
    }

    saveJsonResume(jsonResume) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).saveJsonResume(jsonResume);
        }
        return new UserServiceNetwork(this.authService).saveJsonResume(jsonResume);
    }

    getLogoDrawingsByPublicUserId(publicUserID){
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).getLogoDrawingsByPublicUserId(publicUserID);
        }
        return new UserServiceNetwork(this.authService).getLogoDrawingsByPublicUserId(publicUserID);
    }

    addOrUpdateLogoDrawingInUserProfile(drawingId, code){
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).addOrUpdateLogoDrawingInUserProfile(drawingId, code);
        }
        return new UserServiceNetwork(this.authService).addOrUpdateLogoDrawingInUserProfile(drawingId, code);
    }

    removeLogoDrawingInUserProfile(drawingId){
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).removeLogoDrawingInUserProfile(drawingId);
        }
        return new UserServiceNetwork(this.authService).removeLogoDrawingInUserProfile(drawingId);
    }

    submitConnectionCode(connection, code, redirectUrl){
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).submitConnectionCode(connection, code, redirectUrl);
        }
        return new UserServiceNetwork(this.authService).submitConnectionCode(connection, code, redirectUrl);
    }

    listConnections(){
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).listConnections();
        }
        return new UserServiceNetwork(this.authService).listConnections();
    }

    postBusinessNetworkUserInformation(businessNetworkPlattform, publicProfileUrl) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).postBusinessNetworkUserInformation(businessNetworkPlattform, publicProfileUrl);
        }
        return new UserServiceNetwork(this.authService).postBusinessNetworkUserInformation(businessNetworkPlattform, publicProfileUrl);
    }

    getBusinessNetworkUserInformation(businessNetworkPlattform) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).getBusinessNetworkUserInformation(businessNetworkPlattform);
        }
        return new UserServiceNetwork(this.authService).getBusinessNetworkUserInformation(businessNetworkPlattform);
    }

    deleteBusinessNetworkUserInformation(businessNetworkPlattform) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).deleteBusinessNetworkUserInformation(businessNetworkPlattform);
        }
        return new UserServiceNetwork(this.authService).deleteBusinessNetworkUserInformation(businessNetworkPlattform);
    }

	getMyDreamCompany() {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).getMyDreamCompany();
        }
        return new UserServiceNetwork(this.authService).getMyDreamCompany();
    }

    postMyDreamCompany(postData) {
        if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
            console.info("window.USER_API_URL is not set! Using Stub data!");
            return new UserServiceStub(this.authService).postMyDreamCompany(postData);
        }
        return new UserServiceNetwork(this.authService).postMyDreamCompany(postData);

    }

}
