var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row form-row"},[_c('div',{staticClass:"col-xs-12"},[_c('h3',{staticClass:"text-center"},[_vm._v("\n            Wähle einen Avatar\n        ")]),_vm._v(" "),_c('p',{staticClass:"subtitle"},[_vm._v("Der ist für alle deine Inkognito-Missionen auf der Plattform hilfreich.")]),_vm._v(" "),_c('div',{staticClass:"col-md-offset-2 col-xs-12 col-md-8"},[_c('div',{staticClass:"avatar-selector"},[_c('div',{staticClass:"avatars"},[_c('a',{staticClass:"avatar-wrapper",class:{selectedAvatarAnimation: !_vm.finished && _vm.selectedImage && _vm.selectedImage === _vm.selectedImageList[0], 'isDisabled': _vm.selectedImage},on:{"click":function($event){return _vm.selectAvatar(_vm.selectedImageList[0], _vm.selectedColorList[0])}}},[_c('div',{staticClass:"avatar",class:{avatarAnimation:_vm.showAnimation,
                                selectedAvatarAnimationOther: _vm.selectedImage && _vm.selectedImage !== _vm.selectedImageList[0]},style:({backgroundColor: _vm.selectedColorList[0]})},[(_vm.selectedImageList)?_c('img',{attrs:{"src":_vm.selectedImageList[0]}}):_vm._e()])]),_vm._v(" "),_c('a',{staticClass:"avatar-wrapper",class:{selectedAvatarAnimation: _vm.selectedImage && _vm.selectedImage === _vm.selectedImageList[1], 'isDisabled': _vm.selectedImage},on:{"click":function($event){return _vm.selectAvatar(_vm.selectedImageList[1], _vm.selectedColorList[1])}}},[_c('div',{staticClass:"avatar",class:{avatarAnimation:_vm.showAnimation,
                                selectedAvatarAnimationOther: _vm.selectedImage && _vm.selectedImage !== _vm.selectedImageList[1]},style:({backgroundColor: _vm.selectedColorList[1]})},[(_vm.selectedImageList)?_c('img',{attrs:{"src":_vm.selectedImageList[1]}}):_vm._e()])]),_vm._v(" "),_c('a',{staticClass:"avatar-wrapper",class:{selectedAvatarAnimation: _vm.selectedImage && _vm.selectedImage === _vm.selectedImageList[2], 'isDisabled': _vm.selectedImage},on:{"click":function($event){return _vm.selectAvatar(_vm.selectedImageList[2], _vm.selectedColorList[2])}}},[_c('div',{staticClass:"avatar",class:{avatarAnimation:_vm.showAnimation,
                                selectedAvatarAnimationOther: _vm.selectedImage && _vm.selectedImage !== _vm.selectedImageList[2]},style:({backgroundColor: _vm.selectedColorList[2]})},[(_vm.selectedImageList)?_c('img',{attrs:{"src":_vm.selectedImageList[2]}}):_vm._e()])]),_vm._v(" "),_c('a',{staticClass:"avatar-wrapper",class:{selectedAvatarAnimation: _vm.selectedImage && _vm.selectedImage === _vm.selectedImageList[3], 'isDisabled': _vm.selectedImage},on:{"click":function($event){return _vm.selectAvatar(_vm.selectedImageList[3], _vm.selectedColorList[3])}}},[_c('div',{staticClass:"avatar",class:{avatarAnimation:_vm.showAnimation,
                                selectedAvatarAnimationOther: _vm.selectedImage && _vm.selectedImage !== _vm.selectedImageList[3]},style:({backgroundColor: _vm.selectedColorList[3]})},[(_vm.selectedImageList)?_c('img',{attrs:{"src":_vm.selectedImageList[3]}}):_vm._e()])]),_vm._v(" "),_c('a',{staticClass:"avatar-wrapper",class:{selectedAvatarAnimation: _vm.selectedImage && _vm.selectedImage === _vm.selectedImageList[4], 'isDisabled': _vm.selectedImage},on:{"click":function($event){return _vm.selectAvatar(_vm.selectedImageList[4], _vm.selectedColorList[4])}}},[_c('div',{staticClass:"avatar",class:{avatarAnimation:_vm.showAnimation,
                                selectedAvatarAnimationOther: _vm.selectedImage && _vm.selectedImage !== _vm.selectedImageList[4]},style:({backgroundColor: _vm.selectedColorList[4]})},[(_vm.selectedImageList)?_c('img',{attrs:{"src":_vm.selectedImageList[4]}}):_vm._e()])]),_vm._v(" "),_c('a',{staticClass:"avatar-wrapper",class:{selectedAvatarAnimation: _vm.selectedImage && _vm.selectedImage === _vm.selectedImageList[5], 'isDisabled': _vm.selectedImage},on:{"click":function($event){return _vm.selectAvatar(_vm.selectedImageList[5], _vm.selectedColorList[5])}}},[_c('div',{staticClass:"avatar",class:{avatarAnimation:_vm.showAnimation,
                                selectedAvatarAnimationOther: _vm.selectedImage && _vm.selectedImage !== _vm.selectedImageList[5]},style:({backgroundColor: _vm.selectedColorList[5]})},[(_vm.selectedImageList)?_c('img',{attrs:{"src":_vm.selectedImageList[5]}}):_vm._e()])]),_vm._v(" "),_c('a',{staticClass:"avatar-wrapper",class:{selectedAvatarAnimation: _vm.selectedImage && _vm.selectedImage === _vm.selectedImageList[6], 'isDisabled': _vm.selectedImage},on:{"click":function($event){return _vm.selectAvatar(_vm.selectedImageList[6], _vm.selectedColorList[6])}}},[_c('div',{staticClass:"avatar",class:{avatarAnimation:_vm.showAnimation,
                                selectedAvatarAnimationOther: _vm.selectedImage && _vm.selectedImage !== _vm.selectedImageList[6]},style:({backgroundColor: _vm.selectedColorList[6]})},[(_vm.selectedImageList)?_c('img',{attrs:{"src":_vm.selectedImageList[6]}}):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"new-avatar-button"},[(!_vm.selectedImage)?_c('button',{staticClass:"generateAvatar",on:{"click":[function($event){_vm.counter += 1},function($event){return _vm.generate()}]}},[_c('i',{staticClass:"dev-repeat"}),_vm._v(" Neue Avatare generieren\n                    ")]):_vm._e(),_vm._v(" "),(_vm.selectedImage)?_c('button',{staticClass:"generateAvatar",on:{"click":[function($event){_vm.counter += 1},function($event){return _vm.generateNew()}]}},[_c('i',{staticClass:"dev-repeat"}),_vm._v(" Doch anderen Avatar wählen\n                    ")]):_vm._e()]),_vm._v(" "),(!_vm.selectedImage)?_c('p',[(_vm.counter>=5 && _vm.counter < 10)?_c('span',[_vm._v("Macht wohl Spaß?")]):_vm._e(),_vm._v(" "),(_vm.counter>=10 && _vm.counter < 15)?_c('span',[_vm._v("Unser Ziel war es, einen blitzschnellen Registrierungsprozess zu entwickeln. Hat wohl nicht so geklappt, oder?")]):_vm._e(),_vm._v(" "),(_vm.counter>=15 && _vm.counter < 20)?_c('span',[_vm._v("Du willst dich nicht entscheiden? Du kannst deinen Avatar auch später noch ändern.")]):_vm._e(),_vm._v(" "),(_vm.counter>=20)?_c('span',[_vm._v("😴😴😴")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('p',[(_vm.$v.selectedImage.$error && !_vm.$v.selectedImage.required)?_c('span',{staticClass:"error"},[_vm._v("Du musst hier genau einen Avatar auswählen.")]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }