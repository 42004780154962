import {ChangedFileResult, FileResult, FilesResult, SetActiveResult} from "./WorkspaceServiceResponses";


class SocketStub {
    constructor(props = {}) {
        this.events = {};
        this.emitted = [];
        Object.assign(this, props);
    }

    on(event, handler) {
        this.events[event] = handler;
    }

    emit(...args) {
        this.emitted = [
            ...this.emitted,
            args
        ];
    }

    simulate(event, payload) {
        this.events[event](payload);
    }

    clear() {
        this.emitted = [];
    }
}

export default class WorkspaceServiceStub {

    constructor(auth) {
        this.authService = auth;
    }

    setActive(workspaceUrl) {
        let result = new SetActiveResult();

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 200);
        return result;
    }

    getFiles(workspaceUrl, taskId) {
        let result = new FilesResult();

        setTimeout(function () {
            result.apiData = {
                "files": {
                    "f0402749-0cee-45ae-9c90-7a6a0360d0f6": "fizzbuzz/fizzbuzz.py"
                },
                "programmingLanguage": "Python"
            };
            result.loaded = true;
        }, 200);
        return result;
    }

    getFileContent(workspaceUrl, taskId, filePath) {
        let result = new FileResult();

        setTimeout(function () {
            result.apiData = {
                "programmingLanguage": "Python",
                "content": "def foo(number):\n    pass"
            };
            result.loaded = true;
        }, 200);
        return result;
    }

    changeFileContent(workspaceUrl, taskId, filePath, content) {
        let result = new ChangedFileResult();

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 200);
        return result;
    }

    getEventSocket(workspaceUrl) {
        let socketStub = new SocketStub();
        return socketStub;
    }
}
