import {
    GetChannelDetailsResult,
    GetChannelsResult,
    SendMessageResult,
    SaveDraftMessageResult,
    CloseChatResult
} from "./MessageServiceResponses";


export default class MessageServiceStub {

    constructor(auth) {
        this.authService = auth;
    }

    getChannels() {
        let result = new GetChannelsResult();

        setTimeout(function () {
            result.apiData = [
                {
                    'id': 'channel001',
                    'name': 'Secured secondary solution',
                    'unreadMessage': true,
                    'draftMessage': false,
                    'hasMessages': true,
                    'answeredMessage': true,
                    'members': [
                        {
                            'id': 'a33442ca-21a1-46b7-a52e-713a8ce018c0',
                            'name': 'Philipp Dienst',
                            'image': 'https://res.cloudinary.com/dspi5zyaa/image/upload/w_350/v1600808859/eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png',
                            'companyName': 'EntwicklerHeld GmbH',
                            'companyLogo': '',
                        },
                    ],
                    'sources': {
                        "job_request_ids": ["6537b05c-a405-4b79-beeb-95a56cb0e1b5"],
                        "sonsored_lead_ids": ["6537b05c-a405-4b79-beeb-95a78cf0e1b5"],
                        "job_requests_meta_data": {
                            "6537b05c-a405-4b79-beeb-95a56cb0e1b5": {
                                "post_challenge_job_interest": {
                                    "interact_job_offer_id": "6637b05c-a405-4b79-beeb-95a26cb0e1b5",
                                    "vacancy_id": "6a598adc-6e42-4de5-9e30-00edf3366465",
                                    "challenge_id": "58c45208-c9cc-474e-94d4-ced187b077e0",
                                    "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                                },
                                "direct_apply_job_interest": {
                                    "interact_job_offer_id": "6637b05c-a405-4b79-beec-95a56cb0e1b5",
                                    "vacancy_id": "6a598adc-6e42-4de5-9e30-00edf3366465",
                                    "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c"
                                },
                                "direct_apply_company_interest": {
                                    "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c"
                                },
                                "pre_challenge_company_interest": {
                                    "challenge_id": "58c45208-c9cc-474e-94d4-ced187b077e0",
                                    "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c"
                                },
                                "accepted_sponsored_challenge": {
                                    "challenge_id": "58c45208-c9cc-474e-94d4-ced187b077e0",
                                    "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c"
                                },
                                "solved_sponsored_challenge": {
                                    "challenge_id": "58c45208-c9cc-474e-94d4-ced187b077e0",
                                    "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c"
                                },
                                "generic_company_interest": {
                                    "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                                },
                                "generic_apply_job_interest": {
                                    "interact_job_offer_id": "6637b05c-a405-4b79-beec-95a56cb0e1b5",
                                    "vacancy_id": "6a598adc-6e42-4de5-9e30-00edf3366465",
                                    "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                                },
                            },
                            "6537b05c-a405-4b79-beeb-95a56cb0f1b5": {
                                "direct_apply_job_interest": {
                                    "interact_job_offer_id": "6637b05c-a405-4b79-beec-95a56cb0e1b5",
                                    "vacancy_id": "6a598adc-6e42-4de5-9e30-00edf3366465",
                                    "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c"
                                },
                            }
                        }
                    }
                },
                {
                    'id': 'channel000',
                    'name': 'Ameliorated 5thgeneration system engine',
                    'unreadMessage': false,
                    'draftMessage': false,
                    'hasMessages': true,
                    'answeredMessage': true,
                    'members': [
                        {
                            'id': 'member001',
                            'name': 'Ilja Bauer',
                            'image': '../assets/img/cookie.png',
                            'companyName': 'Henderson, Brown and Reyes',
                            'companyLogo': '',
                        }
                    ]
                },
                {
                    'id': 'channel002',
                    'name': 'Ameliorated 5thgeneration system engine',
                    'unreadMessage': true,
                    'draftMessage': false,
                    'hasMessages': true,
                    'answeredMessage': false,
                    'members': [
                        {
                            'id': 'member002',
                            'name': 'Felix Hanspach',
                            'image': '../assets/img/gavel.png',
                            'companyName': 'polylith',
                            'companyLogo': '',
                        },
                        {
                            'id': 'member002',
                            'name': 'Norma Driske',
                            'image': '../assets/img/gavel.png',
                            'companyName': 'polylith',
                            'companyLogo': '',
                        }
                    ]
                },
                {
                    'id': 'channel003',
                    'name': 'Ameliorated 5thgeneration system engine',
                    'unreadMessage': true,
                    'draftMessage': true,
                    'hasMessages': true,
                    'answeredMessage': true,
                    'members': [
                        {
                            'id': 'member003',
                            'name': 'Jakob Blume',
                            'image': '../assets/img/jakob+phil.png',
                            'companyName': 'Devboost',
                            'companyLogo': '',
                        }
                    ]
                }
            ];
            result.loaded = true;
        }, 200);
        return result;
    }

    getChannelDetails(channelId) {
        let result = new GetChannelDetailsResult();

        setTimeout(function () {
            result.apiData = {
                'id': 'channel001',
                'name': 'Versatile didactic contingency',
                'members': [
                    {
                        'id': 'a33442ca-21a1-46b7-a52e-713a8ce018c0',
                        'name': 'Philipp Dienst',
                        'image': 'https://res.cloudinary.com/dspi5zyaa/image/upload/w_350/v1600808859/eh-platform/company.platform.entwicklerheld.de/profiles/entwicklerheld-gmbh-124bc48d-6302-4af8-910e-3bdc9c214ce5.png',
                        'companyName': 'EntwicklerHeld GmbH',
                        'companyLogo': '',
                        'companyId': 'c25bfe23-e621-465e-911c-0c6c81e2496c',
                    },
                ],
                'messages': [
                    {
                        'id': 'message000',
                        'senderId': 'a33442ca-21a1-46b7-a52e-713a8ce018c0',
                        'senderName': 'Philipp Dienst',
                        'you': false,
                        'content': 'Hey Wonderful Engelbart, dein CV sieht super aus, willst du bei EntwicklerHeld arbeiten?',
                        'serverTimeReceived': '2020-09-24T16:55:42.168542Z',
                        'unRead': false
                    },
                    // {
                    //     'id': 'message001',
                    //     'senderId': 'a33442ca-21a1-46b7-a52e-713a8ce018c1',
                    //     'senderName': 'Mucki Maus',
                    //     'you': true,
                    //     'content': 'Na klaaar! Warum auch nicht? Was soll schon groß schief gehen?',
                    //     'serverTimeReceived': '2020-09-25T07:59:42.168542Z',
                    //     'unRead': false
                    // },
                    {
                        'id': 'message002',
                        'senderId': 'a33442ca-21a1-46b7-a52e-713a8ce018c0',
                        'senderName': 'Philipp Dienst',
                        'you': false,
                        'content': 'Ebend! 😆',
                        'serverTimeReceived': '2020-09-25T07:59:52.168542Z',
                        'unRead': true
                    },
                    {
                        'id': 'message002',
                        'senderId': 'a33442ca-21a1-46b7-a52e-713a8ce018c0',
                        'senderName': 'Philipp Dienst',
                        'you': false,
                        'content': '😆',
                        'serverTimeReceived': '2020-09-25T07:59:52.168542Z',
                        'unRead': true
                    },                    {
                        'id': 'message002',
                        'senderId': 'a33442ca-21a1-46b7-a52e-713a8ce018c0',
                        'senderName': 'Philipp Dienst',
                        'you': true,
                        'content': '😆',
                        'serverTimeReceived': '2020-09-25T07:59:52.168542Z',
                        'unRead': true
                    },                    {
                        'id': 'message002',
                        'senderId': 'a33442ca-21a1-46b7-a52e-713a8ce018c0',
                        'senderName': 'Philipp Dienst',
                        'you': false,
                        'content': 'https://img.netzwelt.de/dw1200_dh675_sw1280_sh720_sx0_sy0_sr16x9_nu0/picture/original/2022/06/game-of-thrones-daenerys-targaryen-343078.jpeg',
                        'serverTimeReceived': '2020-09-25T07:59:52.168542Z',
                        'unRead': true
                    },                    {
                        'id': 'message002',
                        'senderId': 'a33442ca-21a1-46b7-a52e-713a8ce018c0',
                        'senderName': 'Philipp Dienst',
                        'you': true,
                        'content': 'https://files.slack.com/files-pri/T02U86XU4-F060RHQT4CQ/image.png',
                        'serverTimeReceived': '2020-09-25T07:59:52.168542Z',
                        'unRead': true
                    },
                    {
                        'id': 'message003',
                        'senderId': 'a33442ca-21a1-46b7-a52e-713a8ce018c0',
                        'senderName': 'Philipp Dienst',
                        'you': false,
                        'content': `[{
                            "component":"WorkSamplesMessage",
                            "static":{"technology":{"id":1,"name":"","language":"Python"},
                                "challenge":{
                                    "image":"https://production-task-management-media-storage.s3.amazonaws.com/fizzbuzz_python.png",
                                    "title":"FizzBuzz",
                                    "slug":"fizzbuzz",
                                    "id":"cc4d10a7-e4ec-4d1e-b613-5ce7d5b11d9e",
                                    "groupId":"1",
                                    "technology":{"id":1,"name":"","language":"Python"}
                                }
                            },
                            "context":"shareWorkSample"},"Hallo Welt"]`,
                        'serverTimeReceived': '2020-09-25T07:59:52.168542Z',
                        'unRead': true
                    },
                    {
                        'id': 'message004',
                        'senderId': 'a33442ca-21a1-46b7-a52e-713a8ce018c1',
                        'senderName': 'Mucki Maus',
                        'you': true,
                        'content': `[{
                            "component":"WorkSamplesMessage",
                            "static":{"technology":{"id":1,"name":"Spring Boot","language":"Java"},
                                "challenge":{
                                    "image":"https://production-task-management-media-storage.s3.amazonaws.com/047__Programmer-Yellow.png",
                                    "title":"The mighty oracle for 'CannotDoMuch#' programs",
                                    "slug":"the-mighty-oracle-for-cannotdomuch-programs",
                                    "id":"54796871-1190-499d-8ec6-006ab5c2eb64",
                                    "groupId":"6",
                                    "technology":{"id":1,"name":"","language":"Java"}
                                }
                            },
                            "context":"shareWorkSample"}]`,
                        'serverTimeReceived': '2020-09-25T07:59:42.168542Z',
                        'unRead': false
                    },
                ],
                'draftMessage': {},
                'sources': {
                    "job_request_ids": ["6537b05c-a405-4b79-beeb-95a56cb0e1b5"],
                    "sonsored_lead_ids": ["6537b05c-a405-4b79-beeb-95a78cf0e1b5"],
                    "job_requests_meta_data": {
                        "6537b05c-a405-4b79-beeb-95a56cb0e1b5": {
                            "post_challenge_job_interest": {
                                "interact_job_offer_id": "6637b05c-a405-4b79-beeb-95a26cb0e1b5",
                                "vacancy_id": "6a598adc-6e42-4de5-9e30-00edf3366465",
                                "challenge_id": "58c45208-c9cc-474e-94d4-ced187b077e0",
                                "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                            },
                            "direct_apply_job_interest": {
                                "interact_job_offer_id": "6637b05c-a405-4b79-beec-95a56cb0e1b5",
                                "vacancy_id": "6a598adc-6e42-4de5-9e30-00edf3366465",
                                "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c"
                            },
                            "pre_challenge_company_interest": {
                                "challenge_id": "58c45208-c9cc-474e-94d4-ced187b077e0",
                                "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c"
                            },
                            "solved_sponsored_challenge": {
                                "challenge_id": "58c45208-c9cc-474e-94d4-ced187b077e0",
                                "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c"
                            },
                             "generic_company_interest": {
                                    "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                                },
                            "generic_apply_job_interest": {
                                "interact_job_offer_id": "6637b05c-a405-4b79-beec-95a56cb0e1b5",
                                "vacancy_id": "6a598adc-6e42-4de5-9e30-00edf3366465",
                                "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c",
                            },

                        },
                        "6537b05c-a405-4b79-beeb-95a56cb0f1b5": {
                            "direct_apply_job_interest": {
                                "interact_job_offer_id": "6637b05c-a405-4b79-beec-95a56cb0e1b5",
                                "vacancy_id": "6a598adc-6e42-4de5-9e30-00edf3366465",
                                "company_id": "c25bfe23-e621-465e-911c-0c6c81e2496c"
                            },
                        }
                    }
                }
            };
            result.loaded = true;
        }, 200);
        return result;
    }

    sendMessage(channelId, content) {
        let result = new SendMessageResult();
        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, Math.random() * 1500);
        return result;
    }

    saveDraftMessage(channelId, content) {
        let result = new SaveDraftMessageResult();
        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, Math.random() * 1500);
        return result;
    }

    closeChat(channelId) {
        let result = new CloseChatResult();
        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, Math.random() * 1500);
        return result;
    }
}