export let avatars = [
    "1-001_Chubby_Cat-Cartoony.svg",
    "1-001_Chubby_Cat-Flat.svg",
    "1-001_Chubby_Cat-Retro.svg",
    "1-001_Chubby_Cat-Yellow.svg",
    "1-001_Cyclops-Cartoony.svg",
    "1-001_Cyclops-Flat.svg",
    "1-001_Cyclops-Retro.svg",
    "1-001_Cyclops-Yellow.svg",
    "1-001_Dog-Cartoony.svg",
    "1-001_Dog-Flat.svg",
    "1-001_Dog-Retro.svg",
    "1-001_Dog-Yellow.svg",
    "1-001_Hero-Cartoony.svg",
    "1-001_Hero-Flat.svg",
    "1-001_Hero-Retro.svg",
    "1-001_Hero-Yellow.svg",
    "1-001_Love_girl_bot-Cartoony.svg",
    "1-001_Love_girl_bot-Flat.svg",
    "1-001_Love_girl_bot-Retro.svg",
    "1-001_Love_girl_bot-Yellow.svg",
    "1-001_Monkey-Cartoony.svg",
    "1-001_Monkey-Flat.svg",
    "1-001_Monkey-Retro.svg",
    "1-001_Monkey-Yellow.svg",
    "1-001_Super_Girl-Cartoony.svg",
    "1-001_Super_Girl-Flat.svg",
    "1-001_Super_Girl-Retro.svg",
    "1-001_Super_Girl-Yellow.svg",
    "10-010_Chihuahu-Cartoony.svg",
    "10-010_Chihuahu-Flat.svg",
    "10-010_Chihuahu-Retro.svg",
    "10-010_Chihuahu-Yellow.svg",
    "10-010_Punk_Bot-Cartoony.svg",
    "10-010_Punk_Bot-Flat.svg",
    "10-010_Punk_Bot-Retro.svg",
    "10-010_Punk_Bot-Yellow.svg",
    "10-010_Sleepy_Cat-Cartoony.svg",
    "10-010_Sleepy_Cat-Flat.svg",
    "10-010_Sleepy_Cat-Retro.svg",
    "10-010_Sleepy_Cat-Yellow.svg",
    "10-010_Stealth_Girl-Cartoony.svg",
    "10-010_Stealth_Girl-Flat.svg",
    "10-010_Stealth_Girl-Retro.svg",
    "10-010_Stealth_Girl-Yellow.svg",
    "10-010_Super_Hero-Cartoony.svg",
    "10-010_Super_Hero-Flat.svg",
    "10-010_Super_Hero-Retro.svg",
    "10-010_Super_Hero-Yellow.svg",
    "10-010_Three_eyes-Cartoony.svg",
    "10-010_Three_eyes-Flat.svg",
    "10-010_Three_eyes-Retro.svg",
    "10-010_Three_eyes-Yellow.svg",
    "10-010_Tiger-Cartoony.svg",
    "10-010_Tiger-Flat.svg",
    "10-010_Tiger-Retro.svg",
    "10-010_Tiger-Yellow.svg",
    "11-011_Fox-Cartoony.svg",
    "11-011_Fox-Flat.svg",
    "11-011_Fox-Retro.svg",
    "11-011_Fox-Yellow.svg",
    "11-011_Happy_Cat-Cartoony.svg",
    "11-011_Happy_Cat-Flat.svg",
    "11-011_Happy_Cat-Retro.svg",
    "11-011_Happy_Cat-Yellow.svg",
    "11-011_Hero-Cartoony.svg",
    "11-011_Hero-Flat.svg",
    "11-011_Hero-Retro.svg",
    "11-011_Hero-Yellow.svg",
    "11-011_King_Charles-Cartoony.svg",
    "11-011_King_Charles-Flat.svg",
    "11-011_King_Charles-Retro.svg",
    "11-011_King_Charles-Yellow.svg",
    "11-011_Masked_Boy-Cartoony.svg",
    "11-011_Masked_Boy-Flat.svg",
    "11-011_Masked_Boy-Retro.svg",
    "11-011_Masked_Boy-Yellow.svg",
    "11-011_Punk_Bot-Cartoony.svg",
    "11-011_Punk_Bot-Flat.svg",
    "11-011_Punk_Bot-Retro.svg",
    "11-011_Punk_Bot-Yellow.svg",
    "11-011_Cyclops-Cartoony.svg",
    "11-011_Cyclops-Flat.svg",
    "11-011_Cyclops-Retro.svg",
    "11-011_Cyclops-Yellow.svg",
    "12-012_Beast-Cartoony.svg",
    "12-012_Beast-Flat.svg",
    "12-012_Beast-Retro.svg",
    "12-012_Beast-Yellow.svg",
    "12-012_Girl_Punk_Bot-Cartoony.svg",
    "12-012_Girl_Punk_Bot-Flat.svg",
    "12-012_Girl_Punk_Bot-Retro.svg",
    "12-012_Girl_Punk_Bot-Yellow.svg",
    "12-012_Hero-Cartoony.svg",
    "12-012_Hero-Flat.svg",
    "12-012_Hero-Retro.svg",
    "12-012_Hero-Yellow.svg",
    "12-012_Husky-Cartoony.svg",
    "12-012_Husky-Flat.svg",
    "12-012_Husky-Retro.svg",
    "12-012_Husky-Yellow.svg",
    "12-012_Raccoon-Cartoony.svg",
    "12-012_Raccoon-Flat.svg",
    "12-012_Raccoon-Retro.svg",
    "12-012_Raccoon-Yellow.svg",
    "12-012_Sleepy_Cat-Cartoony.svg",
    "12-012_Sleepy_Cat-Flat.svg",
    "12-012_Sleepy_Cat-Retro.svg",
    "12-012_Sleepy_Cat-Yellow.svg",
    "12-012_Super_girl-Cartoony.svg",
    "12-012_Super_girl-Flat.svg",
    "12-012_Super_girl-Retro.svg",
    "12-012_Super_girl-Yellow.svg",
    "13-013_8_Eyes-Cartoony.svg",
    "13-013_8_Eyes-Flat.svg",
    "13-013_8_Eyes-Retro.svg",
    "13-013_8_Eyes-Yellow.svg",
    "13-013_Bullmastiff-Cartoony.svg",
    "13-013_Bullmastiff-Flat.svg",
    "13-013_Bullmastiff-Retro.svg",
    "13-013_Bullmastiff-Yellow.svg",
    "13-013_Happy_Girl_Punk-Cartoony.svg",
    "13-013_Happy_Girl_Punk-Flat.svg",
    "13-013_Happy_Girl_Punk-Retro.svg",
    "13-013_Happy_Girl_Punk-Yellow.svg",
    "13-013_Hero-Cartoony.svg",
    "13-013_Hero-Flat.svg",
    "13-013_Hero-Retro.svg",
    "13-013_Hero-Yellow.svg",
    "13-013_Masked_Man-Cartoony.svg",
    "13-013_Masked_Man-Flat.svg",
    "13-013_Masked_Man-Retro.svg",
    "13-013_Masked_Man-Yellow.svg",
    "13-013_Mouse-Cartoony.svg",
    "13-013_Mouse-Flat.svg",
    "13-013_Mouse-Retro.svg",
    "13-013_Mouse-Yellow.svg",
    "13-013_Sleepy_Cat-Cartoony.svg",
    "13-013_Sleepy_Cat-Flat.svg",
    "13-013_Sleepy_Cat-Retro.svg",
    "13-013_Sleepy_Cat-Yellow.svg",
    "14-014_Gilr_Bot_Kiss-Cartoony.svg",
    "14-014_Gilr_Bot_Kiss-Flat.svg",
    "14-014_Gilr_Bot_Kiss-Retro.svg",
    "14-014_Gilr_Bot_Kiss-Yellow.svg",
    "14-014_Hero-Cartoony.svg",
    "14-014_Hero-Flat.svg",
    "14-014_Hero-Retro.svg",
    "14-014_Hero-Yellow.svg",
    "14-014_Mystic_Woman-Cartoony.svg",
    "14-014_Mystic_Woman-Flat.svg",
    "14-014_Mystic_Woman-Retro.svg",
    "14-014_Mystic_Woman-Yellow.svg",
    "14-014_Penguin-Cartoony.svg",
    "14-014_Penguin-Flat.svg",
    "14-014_Penguin-Retro.svg",
    "14-014_Penguin-Yellow.svg",
    "14-014_Rock_Monster-Cartoony.svg",
    "14-014_Rock_Monster-Flat.svg",
    "14-014_Rock_Monster-Retro.svg",
    "14-014_Rock_Monster-Yellow.svg",
    "14-014_Sad_Cat-Cartoony.svg",
    "14-014_Sad_Cat-Flat.svg",
    "14-014_Sad_Cat-Retro.svg",
    "14-014_Sad_Cat-Yellow.svg",
    "14-014_Welsh_Terrier-Cartoony.svg",
    "14-014_Welsh_Terrier-Flat.svg",
    "14-014_Welsh_Terrier-Retro.svg",
    "14-014_Welsh_Terrier-Yellow.svg",
    "15-015_Gremlin-Cartoony.svg",
    "15-015_Gremlin-Flat.svg",
    "15-015_Gremlin-Retro.svg",
    "15-015_Gremlin-Yellow.svg",
    "15-015_Hero-Cartoony.svg",
    "15-015_Hero-Flat.svg",
    "15-015_Hero-Retro.svg",
    "15-015_Hero-Yellow.svg",
    "15-015_King_Charles-Cartoony.svg",
    "15-015_King_Charles-Flat.svg",
    "15-015_King_Charles-Retro.svg",
    "15-015_King_Charles-Yellow.svg",
    "15-015_Mystic_Man-Cartoony.svg",
    "15-015_Mystic_Man-Flat.svg",
    "15-015_Mystic_Man-Retro.svg",
    "15-015_Mystic_Man-Yellow.svg",
    "15-015_Relaxed_Cat-Cartoony.svg",
    "15-015_Relaxed_Cat-Flat.svg",
    "15-015_Relaxed_Cat-Retro.svg",
    "15-015_Relaxed_Cat-Yellow.svg",
    "15-015_Sentinel_Bot-Cartoony.svg",
    "15-015_Sentinel_Bot-Flat.svg",
    "15-015_Sentinel_Bot-Retro.svg",
    "15-015_Sentinel_Bot-Yellow.svg",
    "15-015_Zebra-Cartoony.svg",
    "15-015_Zebra-Flat.svg",
    "15-015_Zebra-Retro.svg",
    "15-015_Zebra-Yellow.svg",
    "16-016_Annoyed_Cat-Cartoony.svg",
    "16-016_Annoyed_Cat-Flat.svg",
    "16-016_Annoyed_Cat-Retro.svg",
    "16-016_Annoyed_Cat-Yellow.svg",
    "16-016_Dog-Cartoony.svg",
    "16-016_Dog-Flat.svg",
    "16-016_Dog-Retro.svg",
    "16-016_Dog-Yellow.svg",
    "16-016_Goggle_Boy-Cartoony.svg",
    "16-016_Goggle_Boy-Flat.svg",
    "16-016_Goggle_Boy-Retro.svg",
    "16-016_Goggle_Boy-Yellow.svg",
    "16-016_Hero-Cartoony.svg",
    "16-016_Hero-Flat.svg",
    "16-016_Hero-Retro.svg",
    "16-016_Hero-Yellow.svg",
    "16-016_King_Charles-Cartoony.svg",
    "16-016_King_Charles-Flat.svg",
    "16-016_King_Charles-Retro.svg",
    "16-016_King_Charles-Yellow.svg",
    "16-016_Retro_Bot-Cartoony.svg",
    "16-016_Retro_Bot-Flat.svg",
    "16-016_Retro_Bot-Retro.svg",
    "16-016_Retro_Bot-Yellow.svg",
    "16-016_Tongue_Beast-Cartoony.svg",
    "16-016_Tongue_Beast-Flat.svg",
    "16-016_Tongue_Beast-Retro.svg",
    "16-016_Tongue_Beast-Yellow.svg",
    "17-017_Boxer-Cartoony.svg",
    "17-017_Boxer-Flat.svg",
    "17-017_Boxer-Retro.svg",
    "17-017_Boxer-Yellow.svg",
    "17-017_Cow-Cartoony.svg",
    "17-017_Cow-Flat.svg",
    "17-017_Cow-Retro.svg",
    "17-017_Cow-Yellow.svg",
    "17-017_Hairy_Beast-Cartoony.svg",
    "17-017_Hairy_Beast-Flat.svg",
    "17-017_Hairy_Beast-Retro.svg",
    "17-017_Hairy_Beast-Yellow.svg",
    "17-017_Happy_Cat-Cartoony.svg",
    "17-017_Happy_Cat-Flat.svg",
    "17-017_Happy_Cat-Retro.svg",
    "17-017_Happy_Cat-Yellow.svg",
    "17-017_Hero-Cartoony.svg",
    "17-017_Hero-Flat.svg",
    "17-017_Hero-Retro.svg",
    "17-017_Hero-Yellow.svg",
    "17-017_Masked_Boy-Cartoony.svg",
    "17-017_Masked_Boy-Flat.svg",
    "17-017_Masked_Boy-Retro.svg",
    "17-017_Masked_Boy-Yellow.svg",
    "17-017_Retro_Bot_-Cartoony.svg",
    "17-017_Retro_Bot_-Flat.svg",
    "17-017_Retro_Bot_-Retro.svg",
    "17-017_Retro_Bot_-Yellow.svg",
    "18-018_Auburgine_Beast-Cartoony.svg",
    "18-018_Auburgine_Beast-Flat.svg",
    "18-018_Auburgine_Beast-Retro.svg",
    "18-018_Auburgine_Beast-Yellow.svg",
    "18-018_Dog_Love-Cartoony.svg",
    "18-018_Dog_Love-Flat.svg",
    "18-018_Dog_Love-Retro.svg",
    "18-018_Dog_Love-Yellow.svg",
    "18-018_Giraffe-Cartoony.svg",
    "18-018_Giraffe-Flat.svg",
    "18-018_Giraffe-Retro.svg",
    "18-018_Giraffe-Yellow.svg",
    "18-018_Happy_Bot-Cartoony.svg",
    "18-018_Happy_Bot-Flat.svg",
    "18-018_Happy_Bot-Retro.svg",
    "18-018_Happy_Bot-Yellow.svg",
    "18-018_Hero-Cartoony.svg",
    "18-018_Hero-Flat.svg",
    "18-018_Hero-Retro.svg",
    "18-018_Hero-Yellow.svg",
    "18-018_Masked_Girl-Cartoony.svg",
    "18-018_Masked_Girl-Flat.svg",
    "18-018_Masked_Girl-Retro.svg",
    "18-018_Masked_Girl-Yellow.svg",
    "18-018_Sad_Cat-Cartoony.svg",
    "18-018_Sad_Cat-Flat.svg",
    "18-018_Sad_Cat-Retro.svg",
    "18-018_Sad_Cat-Yellow.svg",
    "19-019_Elephant-Cartoony.svg",
    "19-019_Elephant-Flat.svg",
    "19-019_Elephant-Retro.svg",
    "19-019_Elephant-Yellow.svg",
    "19-019_Happy_Cat-Cartoony.svg",
    "19-019_Happy_Cat-Flat.svg",
    "19-019_Happy_Cat-Retro.svg",
    "19-019_Happy_Cat-Yellow.svg",
    "19-019_Happy_Cyclops-Cartoony.svg",
    "19-019_Happy_Cyclops-Flat.svg",
    "19-019_Happy_Cyclops-Retro.svg",
    "19-019_Happy_Cyclops-Yellow.svg",
    "19-019_Hero-Cartoony.svg",
    "19-019_Hero-Flat.svg",
    "19-019_Hero-Retro.svg",
    "19-019_Hero-Yellow.svg",
    "19-019_Love_Bot-Cartoony.svg",
    "19-019_Love_Bot-Flat.svg",
    "19-019_Love_Bot-Retro.svg",
    "19-019_Love_Bot-Yellow.svg",
    "19-019_Power_Man-Cartoony.svg",
    "19-019_Power_Man-Flat.svg",
    "19-019_Power_Man-Retro.svg",
    "19-019_Power_Man-Yellow.svg",
    "19-019_Yorkshire_Terrier-Cartoony.svg",
    "19-019_Yorkshire_Terrier-Flat.svg",
    "19-019_Yorkshire_Terrier-Retro.svg",
    "19-019_Yorkshire_Terrier-Yellow.svg",
    "2-002_Cat-Cartoony.svg",
    "2-002_Cat-Flat.svg",
    "2-002_Cat-Retro.svg",
    "2-002_Cat-Yellow.svg",
    "2-002_Hero-Cartoony.svg",
    "2-002_Hero-Flat.svg",
    "2-002_Hero-Retro.svg",
    "2-002_Hero-Yellow.svg",
    "2-002_Love_Cat-Cartoony.svg",
    "2-002_Love_Cat-Flat.svg",
    "2-002_Love_Cat-Retro.svg",
    "2-002_Love_Cat-Yellow.svg",
    "2-002_Masked_Girl-Cartoony.svg",
    "2-002_Masked_Girl-Flat.svg",
    "2-002_Masked_Girl-Retro.svg",
    "2-002_Masked_Girl-Yellow.svg",
    "2-002_Panting_Dog-Cartoony.svg",
    "2-002_Panting_Dog-Flat.svg",
    "2-002_Panting_Dog-Retro.svg",
    "2-002_Panting_Dog-Yellow.svg",
    "2-002_Squid_Monster-Cartoony.svg",
    "2-002_Squid_Monster-Flat.svg",
    "2-002_Squid_Monster-Retro.svg",
    "2-002_Squid_Monster-Yellow.svg",
    "2-002_flirty_girl_bot-Cartoony.svg",
    "2-002_flirty_girl_bot-Flat.svg",
    "2-002_flirty_girl_bot-Retro.svg",
    "2-002_flirty_girl_bot-Yellow.svg",
    "20-020_Angry_Bot-Cartoony.svg",
    "20-020_Angry_Bot-Flat.svg",
    "20-020_Angry_Bot-Retro.svg",
    "20-020_Angry_Bot-Yellow.svg",
    "20-020_Donkey-Cartoony.svg",
    "20-020_Donkey-Flat.svg",
    "20-020_Donkey-Retro.svg",
    "20-020_Donkey-Yellow.svg",
    "20-020_Goofy_Monster-Cartoony.svg",
    "20-020_Goofy_Monster-Flat.svg",
    "20-020_Goofy_Monster-Retro.svg",
    "20-020_Goofy_Monster-Yellow.svg",
    "20-020_Hero-Cartoony.svg",
    "20-020_Hero-Flat.svg",
    "20-020_Hero-Retro.svg",
    "20-020_Hero-Yellow.svg",
    "20-020_Masked_Girl-Cartoony.svg",
    "20-020_Masked_Girl-Flat.svg",
    "20-020_Masked_Girl-Retro.svg",
    "20-020_Masked_Girl-Yellow.svg",
    "20-020_Sad_Cat-Cartoony.svg",
    "20-020_Sad_Cat-Flat.svg",
    "20-020_Sad_Cat-Retro.svg",
    "20-020_Sad_Cat-Yellow.svg",
    "20-020_Yorkshire_Terrier-Cartoony.svg",
    "20-020_Yorkshire_Terrier-Flat.svg",
    "20-020_Yorkshire_Terrier-Retro.svg",
    "20-020_Yorkshire_Terrier-Yellow.svg",
    "21-021_Akita-Cartoony.svg",
    "21-021_Akita-Flat.svg",
    "21-021_Akita-Retro.svg",
    "21-021_Akita-Yellow.svg",
    "21-021_Cat-Cartoony.svg",
    "21-021_Cat-Flat.svg",
    "21-021_Cat-Retro.svg",
    "21-021_Cat-Yellow.svg",
    "21-021_Goggle_Girl-Cartoony.svg",
    "21-021_Goggle_Girl-Flat.svg",
    "21-021_Goggle_Girl-Retro.svg",
    "21-021_Goggle_Girl-Yellow.svg",
    "21-021_Happy_Bot-Cartoony.svg",
    "21-021_Happy_Bot-Flat.svg",
    "21-021_Happy_Bot-Retro.svg",
    "21-021_Happy_Bot-Yellow.svg",
    "21-021_Three_eyed_yeti-Cartoony.svg",
    "21-021_Three_eyed_yeti-Flat.svg",
    "21-021_Three_eyed_yeti-Retro.svg",
    "21-021_Three_eyed_yeti-Yellow.svg",
    "22-022_Bulldog-Cartoony.svg",
    "22-022_Bulldog-Flat.svg",
    "22-022_Bulldog-Retro.svg",
    "22-022_Bulldog-Yellow.svg",
    "22-022_Chubby_Cat-Cartoony.svg",
    "22-022_Chubby_Cat-Flat.svg",
    "22-022_Chubby_Cat-Retro.svg",
    "22-022_Chubby_Cat-Yellow.svg",
    "22-022_Racoon_Boy-Cartoony.svg",
    "22-022_Racoon_Boy-Flat.svg",
    "22-022_Racoon_Boy-Retro.svg",
    "22-022_Racoon_Boy-Yellow.svg",
    "22-022_Sad_Bot-Cartoony.svg",
    "22-022_Sad_Bot-Flat.svg",
    "22-022_Sad_Bot-Retro.svg",
    "22-022_Sad_Bot-Yellow.svg",
    "22-022_Squid_Monster-Cartoony.svg",
    "22-022_Squid_Monster-Flat.svg",
    "22-022_Squid_Monster-Retro.svg",
    "22-022_Squid_Monster-Yellow.svg",
    "23-023_Blob-Cartoony.svg",
    "23-023_Blob-Flat.svg",
    "23-023_Blob-Retro.svg",
    "23-023_Blob-Yellow.svg",
    "23-023_Cat-Cartoony.svg",
    "23-023_Cat-Flat.svg",
    "23-023_Cat-Retro.svg",
    "23-023_Cat-Yellow.svg",
    "23-023_Husky-Cartoony.svg",
    "23-023_Husky-Flat.svg",
    "23-023_Husky-Retro.svg",
    "23-023_Husky-Yellow.svg",
    "23-023_Masked_Girl-Cartoony.svg",
    "23-023_Masked_Girl-Flat.svg",
    "23-023_Masked_Girl-Retro.svg",
    "23-023_Masked_Girl-Yellow.svg",
    "23-023_Smug_Bot-Cartoony.svg",
    "23-023_Smug_Bot-Flat.svg",
    "23-023_Smug_Bot-Retro.svg",
    "23-023_Smug_Bot-Yellow.svg",
    "24-024_Cat-Cartoony.svg",
    "24-024_Cat-Flat.svg",
    "24-024_Cat-Retro.svg",
    "24-024_Cat-Yellow.svg",
    "24-024_Cute_yeti-Cartoony.svg",
    "24-024_Cute_yeti-Flat.svg",
    "24-024_Cute_yeti-Retro.svg",
    "24-024_Cute_yeti-Yellow.svg",
    "24-024_Happy_Dog-Cartoony.svg",
    "24-024_Happy_Dog-Flat.svg",
    "24-024_Happy_Dog-Retro.svg",
    "24-024_Happy_Dog-Yellow.svg",
    "24-024_Laughing_Bot-Cartoony.svg",
    "24-024_Laughing_Bot-Flat.svg",
    "24-024_Laughing_Bot-Retro.svg",
    "24-024_Laughing_Bot-Yellow.svg",
    "24-024_Super_Girl-Cartoony.svg",
    "24-024_Super_Girl-Flat.svg",
    "24-024_Super_Girl-Retro.svg",
    "24-024_Super_Girl-Yellow.svg",
    "25-025_Chubby_Cat-Cartoony.svg",
    "25-025_Chubby_Cat-Flat.svg",
    "25-025_Chubby_Cat-Retro.svg",
    "25-025_Chubby_Cat-Yellow.svg",
    "25-025_Cute_gremlin-Cartoony.svg",
    "25-025_Cute_gremlin-Flat.svg",
    "25-025_Cute_gremlin-Retro.svg",
    "25-025_Cute_gremlin-Yellow.svg",
    "25-025_Low_Power-Cartoony.svg",
    "25-025_Low_Power-Flat.svg",
    "25-025_Low_Power-Retro.svg",
    "25-025_Low_Power-Yellow.svg",
    "25-025_Robot_Boy-Cartoony.svg",
    "25-025_Robot_Boy-Flat.svg",
    "25-025_Robot_Boy-Retro.svg",
    "25-025_Robot_Boy-Yellow.svg",
    "25-025_St_Bernard-Cartoony.svg",
    "25-025_St_Bernard-Flat.svg",
    "25-025_St_Bernard-Retro.svg",
    "25-025_St_Bernard-Yellow.svg",
    "26-026_Chubby_Cat-Cartoony.svg",
    "26-026_Chubby_Cat-Flat.svg",
    "26-026_Chubby_Cat-Retro.svg",
    "26-026_Chubby_Cat-Yellow.svg",
    "26-026_Robot_Girl-Cartoony.svg",
    "26-026_Robot_Girl-Flat.svg",
    "26-026_Robot_Girl-Retro.svg",
    "26-026_Robot_Girl-Yellow.svg",
    "26-026_Thinking-Cartoony.svg",
    "26-026_Thinking-Flat.svg",
    "26-026_Thinking-Retro.svg",
    "26-026_Thinking-Yellow.svg",
    "26-026_Trunked_Beast-Cartoony.svg",
    "26-026_Trunked_Beast-Flat.svg",
    "26-026_Trunked_Beast-Retro.svg",
    "26-026_Trunked_Beast-Yellow.svg",
    "26-026_labrador_Pup-Cartoony.svg",
    "26-026_labrador_Pup-Flat.svg",
    "26-026_labrador_Pup-Retro.svg",
    "26-026_labrador_Pup-Yellow.svg",
    "27-027_Cat-Cartoony.svg",
    "27-027_Cat-Flat.svg",
    "27-027_Cat-Retro.svg",
    "27-027_Cat-Yellow.svg",
    "27-027_Goggle_Girl-Cartoony.svg",
    "27-027_Goggle_Girl-Flat.svg",
    "27-027_Goggle_Girl-Retro.svg",
    "27-027_Goggle_Girl-Yellow.svg",
    "27-027_Shih_Tzu-Cartoony.svg",
    "27-027_Shih_Tzu-Flat.svg",
    "27-027_Shih_Tzu-Retro.svg",
    "27-027_Shih_Tzu-Yellow.svg",
    "27-027_Squid_Beast-Cartoony.svg",
    "27-027_Squid_Beast-Flat.svg",
    "27-027_Squid_Beast-Retro.svg",
    "27-027_Squid_Beast-Yellow.svg",
    "27-027_Upset_Bot-Cartoony.svg",
    "27-027_Upset_Bot-Flat.svg",
    "27-027_Upset_Bot-Retro.svg",
    "27-027_Upset_Bot-Yellow.svg",
    "28-028_404_bot-Cartoony.svg",
    "28-028_404_bot-Flat.svg",
    "28-028_404_bot-Retro.svg",
    "28-028_404_bot-Yellow.svg",
    "28-028_Cat-Cartoony.svg",
    "28-028_Cat-Flat.svg",
    "28-028_Cat-Retro.svg",
    "28-028_Cat-Yellow.svg",
    "28-028_Racoon_Boy-Cartoony.svg",
    "28-028_Racoon_Boy-Flat.svg",
    "28-028_Racoon_Boy-Retro.svg",
    "28-028_Racoon_Boy-Yellow.svg",
    "28-028_Shih_Tzu-Cartoony.svg",
    "28-028_Shih_Tzu-Flat.svg",
    "28-028_Shih_Tzu-Retro.svg",
    "28-028_Shih_Tzu-Yellow.svg",
    "28-028_Toothy_Monster-Cartoony.svg",
    "28-028_Toothy_Monster-Flat.svg",
    "28-028_Toothy_Monster-Retro.svg",
    "28-028_Toothy_Monster-Yellow.svg",
    "29-029_Fold_Ear_Cat-Cartoony.svg",
    "29-029_Fold_Ear_Cat-Flat.svg",
    "29-029_Fold_Ear_Cat-Retro.svg",
    "29-029_Fold_Ear_Cat-Yellow.svg",
    "29-029_St_Bernard_Pup-Cartoony.svg",
    "29-029_St_Bernard_Pup-Flat.svg",
    "29-029_St_Bernard_Pup-Retro.svg",
    "29-029_St_Bernard_Pup-Yellow.svg",
    "29-029_Stealth_Girl-Cartoony.svg",
    "29-029_Stealth_Girl-Flat.svg",
    "29-029_Stealth_Girl-Retro.svg",
    "29-029_Stealth_Girl-Yellow.svg",
    "29-029_Vodoo_Presit-Cartoony.svg",
    "29-029_Vodoo_Presit-Flat.svg",
    "29-029_Vodoo_Presit-Retro.svg",
    "29-029_Vodoo_Presit-Yellow.svg",
    "29-029_Yawn_Bot-Cartoony.svg",
    "29-029_Yawn_Bot-Flat.svg",
    "29-029_Yawn_Bot-Retro.svg",
    "29-029_Yawn_Bot-Yellow.svg",
    "3-003_Excited_Cat-Cartoony.svg",
    "3-003_Excited_Cat-Flat.svg",
    "3-003_Excited_Cat-Retro.svg",
    "3-003_Excited_Cat-Yellow.svg",
    "3-003_Hero-Cartoony.svg",
    "3-003_Hero-Flat.svg",
    "3-003_Hero-Retro.svg",
    "3-003_Hero-Yellow.svg",
    "3-003_Meatball-Cartoony.svg",
    "3-003_Meatball-Flat.svg",
    "3-003_Meatball-Retro.svg",
    "3-003_Meatball-Yellow.svg",
    "3-003_Panda-Cartoony.svg",
    "3-003_Panda-Flat.svg",
    "3-003_Panda-Retro.svg",
    "3-003_Panda-Yellow.svg",
    "3-003_Pug-Cartoony.svg",
    "3-003_Pug-Flat.svg",
    "3-003_Pug-Retro.svg",
    "3-003_Pug-Yellow.svg",
    "3-003_Super_Woman-Cartoony.svg",
    "3-003_Super_Woman-Flat.svg",
    "3-003_Super_Woman-Retro.svg",
    "3-003_Super_Woman-Yellow.svg",
    "3-003_happy_girl_bot-Cartoony.svg",
    "3-003_happy_girl_bot-Flat.svg",
    "3-003_happy_girl_bot-Retro.svg",
    "3-003_happy_girl_bot-Yellow.svg",
    "30-030_Alien_Cyclops-Cartoony.svg",
    "30-030_Alien_Cyclops-Flat.svg",
    "30-030_Alien_Cyclops-Retro.svg",
    "30-030_Alien_Cyclops-Yellow.svg",
    "30-030_Chubby_Cat-Cartoony.svg",
    "30-030_Chubby_Cat-Flat.svg",
    "30-030_Chubby_Cat-Retro.svg",
    "30-030_Chubby_Cat-Yellow.svg",
    "30-030_Kiss_Bot-Cartoony.svg",
    "30-030_Kiss_Bot-Flat.svg",
    "30-030_Kiss_Bot-Retro.svg",
    "30-030_Kiss_Bot-Yellow.svg",
    "30-030_Masked_Man-Cartoony.svg",
    "30-030_Masked_Man-Flat.svg",
    "30-030_Masked_Man-Retro.svg",
    "30-030_Masked_Man-Yellow.svg",
    "30-030_Shar_pei-Cartoony.svg",
    "30-030_Shar_pei-Flat.svg",
    "30-030_Shar_pei-Retro.svg",
    "30-030_Shar_pei-Yellow.svg",
    "31-031_Bulldog-Cartoony.svg",
    "31-031_Bulldog-Flat.svg",
    "31-031_Bulldog-Retro.svg",
    "31-031_Bulldog-Yellow.svg",
    "31-031_Happy_Cat-Cartoony.svg",
    "31-031_Happy_Cat-Flat.svg",
    "31-031_Happy_Cat-Retro.svg",
    "31-031_Happy_Cat-Yellow.svg",
    "31-031_Love_Bot-Cartoony.svg",
    "31-031_Love_Bot-Flat.svg",
    "31-031_Love_Bot-Retro.svg",
    "31-031_Love_Bot-Yellow.svg",
    "32-032_Alert_Bot-Cartoony.svg",
    "32-032_Alert_Bot-Flat.svg",
    "32-032_Alert_Bot-Retro.svg",
    "32-032_Alert_Bot-Yellow.svg",
    "32-032_Angry_Cat-Cartoony.svg",
    "32-032_Angry_Cat-Flat.svg",
    "32-032_Angry_Cat-Retro.svg",
    "32-032_Angry_Cat-Yellow.svg",
    "32-032_Bloodhound-Cartoony.svg",
    "32-032_Bloodhound-Flat.svg",
    "32-032_Bloodhound-Retro.svg",
    "32-032_Bloodhound-Yellow.svg",
    "33-033_Happy_Bot-Cartoony.svg",
    "33-033_Happy_Bot-Flat.svg",
    "33-033_Happy_Bot-Retro.svg",
    "33-033_Happy_Bot-Yellow.svg",
    "33-033_Sad_Cat-Cartoony.svg",
    "33-033_Sad_Cat-Flat.svg",
    "33-033_Sad_Cat-Retro.svg",
    "33-033_Sad_Cat-Yellow.svg",
    "33-033_labrador-Cartoony.svg",
    "33-033_labrador-Flat.svg",
    "33-033_labrador-Retro.svg",
    "33-033_labrador-Yellow.svg",
    "34-034_Sad_Bot-Cartoony.svg",
    "34-034_Sad_Bot-Flat.svg",
    "34-034_Sad_Bot-Retro.svg",
    "34-034_Sad_Bot-Yellow.svg",
    "34-034_Sad_Cat-Cartoony.svg",
    "34-034_Sad_Cat-Flat.svg",
    "34-034_Sad_Cat-Retro.svg",
    "34-034_Sad_Cat-Yellow.svg",
    "34-034_labrador-Cartoony.svg",
    "34-034_labrador-Flat.svg",
    "34-034_labrador-Retro.svg",
    "34-034_labrador-Yellow.svg",
    "35-035_Bichon_Frise-Cartoony.svg",
    "35-035_Bichon_Frise-Flat.svg",
    "35-035_Bichon_Frise-Retro.svg",
    "35-035_Bichon_Frise-Yellow.svg",
    "35-035_Happy_Cat-Cartoony.svg",
    "35-035_Happy_Cat-Flat.svg",
    "35-035_Happy_Cat-Retro.svg",
    "35-035_Happy_Cat-Yellow.svg",
    "35-035_Search_Bot-Cartoony.svg",
    "35-035_Search_Bot-Flat.svg",
    "35-035_Search_Bot-Retro.svg",
    "35-035_Search_Bot-Yellow.svg",
    "36-036_Boerboel-Cartoony.svg",
    "36-036_Boerboel-Flat.svg",
    "36-036_Boerboel-Retro.svg",
    "36-036_Boerboel-Yellow.svg",
    "36-036_Love_Cat-Cartoony.svg",
    "36-036_Love_Cat-Flat.svg",
    "36-036_Love_Cat-Retro.svg",
    "36-036_Love_Cat-Yellow.svg",
    "36-036_Ouch_Bot-Cartoony.svg",
    "36-036_Ouch_Bot-Flat.svg",
    "36-036_Ouch_Bot-Retro.svg",
    "36-036_Ouch_Bot-Yellow.svg",
    "37-037_Cry_Bot-Cartoony.svg",
    "37-037_Cry_Bot-Flat.svg",
    "37-037_Cry_Bot-Retro.svg",
    "37-037_Cry_Bot-Yellow.svg",
    "37-037_Excited_Cat-Cartoony.svg",
    "37-037_Excited_Cat-Flat.svg",
    "37-037_Excited_Cat-Retro.svg",
    "37-037_Excited_Cat-Yellow.svg",
    "37-037_Shaggy_Dog-Cartoony.svg",
    "37-037_Shaggy_Dog-Flat.svg",
    "37-037_Shaggy_Dog-Retro.svg",
    "37-037_Shaggy_Dog-Yellow.svg",
    "38-038_Boerboel-Cartoony.svg",
    "38-038_Boerboel-Flat.svg",
    "38-038_Boerboel-Retro.svg",
    "38-038_Boerboel-Yellow.svg",
    "38-038_Sleepy_Cat-Cartoony.svg",
    "38-038_Sleepy_Cat-Flat.svg",
    "38-038_Sleepy_Cat-Retro.svg",
    "38-038_Sleepy_Cat-Yellow.svg",
    "38-038_Waiting_Bot-Cartoony.svg",
    "38-038_Waiting_Bot-Flat.svg",
    "38-038_Waiting_Bot-Retro.svg",
    "38-038_Waiting_Bot-Yellow.svg",
    "39-039_Happy_Cat-Cartoony.svg",
    "39-039_Happy_Cat-Flat.svg",
    "39-039_Happy_Cat-Retro.svg",
    "39-039_Happy_Cat-Yellow.svg",
    "39-039_Low_Power-Cartoony.svg",
    "39-039_Low_Power-Flat.svg",
    "39-039_Low_Power-Retro.svg",
    "39-039_Low_Power-Yellow.svg",
    "39-039_Shar_pei-Cartoony.svg",
    "39-039_Shar_pei-Flat.svg",
    "39-039_Shar_pei-Retro.svg",
    "39-039_Shar_pei-Yellow.svg",
    "4-004_Happy_Hero-Cartoony.svg",
    "4-004_Happy_Hero-Flat.svg",
    "4-004_Happy_Hero-Retro.svg",
    "4-004_Happy_Hero-Yellow.svg",
    "4-004_Lady_Bot-Cartoony.svg",
    "4-004_Lady_Bot-Flat.svg",
    "4-004_Lady_Bot-Retro.svg",
    "4-004_Lady_Bot-Yellow.svg",
    "4-004_Pig-Cartoony.svg",
    "4-004_Pig-Flat.svg",
    "4-004_Pig-Retro.svg",
    "4-004_Pig-Yellow.svg",
    "4-004_Pug_Thirsty-Cartoony.svg",
    "4-004_Pug_Thirsty-Flat.svg",
    "4-004_Pug_Thirsty-Retro.svg",
    "4-004_Pug_Thirsty-Yellow.svg",
    "4-004_Racoon_Girl-Cartoony.svg",
    "4-004_Racoon_Girl-Flat.svg",
    "4-004_Racoon_Girl-Retro.svg",
    "4-004_Racoon_Girl-Yellow.svg",
    "4-004_Sleepy_Cat-Cartoony.svg",
    "4-004_Sleepy_Cat-Flat.svg",
    "4-004_Sleepy_Cat-Retro.svg",
    "4-004_Sleepy_Cat-Yellow.svg",
    "4-004_Vodoo_Man-Cartoony.svg",
    "4-004_Vodoo_Man-Flat.svg",
    "4-004_Vodoo_Man-Retro.svg",
    "4-004_Vodoo_Man-Yellow.svg",
    "40-040_Chihuahu-Cartoony.svg",
    "40-040_Chihuahu-Flat.svg",
    "40-040_Chihuahu-Retro.svg",
    "40-040_Chihuahu-Yellow.svg",
    "40-040_Happy_Cat-Cartoony.svg",
    "40-040_Happy_Cat-Flat.svg",
    "40-040_Happy_Cat-Retro.svg",
    "40-040_Happy_Cat-Yellow.svg",
    "40-040_Question_Bot-Cartoony.svg",
    "40-040_Question_Bot-Flat.svg",
    "40-040_Question_Bot-Retro.svg",
    "40-040_Question_Bot-Yellow.svg",
    "41-041_Chihuahu-Cartoony.svg",
    "41-041_Chihuahu-Flat.svg",
    "41-041_Chihuahu-Retro.svg",
    "41-041_Chihuahu-Yellow.svg",
    "41-041_Happy_Cat-Cartoony.svg",
    "41-041_Happy_Cat-Flat.svg",
    "41-041_Happy_Cat-Retro.svg",
    "41-041_Happy_Cat-Yellow.svg",
    "41-041_Love_Bot-Cartoony.svg",
    "41-041_Love_Bot-Flat.svg",
    "41-041_Love_Bot-Retro.svg",
    "41-041_Love_Bot-Yellow.svg",
    "42-042_Alert_Bot-Cartoony.svg",
    "42-042_Alert_Bot-Flat.svg",
    "42-042_Alert_Bot-Retro.svg",
    "42-042_Alert_Bot-Yellow.svg",
    "42-042_Happy_Cat-Cartoony.svg",
    "42-042_Happy_Cat-Flat.svg",
    "42-042_Happy_Cat-Retro.svg",
    "42-042_Happy_Cat-Yellow.svg",
    "42-042_Welsh_Corgi-Cartoony.svg",
    "42-042_Welsh_Corgi-Flat.svg",
    "42-042_Welsh_Corgi-Retro.svg",
    "42-042_Welsh_Corgi-Yellow.svg",
    "43-043_Angry_Bot-Cartoony.svg",
    "43-043_Angry_Bot-Flat.svg",
    "43-043_Angry_Bot-Retro.svg",
    "43-043_Angry_Bot-Yellow.svg",
    "43-043_Love_Cat-Cartoony.svg",
    "43-043_Love_Cat-Flat.svg",
    "43-043_Love_Cat-Retro.svg",
    "43-043_Love_Cat-Yellow.svg",
    "43-043_Poodle-Cartoony.svg",
    "43-043_Poodle-Flat.svg",
    "43-043_Poodle-Retro.svg",
    "43-043_Poodle-Yellow.svg",
    "44-044_Dalmatian-Cartoony.svg",
    "44-044_Dalmatian-Flat.svg",
    "44-044_Dalmatian-Retro.svg",
    "44-044_Dalmatian-Yellow.svg",
    "44-044_Kiss_Bot-Cartoony.svg",
    "44-044_Kiss_Bot-Flat.svg",
    "44-044_Kiss_Bot-Retro.svg",
    "44-044_Kiss_Bot-Yellow.svg",
    "44-044_Sleepy_Cat-Cartoony.svg",
    "44-044_Sleepy_Cat-Flat.svg",
    "44-044_Sleepy_Cat-Retro.svg",
    "44-044_Sleepy_Cat-Yellow.svg",
    "45-045_Happy_Cat-Cartoony.svg",
    "45-045_Happy_Cat-Flat.svg",
    "45-045_Happy_Cat-Retro.svg",
    "45-045_Happy_Cat-Yellow.svg",
    "45-045_Lapphund-Cartoony.svg",
    "45-045_Lapphund-Flat.svg",
    "45-045_Lapphund-Retro.svg",
    "45-045_Lapphund-Yellow.svg",
    "45-045_Wide_awake_Bot-Cartoony.svg",
    "45-045_Wide_awake_Bot-Flat.svg",
    "45-045_Wide_awake_Bot-Retro.svg",
    "45-045_Wide_awake_Bot-Yellow.svg",
    "46-046_Cry_Bot-Cartoony.svg",
    "46-046_Cry_Bot-Flat.svg",
    "46-046_Cry_Bot-Retro.svg",
    "46-046_Cry_Bot-Yellow.svg",
    "46-046_Love_Cat-Cartoony.svg",
    "46-046_Love_Cat-Flat.svg",
    "46-046_Love_Cat-Retro.svg",
    "46-046_Love_Cat-Yellow.svg",
    "46-046_Welsh_Corgi-Cartoony.svg",
    "46-046_Welsh_Corgi-Flat.svg",
    "46-046_Welsh_Corgi-Retro.svg",
    "46-046_Welsh_Corgi-Yellow.svg",
    "47-047_Bullmastiff_pup-Cartoony.svg",
    "47-047_Bullmastiff_pup-Flat.svg",
    "47-047_Bullmastiff_pup-Retro.svg",
    "47-047_Bullmastiff_pup-Yellow.svg",
    "47-047_Cat_in_A_Mug-Cartoony.svg",
    "47-047_Cat_in_A_Mug-Flat.svg",
    "47-047_Cat_in_A_Mug-Retro.svg",
    "47-047_Cat_in_A_Mug-Yellow.svg",
    "47-047_Happy_Bot-Cartoony.svg",
    "47-047_Happy_Bot-Flat.svg",
    "47-047_Happy_Bot-Retro.svg",
    "47-047_Happy_Bot-Yellow.svg",
    "48-048_Happy_Cat-Cartoony.svg",
    "48-048_Happy_Cat-Flat.svg",
    "48-048_Happy_Cat-Retro.svg",
    "48-048_Happy_Cat-Yellow.svg",
    "48-048_Pointer-Cartoony.svg",
    "48-048_Pointer-Flat.svg",
    "48-048_Pointer-Retro.svg",
    "48-048_Pointer-Yellow.svg",
    "48-048_Sad_Bot-Cartoony.svg",
    "48-048_Sad_Bot-Flat.svg",
    "48-048_Sad_Bot-Retro.svg",
    "48-048_Sad_Bot-Yellow.svg",
    "49-049_Doberman-Cartoony.svg",
    "49-049_Doberman-Flat.svg",
    "49-049_Doberman-Retro.svg",
    "49-049_Doberman-Yellow.svg",
    "49-049_Happy_Cat-Cartoony.svg",
    "49-049_Happy_Cat-Flat.svg",
    "49-049_Happy_Cat-Retro.svg",
    "49-049_Happy_Cat-Yellow.svg",
    "49-049_Kiss_Bot-Cartoony.svg",
    "49-049_Kiss_Bot-Flat.svg",
    "49-049_Kiss_Bot-Retro.svg",
    "49-049_Kiss_Bot-Yellow.svg",
    "5-005_Bull_Terrier-Cartoony.svg",
    "5-005_Bull_Terrier-Flat.svg",
    "5-005_Bull_Terrier-Retro.svg",
    "5-005_Bull_Terrier-Yellow.svg",
    "5-005_Cat_In_A_Box-Cartoony.svg",
    "5-005_Cat_In_A_Box-Flat.svg",
    "5-005_Cat_In_A_Box-Retro.svg",
    "5-005_Cat_In_A_Box-Yellow.svg",
    "5-005_Frog-Cartoony.svg",
    "5-005_Frog-Flat.svg",
    "5-005_Frog-Retro.svg",
    "5-005_Frog-Yellow.svg",
    "5-005_Gargoyle-Cartoony.svg",
    "5-005_Gargoyle-Flat.svg",
    "5-005_Gargoyle-Retro.svg",
    "5-005_Gargoyle-Yellow.svg",
    "5-005_Hero-Cartoony.svg",
    "5-005_Hero-Flat.svg",
    "5-005_Hero-Retro.svg",
    "5-005_Hero-Yellow.svg",
    "5-005_Lady_Bot_kiss-Cartoony.svg",
    "5-005_Lady_Bot_kiss-Flat.svg",
    "5-005_Lady_Bot_kiss-Retro.svg",
    "5-005_Lady_Bot_kiss-Yellow.svg",
    "5-005_Mystic_Girl-Cartoony.svg",
    "5-005_Mystic_Girl-Flat.svg",
    "5-005_Mystic_Girl-Retro.svg",
    "5-005_Mystic_Girl-Yellow.svg",
    "50-050_Happy_Cat-Cartoony.svg",
    "50-050_Happy_Cat-Flat.svg",
    "50-050_Happy_Cat-Retro.svg",
    "50-050_Happy_Cat-Yellow.svg",
    "50-050_Loading_Bot-Cartoony.svg",
    "50-050_Loading_Bot-Flat.svg",
    "50-050_Loading_Bot-Retro.svg",
    "50-050_Loading_Bot-Yellow.svg",
    "50-050_Manchester_Terrier-Cartoony.svg",
    "50-050_Manchester_Terrier-Flat.svg",
    "50-050_Manchester_Terrier-Retro.svg",
    "50-050_Manchester_Terrier-Yellow.svg",
    "51-051_Bulldog-Cartoony.svg",
    "51-051_Bulldog-Flat.svg",
    "51-051_Bulldog-Retro.svg",
    "51-051_Bulldog-Yellow.svg",
    "52-052_Irish_Terrier-Cartoony.svg",
    "52-052_Irish_Terrier-Flat.svg",
    "52-052_Irish_Terrier-Retro.svg",
    "52-052_Irish_Terrier-Yellow.svg",
    "53-053_Irish_Terrier-Cartoony.svg",
    "53-053_Irish_Terrier-Flat.svg",
    "53-053_Irish_Terrier-Retro.svg",
    "53-053_Irish_Terrier-Yellow.svg",
    "54-054_Bulldog-Cartoony.svg",
    "54-054_Bulldog-Flat.svg",
    "54-054_Bulldog-Retro.svg",
    "54-054_Bulldog-Yellow.svg",
    "55-055_Bichon_Frise-Cartoony.svg",
    "55-055_Bichon_Frise-Flat.svg",
    "55-055_Bichon_Frise-Retro.svg",
    "55-055_Bichon_Frise-Yellow.svg",
    "56-056_Shiba_Inu-Cartoony.svg",
    "56-056_Shiba_Inu-Flat.svg",
    "56-056_Shiba_Inu-Retro.svg",
    "56-056_Shiba_Inu-Yellow.svg",
    "57-057_Poodle-Cartoony.svg",
    "57-057_Poodle-Flat.svg",
    "57-057_Poodle-Retro.svg",
    "57-057_Poodle-Yellow.svg",
    "58-058_Poodle-Cartoony.svg",
    "58-058_Poodle-Flat.svg",
    "58-058_Poodle-Retro.svg",
    "58-058_Poodle-Yellow.svg",
    "59-059_Poodle-Cartoony.svg",
    "59-059_Poodle-Flat.svg",
    "59-059_Poodle-Retro.svg",
    "59-059_Poodle-Yellow.svg",
    "6-006_Bullmastiff-Cartoony.svg",
    "6-006_Bullmastiff-Flat.svg",
    "6-006_Bullmastiff-Retro.svg",
    "6-006_Bullmastiff-Yellow.svg",
    "6-006_Cat_In_A_Box-Cartoony.svg",
    "6-006_Cat_In_A_Box-Flat.svg",
    "6-006_Cat_In_A_Box-Retro.svg",
    "6-006_Cat_In_A_Box-Yellow.svg",
    "6-006_Hero-Cartoony.svg",
    "6-006_Hero-Flat.svg",
    "6-006_Hero-Retro.svg",
    "6-006_Hero-Yellow.svg",
    "6-006_Lion-Cartoony.svg",
    "6-006_Lion-Flat.svg",
    "6-006_Lion-Retro.svg",
    "6-006_Lion-Yellow.svg",
    "6-006_Love_Lady_Bot-Cartoony.svg",
    "6-006_Love_Lady_Bot-Flat.svg",
    "6-006_Love_Lady_Bot-Retro.svg",
    "6-006_Love_Lady_Bot-Yellow.svg",
    "6-006_Mystic_Girl-Cartoony.svg",
    "6-006_Mystic_Girl-Flat.svg",
    "6-006_Mystic_Girl-Retro.svg",
    "6-006_Mystic_Girl-Yellow.svg",
    "6-006_Three_Eyes-Cartoony.svg",
    "6-006_Three_Eyes-Flat.svg",
    "6-006_Three_Eyes-Retro.svg",
    "6-006_Three_Eyes-Yellow.svg",
    "60-060_labrador-Cartoony.svg",
    "60-060_labrador-Flat.svg",
    "60-060_labrador-Retro.svg",
    "60-060_labrador-Yellow.svg",
    "61-061_Pug-Cartoony.svg",
    "61-061_Pug-Flat.svg",
    "61-061_Pug-Retro.svg",
    "61-061_Pug-Yellow.svg",
    "62-062_labrador_Puppy-Cartoony.svg",
    "62-062_labrador_Puppy-Flat.svg",
    "62-062_labrador_Puppy-Retro.svg",
    "62-062_labrador_Puppy-Yellow.svg",
    "63-063_Boston_Terrier-Cartoony.svg",
    "63-063_Boston_Terrier-Flat.svg",
    "63-063_Boston_Terrier-Retro.svg",
    "63-063_Boston_Terrier-Yellow.svg",
    "64-064_English_Pointer-Cartoony.svg",
    "64-064_English_Pointer-Flat.svg",
    "64-064_English_Pointer-Retro.svg",
    "64-064_English_Pointer-Yellow.svg",
    "65-065_Chihuahua-Cartoony.svg",
    "65-065_Chihuahua-Flat.svg",
    "65-065_Chihuahua-Retro.svg",
    "65-065_Chihuahua-Yellow.svg",
    "66-066_Bull_Terrier-Cartoony.svg",
    "66-066_Bull_Terrier-Flat.svg",
    "66-066_Bull_Terrier-Retro.svg",
    "66-066_Bull_Terrier-Yellow.svg",
    "67-067_Cardigan_Welsh_Corgi-Cartoony.svg",
    "67-067_Cardigan_Welsh_Corgi-Flat.svg",
    "67-067_Cardigan_Welsh_Corgi-Retro.svg",
    "67-067_Cardigan_Welsh_Corgi-Yellow.svg",
    "68-068_St_Bernard-Cartoony.svg",
    "68-068_St_Bernard-Flat.svg",
    "68-068_St_Bernard-Retro.svg",
    "68-068_St_Bernard-Yellow.svg",
    "69-069_Poodle-Cartoony.svg",
    "69-069_Poodle-Flat.svg",
    "69-069_Poodle-Retro.svg",
    "69-069_Poodle-Yellow.svg",
    "7-007_Bullmastiff-Cartoony.svg",
    "7-007_Bullmastiff-Flat.svg",
    "7-007_Bullmastiff-Retro.svg",
    "7-007_Bullmastiff-Yellow.svg",
    "7-007_Cat_Hero-Cartoony.svg",
    "7-007_Cat_Hero-Flat.svg",
    "7-007_Cat_Hero-Retro.svg",
    "7-007_Cat_Hero-Yellow.svg",
    "7-007_Cat_In_A_Box-Cartoony.svg",
    "7-007_Cat_In_A_Box-Flat.svg",
    "7-007_Cat_In_A_Box-Retro.svg",
    "7-007_Cat_In_A_Box-Yellow.svg",
    "7-007_Cat-Cartoony.svg",
    "7-007_Cat-Flat.svg",
    "7-007_Cat-Retro.svg",
    "7-007_Cat-Yellow.svg",
    "7-007_Ninja_Girl-Cartoony.svg",
    "7-007_Ninja_Girl-Flat.svg",
    "7-007_Ninja_Girl-Retro.svg",
    "7-007_Ninja_Girl-Yellow.svg",
    "7-007_Puppy_Bot-Cartoony.svg",
    "7-007_Puppy_Bot-Flat.svg",
    "7-007_Puppy_Bot-Retro.svg",
    "7-007_Puppy_Bot-Yellow.svg",
    "7-007_Sharky-Cartoony.svg",
    "7-007_Sharky-Flat.svg",
    "7-007_Sharky-Retro.svg",
    "7-007_Sharky-Yellow.svg",
    "70-070_Labrador_Puppy-Cartoony.svg",
    "70-070_Labrador_Puppy-Flat.svg",
    "70-070_Labrador_Puppy-Retro.svg",
    "70-070_Labrador_Puppy-Yellow.svg",
    "8-008_Blob-Cartoony.svg",
    "8-008_Blob-Flat.svg",
    "8-008_Blob-Retro.svg",
    "8-008_Blob-Yellow.svg",
    "8-008_Bullmastiff_pup-Cartoony.svg",
    "8-008_Bullmastiff_pup-Flat.svg",
    "8-008_Bullmastiff_pup-Retro.svg",
    "8-008_Bullmastiff_pup-Yellow.svg",
    "8-008_Cat_Bot-Cartoony.svg",
    "8-008_Cat_Bot-Flat.svg",
    "8-008_Cat_Bot-Retro.svg",
    "8-008_Cat_Bot-Yellow.svg",
    "8-008_Cat_In_A_Box-Cartoony.svg",
    "8-008_Cat_In_A_Box-Flat.svg",
    "8-008_Cat_In_A_Box-Retro.svg",
    "8-008_Cat_In_A_Box-Yellow.svg",
    "8-008_Dog-Cartoony.svg",
    "8-008_Dog-Flat.svg",
    "8-008_Dog-Retro.svg",
    "8-008_Dog-Yellow.svg",
    "8-008_Hero-Cartoony.svg",
    "8-008_Hero-Flat.svg",
    "8-008_Hero-Retro.svg",
    "8-008_Hero-Yellow.svg",
    "8-008_Rebel_Girl-Cartoony.svg",
    "8-008_Rebel_Girl-Flat.svg",
    "8-008_Rebel_Girl-Retro.svg",
    "8-008_Rebel_Girl-Yellow.svg",
    "9-009_Bull-Cartoony.svg",
    "9-009_Bull-Flat.svg",
    "9-009_Bull-Retro.svg",
    "9-009_Bull-Yellow.svg",
    "9-009_Bullmastiff-Cartoony.svg",
    "9-009_Bullmastiff-Flat.svg",
    "9-009_Bullmastiff-Retro.svg",
    "9-009_Bullmastiff-Yellow.svg",
    "9-009_Cat_Girl-Cartoony.svg",
    "9-009_Cat_Girl-Flat.svg",
    "9-009_Cat_Girl-Retro.svg",
    "9-009_Cat_Girl-Yellow.svg",
    "9-009_Dog_Bot-Cartoony.svg",
    "9-009_Dog_Bot-Flat.svg",
    "9-009_Dog_Bot-Retro.svg",
    "9-009_Dog_Bot-Yellow.svg",
    "9-009_Futuristic_Hero-Cartoony.svg",
    "9-009_Futuristic_Hero-Flat.svg",
    "9-009_Futuristic_Hero-Retro.svg",
    "9-009_Futuristic_Hero-Yellow.svg",
    "9-009_Happy_Cat-Cartoony.svg",
    "9-009_Happy_Cat-Flat.svg",
    "9-009_Happy_Cat-Retro.svg",
    "9-009_Happy_Cat-Yellow.svg",
    "9-009_Horned_Devil-Cartoony.svg",
    "9-009_Horned_Devil-Flat.svg",
    "9-009_Horned_Devil-Retro.svg",
    "9-009_Horned_Devil-Yellow.svg",
]