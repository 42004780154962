import {
    ChallengeSolutions,
    ChallengeStatistics,
    CodeCommentPostResult,
    CodeComments, CommentForCodeComment, CommentForSolutionRating, RandomSolution, RankTheCodeUser, RatedSolutions,
    RatingPost,
    Ratings, ReceivedSolutionRatings,
    Solution, OtherSolutionRatings
} from "./RankTheCodeServiceResponses";
import {MockResponse} from "../Utilities";

export class RankTheCodeServiceStub {
    constructor(auth) {
        this.authService = auth;
    }

    getOtherSolutionRatings(taskId, view, pageId = 1) {
        let result = new OtherSolutionRatings();
        result.pagination = { "pageID": Math.min(4, pageId), "numPages": 3,};
        setTimeout(function () {
            if (taskId === '0ef7ada0-068a-4c75-87c2-ec08e8d15676')
            {
                result.apiData = [
                    {
                        "timeSolved": "2018-12-10T13:49:51.141Z",
                        "publicUserId": "6",
                        "solutionId": "2305678",
                        "averageRating": 0.8,
                        "ratings": [
                            {"rate": 5, "publicUserId": "1", "comment": "Nicer Code"},
                            {"rate": 3, "publicUserId": "2", "comment": "Cool"},
                            {"rate": 3, "publicUserId": "6", "comment": "Coolio"}
                        ],
                        "quantityOfComments": 8,
                        "codeComments": [
                            {
                                id: "comment_1",
                                user: {
                                    publicId: "2"
                                },
                                message: "Bitte Dockerfile hinzufügen!",
                                range: {startLineNumber: 3, startColumn: 5, endLineNumber: 5, endColumn: 28},
                                judgement: "SMILE",
                                comments: [
                                    {
                                        "id": 1,
                                        "publicUserId": "3",
                                        "text": "i like!",
                                    },
                                    {
                                        "id": 2,
                                        "publicUserId": "4",
                                        "text": "i don't like!",
                                    },
                                ],
                            }
                        ],
                    },
                    {
                        "timeSolved": "2018-11-10T13:49:51.141Z",
                        "publicUserId": "4",
                        "solutionId": "2315672",
                        "averageRating": 1.2,
                        "ratings": [
                            {"rate": 5, "publicUserId": "1", "comment": "Nicer Code"},
                            {"rate": 3, "publicUserId": "2", "comment": "Cool"},
                            {"rate": 3, "publicUserId": "6", "comment": "Coolio"}
                        ],
                        "quantityOfComments": 4,
                        "codeComments": [
                            {
                                id: "comment_1",
                                user: {
                                    publicId: "2"
                                },
                                message: "Bitte Dockerfile hinzufügen!",
                                range: {startLineNumber: 3, startColumn: 5, endLineNumber: 5, endColumn: 28},
                                judgement: "SMILE",
                                comments: [
                                    {
                                        "id": 1,
                                        "publicUserId": "3",
                                        "text": "i like!",
                                    },
                                    {
                                        "id": 2,
                                        "publicUserId": "4",
                                        "text": "i don't like!",
                                    },
                                ],
                            }
                        ],
                    },
                    {
                        "timeSolved": "2018-12-11T13:49:51.141Z",
                        "publicUserId": "5",
                        "solutionId": "2349624",
                        "averageRating": 4.9,
                        "ratings": [
                            {"rate": 5, "publicUserId": "1", "comment": "Nicer Code"},
                            {"rate": 3, "publicUserId": "2", "comment": "Cool"},
                        ],
                        "quantityOfComments": 3,
                        "codeComments": [
                            {
                                id: "comment_1",
                                user: {
                                    publicId: "2"
                                },
                                message: "Bitte Dockerfile hinzufügen!",
                                range: {startLineNumber: 3, startColumn: 5, endLineNumber: 5, endColumn: 28},
                                judgement: "SMILE",
                                comments: [
                                    {
                                        "id": 1,
                                        "publicUserId": "3",
                                        "text": "i like!",
                                    },
                                    {
                                        "id": 2,
                                        "publicUserId": "4",
                                        "text": "i don't like!",
                                    },
                                ],
                            }
                        ],
                    },
                ];
            }
            else
            {
                result.apiData = [
                    {
                        "timeSolved": "2018-11-10T13:49:51.141Z",
                        "publicUserId": "8",
                        "solutionId": "2385678",
                        "averageRating": 3.8,
                        "ratings": [
                            {"rate": 5, "publicUserId": "1", "comment": "Nicer Code"},
                            {"rate": 3, "publicUserId": "2", "comment": "Cool"},
                            {"rate": 3, "publicUserId": "6", "comment": "Coolio"}
                        ],
                        "quantityOfComments": 3,
                        "codeComments": [
                            {
                                id: "comment_1",
                                user: {
                                    publicId: "2"
                                },
                                message: "Bitte Dockerfile hinzufügen!",
                                range: {startLineNumber: 3, startColumn: 5, endLineNumber: 5, endColumn: 28},
                                judgement: "SMILE",
                                comments: [
                                    {
                                        "id": 1,
                                        "publicUserId": "3",
                                        "text": "i like!",
                                    },
                                    {
                                        "id": 2,
                                        "publicUserId": "4",
                                        "text": "i don't like!",
                                    },
                                ],
                            }
                        ],
                    },
                    {
                        "timeSolved": "2017-11-10T13:49:51.141Z",
                        "publicUserId": "3",
                        "solutionId": "2347672",
                        "averageRating": 2.2,
                        "ratings": [
                            {"rate": 5, "publicUserId": "1", "comment": "Nicer Code"},
                            {"rate": 3, "publicUserId": "2", "comment": "Cool"},
                            {"rate": 3, "publicUserId": "6", "comment": "Coolio"}
                        ],
                        "quantityOfComments": 4,
                        "codeComments": [
                            {
                                id: "comment_1",
                                user: {
                                    publicId: "2"
                                },
                                message: "Bitte Dockerfile hinzufügen!",
                                range: {startLineNumber: 3, startColumn: 5, endLineNumber: 5, endColumn: 28},
                                judgement: "SMILE",
                                comments: [
                                    {
                                        "id": 1,
                                        "publicUserId": "3",
                                        "text": "i like!",
                                    },
                                    {
                                        "id": 2,
                                        "publicUserId": "4",
                                        "text": "i don't like!",
                                    },
                                ],
                            }
                        ],
                    },
                    {
                        "timeSolved": "2016-12-11T13:49:51.141Z",
                        "publicUserId": "9",
                        "solutionId": "2365624",
                        "averageRating": 1.9,
                        "ratings": [
                            {"rate": 5, "publicUserId": "1", "comment": "Nicer Code"},
                            {"rate": 3, "publicUserId": "2", "comment": "Cool"},
                        ],
                        "quantityOfComments": 13,
                        "codeComments": [
                            {
                                id: "comment_1",
                                user: {
                                    publicId: "2"
                                },
                                message: "Bitte Dockerfile hinzufügen!",
                                range: {startLineNumber: 3, startColumn: 5, endLineNumber: 5, endColumn: 28},
                                judgement: "SMILE",
                                comments: [
                                    {
                                        "id": 1,
                                        "publicUserId": "3",
                                        "text": "i like!",
                                    },
                                    {
                                        "id": 2,
                                        "publicUserId": "4",
                                        "text": "i don't like!",
                                    },
                                ],
                            }
                        ],
                    },
                    {
                        "timeSolved": "2015-12-11T13:49:51.141Z",
                        "publicUserId": "1",
                        "solutionId": "2545624",
                        "averageRating": 4.9,
                        "ratings": [
                            {"rate": 5, "publicUserId": "1", "comment": "Nicer Code"},
                            {"rate": 3, "publicUserId": "2", "comment": "Cool"},
                        ],
                        "quantityOfComments": 2,
                        "codeComments": [
                            {
                                id: "comment_1",
                                user: {
                                    publicId: "2"
                                },
                                message: "Bitte Dockerfile hinzufügen!",
                                range: {startLineNumber: 3, startColumn: 5, endLineNumber: 5, endColumn: 28},
                                judgement: "SMILE",
                                comments: [],
                            }
                        ],
                    }
                ];
            }

            if (view === 'new') {
                result.apiData .sort((a, b) => (a['timeSolved'] < b['timeSolved']) ? 1 : -1);
            }
            else if (view === 'bestRated') {
                result.apiData .sort((a, b) => (a['averageRating'] < b['averageRating']) ? 1 : -1);
            }
            else if (view === 'mostComments') {
                result.apiData .sort((a, b) => (a['quantityOfComments'] < b['quantityOfComments']) ? 1 : -1);
            }
            result.loaded = true;
        }, 1000 + Math.random() * 1000);
        return result;
    }

    getOtherSolutionRatingsById(solutionId) {
        let result = new OtherSolutionRatings();
        setTimeout(function () {
            result.apiData = {
                "timeSolved": "2018-12-10T13:49:51.141Z",
                "publicUserId": "6",
                "solutionId": "2305678",
                "averageRating": 0.8,
                "ratings": [
                    {"rate": 5, "publicUserId": "1", "comment": "Nicer Code"},
                    {"rate": 3, "publicUserId": "2", "comment": "Cool"},
                    {"rate": 3, "publicUserId": "6", "comment": "Coolio"}
                ],
                "quantityOfComments": 8,
                "codeComments": [
                    {
                        id: "comment_1",
                        user: {
                            publicId: "2"
                        },
                        message: "Bitte Dockerfile hinzufügen!",
                        range: {startLineNumber: 3, startColumn: 5, endLineNumber: 5, endColumn: 28},
                        judgement: "SMILE",
                        comments: [
                            {
                                "id": 1,
                                "publicUserId": "3",
                                "text": "i like!",
                            },
                            {
                                "id": 2,
                                "publicUserId": "4",
                                "text": "i don't like!",
                            },
                        ],
                    }
                ],
            };
            result.loaded = true;
        }, 1000 + Math.random() * 1000);
        return result;
    }

    //get List of Solutions from user Id and Challenge Id
    getSolutionsForChallenge(challengeId) {
        let result = new ChallengeSolutions();
        setTimeout(function () {
            result.apiData = [
                {
                    "solutionId": "solution_id_2",
                    "challengeId": "82ee47f5-36f9-4b61-8b1b-3aab8eca0ea5",
                    "taskId": "0b465303-ae29-44fc-9d17-e61732585a01",
                    "timeSolved": "2022-08-18T12:55:42.168542Z",
                    "filePath": "/solution.py",
                    "publicUserId": "1",
                },
                {
                    "solutionId": "solution_id_3",
                    "challengeId": "82ee47f5-36f9-4b61-8b1b-3aab8eca0ea5",
                    "taskId": "0ef7ada0-068a-4c75-87c2-ec08e8d15676",
                    "timeSolved": "2022-08-18T12:55:42.168542Z",
                    "filePath": "/solution.py",
                    "publicUserId": "1",
                }
              ];
            result.loaded = true;
        }, 100);
        return result;
    }

    //get List of Solutions for a public user Id and Challenge Id
    getSolutionsOfUserForChallenge(challengeId, public_user_id) {
        let result = new ChallengeSolutions();
        setTimeout(function () {
            result.apiData = [
                {
                    "solutionId": "solution_id_2",
                    "challengeId": challengeId,
                    "taskId": "0b465303-ae29-44fc-9d17-e61732585a01",
                    "timeSolved": "2022-08-18T12:55:42.168542Z",
                    "filePath": "/solution.py",
                    "publicUserId": public_user_id,
                },
                {
                    "solutionId": "solution_id_3",
                    "challengeId": challengeId,
                    "taskId": "0ef7ada0-068a-4c75-87c2-ec08e8d15676",
                    "timeSolved": "2022-08-18T12:55:42.168542Z",
                    "filePath": "/solution.py",
                    "publicUserId": public_user_id,
                }
              ];
            result.loaded = true;
        }, 100);
        return result;
    }

    //zu einer Challenge alle Statistics
    getChallengeStatistics(challengeId) {
        let result = new ChallengeStatistics();
        setTimeout(function () {
            result.apiData = {
                "averageRating": 3.5,
                "quantityOfRatings": 36,
                "quantityOfComments": 19,
            };
            result.loaded = true;
        }, 100);
        return result;
    }
    //zu einer Lösung alle Bewertungen
    getSolutionRatings(solutionId) {
        let result = new Ratings();
        setTimeout(function () {
            result.apiData = {
                "ratings": [
                    {"solutionId": "2349624", "averageRating": 3, "quantityOfComments": 5, "rate": 5, "publicUserId": "1", "comment": "Nicer Code", "timeCreated": "2022-08-18T12:55:42.168542Z", "comments": [{"id":"37302a18-a529-433d-ac29-c11aad34e92d","publicUserId":"8028ca5d1c6165a975d8bc903a8ce61d","text":"ich freu mich","isYourComment":true,"timeCreated":"2022-11-28 13:25:50.126505+00:00"},{"id":"d7c50212-11de-4e71-a826-29ec42442b83","publicUserId":"5","text":"mach doch","isYourComment":false,"timeCreated":"2022-11-28 13:57:50.128658+00:00"}]},
                    {"solutionId": "2349624", "averageRating": 1, "quantityOfComments": 1, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2022-08-14T12:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 2.6, "quantityOfComments": 2, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2022-07-18T12:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 3.4, "quantityOfComments": 3, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2022-08-17T12:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 5, "quantityOfComments": 4, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2022-08-18T13:52:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 4, "quantityOfComments": 5, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 1, "quantityOfComments": 4, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 2, "quantityOfComments": 3, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 3, "quantityOfComments": 2, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 4, "quantityOfComments": 1, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 5, "quantityOfComments": 0, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 4, "quantityOfComments": 0, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 3, "quantityOfComments": 0, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 2, "quantityOfComments": 0, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 1, "quantityOfComments": 0, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 2, "quantityOfComments": 0, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 3, "quantityOfComments": 1, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 2, "quantityOfComments": 2, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 1, "quantityOfComments": 3, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 3, "quantityOfComments": 15, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 1, "quantityOfComments": 9, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 3, "quantityOfComments": 1, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                    {"solutionId": "2349624", "averageRating": 1, "quantityOfComments": 0, "rate": 3, "publicUserId": "2", "comment": "Cool", "timeCreated": "2020-09-24T16:55:42.168542Z"},
                ],
                "yourRating": {
                    "rate": 5, "publicUserId": "6296f40d95c3fdeb12216d525f44f36d", "comment": "Nicer Code",
                }
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    postRating(solutionId, rating, comment) {
        let result = new RatingPost();
        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 100);
        return result;
    }

    getCodeComments(solutionId) {
        let result = new CodeComments();

        setTimeout(function () {
            result.apiData = [
                {
                    id: "comment_1",
                    user: {
                        publicId: "1",
                    },
                    timeCreated: "2022-08-18T12:55:42.168542Z",
                    message: "Nicht perfekt aber alles gut!",
                    range: {startLineNumber: 1, startColumn: 1, endLineNumber: 1, endColumn: 22},
                    judgement: "SLIGHTLY_FROWNING_FACE",
                    comments: [{
                        "id": 1,
                        "publicUserId": "3",
                        "timeCreated": "2022-08-18T12:55:42.168542Z",
                        "text": "i like!",
                    },
                        {
                            "id": 2,
                            "publicUserId": "4",
                            "timeCreated": "2022-08-18T12:55:42.168542Z",
                            "text": "i don't like!",
                        },]
                },
                {
                    id: "comment_2",
                    user: {
                        publicId: "2"
                    },
                    timeCreated: "2022-08-30T12:55:42.168542Z",
                    message: "Bitte Dockerfile hinzufügen!",
                    range: {startLineNumber: 3, startColumn: 5, endLineNumber: 5, endColumn: 28},
                    judgement: "SMILE",
                    comments: [],
                }
            ];
            result.loaded = true;
        }, 100);

        return result;
    }

    postCodeComment(taskId, range, judgement, comment) {
        let result = new CodeCommentPostResult();

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 100);

        return result;
    }

    postCommentForCodeComment(codeCommentId, text) {
        let result = new CommentForCodeComment();

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 100);

        return result;
    }

    postCommentForSolutionRating(solutionRatingId, text) {
        let result = new CommentForSolutionRating();

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 100);

        return result;
    }

    getRatedSolutions() {
        let result = new RatedSolutions();

        setTimeout(function () {
            result.apiData = [
                {
                    "solutionId": "solution_id_1",
                    "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                    "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
                    "filePath": "/solution.js",
                    "publicUserId": "1",
                    "rate": "2",
                },
                {
                    "solutionId": "solution_id_1",
                    "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                    "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
                    "filePath": "/solution.js",
                    "publicUserId": "2",
                    "rate": "2",
                },
                {
                    "solutionId": "solution_id_1",
                    "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                    "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
                    "filePath": "/solution.js",
                    "publicUserId": "3",
                    "rate": "2",
                },
                {
                    "solutionId": "solution_id_1",
                    "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                    "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
                    "filePath": "/solution.js",
                    "publicUserId": "4",
                    "rate": "2",
                },
                {
                    "solutionId": "solution_id_1",
                    "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                    "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
                    "filePath": "/solution.js",
                    "publicUserId": "5",
                    "rate": "2",
                },
                {
                    "solutionId": "solution_id_1",
                    "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                    "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
                    "filePath": "/solution.js",
                    "publicUserId": "6",
                    "rate": "2",
                },
                {
                    "solutionId": "solution_id_1",
                    "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                    "taskId": "0b465303-ae29-44fc-9d17-e61732585a01",
                    "filePath": "/solution.js",
                    "publicUserId": "7",
                    "rate": "2",
                },
                {
                    "solutionId": "solution_id_1",
                    "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                    "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
                    "filePath": "/solution.js",
                    "publicUserId": "8",
                    "rate": "2",
                },
            ];
            result.loaded = true;
        }, 100);

        return result;
    }

    receivedRatedSolutions() {
        let result = new ReceivedSolutionRatings();

        setTimeout(function () {
            result.apiData = [
                {
                    "solutionId": "solution_id_1",
                    "challengeId": "6799bb44-e79c-4288-a961-26c9d5fa2fd7",
                    "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
                    "filePath": "/solution.js",
                    "averageScore": 4.5
                },
                {
                    "solutionId": "solution_id_2",
                    "challengeId": "82ee47f5-36f9-4b61-8b1b-3aab8eca0ea5",
                    "taskId": "0b465303-ae29-44fc-9d17-e61732585a01",
                    "filePath": "/solution.js",
                    "averageScore": 3.0
                },
                {
                    "solutionId": "solution_id_3",
                    "challengeId": "82ee47f5-36f9-4b61-8b1b-3aab8eca0ea5",
                    "taskId": "0ef7ada0-068a-4c75-87c2-ec08e8d15676",
                    "filePath": "/solution.js",
                    "averageScore": 3.3

                }
            ];
            result.loaded = true;
        }, 100);

        return result;
    }

    getRandomSolution(challengeId, excludeAlreadyRated) {
        let result = new RandomSolution();

        setTimeout(function () {
            result.apiData =
                    {
                        "solutionId": "89abca73-c45b-4dfe-88f8-9603d3e46cc9",
                        "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                        "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
                        "filePath": "/solution.js",
                        "publicUserId": "1",
                        "isUsersSolution": false,
                        "usersRating": {
                            "rate": 5,
                            "comment": "Nicer Code"
                        },
                        "code": "class Area(object):\n" +
                        "    def __init__(self, map):\n" +
                        "        self.map = map\n" +
                        "\n" +
                        "    def get_point(self, x, y):\n" +
                        "        for found_y, row in enumerate(self.map):\n" +
                        "            if found_y != y:\n" +
                        "                continue\n" +
                        "            for found_x, cell in enumerate(row):\n" +
                        "                if found_x == x:\n" +
                        "                    return cell\n" +
                        "\n" +
                        "    def set_point(self, x, y, value=0):\n" +
                        "        self.map[y][x] = value\n" +
                        "\n" +
                        "\n" +
                        "class AreaHelper(object):\n" +
                        "    @staticmethod\n" +
                        "    def fill_area(area: Area, point_in_area: (int, int)) -> Area:\n" +
                        "        def split_row(rowmap):\n" +
                        "            # returns indices inside the borders\n" +
                        "            # get all borders\n" +
                        "            lenx = len(rowmap)\n" +
                        "            row_idx = range(lenx)\n" +
                        "            border_idx = [idx for idx, val in enumerate(rowmap) if val == 1]\n" +
                        "            left = border_idx[:-1]\n" +
                        "            right = border_idx[1:]\n" +
                        "            return [row_idx[pair[0]+1:pair[1]] for pair in zip(left,right)]\n" +
                        "\n" +
                        "        def fuse_rows(yx_cand, yx_area, ystart, ystop, sign):\n" +
                        "            for yy in range(ystart,ystop,sign):\n" +
                        "                for x_list in yx_cand[yy]:\n" +
                        "                    for xx in yx_area[yy-sign]:\n" +
                        "                    #for xx in itertools.chain.from_iterable(yx_area[yy-sign]):\n" +
                        "                        if xx in x_list:\n" +
                        "                            yx_area[yy] += x_list\n" +
                        "                            yx_cand[yy].remove(x_list)\n" +
                        "                            break   \n" +
                        "\n" +
                        "        # detect upper and lower border\n" +
                        "        leny = len(area.map)\n" +
                        "        lenx = len(area.map[0])\n" +
                        "        row_sum = [sum(row) for row in area.map]\n" +
                        "        row_ful = [idx for idx, val in enumerate(row_sum) if val > 0]\n" +
                        "        border_up = max(row_ful)\n" +
                        "        border_do = min(row_ful)\n" +
                        "        yx_cand = [[] for i in range(leny)]        \n" +
                        "        yx_area = [[] for i in range(leny)]\n" +
                        "        for yy in range(border_do+1,border_up):\n" +
                        "            # get fill candidates from row splitting\n" +
                        "            yx_cand[yy] = split_row(area.map[yy])\n" +
                        "\n" +
                        "        #print('cand=', yx_cand)\n" +
                        "        #print('area=', yx_area)\n" +
                        "\n" +
                        "        # starting point given fill the area\n" +
                        "        # otherwise it is assumed that no area can be filled\n" +
                        "        if (point_in_area[0] is not None) and (point_in_area[1] is not None):\n" +
                        "            # starting from point_in_area fuse the xy_list\n" +
                        "            # find row and column with starting point\n" +
                        "            for x_list in yx_cand[point_in_area[1]]:\n" +
                        "                if point_in_area[0] in x_list:\n" +
                        "                    yx_area[point_in_area[1]] += x_list\n" +
                        "                    yx_cand[point_in_area[1]].remove(x_list)\n" +
                        "\n" +
                        "            # now checking for cohesion\n" +
                        "            # move first up to border_up\n" +
                        "            fuse_rows(yx_cand, yx_area, point_in_area[1]+1,border_up, 1)\n" +
                        "            \n" +
                        "            # then down to border_do\n" +
                        "            fuse_rows(yx_cand, yx_area, point_in_area[1]-1,border_do, -1)\n" +
                        "            \n" +
                        "            # and up again to avoid forgetting concave split area\n" +
                        "            fuse_rows(yx_cand, yx_area, border_do+1,border_up, 1)\n" +
                        "            \n" +
                        "            # and down again to avoid forgetting concave split area\n" +
                        "            fuse_rows(yx_cand, yx_area, border_up-1, border_do, -1)\n" +
                        "\n" +
                        "            # last but not least fill arr\n" +
                        "            for yy in range(border_do+1,border_up):\n" +
                        "                for xx in yx_area[yy]:\n" +
                        "                    area.map[yy][xx] = 1\n" +
                        "        \n" +
                        "        return area",
                    };
            result.loaded = true;
        }, 100);

        return result;
    }

    getSolution() {
        let result = new Solution();

        setTimeout(function () {
            result.apiData =
                    {
                        "solutionId": "solution_id_1",
                        "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                        "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
                        "timeSolved": "2022-08-18T12:55:42.168542Z",
                        "filePath": "/solution.py",
                        "publicUserId": "1",
                        "isUsersSolution": false,
                        "usersRating": {
                            "rate": 5,
                            "comment": "Nicer Code"
                        },
                        "code": "class Area(object):\n" +
                        "    def __init__(self, map):\n" +
                        "        self.map = map\n" +
                        "\n" +
                        "    def get_point(self, x, y):\n" +
                        "        for found_y, row in enumerate(self.map):\n" +
                        "            if found_y != y:\n" +
                        "                continue\n" +
                        "            for found_x, cell in enumerate(row):\n" +
                        "                if found_x == x:\n" +
                        "                    return cell\n" +
                        "\n" +
                        "    def set_point(self, x, y, value=0):\n" +
                        "        self.map[y][x] = value\n" +
                        "\n" +
                        "\n" +
                        "class AreaHelper(object):\n" +
                        "    @staticmethod\n" +
                        "    def fill_area(area: Area, point_in_area: (int, int)) -> Area:\n" +
                        "        def split_row(rowmap):\n" +
                        "            # returns indices inside the borders\n" +
                        "            # get all borders\n" +
                        "            lenx = len(rowmap)\n" +
                        "            row_idx = range(lenx)\n" +
                        "            border_idx = [idx for idx, val in enumerate(rowmap) if val == 1]\n" +
                        "            left = border_idx[:-1]\n" +
                        "            right = border_idx[1:]\n" +
                        "            return [row_idx[pair[0]+1:pair[1]] for pair in zip(left,right)]\n" +
                        "\n" +
                        "        def fuse_rows(yx_cand, yx_area, ystart, ystop, sign):\n" +
                        "            for yy in range(ystart,ystop,sign):\n" +
                        "                for x_list in yx_cand[yy]:\n" +
                        "                    for xx in yx_area[yy-sign]:\n" +
                        "                    #for xx in itertools.chain.from_iterable(yx_area[yy-sign]):\n" +
                        "                        if xx in x_list:\n" +
                        "                            yx_area[yy] += x_list\n" +
                        "                            yx_cand[yy].remove(x_list)\n" +
                        "                            break   \n" +
                        "\n" +
                        "        # detect upper and lower border\n" +
                        "        leny = len(area.map)\n" +
                        "        lenx = len(area.map[0])\n" +
                        "        row_sum = [sum(row) for row in area.map]\n" +
                        "        row_ful = [idx for idx, val in enumerate(row_sum) if val > 0]\n" +
                        "        border_up = max(row_ful)\n" +
                        "        border_do = min(row_ful)\n" +
                        "        yx_cand = [[] for i in range(leny)]        \n" +
                        "        yx_area = [[] for i in range(leny)]\n" +
                        "        for yy in range(border_do+1,border_up):\n" +
                        "            # get fill candidates from row splitting\n" +
                        "            yx_cand[yy] = split_row(area.map[yy])\n" +
                        "\n" +
                        "        #print('cand=', yx_cand)\n" +
                        "        #print('area=', yx_area)\n" +
                        "\n" +
                        "        # starting point given fill the area\n" +
                        "        # otherwise it is assumed that no area can be filled\n" +
                        "        if (point_in_area[0] is not None) and (point_in_area[1] is not None):\n" +
                        "            # starting from point_in_area fuse the xy_list\n" +
                        "            # find row and column with starting point\n" +
                        "            for x_list in yx_cand[point_in_area[1]]:\n" +
                        "                if point_in_area[0] in x_list:\n" +
                        "                    yx_area[point_in_area[1]] += x_list\n" +
                        "                    yx_cand[point_in_area[1]].remove(x_list)\n" +
                        "\n" +
                        "            # now checking for cohesion\n" +
                        "            # move first up to border_up\n" +
                        "            fuse_rows(yx_cand, yx_area, point_in_area[1]+1,border_up, 1)\n" +
                        "            \n" +
                        "            # then down to border_do\n" +
                        "            fuse_rows(yx_cand, yx_area, point_in_area[1]-1,border_do, -1)\n" +
                        "            \n" +
                        "            # and up again to avoid forgetting concave split area\n" +
                        "            fuse_rows(yx_cand, yx_area, border_do+1,border_up, 1)\n" +
                        "            \n" +
                        "            # and down again to avoid forgetting concave split area\n" +
                        "            fuse_rows(yx_cand, yx_area, border_up-1, border_do, -1)\n" +
                        "\n" +
                        "            # last but not least fill arr\n" +
                        "            for yy in range(border_do+1,border_up):\n" +
                        "                for xx in yx_area[yy]:\n" +
                        "                    area.map[yy][xx] = 1\n" +
                        "        \n" +
                        "        return area",
                    };
            result.loaded = true;
        }, 100);

        return result;
    }

    getRankTheCodeUser() {
        let result = new RankTheCodeUser();

        setTimeout(function () {
            result.apiData =
                    {
                        "publicUserId": "1",
                    };
            result.loaded = true;
        }, 100);

        return result;
    }

    createRankTheCodeUser() {
        let result = new RankTheCodeUser();

        setTimeout(function () {
            result.apiData =
                    {
                        "publicUserId": "1",
                    };
            result.loaded = true;
        }, 100);

        return result;
    }

    getRandomSolutionPromise(challengeId, excludeAlreadyRated) {
        const data = {
            "solutionId": "89abca73-c45b-4dfe-88f8-9603d3e46cc9",
            "challengeId": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
            "taskId": "1b20123e-0d49-4ea4-a213-59e869675172",
            "filePath": "/solution.js",
            "publicUserId": "1",
            "isUsersSolution": false,
            "usersRating": {
                "rate": 5,
                "comment": "Nicer Code"
            },
            "code": "class Area(object):\n" +
                "    def __init__(self, map):\n" +
                "        self.map = map\n" +
                "\n" +
                "    def get_point(self, x, y):\n" +
                "        for found_y, row in enumerate(self.map):\n" +
                "            if found_y != y:\n" +
                "                continue\n" +
                "            for found_x, cell in enumerate(row):\n" +
                "                if found_x == x:\n" +
                "                    return cell\n" +
                "\n" +
                "    def set_point(self, x, y, value=0):\n" +
                "        self.map[y][x] = value\n" +
                "\n" +
                "\n" +
                "class AreaHelper(object):\n" +
                "    @staticmethod\n" +
                "    def fill_area(area: Area, point_in_area: (int, int)) -> Area:\n" +
                "        def split_row(rowmap):\n" +
                "            # returns indices inside the borders\n" +
                "            # get all borders\n" +
                "            lenx = len(rowmap)\n" +
                "            row_idx = range(lenx)\n" +
                "            border_idx = [idx for idx, val in enumerate(rowmap) if val == 1]\n" +
                "            left = border_idx[:-1]\n" +
                "            right = border_idx[1:]\n" +
                "            return [row_idx[pair[0]+1:pair[1]] for pair in zip(left,right)]\n" +
                "\n" +
                "        def fuse_rows(yx_cand, yx_area, ystart, ystop, sign):\n" +
                "            for yy in range(ystart,ystop,sign):\n" +
                "                for x_list in yx_cand[yy]:\n" +
                "                    for xx in yx_area[yy-sign]:\n" +
                "                    #for xx in itertools.chain.from_iterable(yx_area[yy-sign]):\n" +
                "                        if xx in x_list:\n" +
                "                            yx_area[yy] += x_list\n" +
                "                            yx_cand[yy].remove(x_list)\n" +
                "                            break   \n" +
                "\n" +
                "        # detect upper and lower border\n" +
                "        leny = len(area.map)\n" +
                "        lenx = len(area.map[0])\n" +
                "        row_sum = [sum(row) for row in area.map]\n" +
                "        row_ful = [idx for idx, val in enumerate(row_sum) if val > 0]\n" +
                "        border_up = max(row_ful)\n" +
                "        border_do = min(row_ful)\n" +
                "        yx_cand = [[] for i in range(leny)]        \n" +
                "        yx_area = [[] for i in range(leny)]\n" +
                "        for yy in range(border_do+1,border_up):\n" +
                "            # get fill candidates from row splitting\n" +
                "            yx_cand[yy] = split_row(area.map[yy])\n" +
                "\n" +
                "        #print('cand=', yx_cand)\n" +
                "        #print('area=', yx_area)\n" +
                "\n" +
                "        # starting point given fill the area\n" +
                "        # otherwise it is assumed that no area can be filled\n" +
                "        if (point_in_area[0] is not None) and (point_in_area[1] is not None):\n" +
                "            # starting from point_in_area fuse the xy_list\n" +
                "            # find row and column with starting point\n" +
                "            for x_list in yx_cand[point_in_area[1]]:\n" +
                "                if point_in_area[0] in x_list:\n" +
                "                    yx_area[point_in_area[1]] += x_list\n" +
                "                    yx_cand[point_in_area[1]].remove(x_list)\n" +
                "\n" +
                "            # now checking for cohesion\n" +
                "            # move first up to border_up\n" +
                "            fuse_rows(yx_cand, yx_area, point_in_area[1]+1,border_up, 1)\n" +
                "            \n" +
                "            # then down to border_do\n" +
                "            fuse_rows(yx_cand, yx_area, point_in_area[1]-1,border_do, -1)\n" +
                "            \n" +
                "            # and up again to avoid forgetting concave split area\n" +
                "            fuse_rows(yx_cand, yx_area, border_do+1,border_up, 1)\n" +
                "            \n" +
                "            # and down again to avoid forgetting concave split area\n" +
                "            fuse_rows(yx_cand, yx_area, border_up-1, border_do, -1)\n" +
                "\n" +
                "            # last but not least fill arr\n" +
                "            for yy in range(border_do+1,border_up):\n" +
                "                for xx in yx_area[yy]:\n" +
                "                    area.map[yy][xx] = 1\n" +
                "        \n" +
                "        return area",
        };
        return new MockResponse().respond(data);
    }
}

