<template>
  <footer class="footer">
    <div class="row">
      <div class="col-xs-12">

        &copy; EntwicklerHeld {{year}}. All rights reserved.
        <a href="#">Terms of Service</a> |
        <a href="#">Privacy Policy</a>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'footer-component',
    data() {
      return {
        year: (new Date()).getFullYear()
      }
    },
  }
</script>

<style>

</style>
