export class SetSubscriptionStateResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }

    hasLoaded() {
        return this.loaded && this.error === null;
    }
}

export class GetSubscriptionStateResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }

    hasLoaded() {
        return this.loaded && this.error === null;
    }

    hasError() {
        return this.loaded && this.error !== null;
    }
}

export class GetPlatformNotificationsResult {
    constructor() {
        this.apiData = [];
        this.pagination = {};
        this.error = null;
        this.loaded = false;
    }

    hasLoaded() {
        return this.loaded && this.error === null;
    }

    hasError() {
        return this.loaded && this.error !== null;
    }
}


export class SetPlatformNotificationAsReadResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }

    hasLoaded() {
        return this.loaded && this.error === null;
    }

    hasError() {
        return this.loaded && this.error !== null;
    }
}

export class SetPlatformNotificationAsSeenResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }

    hasLoaded() {
        return this.loaded && this.error === null;
    }

    hasError() {
        return this.loaded && this.error !== null;
    }
}
