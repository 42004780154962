<template>
    <DailyRewardDropModal :isVisible="rewardDropModalVisible"
                          @close="closeModal"/>
</template>

<script>
import DailyRewardDropModal from "./login/DailyRewardDropModal";
import {services} from "../main";

export default {
    name: "DailyRewardListener",
    props: {
        onboardingModalOpen: {
            type: Boolean,
            default: false
        },
        openModalLater: {
            type: Boolean,
            default: false
        }
    },
    components: {DailyRewardDropModal},
    data: function () {
        return {
            rewardDropModalVisible: false,
            userServiceSocket: null,
        }
    },
    created() {
        const userID = services.auth.getUserID();
        this.userServiceSocket = services.notificationsService.getUserServiceSocket();
        this.userServiceSocket.on(`notifications_user_${userID}_user-logged-in`, () => {
            setTimeout(() => {
                if (this.onboardingModalOpen) {
                    this.openModalLater = true;
                    return
                }
                this.openRewardDropModal();
            }, 2000);
        });
    },
    methods: {
        openRewardDropModal: function () {
            this.rewardDropModalVisible = true;
        },
        closeModal() {
            this.rewardDropModalVisible = false;
        },
    },
    watch: {
        onboardingModalOpen: function () {
            if (this.openModalLater) {
                setTimeout(() => {
                    this.openRewardDropModal();
                }, 2000);
            }
        }
    }
}
</script>

<style scoped>

</style>