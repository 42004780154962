import {ActiveCampusList, RankingList, UserContribution} from "./CampusChallengeServiceResponses";


export class CampusChallengeServiceStub {
    constructor(auth) {
        this.authService = auth;
    }

    getUserContribution() {
        let result = new UserContribution();
        setTimeout(function () {
            result.apiData = {
                "university": "U_134",
                "contribution": 2300
            };
            result.loaded = true;
        }, 100);
        return result;
    }

    getRankingList() {
        let result = new RankingList();
        setTimeout(function () {
            result.apiData = [
                {
                    universityCode: "1",
                    university: "Werner-von-Siemens-Gymnasium Weißenburg",
                    score: "1234"
                },
                {
                    universityCode: "2",
                    university: "Hasso-Plattner-Institut Potsdam",
                    score: "1234"
                },
                {
                    universityCode: "3",
                    university: "Uni Frankfurt",
                    score: "1234"
                },
                {
                    universityCode: "4",
                    university: "HS Karlsruhe",
                    score: "1234"
                },
                {
                    universityCode: "5",
                    university: "Universität Bremen",
                    score: "1234"
                },
                {
                    universityCode: "6",
                    university: "TU Dresden",
                    score: "1234"
                },
                {
                    universityCode: "7",
                    university: "RWTH Aachen University",
                    score: "1234"
                },
                {
                    universityCode: "8",
                    university: "OVGU Magdeburg",
                    score: "1234"
                },
                {
                    universityCode: "9",
                    university: "HU Berlin",
                    score: "1234"
                },
                {
                    universityCode: "10",
                    university: "FSU Jena",
                    score: "1234"
                },
                {
                    universityCode: "11",
                    university: "CAU Kiel",
                    score: "1234"
                },
            ];
            result.loaded = true;
        }, 100);
        return result;
    }

    getActiveCampusList() {
        let result = new ActiveCampusList();
        setTimeout(function () {
            result.apiData = [
                "Werner-von-Siemens-Gymnasium Weißenburg",
                "Hasso-Plattner-Institut Potsdam",
                "Uni Frankfurt",
                "HS Karlsruhe",
                "Universität Bremen",
                "TU Dresden",
                "RWTH Aachen University",
                "OVGU Magdeburg",
                "HU Berlin",
                "FSU Jena",
                "CAU Kiel",
            ];
            result.loaded = true;
        }, 100);
        return result;
    }

}

