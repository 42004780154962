import {responseHandler} from '../Utilities.js';
import {
    AnalysisReport,
    HistoryReport,
    PercentagePosition,
} from "./QualityServiceResponses";

export class QualityServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    getHistoryReport(challengeId, userId, file, task) {
        let result = new HistoryReport();
        fetch(window.QUALITY_API_URL + "/api/report/" + challengeId + "/history/" + userId + "/?file=" + file + "&task=" + task, {
            headers: this.authService.getAuthorizationHeader(),
        })
        .then(responseHandler)
        .then((json) => {
            result.history_report_data = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            result.error = error;
            result.loaded = true;
            console.error(error);

        });
        return result;
    }

    getPercentagePosition(challengeId) {
        let result = new PercentagePosition();
        fetch(window.QUALITY_API_URL + "/api/scores/challenge/" + challengeId + "/get_percentage_position/", {
            headers: this.authService.getAuthorizationHeader(),
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            result.error = error;
            result.loaded = true;
            console.error(error);

        });
        return result;
    }

    getAnalysisReport(challengeId) {
        let result = new AnalysisReport();
        fetch(window.QUALITY_API_URL + "/api/scores/challenge/" + challengeId + "/analysis/", {
            headers: this.authService.getAuthorizationHeader(),
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                result.error = error;
                result.loaded = true;
                console.error(error);

            });
        return result;
    }
}
