<template>
    <div class="row" id="navbar_row" ref="topbar">
        <div class="col-xs-12">
            <nav class="navbar navbar-default" v-bind:class="{'navbar_vip':isVip}">
                <div class="container-fluid">
                    <div class="collapse navbar-collapse" id="navbar_main">
                        <img v-if="this.$features.WHITELABEL" :src="this.$whitelabelConfig.logo" class="whitelabelLogo"/>
                        <a v-if="!this.$features.WHITELABEL" href="#" class="btn btn-secondary navbar-btn navbar-left" v-bind:class="{'btn_vip':isVip}"
                           id="sidebar__toggle" @click="toggleSidebar()">
                            <i class="dev-menu"></i>
                        </a>
                      <TopbarCodingWeekendWidget v-if="showCodingWeekendWidget"></TopbarCodingWeekendWidget>
                        <a v-if="!isAuthenticated" href="#" @click="login()" class="login-button btn btn-primary navbar-btn navbar-right">
                            <i class="dev-login">&nbsp;</i>
                            Anmelden
                        </a>
                        <a v-if="this.$features.WHITELABEL && isAuthenticated" href="#" @click="logout()" class="login-button btn btn-primary navbar-btn navbar-right">
                            <i class="dev-login">&nbsp;</i>
                            Abmelden
                        </a>
                        <ul class="nav navbar-nav navbar-right" v-if="!this.$features.WHITELABEL">
                            <PlatformNotificationComponent />
                            <li>
                                <ChatToggleButton />
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    import {services, eventBus} from "../main"
    import {mapState} from 'vuex';
    import PlatformNotificationComponent from "./PlatformNotificationComponent";
    import ChatToggleButton from "../components/message/ChatToggleButton";

    export default {
        name: "topbar",
        components: {
            PlatformNotificationComponent,
            ChatToggleButton,
            TopbarCodingWeekendWidget: () => import('../components/TopbarCodingWeekendWidget')
        },
        data() {
          return {
            showCodingWeekendWidget: window.CODING_WEEKEND_FEATURE_FLAG,
          }
        },
        props: {
            isVip: {type: Boolean, default: false},
        },
        updated() {
            this.$nextTick(function () {
                let tooltips = $('[data-toggle="tooltip"]');
                tooltips.tooltip('destroy');
                tooltips.tooltip();
            });
        },
        methods: {
            openDonationModal() {
                eventBus.$emit("open-donate-modal")
            },
            logout: () => services.auth.logout(),
            login: function () {
                services.auth.login({fullPath: "/dashboard"})
            },
            toggleSidebar: function () {
                $(".wrapper").toggleClass("alt");
                eventBus.$emit("sideBarToggle");
                return false;
            },
        },
        computed: {
            ...mapState([
                'isAuthenticated',
            ])
        },
    }
</script>

<style scoped>
    .whitelabelLogo {
      width: 150px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 5px;
    }
    .navbar {
        z-index: 99;
    }

    .navbar_vip {
        background: rgba(255, 255, 255, 0.2) !important;
    }

    .btn_vip {
        background-color: #4c4c4c;
        border-color: #4c4c4c;
        color: white !important;
    }

    #navbar-main {
        position: relative;
    }

    .login-button {
      position: relative;
    }

    .navbar-btn {
      font-size: 1.5rem;
    }

    a:focus {
      text-decoration: none;
    }
</style>
