import {MockResponse} from "../Utilities";
import {
    AcceptChallengeResult,
    ChallengeDetails,
    ChallengeGroupDetails,
    SubmitChallengeResult,
    SolvedChallengesResult, TaskList, RunnTests, ChallengeGroupList
} from "./TaskManagementServiceResponses";

export class TaskManagementServiceStub {
    constructor(auth) {
        this.authService = auth;
    }

    accceptChallenge(rewardOption, userUuid) {
        let result = new AcceptChallengeResult();
        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 100);
        return result;
    }

    submitChallenge() {
        let result = new SubmitChallengeResult();
        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 100);
        return result;
    }

    getChallengeDetails(challengeIdOrSlug, region) {
        let result = new ChallengeDetails();

        setTimeout(() => {
            result.loaded = true;
            result.challenge_data = {
                "groupId": "cc4d10a7-e4ec-4d1e-b613-5ce7d5b11d9e",
                "id": "cc4d10a7-e4ec-4d1e-b613-5ce7d5b11d9e",
                "title": "FizzBuzz",
                "slug": "fizzbuzz",
                "complexity": 1,
                "description": "This is a testing task to get a first impression of the EntwicklerHeld platform and the code editor. You are supposed to implement the \"FizzBuzz\" problem which is typically used for employment tests.",
                "description_detail": "This is a testing task to get a first impression of the EntwicklerHeld platform and the code editor. You are supposed to implement the \"FizzBuzz\" problem which is typically used for employment tests.",
                "solved": true,
                "surveyUrl": "",
                "actions": {"accept": {}},
                "nextTaskId": null,
                "company": "",
                "rewardDefault": 50,
                "rewardSponsored": 500,
                "rewardInteract": 250,
                "selectedReward": "WITHOUT_SPONSORING_AFTER_CONTACT",
                "type": "TRAINING",
                "isNew": false,
                "newEditor": true,
                "statistics": {
                    "acceptancesCount": 9,
                    "solutionsCount": 1,
                    "publicUserIdOfFirstSolution": "788105038a8c156621d2b111bf79a3b1"
                },
                "technology": {
                    "name": "",
                    "language": "Python",
                    "icon": "https://staging-task-management-media-storage.s3.amazonaws.com/2000px-Python-logo-notext.svg.png"
                },
                "tasks": [{
                    "task_id": "0ef7ada0-068a-4c75-87c2-ec08e8d15676",
                    "index": 0,
                    "title": "Let's code fizzbuzz",
                    "description": "You are supposed to implement the \"FizzBuzz\" problem which is typically used for employment tests.",
                    "solved": false,
                    "debugCommand": "",
                    "hasLiveServer": true,
                    "debugFile": ""
                },
                    {
                        "task_id": "0b465303-ae29-44fc-9d17-e61732585a01",
                        "index": 1,
                        "title": "Let's code fizzbuzz",
                        "description": "You are supposed to implement the \"FizzBuzz\" problem which is typically used for employment tests.",
                        "solved": false,
                        "debugCommand": "",
                        "hasLiveServer": false,
                        "debugFile": ""
                    }],
                "files": {"0ef7ada0-068a-4c75-87c2-ec08e8d15676": ["fizzbuzz/fizzbuzz.py"]},
                "regions": ["\u00d6sterreich", "Schweiz", "Baden-W\u00fcrttemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Th\u00fcringen"],
                "regionsNotAvailable": []
            };
            if (!this.authService.isAuthenticated()) {
                delete result.challenge_data.statistics.publicUserIdOfFirstSolution
            }
            if (challengeIdOrSlug === "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53") {
                result.challenge_data.technology.language = "JavaScript";
                result.challenge_data.id = "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53";
            }
        }, 100);

        return result;
    }

    getDataOfMultipleChallenges() {
        let data = [{
            "id": "e918f361-abe7-4187-a89a-8be94bd4f64b",
            "groupId": "1",
            "title": "The mighty oracle for 'CannotDoMuch#' programs",
            "slug": "the-mighty-oracle-for-cannotdomuch-programs",
            "isNew": true,
            "newEditor": false,
            "deactivated": true,
            "icon": "https://production-task-management-media-storage.s3.amazonaws.com/tc_icon.png",
            "technology": {
                "id": 1,
                "name": "Spring Boot",
                "language": "Java",
            },
        }, {
            "id": "e6458744-d7bf-4fa1-8689-ef1643937339",
            "groupId": "2",
            "title": "Selfies in Copenhagen",
            "slug": "selfies-in-copenhagen",
            "isNew": false,
            "newEditor": false,
            "deactivated": false,
            "icon": "https://production-task-management-media-storage.s3.amazonaws.com/028__Desert_Island-Yellow.png",
            "technology": {
                "id": 2,
                "name": "Django",
                "language": "Python",
            },
        }, {
            "id": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
            "groupId": "3",
            "title": "FizzBuzz JavaScript",
            "slug": "fizzbuzz-javascript",
            "isNew": false,
            "newEditor": false,
            "deactivated": false,
            "icon": "https://production-task-management-media-storage.s3.amazonaws.com/tc_icon.png",
            "technology": {
                "id": 1,
                "name": "",
                "language": "JavaScript",
            },
        }, {
            "id": "58c45208-c9cc-474e-94d4-ced187b077e0",
            "groupId": "4",
            "title": "Spaceship validation when entering the motherships",
            "slug": "spaceship-validation-when-entering-the-motherships",
            "isNew": false,
            "newEditor": false,
            "deactivated": false,
            "icon": "https://production-task-management-media-storage.s3.amazonaws.com/Spaceship-Yellow.png",
            "technology": {
                "id": 1,
                "name": "Spring Boot",
                "language": "Java",
            },
        }];
        return new MockResponse().respond(data);
    }

    getSolvedChallenge() {
        let result = new SolvedChallengesResult();

        setTimeout(function () {
            result.apiData = [{
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/044__retro_slot_machine-Yellow.png",
                "title": "Hacking a Slot Machine",
                "slug": "hacking-a-slot-machine",
                "id": "6799bb44-e79c-4288-a961-26c9d5fa2fd7",
                "groupId": "2",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/018__Airport-Yellow.png",
                "title": "Airport Runway Direction",
                "slug": "airport-runway-direction",
                "id": "9e92453e-9ee8-4497-9c81-11e637012290",
                "groupId": "3",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/016__Paint_Bucket-Yellow_1.png",
                "title": "Filling Area",
                "slug": "filling-area",
                "id": "2c898f61-a29f-4804-909a-3fc922d4d842",
                "groupId": "4",
                "technology": {
                    "id": 1,
                    "name": "",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/024__Laughing_Bot-Yellow.png",
                "title": "Emoji Bot",
                "slug": "emoji-bot",
                "id": "82ee47f5-36f9-4b61-8b1b-3aab8eca0ea5",
                "groupId": "4",
                "technology": {
                    "id": 1,
                    "name": "",
                    "language": "PHP",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/fizzbuzz_java.png",
                "title": "FizzBuzz",
                "slug": "fizzbuzz-java",
                "id": "9cb7ef41-a8ca-41fa-ae57-c54ef9d3a8dd",
                "groupId": "1",
                "technology": {
                    "id": 1,
                    "name": "",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/paint_bucket_hard_mode-01.png",
                "title": "Filling Area (Hard Mode)",
                "slug": "filling-area-hard-mode",
                "id": "24571ac0-24a9-48da-bffe-f56f55852421",
                "groupId": "5",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/047__Programmer-Yellow.png",
                "title": "The mighty oracle for 'CannotDoMuch#' programs",
                "slug": "the-mighty-oracle-for-cannotdomuch-programs",
                "id": "54796871-1190-499d-8ec6-006ab5c2eb64",
                "groupId": "6",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/047__Weighing_Scale-Yellow.png",
                "title": "Weigh the Slow Loris",
                "slug": "weigh-the-slow-loris",
                "id": "50c10b58-9a4f-4c86-abdd-32d81551d5ac",
                "groupId": "7",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/Sliced_Pizza-Yellow.png",
                "title": "Pizza for my Friends Challenge",
                "slug": "pizza-for-my-friends-challenge",
                "id": "5d0fe499-4832-4f88-82b6-0a4a57a69fb3",
                "groupId": "8",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/Spaceship-Yellow.png",
                "title": "New Spaceship validation when entering the motherships",
                "slug": "new-spaceship-validation-when-entering-the-motherships",
                "id": "f2e99daa-133a-4b9a-b1d8-bb6cdc587b8b",
                "groupId": "9",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/5f3993f6-fd8b-4959-a3ca-5a80981cbacf.png",
                "title": "TextHero",
                "slug": "texthero",
                "id": "2a977a42-c86a-4226-b20f-10a683e61927",
                "groupId": "10",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/037__Evil_Pumpkin-Yellow_1.png",
                "title": "Scary Python",
                "slug": "scary-python",
                "id": "26dd4f5a-5550-4470-8299-d32097e301b3",
                "groupId": "11",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/003__Footsteps-Yellow.png",
                "title": "Sportly Webapp - Getting Started",
                "slug": "sportly-webapp-getting-started",
                "id": "be3cf556-eb65-4692-9fc8-64a4d8637c8c",
                "groupId": "12",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/028__Desert_Island-Yellow.png",
                "title": "Number of islands",
                "slug": "number-of-islands",
                "id": "5fb64776-ec46-48ae-ae04-b9c77949eb80",
                "groupId": "13",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/Divide-Yellow_1.png",
                "title": "Polynomial Division",
                "slug": "polynomial-division",
                "id": "7ca868e4-b4d8-41ba-a643-e13bc075c033",
                "groupId": "14",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/065__Leaderboard-Yellow.png",
                "title": "Sportly Webapp - Add the Leaderboard",
                "slug": "sportly-webapp-add-the-leaderboard",
                "id": "fe7b8b35-1e38-458c-bdb0-77a66dccd444",
                "groupId": "15",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/Voltmeter-Yellow_1.png",
                "title": "Meter readings",
                "slug": "meter-readings",
                "id": "9a265ddd-3e2f-4437-8f38-a2d13580a5cc",
                "groupId": "16",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/008__Present-Yellow.png",
                "title": "Christmas Boxes",
                "slug": "christmas-boxes",
                "id": "ad655485-aea1-41a3-8a82-7508de5b9018",
                "groupId": "17",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/csharp.png",
                "title": "Fizzbuzz (C#)",
                "slug": "fizzbuzz-c",
                "id": "f8703416-09ca-405b-9978-a47201a36ec6",
                "groupId": "1",
                "technology": {
                    "id": 1,
                    "name": "",
                    "language": "CSharp",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/Lego_Toys-Yellow.png",
                "title": "Bricks and Water",
                "slug": "bricks-and-water",
                "id": "e8843f3a-a0e2-4c8b-9116-f05c547a0c3f",
                "groupId": "19",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/tc_icon.png",
                "title": "Find the Words",
                "slug": "find-the-words",
                "id": "0d515182-8379-439b-8e14-18e53f9a57cd",
                "groupId": "20",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/025__Home_Entertainmemt-Yellow.png",
                "title": "TV Channel",
                "slug": "tv-channel",
                "id": "5848a48b-928e-4f16-a13a-75274eb4217d",
                "groupId": "21",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/Give_Plant-Yellow.png",
                "title": "Make the world a better place",
                "slug": "make-the-world-a-better-place",
                "id": "6e348d15-c061-4eb7-b7dc-088bbfe1c1b0",
                "groupId": "22",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/026__Selfie-Yellow.png",
                "title": "Selfies in Copenhagen",
                "slug": "selfies-in-copenhagen",
                "id": "e6458744-d7bf-4fa1-8689-ef1643937339",
                "groupId": "23",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/016__ABC_Blocks-Yellow.png",
                "title": "Anagram",
                "slug": "anagram",
                "id": "ea2d5525-8d19-4db1-912c-6fbc5b0825ab",
                "groupId": "24",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/fizzbuzz_c.png",
                "title": "FizzBuzz (Cpp)",
                "slug": "fizzbuzz-cpp",
                "id": "e93b4e42-9b1e-4600-a2f3-c181cf0a0ea0",
                "groupId": "1",
                "technology": {
                    "id": 1,
                    "name": "",
                    "language": "Cpp",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/051__Stairs-Yellow.png",
                "title": "Climbing Stairs",
                "slug": "climbing-stairs",
                "id": "5c43f41f-9e54-439c-8915-30b93b692342",
                "groupId": "25",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/021__Flight-Yellow.png",
                "title": "Flights in Europe",
                "slug": "flights-in-europe",
                "id": "6ec4f856-a1cd-491c-86fa-fec46da8ab3c",
                "groupId": "26",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/004__Magic_Ball-Yellow_1.png",
                "title": "Linear Regression",
                "slug": "linear-regression",
                "id": "fe5678c9-1879-4de9-899d-5518cd96ae21",
                "groupId": "27",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/Cinema_Seats-Yellow.png",
                "title": "Cinema Seat Reservation",
                "slug": "cinema-seat-reservation",
                "id": "cd14ba5e-a1f3-4b78-b770-bea57a23db7a",
                "groupId": "28",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/Industrial_Robot-Yellow.png",
                "title": "Analyse the Plant",
                "slug": "analyse-the-plant",
                "id": "2b93cfa7-5495-4a0f-96fc-a4c4b57a9f33",
                "groupId": "29",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/023__Wine_Glasses-Yellow.png",
                "title": "EntwicklerHeld Celebration",
                "slug": "entwicklerheld-celebration",
                "id": "b90a83d6-10bf-4881-a53f-464d99cefdfc",
                "groupId": "30",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/037__Chatbot-Yellow.png",
                "title": "A small dumb Chatbot",
                "slug": "a-small-dumb-chatbot",
                "id": "69015cfc-c452-4a09-96dd-e1b73c062f5b",
                "groupId": "31",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/069__Text_Box-Yellow.png",
                "title": "Word Counter",
                "slug": "word-counter",
                "id": "99fca3a7-c250-4058-bd40-c7ff8969ef7d",
                "groupId": "32",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/043__Scrabble-Yellow.png",
                "title": "Non Repeating Characters",
                "slug": "non-repeating-characters",
                "id": "5aa72009-4eda-4e2c-a1b4-ff0f5e6c230f",
                "groupId": "33",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/fizzbuzz_javascript.png",
                "title": "FizzBuzz (JavaScript)",
                "slug": "fizzbuzz-javascript",
                "id": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                "groupId": "1",
                "technology": {
                    "id": 1,
                    "name": "",
                    "language": "JavaScript",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/fizzbuzz_python.png",
                "title": "FizzBuzz",
                "slug": "fizzbuzz",
                "id": "cc4d10a7-e4ec-4d1e-b613-5ce7d5b11d9e",
                "groupId": "1",
                "technology": {
                    "id": 1,
                    "name": "",
                    "language": "Python",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/fizzbuzz_php-01.png",
                "title": "FizzBuzz (PHP)",
                "slug": "fizzbuzz-php",
                "id": "f88564df-be47-4e41-a585-9e7c9002e4b8",
                "groupId": "1",
                "technology": {
                    "id": 1,
                    "name": "",
                    "language": "PHP",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/ancient-encryption-logo.png",
                "title": "Ancient Encryption",
                "slug": "ancient-encryption",
                "id": "fb03fb21-9f44-4fe1-bf42-c4122aa94ddb",
                "groupId": "34",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }, {
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/099__Box-Yellow.png",
                "title": "Easy Storage",
                "slug": "easy-storage",
                "id": "5e7e77a6-0243-43c9-bfa3-fa3637575fab",
                "groupId": "35",
                "technology": {
                    "id": 1,
                    "name": "Spring Boot",
                    "language": "Java",
                },
            }];
            result.loaded = true;
        }, 100);
        return result;
    }

    getDataOfMultipleChallengeTasks() {
        let result = new TaskList();
        setTimeout(function () {
            result.apiData = [
                {
                    "id": "1b20123e-0d49-4ea4-a213-59e869675172",
                    "title": "Coole ",
                    "challengeTitle": "The mighty oracle for 'CannotDoMuch#' programs",
                    "challengeSlug": "the-mighty-oracle-for-cannotdomuch-programs",
                    "challengeIcon": "https://production-task-management-media-storage.s3.amazonaws.com/tc_icon.png",
                    "taskIndex": 1,
                    "technology": {
                        "id": 1,
                        "name": "Spring Boot",
                        "language": "Java",
                    },
                }, {
                    "id": "0b465303-ae29-44fc-9d17-e61732585a01",
                    "title": "The mighty oracle for 'CannotDoMuch#' programs",
                    "challengeTitle": "The mighty oracle for 'CannotDoMuch#' programs",
                    "challengeSlug": "the-mighty-oracle-for-cannotdomuch-programs",
                    "challengeIcon": "https://production-task-management-media-storage.s3.amazonaws.com/tc_icon.png",
                    "taskIndex": 2,
                    "technology": {
                        "id": 1,
                        "name": "Spring Boot",
                        "language": "Java",
                    },
                }, {
                    "id": "f0402749-0cee-45ae-9c90-7a6a0360d0f6",
                    "challengeTitle": "The mighty oracle for 'CannotDoMuch#' programs",
                    "challengeSlug": "the-mighty-oracle-for-cannotdomuch-programs",
                    "challengeIcon": "https://production-task-management-media-storage.s3.amazonaws.com/tc_icon.png",
                    "taskIndex": 3,
                    "technology": {
                        "id": 1,
                        "name": "Spring Boot",
                        "language": "Java",
                    },
                }, {

                    "id": "45644b62-8c23-4320-b1fb-29a9dd6d6a7c",
                    "challengeTitle": "The mighty oracle for 'CannotDoMuch#' programs",
                    "challengeSlug": "the-mighty-oracle-for-cannotdomuch-programs",
                    "challengeIcon": "https://production-task-management-media-storage.s3.amazonaws.com/028__Desert_Island-Yellow.png",
                    "taskIndex": 3,
                    "technology": {
                        "id": 1,
                        "name": "Spring Boot",
                        "language": "Java",
                    },
                },
            ];
            result.loaded = true;
        }, 100);
        return result;
    }

    runnTests(taskId, code) {
        let result = new RunnTests();

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 100);

        return result;
    }

    getChallengeGroups() {
        let result = new ChallengeGroupList();
        setTimeout(function () {
            result.apiData = [
                {
                    "id": "9c778212-5cf0-4b5d-b2b5-49bea7eb1df7",
                    "title": "FizzBuzz",
                    "slug": "fizzbuzz",
                    "complexity": 1,
                    "description": "This is a testing task to get a first impression of the EntwicklerHeld platform and the code editor. You are supposed to implement the \"FizzBuzz\" problem which is typically used for employment tests.",
                    "image": "https://production-task-management-media-storage.s3.amazonaws.com/tc_icon.png",
                    "rewardDefault": 10,
                    "rewardInteract": 250,
                    "rewardSponsored": 1000,
                    "type": "CHALLENGE",
                    "challenges": [
                        {
                            "id": "57678212-5cf0-4b5d-b2b5-49bea7eb1bac",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': true,
                            "technology": {
                                "id": 1,
                                "name": "",
                                "language": "ABAP",
                            },
                        },
                        {
                            "id": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': true,
                            "technology": {
                                "id": 1,
                                "name": "",
                                "language": "JavaScript",
                            },
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb80",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 2,
                                "name": "",
                                "language": "PHP",
                            }
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb48",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': false,
                            "technology": {
                                "id": 3,
                                "name": "",
                                "language": "Java",
                            }
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb98",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 4,
                                "name": "",
                                "language": "Cpp",
                            }
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb40",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': true,
                            "technology": {
                                "id": 5,
                                "name": "",
                                "language": "CSharp",
                            }
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb17",
                            "isNew": false,
                            "newEditor": true,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 6,
                                "name": "",
                                "language": "Python",
                            }
                        }]
                },
                {
                    "id": "55555-abe7-4187-a89a-8be94bd4f64b",
                    "title": "The mighty oracle for 'CannotDoMuch#' programs",
                    "slug": "the-mighty-oracle-for-cannotdomuch-programs",
                    "complexity": 4,
                    "description": "Imagine you are working for a very wicked software company. Everyone in this company writes software using a language called CannotDoMuch#. The language is very limited, but it is sufficient to write programs for the company's clients, because they are also very wicked. They do not care about the actual behavior of the software - the only thing they care about is that you can - for any given number - precisely say whether your program can produce this number as output (or not).",
                    "image": "https://production-task-management-media-storage.s3.amazonaws.com/Spaceship-Yellow.png",
                    "rewardInteract": 250,
                    "rewardDefault": 10,
                    "rewardSponsored": 1000,
                    "type": "CHALLENGE",
                    "challenges": [
                        {
                            "id": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                            "isNew": false,
                            "newEditor": true,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': false,
                            "technology": {
                                "id": 7,
                                "name": "Spring Boot",
                                "language": "Java",
                            }
                        }]
                },
                {
                    "id": "51ec2db5-da2e-4218-8436-653e06ed470a",
                    "title": "FizzBuzz (ABAP)",
                    "slug": "fizzbuzz-abap",
                    "complexity": 1,
                    "description": "This is a testing task to get a first impression of the EntwicklerHeld platform and the code editor. You are supposed to implement the \"FizzBuzz\" problem which is typically used for employment tests.",
                    "image": "https://production-task-management-media-storage.s3.amazonaws.com/smashicons_Tech_Support_laptop-solutions-icon-11-yellow_256.png",
                    "rewardDefault": 25,
                    "rewardInteract": 50,
                    "rewardSponsored": 250,
                    "type": "TRAINING",
                    "challenges": [{
                        "id": "492e9f73-9777-4ce9-92e8-9a94f361f8c3",
                        "isNew": false,
                        "newEditor": true,
                        "isAccepted": false,
                        "isSolved": false,
                        "technology": {"id": 48, "name": "ABAP", "language": "ABAP"}
                    }]
                },
                {
                    "id": "11111-ec46-48ae-ae04-b9c77949eb80",
                    "title": "Number of Islands",
                    "slug": "number-of-island",
                    "complexity": 2,
                    "description": "You have a 2D map with water (marked by 0) and land (marked by 1). Connected parts of land are forming an island. Your algorithm should take such a map and return the number of islands. Many companies like Microsoft, Amazon or Visa ask this in an interview.",
                    "image": "https://production-task-management-media-storage.s3.amazonaws.com/028__Desert_Island-Yellow.png",
                    "rewardInteract": 250,
                    "rewardDefault": 100,
                    "rewardSponsored": 1000,
                    "type": "INTERVIEW_TEST",
                    "challenges": [
                        {
                            "id": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                            "isNew": true,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 8,
                                "name": "",
                                "language": "Java",
                            }
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb80",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': true,
                            "technology": {
                                "id": 9,
                                "name": "",
                                "language": "JavaScript",
                            }
                        },
                        {
                            "id": "e918f361-abe7-4187-a89a-8be94bd4f64b",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': false,
                            "technology": {
                                "id": 10,
                                "name": "",
                                "language": "PHP",
                            }
                        }]
                },
                {
                    "id": "ea2d5525-8d19-4db1-912c-6fbc5b082583",
                    "title": "Anagram",
                    "slug": "anagram",
                    "complexity": 1,
                    "description": "An anagram is a word made by rearranging the letters of another word in a different order by using all original letters only once. Implement an algorithm which decides if two words are anagrams to each other.",
                    "image": "https://production-task-management-media-storage.s3.amazonaws.com/028__Desert_Island-Yellow.png",
                    "rewardInteract": 250,
                    "rewardDefault": 100,
                    "rewardSponsored": 1000,
                    "type": "INTERVIEW_TEST",
                    "challenges": [
                        {
                            "id": "ea2d5525-8d19-4db1-912c-6fbc5b0825ab",
                            "isNew": true,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': true,
                            "technology": {
                                "id": 11,
                                "name": "",
                                "language": "CSharp",
                            }
                        },
                        {
                            "id": "ea2d5525-8d19-4db1-912c-6fbc5b082512",
                            "isNew": true,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': true,
                            "technology": {
                                "id": 12,
                                "name": "",
                                "language": "JavaScript",
                            }
                        }]
                },
                {
                    "id": "ea2d5525-8d19-4db1-912c-6fbc5b082581",
                    "title": "Cinema Seats",
                    "slug": "cinema-seats",
                    "complexity": 1,
                    "description": "The small cinema in your neighbouring town is becoming more and more popular. Since it is sold out more often, a developer should be hired to implement a seat reservation tool. You have been chosen to take on this task.",
                    "image": "https://production-task-management-media-storage.s3.amazonaws.com/028__Desert_Island-Yellow.png",
                    "rewardInteract": 250,
                    "rewardDefault": 100,
                    "rewardSponsored": 1000,
                    "type": "INTERVIEW_TEST",
                    "challenges": [
                        {
                            "id": "ea2d5525-8d19-4db1-912c-6fbc5b0825ab",
                            "isNew": true,
                            "newEditor": true,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 11,
                                "name": "",
                                "language": "CSharp",
                            }
                        },
                        {
                            "id": "ea2d5525-8d19-4db1-912c-6fbc5b082512",
                            "isNew": true,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 12,
                                "name": "",
                                "language": "JavaScript",
                            }
                        }]
                },
                {
                    "id": "ea2d5525-8d19-4db1-912c-6fbc5b082580",
                    "title": "Flights in Europe",
                    "slug": "flights-in-europe",
                    "complexity": 1,
                    "description": "You are a freelancer with the task to implement a flight booking system for the reliable airline „Oceanic Airline“. The system is very simple. Its only function is to search for the shortest (shortest time) route from one origin airport to a destination airport in Europe.",
                    "image": "https://production-task-management-media-storage.s3.amazonaws.com/028__Desert_Island-Yellow.png",
                    "rewardInteract": 250,
                    "rewardDefault": 100,
                    "rewardSponsored": 1000,
                    "type": "INTERVIEW_TEST",
                    "challenges": [
                        {
                            "id": "ea2d5525-8d19-4db1-912c-6fbc5b0825ab",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 11,
                                "name": "",
                                "language": "cpp",
                            }
                        },
                        {
                            "id": "ea2d5525-8d19-4db1-912c-6fbc5b082512",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 12,
                                "name": "",
                                "language": "python",
                            }
                        },
                        {
                            "id": "2617136e-97da-4842-bbba-9c21e888e6e4",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 11,
                                "name": "",
                                "language": "python",
                            }
                        }]
                },
                {
                    "id": "ea2d5525-8d19-4db1-912c-6fbc5b082583",
                    "title": "FizzBuzz Public",
                    "slug": "fizzbuzz-public",
                    "complexity": 1,
                    "description": "You are a freelancer with the task to implement a flight booking system for the reliable airline „Oceanic Airline“. The system is very simple. Its only function is to search for the shortest (shortest time) route from one origin airport to a destination airport in Europe.",
                    "image": "https://production-task-management-media-storage.s3.amazonaws.com/028__Desert_Island-Yellow.png",
                    "rewardInteract": 250,
                    "rewardDefault": 100,
                    "rewardSponsored": 1000,
                    "type": "INTERVIEW_TEST",
                    "challenges": [
                        {
                            "id": "ea2d5525-8d19-4db1-912c-6fbc5b0825ab",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 11,
                                "name": "",
                                "language": "cpp",
                            }
                        },
                        {
                            "id": "ea2d5525-8d19-4db1-912c-6fbc5b082512",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 12,
                                "name": "",
                                "language": "csharp",
                            }
                        },
                        {
                            "id": "ea2d5525-8d19-4db1-912c-6fbc5b0825ab",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 11,
                                "name": "",
                                "language": "javascript",
                            }
                        },
                        {
                            "id": "ea2d5525-8d19-4db1-912c-6fbc5b082512",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 12,
                                "name": "",
                                "language": "java",
                            }
                        }]
                }
            ];
            result.loaded = true;
        }, 100);
        return result;
    }

    getChallengeGroupDetails(groupIdOrSlug) {
        let result = new ChallengeGroupDetails();

        setTimeout(() => {
            result.apiData = {
                "id": "861e6bba-12d3-461d-8fc6-d91902272b3e",
                "title": "FizzBuzz",
                "slug": "fizzbuzz",
                "complexity": 1,
                "description": "This is testing task to get a first impression of the EntwicklerHeld platform and the code editor. You are supposed to implement the 'FizzBuzz' problem which is typically used for employment tests.",
                "descriptionDetail": "This is testing task to get a first impression of the EntwicklerHeld platform and the code editor. You are supposed to implement the 'FizzBuzz' problem which is typically used for employment tests.",
                "image": "https://production-task-management-media-storage.s3.amazonaws.com/028__Desert_Island-Yellow.png",
                "rewardDefault": 10,
                "rewardInteract": 250,
                "rewardSponsored": 1000,
                "type": "CHALLENGE",
                "challenges": [
                        {
                            "id": "57678212-5cf0-4b5d-b2b5-49bea7eb1bac",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': true,
                            "technology": {
                                "id": 1,
                                "name": "",
                                "language": "ABAP",
                            },
                        },
                    {
                            "id": "57678212-5cf0-4b5d-b2b5-49bea7eb1bad",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': true,
                            "technology": {
                                "id": 1,
                                "name": "",
                                "language": "Kotlin",
                            },
                        },
                        {
                            "id": "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': true,
                            "technology": {
                                "id": 1,
                                "name": "",
                                "language": "JavaScript",
                            },
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb80",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 2,
                                "name": "",
                                "language": "PHP",
                            }
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb48",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': false,
                            "technology": {
                                "id": 3,
                                "name": "",
                                "language": "Java",
                            }
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb98",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 4,
                                "name": "",
                                "language": "Cpp",
                            }
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb40",
                            "isNew": false,
                            "newEditor": false,
                            "isDeactivated": false,
                            'isAccepted': true,
                            'isSolved': true,
                            "technology": {
                                "id": 5,
                                "name": "",
                                "language": "CSharp",
                            }
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb17",
                            "isNew": false,
                            "newEditor": true,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 6,
                                "name": "",
                                "language": "Python",
                            }
                        },
                        {
                            "id": "5fb64776-ec46-48ae-ae04-b9c77949eb18",
                            "isNew": false,
                            "newEditor": true,
                            "isDeactivated": false,
                            'isAccepted': false,
                            'isSolved': false,
                            "technology": {
                                "id": 6,
                                "name": "",
                                "language": "Powershell",
                            }
                        }
                ]
            }
            result.loaded = true;
        }, 100);

        return result;
    }
}
