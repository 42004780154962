export class UserContribution {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}

export class RankingList {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}

export class ActiveCampusList {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}
