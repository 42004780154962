import {RankTheCodeServiceStub} from "./RankTheCodeServiceStub";
import RankTheCodeServiceNetwork from "./RankTheCodeServiceNetwork";

export default class RankTheCodeService {
    constructor(auth) {
        this.authService = auth;
    }

    getOtherSolutionRatings(taskId, view, pageId= 1) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || window.RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getOtherSolutionRatings(taskId, view, pageId);
        }
        return new RankTheCodeServiceNetwork(this.authService).getOtherSolutionRatings(taskId, view, pageId);
    }

    getOtherSolutionRatingsById(solutionId) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || window.RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getOtherSolutionRatingsById(solutionId);
        }
        return new RankTheCodeServiceNetwork(this.authService).getOtherSolutionRatingsById(solutionId);
    }

    getSolutionsForChallenge(challengeId) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || window.RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getSolutionsForChallenge(challengeId);
        }
        return new RankTheCodeServiceNetwork(this.authService).getSolutionsForChallenge(challengeId);
    }

    getSolutionsOfUserForChallenge(challengeId, public_user_id) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || window.RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getSolutionsOfUserForChallenge(challengeId, public_user_id);
        }
        return new RankTheCodeServiceNetwork(this.authService).getSolutionsOfUserForChallenge(challengeId, public_user_id);
    }

    getChallengeStatistics(challengeId) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || window.RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getChallengeStatistics(challengeId);
        }
        return new RankTheCodeServiceNetwork(this.authService).getChallengeStatistics(challengeId);
    }

    getSolutionRatings(solutionId) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || window.RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getSolutionRatings(solutionId);
        }
        return new RankTheCodeServiceNetwork(this.authService).getSolutionRatings(solutionId);
    }

    postRating(solutionId, rate, comment) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || window.RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).postRating(solutionId, rate, comment);
        }
        return new RankTheCodeServiceNetwork(this.authService).postRating(solutionId, rate, comment);
    }

    getCodeComments(solutionId) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getCodeComments(solutionId);
        }
        return new RankTheCodeServiceNetwork(this.authService).getCodeComments(solutionId);
    }

    postCodeComment(solutionId, range, judgement, comment) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).postCodeComment(solutionId, range, judgement, comment);
        }
        return new RankTheCodeServiceNetwork(this.authService).postCodeComment(solutionId, range, judgement, comment);
    }

    postCommentForCodeComment(codeCommentId, text) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).postCommentForCodeComment(codeCommentId, text);
        }
        return new RankTheCodeServiceNetwork(this.authService).postCommentForCodeComment(codeCommentId, text);
    }

    postCommentForSolutionRating(solutionRatingId, text) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).postCommentForSolutionRating(solutionRatingId, text);
        }
        return new RankTheCodeServiceNetwork(this.authService).postCommentForSolutionRating(solutionRatingId, text);
    }

    getRatedSolutions() {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getRatedSolutions();
        }
        return new RankTheCodeServiceNetwork(this.authService).getRatedSolutions();
    }

    getReceivedSolutionRatings() {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).receivedRatedSolutions();
        }
        return new RankTheCodeServiceNetwork(this.authService).receivedRatedSolutions();
    }

    getSolution(solutionId) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getSolution(solutionId);
        }
        return new RankTheCodeServiceNetwork(this.authService).getSolution(solutionId);
    }

    getRankTheCodeUser() {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getRankTheCodeUser();
        }
        return new RankTheCodeServiceNetwork(this.authService).getRankTheCodeUser();
    }

    createRankTheCodeUser() {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).createRankTheCodeUser();
        }
        return new RankTheCodeServiceNetwork(this.authService).createRankTheCodeUser();
    }

    getRandomSolution(challengeId = null, excludeAlreadyRated = true) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getRandomSolution(challengeId, excludeAlreadyRated);
        }
        return new RankTheCodeServiceNetwork(this.authService).getRandomSolution(challengeId, excludeAlreadyRated);
    }

    getRandomSolutionPromise(challengeId = null, excludeAlreadyRated = true) {
        if (typeof window.RANK_THE_CODE_API_URL === "undefined" || RANK_THE_CODE_API_URL === "") {
            console.info("window.RANK_THE_CODE_API_URL is not set! Using Stub data!");
            return new RankTheCodeServiceStub(this.authService).getRandomSolutionPromise(challengeId, excludeAlreadyRated);
        }
        return new RankTheCodeServiceNetwork(this.authService).getRandomSolutionPromise(challengeId, excludeAlreadyRated);
    }

}
