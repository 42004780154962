import CookieConsentServiceNetwork from "./CookieConsentServiceNetwork";
import CookieConsentServiceStub from "./CookieConsentServiceStub";


export default class CookieConsentService {

    constructor(auth) {
        this.authService = auth;
    }
    decline() {
        if (typeof window.COOKIE_CONSENT_API_URL === "undefined" || window.COOKIE_CONSENT_API_URL === "") {
            console.info("window.COOKIE_CONSENT_API_URL is not set! Using Stub data!");
            return new CookieConsentServiceStub(this.authService).decline();
        }
        return new CookieConsentServiceNetwork(this.authService).decline();
    }

    accept() {
         if (typeof window.COOKIE_CONSENT_API_URL === "undefined" || window.COOKIE_CONSENT_API_URL === "") {
            console.info("window.COOKIE_CONSENT_API_URL is not set! Using Stub data!");
            return new CookieConsentServiceStub(this.authService).accept();
        }
        return new CookieConsentServiceNetwork(this.authService).accept();
    }
}

