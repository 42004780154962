<template>
    <VueGooglePlaces
        api-key="AIzaSyCVWu7dN6ToxD8ksp17hJ9DOtVkN19jbxY"
        types="(cities)"
        @placechanged="onPlaceChanged"
        @noresult="onPlaceNotFound"
    >
        <div class="location-tag-input-component" :class="{'has-error': error}">
            <input ref="locationSearch"
                   @change="onManualChange"
                   @keyup.enter="submit"
                   :placeholder="placeholder"
            />
            <button class="location-add-button" @click="submit" v-if="location" :class="{'show': valid }">
                +
            </button>
        </div>
    </VueGooglePlaces>
</template>

<script>

export default {
    name: "LocationInputComponent",
    components: {},
    props: {
        selectedLocations: {
            type: Array,
            default: () => [],
        },
        error: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
            location: null,
            searchValue: '',
            active: false
        }
    },
    computed: {
        valid() {
            return !!this.location;
        },
        placeholder: function () {
            return this.selectedLocations.length > 0 ? 'Wo sonst noch?' : 'Wo möchtest du arbeiten?';
        }
    },
    methods: {
        setLocation: function (value) {
            this.location = value;
            this.$v.location.$touch();
        },
        onPlaceChanged: function (data) {
            this.setLocation({
                address: data.formatted_address,
                city: data.locality,
                country_verbose: data.country,
                latitude: data.latitude,
                longitude: data.longitude,
                region: data.country_code + "_" + data.administrative_area_level_1,
                country: data.country_code,
            });
        },
        onPlaceNotFound: function (data) {
            this.setLocation(null);
        },
        onManualChange: function (event) {
            // we have to use this @change handler, because :v-model is not working in VueGooglePlaces input
            // we have to reset the location here as the VueGooglePlaces will fill the data if it founds the place
            this.setLocation(null);
        },
        submit: function () {
            if (this.location) {
                this.$emit('location-changed', this.location);
                this.$refs.locationSearch.value = '';
                this.location = null;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$input-size: 44px;
$color-brand-primary: #08AB99;

::v-deep {
    .tags-input-badge {
        font-family: "Source Code Pro", sans-serif;
        color: $color-brand-primary;
        overflow: hidden;
        padding: 6px 24px;
        margin-bottom: 8px;
        border-radius: 100px;
        background-color: white;
        margin-right: 1rem;
        line-height: 1.6;
        box-shadow: 0 4px 15px rgba(13, 51, 85, 0.2);
        display: inline-block;
        text-transform: uppercase;
        cursor: pointer;


        &.tags-input-badge-selected-default {
            display: none;
        }
    }

    .tags-input input {
        display: block;
        width: 100%;
        padding: 10px 60px 10px 24px;
        border: 1px solid #dbdbdb;
        border-radius: 60px;
        height: $input-size + 4px;
        font-size: 1.6rem;

        &:focus-visible {
            outline-color: $color-brand-primary;
        }
    }

    .typeahead-badges {
        margin-top: 1.5rem;
    }
}

.location-tag-input-component {
    position: relative;
}

.pac-target-input {
    width: 100%;
    border-radius: 60px;
    padding: 10px 60px 10px 24px;
    border: 1px solid #dbdbdb;
    height: 48px;
    font-size: 1.6rem;
}

.location-add-button {
    border-radius: 50%;
    background: $color-brand-primary;
    border: 2px solid #fff;
    color: #fff;
    font-size: 35px;
    line-height: 0;
    position: absolute;
    right: 2px;
    top: 2px;
    width: $input-size;
    height: $input-size;
    @extend .fade-out-left;

    &.show {
        @extend .fade-in-left
    }
}

.fade-out-left {
    -webkit-animation: fade-out-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: fade-out-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.fade-in-left {
    -webkit-animation: fade-in-left 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-left 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2022-9-16 15:51:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-16 15:58:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
}

@keyframes fade-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
}

.has-error input {
    border: 1px solid #e74c3c;
}
</style>