export class NewsListResult {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}

export class PostResult {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}


export class UnreadPostsResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class SetAllPostsReadResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class VotedPostsResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class VotePostResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}