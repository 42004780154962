<template>
     <div class="row form-row">
        <div class="col-xs-12">
            <div class="content">
                <div class="row">
                    <div class="col-xs-12">
                         <div class="text-center">
                            <h3><i class="dev-circle-check"></i> Yay! Das hat geklappt!</h3>
                            <p>Und weiter gehts zur Plattform, viel Spaß!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>

<script>
    import {eventBus} from "../../main";
    import confetti from "canvas-confetti"

    export default {
      name: "SubmitComponent",

      props: {
        information: {
          type: Object,
          default: () => ({})
        },
      },
      data() {
        return {}
      },
      mounted() {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
        setTimeout(function () {
         eventBus.$emit("closeRegistrationEvent");
          }, 1000);
      }
    }
</script>

<style scoped>
    h3 {
        color: #4c4c4c;
        margin: 5rem 0;
        line-height: 1.4em;
    }
    p {
        margin-top: 1rem;
        font-size: 15px;
        color: #999999;
        text-align: center;
    }
    .content {
        width: 100%;
    }
</style>