var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row form-row"},[_c('div',{staticClass:"col-xs-12"},[_c('h3',{staticClass:"text-center"},[_vm._v("\n            Perfekt "),_c('b',[_vm._v(_vm._s(_vm.information.name))]),_vm._v(", Du hast es geschafft!\n        ")]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"avatar-wrapper"},[_c('div',{staticClass:"avatar",style:({backgroundColor: '#'+_vm.information.selectedColor})},[_c('img',{attrs:{"src":_vm.information.selectedImage}})])])]),_vm._v(" "),_c('div',{staticClass:"col-xs-12"},[_c('table',{staticClass:"information"},[_c('tr',{staticClass:"information-element"},[_c('td',[_c('img',{staticClass:"information-icon",attrs:{"src":_vm.professionIcons[_vm.information.selectedProfession]}})]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm.displayProfession[_vm.information.selectedProfession])+"\n                            ")])]),_vm._v(" "),_c('tr',{staticClass:"information-element"},[_vm._m(0),_vm._v(" "),_c('td',[_vm._v("\n                                 "+_vm._s(_vm.information.workExperience)+" Jahre\n                            ")])]),_vm._v(" "),(_vm.information.technologies && _vm.information.technologies.length !== 0)?_c('tr',{staticClass:"information-element"},[_c('td'),_vm._v(" "),_c('td',[_c('div',{staticClass:"technologies"},_vm._l((_vm.information.technologies),function(value){return _c('span',{key:value.id,staticClass:"technology"},[_c('label',{staticClass:"technology__label",class:{
                                          'bg-tec-python': value.name === 'python',
                                          'bg-tec-dockerfile': value.name === 'dockerfile',
                                          'bg-tec-java': value.name === 'java',
                                          'bg-tec-javascript': value.name === 'javascript',
                                          'bg-tec-csharp': value.name === 'csharp',
                                          'bg-tec-cpp': value.name === 'cpp',
                                          'bg-tec-php': value.name === 'php',
                                      }},[_c('i',{staticClass:"fa fa-code"}),_vm._v(" "),_c('span',{staticClass:"technology__name"},[_vm._v(_vm._s(value.displayName))])])])}),0)])]):_vm._e(),_vm._v(" "),(_vm.information.location)?_c('tr',{staticClass:"information-element"},[_vm._m(1),_vm._v(" "),_c('td',[_vm._v("\n                              "+_vm._s(_vm.information.location.address)+"\n                            ")])]):_vm._e(),_vm._v(" "),(_vm.information.selectedJobInterest !== 'no-job' && _vm.information.selectedJobInterest !== 'no-studies')?_c('tr',{staticClass:"information-element"},[_c('td',[_c('img',{staticClass:"information-icon",attrs:{"src":_vm.jobInterestIcons[_vm.information.selectedJobInterest]}})]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm.displayJobInterest[_vm.information.selectedJobInterest])+"\n                            ")])]):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"newsletter"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newsletterApproval),expression:"newsletterApproval"}],attrs:{"type":"checkbox","id":"newsletter"},domProps:{"checked":Array.isArray(_vm.newsletterApproval)?_vm._i(_vm.newsletterApproval,null)>-1:(_vm.newsletterApproval)},on:{"change":function($event){var $$a=_vm.newsletterApproval,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.newsletterApproval=$$a.concat([$$v]))}else{$$i>-1&&(_vm.newsletterApproval=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.newsletterApproval=$$c}}}}),_vm._v(" "),_c('label',{attrs:{"for":"newsletter"}},[_vm._v("Ich will über neue Challenges und Features auf der Plattform per Mail informiert werden.")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{staticClass:"information-icon",attrs:{"src":"https://polylith-public-files.s3.eu-central-1.amazonaws.com/Web+Development+-+Retro+(1).svg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{staticClass:"information-icon",attrs:{"src":"https://polylith-public-files.s3.eu-central-1.amazonaws.com/Location+Pin+-+Retro.svg"}})])
}]

export { render, staticRenderFns }