<template>
    <div id="registration-form" class="row">
        <div v-if="!isAuthenticated" @click="closeButtonHandler" class="close-button-wrapper">
            <svg class="close-button" aria-hidden="true" width="24" focusable="false"
                 enable-background="new 0 0 128 128"
                 version="1.1" viewBox="0 0 128 128"
                 xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polygon fill="#373737" points="123.5429688,11.59375 116.4765625,4.5185547 64.0019531,56.9306641 11.5595703,4.4882813     4.4882813,11.5595703 56.9272461,63.9970703 4.4570313,116.4052734 11.5244141,123.4814453 63.9985352,71.0683594     116.4423828,123.5117188 123.5126953,116.4414063 71.0732422,64.0019531   "></polygon></g></svg>
        </div>
        <div class="col-xs-12 col-md-offset-1 col-lg-offset-2 col-md-10 col-lg-8 header">
                <img class="header-img" src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1638188273/eh-platform/logos/eh_logo_full_green_new_svg.svg">
        </div>
        <form-wizard
            class="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8"
            ref="registrationWizard"
            shape="circle"
            title=""
            subtitle=""
            next-button-text="WEITER"
            back-button-text="ZURÜCK"
            finish-button-text="GESCHAFFT!"
            step-size="xs"
            color="#08AB99"
            error-color="#e74c3c"
            @on-complete="onComplete"
            @on-change="onChange"
        >
            <template slot="step" slot-scope="props">
                {{props.activeTabIndex}}
                <wizard-step
                        :tab="props.tab"
                        :step-size="props.stepSize"
                        :transition="props.transition"
                        :index="props.index"
                        :class="{'all-active': lastStep}"
                        @click.native="delegate(props)"
                        @keyup.enter.native="props.navigateToTab(props.index)"
                >
                </wizard-step>
            </template>
             <tab-content title="Welcome" icon=none :before-change="() => validateStep('welcome')">
             <form @submit.prevent="nextStep">
                 <welcome-component
                        :displayName="userProfile.display_name" ref="welcome"/>
             </form>
            </tab-content>
            <tab-content title="Heldenname" icon=none :before-change="() => validateStep('nickname')">
                <form @submit.prevent="nextStep">
                    <nickname-form ref="nickname" :displayName="userProfile.display_name" @on-validate="mergePartialModels" />
                </form>
            </tab-content>
            <tab-content title="Avatar" icon=none :before-change="() => validateStep('avatar')">
                <avatar-form :name="finalModel.name" :nextFunction="nextStep" ref="avatar" @on-validate="mergePartialModels"/>
            </tab-content>
            <tab-content title="Heldenstatus" icon=none :before-change="() => validateStep('profession')">
                <profession-form :name="finalModel.name" :nextFunction="nextStep" ref="profession" @on-validate="mergePartialModels"/>
            </tab-content>
            <tab-content title="Heldenskills" icon=none :before-change="() => validateStep('skills')">
              <form @submit.prevent="nextStep">
                <skills-form ref="skills" @on-validate="mergePartialModels"/>
              </form>
            </tab-content>
            <tab-content title="Erfahrung" icon=none :before-change="() => validateStep('work-experience')">
                <work-experience-form @submit="nextStep" :name="finalModel.name" ref="work-experience" @on-validate="mergePartialModels"/>
            </tab-content>
            <tab-content title="Homebase" icon=none :before-change="() => validateStep('location')">
              <form @submit.prevent="nextStep">
                  <location-form :name="finalModel.name" ref="location" @on-validate="mergePartialModels"/>
              </form>
            </tab-content>
            <tab-content title="Interesse" icon=none :before-change="() => validateStep('job-interest')">
                <job-interest-form
                        :name="finalModel.name"
                        :nextFunction="nextStep"
                        :selected-profession="finalModel.selectedProfession"
                        ref="job-interest"
                        @on-validate="mergePartialModels"
                />
            </tab-content>
            <tab-content title="Completion" icon="none" :before-change="() => validateStep('completed')">
                <completion-component
                        v-if="!isCompleted"
                        :information="finalModel"
                        ref="completed"
                        @on-validate="mergePartialModels"
                        @on-loading="changeProgessBar"/>
                <submit-component
                        v-if="isCompleted"
                        :information="finalModel"
                        ref="submit"/>
            </tab-content>

            <template slot="footer" slot-scope="props">
                <div class="row wizard-footer">
                    <div class="col-xs-6 wizard-footer-left">
                        <wizard-button v-if="props.activeTabIndex >= 1 && !isCompleted" class="wizard-btn"
                                       @click.native="props.prevTab()">
                            ZURÜCK
                        </wizard-button>
                    </div>
                    <div class="col-xs-6 wizard-footer-right text-right">
                        <wizard-button v-if="props.activeTabIndex >= 0 && props.activeTabIndex < 8" class="wizard-btn"
                                       @click.native="props.nextTab()">
                            WEITER
                        </wizard-button>
                        <wizard-button v-if="props.activeTabIndex === 8 && !isCompleted" class="wizard-btn"
                                    @click.native="props.nextTab()">
                            ABSCHLIESSEN
                        </wizard-button>
                    </div>
                </div>
            </template>
        </form-wizard>
    </div>
</template>

<script>
    import {services} from "../../main.js";
    import {eventBus} from "../../main";
    import UniversitySelectionField from "./UniversitySelectionField";
    import WelcomeComponent from "../register/WelcomeComponent";
    import NicknameForm from "../register/NicknameForm";
    import AvatarForm from "../register/AvatarForm";
    import JobInterestForm from "../register/JobInterestForm";
    import LocationForm from "../register/LocationForm";
    import ProfessionForm from "../register/ProfessionForm";
    import WorkExperienceForm from "../register/WorkExperienceForm";
    import SkillsForm from "../register/SkillsForm";
    import CompletionComponent from "../register/CompletionComponent";
    import SubmitComponent from "../register/SubmitComponent";
    import { FormWizard, TabContent, WizardButton, WizardStep } from "vue-form-wizard";
    import {isEmpty} from 'lodash';
    import "vue-form-wizard/dist/vue-form-wizard.min.css";
    import {findGetParameter} from "../../services/Utilities";
    import VueToast from 'vue-toast-notification';
    import 'vue-toast-notification/dist/theme-default.css';
    import {mapState} from "vuex";
    import Vue from "vue";

    Vue.use(VueToast);

    export default {
        name: "Registration",
        components: {
            SubmitComponent,
            CompletionComponent,
            UniversitySelectionField,
            FormWizard,
            TabContent,
            WizardStep,
            WizardButton,
            WelcomeComponent,
            NicknameForm,
            AvatarForm,
            SkillsForm,
            JobInterestForm,
            LocationForm,
            ProfessionForm,
            WorkExperienceForm,
        },
        props: {
            userProfile: {type: Object, default: () => {return {}}},
        },
        data() {
            return {
                finishOnboardingRequest: {loaded: false, error: null},
                updateUserTechnologiesRequest: {loaded: false, error: null},
                userProfileRequest: {loaded: false, error: null},
                isCompleted: false,
                finalModel: {},
                lastStep : false,
            }
        },
        computed: {
            ...mapState([
                'isAuthenticated',
            ])
        },
        created() {
          eventBus.$on("userLoggedIn", () => {
              // send user request to create profile before send completion request
              this.userProfileRequest = services.userService.getUserProfile();
          })
        },
      destroyed() {
          eventBus.$off("userLoggedIn");
      },
      methods: {
          validateStep(name) {
              let refToValidate = this.$refs[name];
              return refToValidate.validate();
          },
          delegate: function (props) {
              if (this.isCompleted) {
                  return function () {
                  };
              }
              return props.navigateToTab(props.index);
          },
          mergePartialModels(model, isValid) {
              if (isValid) {
                  this.finalModel = Object.assign({}, this.finalModel, model);
              }
          },
          nextStep() {
              this.$refs.registrationWizard.nextTab();
              window.scrollTo(0,0);
          },
          onComplete() {
              if (services.auth.isAuthenticated()) {
                this.sendTechnologiesRequest();
                return;
              }
              services.auth.signUp()
          },
          changeProgessBar() {
              let elements = document.getElementsByClassName("wizard-icon-circle");
              elements.classList.add("all-active");
          },
          onChange(prevIndex, nextIndex){
              this.lastStep = nextIndex === 7;
            this.sendTrackingInformation(nextIndex)
          },
          sendTrackingInformation(index) {
            if(index < 0 || !this.$refs.registrationWizard) {
              return;
            }
            const tab = this.$refs.registrationWizard.tabs[index];
            if (tab) {
              services.googleAnalytics.sendEvent('User', `onboarding`, `Step-${index} (${tab.$props.title})`);
            }
          },
          sendCompletionRequest: function () {
            const jobInterestMapping = {
                    "no-studies": "NO_INFORMATION",
                    "maybe": "UNDECIDED",
                    "studies": "WANT_TO_BE_IN_IT_LATER",
                    "no-job": "NO_INFORMATION",
                    "freelancer": "FREELANCER",
                    "offers": "JOB_OFFERS",
                    "job": "NEW_JOB",
                };

                const professionMapping = {
                    "student/trainee": "STUDENT",
                    "professional/academics": "PROFESSIONAL",
                    "pupil": "PUPIL",
                };
                let payload = {
                    "profession": professionMapping[this.finalModel.selectedProfession],
                    "displayName": this.finalModel.name,
                    "location": this.finalModel.location,
                    "region": this.finalModel.location.region,
                    "jobInterest": jobInterestMapping[this.finalModel.selectedJobInterest],
                    "experience": this.finalModel.workExperience,
                    "avatar": {
                        "url": this.finalModel.selectedImage,
                        "bgColor": this.finalModel.selectedColor,
                    },
                    "newsletter": this.finalModel.newsletterApproval,
                    "trackingData": services.auth.getTrackingData()
                };
                this.finishOnboardingRequest = services.userService.finishOnboarding(payload);
          },
          sendTechnologiesRequest: function() {
            if (this.finalModel.technologies && this.finalModel.technologies.length !== 0) {
              this.updateUserTechnologiesRequest = services.technologyService.updateUserTechnologies(this.finalModel.technologies);
            } else {
              this.$nextTick(() => {
                this.updateUserTechnologiesRequest.error = null;
                this.updateUserTechnologiesRequest.loaded = true;
              });
            }
          },
          closeButtonHandler: function (event) {
              if (!this.isAuthenticated) {
                  services.googleAnalytics.sendEvent('User', `onboarding`, 'close button clicked');
                  eventBus.$emit("closeRegistrationEvent");
              }
          }
        },
        watch: {
            'userProfileRequest.loaded': function () {
                if (this.userProfileRequest.loaded && !this.userProfileRequest.error) {
                    this.sendCompletionRequest();
                }
                if (this.userProfileRequest.loaded && this.userProfileRequest.error) {
                    Vue.$toast.open({
                        message: 'Beim Absenden ist was schief gelaufen. Probiere es nochmal oder schreibe uns über den Feedback-Button.',
                        type: 'error',
                        position: 'bottom',
                    });
                }
            },
            'updateUserTechnologiesRequest.loaded': function() {
              if (this.updateUserTechnologiesRequest.loaded && !this.updateUserTechnologiesRequest.error) {
                this.sendCompletionRequest();
              }
              if (this.updateUserTechnologiesRequest.loaded && this.updateUserTechnologiesRequest.error) {
                  Vue.$toast.open({
                      message: 'Beim Absenden ist was schief gelaufen. Probiere es nochmal oder schreibe uns über den Feedback-Button.',
                      type: 'error',
                      position: 'bottom',
                  });
              }
            },
            'finishOnboardingRequest.loaded': function() {
                if (this.finishOnboardingRequest.loaded && !this.finishOnboardingRequest.error) {
                    eventBus.$emit("user-profile-changed");
                    if (!isEmpty(services.redditAnalytics)){
                        services.redditAnalytics.sendRegistrationCompletionEvent();
                    }
                    if (!isEmpty(services.facebookAnalytics)) {
                        services.facebookAnalytics.sendRegistrationCompletionEvent();
                    }
                    if (!isEmpty(services.googleAnalytics)){
                        services.googleAnalytics.sendEvent('User', 'register', 'register');
                    }
                    if (!isEmpty(services.twitterAnalytics)){
                        services.twitterAnalytics.sendRegistrationCompletionEvent();
                    }
                    if (!isEmpty(services.linkedInAnalytics)){
                        services.linkedInAnalytics.sendRegistrationCompletionEvent();
                    }
                    if (!isEmpty(services.snapChatAnalytics)){
                        services.snapChatAnalytics.sendRegistrationCompletionEvent();
                    }
                    this.isCompleted = true;
                }
                if (this.finishOnboardingRequest.loaded && this.finishOnboardingRequest.error) {
                    Vue.$toast.open({
                        message: 'Beim Absenden ist was schief gelaufen. Probiere es nochmal oder schreibe uns über den Feedback-Button.',
                        type: 'error',
                        position: 'bottom',
                    });
                }
            }
        },
    }
</script>

<style scoped>
    .close-button-wrapper {
        height: 39px;
    }

    .close-button-wrapper:hover {
        cursor: pointer;
    }

    .close-button {
        width: 24px;
        top: 15px;
        position: absolute;
        right: 15px;
    }
    #app{
        padding-left: 0;
    }

    .container {
        width: 100%;
    }

    .wrapper{
        padding-left: 0;
    }

    h2, h3 {
        color: #4c4c4c;
    }

    .wizard-tab-content {
        display: flex;
    }

    .wizard-tab-content .wizard-tab-container {
        display: block;
        animation: fadeInRight 0.3s;
    }

    .wizard-btn {
        border-radius: 99px;
        background-color: #08AB99;
        color: white;
        opacity: 0.9;
    }

    .wizard-btn:hover {
        opacity: 1;
    }

    .wizard-footer {
        margin-top: 2rem;
    }

    .header {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    .wizard-nav li {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .wizard-nav li:focus {
        outline: none;
    }

    .wizard-nav li a{
        border-radius: 10px;
    }

    .wizard-nav li a:focus {
        outline: none;
    }

    .vue-form-wizard .wizard-icon-circle.tab_shape {
        height: 15px;
    }

    .wizard-icon-circle:focus {
        outline: none;
    }

    #registration-form {
        margin: 0;
    }

    #registration-form >>> .vue-form-wizard .wizard-icon-circle {
        border-radius:0;
        width: 100%;
        height: 15px;
    }

    #registration-form >>> .vue-form-wizard .wizard-icon-circle {
        border-radius:0;
        width: 100%;
        height: 15px;
    }

    #registration-form >>> .vue-form-wizard .wizard-icon-container {
        border-radius: 0;
        display: none !important;
    }

    #registration-form >>> .wizard-progress-with-circle {
        display: none;
    }

    #registration-form >>> .vue-form-wizard .stepTitle {
        color: #08AB99;
    }

    #registration-form >>> .wizard-nav.wizard-nav-pills li:first-child {
        display: none;
    }

    #registration-form >>> .wizard-nav > li:nth-child(2) .wizard-icon-circle {
        border-radius: 99px 0 0 99px;
    }

    #registration-form >>> .wizard-nav > li:nth-last-child(2) .wizard-icon-circle {
        border-radius: 0 99px 99px 0;
    }

    #registration-form >>> .wizard-nav.wizard-nav-pills li:nth-last-child(1) {
        display: none;
    }

    #registration-form >>> .wizard-nav > li > a > .wizard-icon-circle {
        transition: background-color 0.5s ease, border-color 0.5s ease !important;
    }

    #registration-form >>> .wizard-nav > li > a.disabled > .wizard-icon-circle {
        background-color: #F2F7FD;
        border-color: transparent !important;
    }

    #registration-form >>> .wizard-nav > li > a > .wizard-icon-circle.checked {
        background-color: #08AB9999;
        border-color: transparent !important;
    }

    #registration-form >>> .wizard-nav > li.all-active > a > .wizard-icon-circle.checked {
        background-color: #08AB99;
        border-color: transparent !important;
    }

    #registration-form >>> .wizard-nav > li.active > a > .wizard-icon-circle {
        background-color: #08AB99;
        border-color: transparent !important;
    }

    @media (max-width: 759px) {
        .header {
          margin-top: 2rem;
          margin-bottom: 1rem;
        }
        #registration-form >>> .vue-form-wizard .stepTitle {
            display: none;
        }
        #registration-form >>> .vue-form-wizard .wizard-tab-content {
          padding: 10px 20px 10px;
        }
    }

    @media (min-width: 760px) {
      .header {
          margin-bottom: 1rem;
          text-align: center;
      }
      .header-img {
        max-width: 60%;
      }
      .wizard-footer {
        margin-top: 3rem;
      }

    }
    @media (min-width: 1400px) {
      .header-img {
        max-width: 40%;
      }
    }

    #registration-form {
        padding-bottom: 4rem;
    }
</style>

<style>
    .wizard-icon-circle {
        outline: 0 !important;
    }
</style>