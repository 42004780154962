import {responseHandler} from '../Utilities.js';
import {
    GetPlatformNotificationsResult,
    GetSubscriptionStateResult,
    SetPlatformNotificationAsReadResult, SetPlatformNotificationAsSeenResult,
    SetSubscriptionStateResult
} from "./NotificationServiceResponses";

export class NotificationServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    setSubscriptionState(subscribed, notificationType) {
        let result = new SetSubscriptionStateResult();
        fetch(window.NOTIFICATION_API_URL + "/api/subscription_state/set_state/", {
            headers: this.authService.getAuthorizationHeader(),
            body: JSON.stringify({
                subscribed: subscribed,
                notificationType: notificationType,
            }),
            method: "POST"
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            result.error = error;
            result.loaded = true;
            console.error(error);

        });
        return result;
    }

    getSubscriptionState() {
        let result = new GetSubscriptionStateResult();
        fetch(window.NOTIFICATION_API_URL + "/api/subscription_state/", {
            headers: this.authService.getAuthorizationHeader(),
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            result.error = error;
            result.loaded = true;
            console.error(error);

        });
        return result;
    }

    getPlatformNotifications(pageNumber, pageSize) {
        let result = new GetPlatformNotificationsResult();

        fetch(`${window.NOTIFICATION_API_URL}/api/platform_notifications/?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: this.authService.getAuthorizationHeader(),
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.pagination = json.pagination;
            result.loaded = true;
        })
        .catch((error) => {
            result.error = error;
            result.loaded = true;
            console.error(error);

        });
        return result;
    }

    setPlatformNotificationsAsRead(notificationId) {
        let result = new SetPlatformNotificationAsReadResult();

        fetch(`${window.NOTIFICATION_API_URL}/api/platform_notifications/${notificationId}/set_as_read/`, {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST"
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            result.error = error;
            result.loaded = true;
            console.error(error);

        });
        return result;
    }

    setPlatformNotificationsAsSeen() {
        let result = new SetPlatformNotificationAsSeenResult();

        fetch(`${window.NOTIFICATION_API_URL}/api/platform_notifications/set_all_as_seen/`, {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST"
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                result.error = error;
                result.loaded = true;
                console.error(error);

            });
        return result;
    }
}