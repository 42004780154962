import {findGetParameter} from "../Utilities";

export default class RedditAnalyticsService {
    constructor() {
        this.userComesFromAdvertising = findGetParameter("utm_campaign")? true : false;
        if (this.userComesFromAdvertising) {
            this.initializePixel();
        }
    }

    initializePixel() {
        !function (w, d) {
            if (!w.rdt) {
                var p = w.rdt = function () {
                    p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments)
                };
                p.callQueue = [];
                var t = d.createElement("script");
                t.src = "https://www.redditstatic.com/ads/pixel.js", t.async = !0;
                var s = d.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(t, s)
            }
        }(window, document);
        this.rdt = rdt;
        this.rdt('init', 't2_30dldqer');
    }

    sendRegistrationCompletionEvent() {
        if (this.userComesFromAdvertising) {
            this.rdt('track', 'SignUp');
        }
    }

}
