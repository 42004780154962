import {services} from "../main";

export class Cache {
    constructor() {
        this._cache = new Map();
    }

    pushToCache(key, value) {
        this._cache.set(key, {value: value, timestamp: Date.now()});
    }

    getValueFromCache(key) {
        let valueFromCache = this._cache.get(key);
        if (!valueFromCache) {
            return null;
        }

        let now = Date.now();
        let difference = now - valueFromCache.timestamp;
        if (difference > 5000) {
            return null;
        }

        return valueFromCache.value;
    }
}

export async function responseHandler(response) {
    let statusMessage = "Error occurred with status " + response.status;
    if (response.status.toString()[0] !== '2') {
        let errorMessage;
        let json = {};
        try {
            json = await response.json();
            let error = json.error;
            errorMessage = statusMessage + ": " + error.message;
        } catch (err) {
            errorMessage = statusMessage;
        }
        throw new ApiError(response.status, json.error, errorMessage);
    }
    return response.json();
}


export class ApiError extends Error {
    constructor(status, additionalData, ...args) {
        super(...args);
        this.status = status;
        this.additionalData = additionalData;
    }
}

export function findGetParameter(parameterName) {
    let result = null;
    let tmp = [];
    let items = location.search.substr(1).split("&");
    for (let index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) {
            result = decodeURIComponent(tmp[1]);
        }

    }
    return result;
}


export function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

}

export function generateSrcSetForCloudinary(baseUrl, url) {
    let src_576 = baseUrl + "/c_limit,q_auto,w_600/" + url;
    let src_768 = baseUrl + "/c_limit,q_auto,w_800/" + url;
    let src_992 = baseUrl + "/c_limit,q_auto,w_1000/" + url;
    let src_1200 = baseUrl + "/c_limit,q_auto,w_1200/" + url;
    return src_576 + " 576w," + src_768 + " 768w," + src_992 + " 992w," + src_1200 + " 1200w"
}

export function isCookieConsentSet() {
    let consent = document.cookie.split(';').filter((item) => item.includes(window.COOKIE_CONSENT_NAME));
    if (consent.length === 0) {
        return false;
    }
    return true;
}

export function getCookieConsent() {
    let consent = document.cookie.split(';').filter((item) => item.includes(window.COOKIE_CONSENT_NAME));
    if (consent.length === 0) {
        return false;
    }
    return !consent[0].includes("=" + window.COOKIE_CONSENT_DECLINE_VALUE);
}

export function isInstantDonationChallenge(challengeId) {
    // This list is currently empty because no challenge is marked as instants donation challenge
    return [].indexOf(challengeId) >= 0;
}

export function getTechnologyQuery(technology) {
    let technologyQuery = technology.language.toLowerCase();
    if (technology.name) {
        technologyQuery = technologyQuery + "+" + technology.name.replace(/\s/g, "").toLowerCase()
    }
    return technologyQuery;
}

export class APIResponse {
    constructor(async) {
        this.async = async;
        this.error = null;
        this.apiData = undefined;
        this.loaded = false;
    }

    hasLoaded() {
        return this.loaded && this.error === null;
    }
}

export class MockResponse {
    respond(data) {
        let response = new APIResponse(
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    response.apiData = data;
                    response.loaded = true;
                    resolve(data);
                }, 500);
            })
        );
        return response;
    }

    fail(data) {
        let response = new APIResponse(
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    response.error = "Failed the Request"
                    response.loaded = true;
                    reject("Failed the Request");
                }, 500);
            })
        );
        return response;
    }
}

export class MultiDataRequest {
    constructor(serviceMethod) {
        this.serviceMethod = serviceMethod;
        this.handlerFunction = this.defaultHandler;
    }

    defaultHandler(responseData) {
        return responseData;
    }

    handler(handlerFunction) {
        this.handlerFunction = handlerFunction;
    }

    /**
     * @param {Array<Array<(string|number)>>} data - Array with the service-call params as Array
     */
    get(data) {
        if (!Array.isArray(data)) {
            throw new Error("Data must be an array for multi request");
        }
        // Should return API Response with linked data
        let result = new APIResponse(
            new Promise((resolve, reject) => {
                let returnData = [];
                let errorData = [];
                if (data.length === 0) {
                    console.error("No data provided for service request");
                    // This makes setting the result error after result init. Maybe ther is a better way to do this but it works.
                    setTimeout(() => result.error = "No data provided for service request", 0);
                    reject("No data provided for service request");
                }
                data.forEach((dataSet) => {
                    let request = this.serviceMethod(...dataSet)
                    if (!request.async) {
                        throw new Error("This API call is based on the old synchronous way. Please migrate to a new hybrid service method.")
                    }
                    request.async
                        .then(responseData => {
                            returnData.push(this.handlerFunction(responseData));
                            this.finishedCheck(resolve, reject, result, returnData, errorData, data);
                        })
                        .catch((error) => {
                            errorData.push({dataSet: error});
                            this.finishedCheck(resolve, reject, result, returnData, errorData, data);
                        });
                });
            })
        )
        return result;
    }

    /**
     * @private
     */
    finishedCheck(resolve, reject, result, returnData, errorData, data) {
        if (returnData.length + errorData.length !== data.length) {
            return;
        }
        if (errorData.length === data.length) {
            result.error = errorData;
            console.error(errorData);
            reject(errorData);
            return;
        }
        if (errorData.length > 0) {
            result.error = errorData;
            console.error(errorData);
        }
        result.loaded = true;
        result.apiData = returnData;
        resolve(returnData);
        return;
    }
}

const measureScrollbar = () => {
    const scrollDiv = document.createElement('div')
    scrollDiv.className = 'modal-scrollbar-measure'
    const htmlRooElement = document.getElementsByTagName('html')[0];
    htmlRooElement.append(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    htmlRooElement.removeChild(scrollDiv)
    return scrollbarWidth
};

/**
 * This function can be used for modals to prevent the background from scrolling
 */
export const makeBackgroundNotScrollable = () => {
    // This code is needed for the ios bug https://bugs.webkit.org/show_bug.cgi?id=153852 and to make the background not scrollable
    const htmlRootElement = document.getElementsByTagName('html')[0];
    const offsetY = window.scrollY;
    document.body.style.top = `${-offsetY}px`;
    const scrollbarWidth = measureScrollbar();
    htmlRootElement.style.paddingRight = `${scrollbarWidth}px`;
    htmlRootElement.classList.add('modal-open');
}

/**
 * This function should be used in destroy method of the modal to reactivate scrolling
 */
export const makeBackgroundScrollable = () => {
    // This code is needed for the ios bug https://bugs.webkit.org/show_bug.cgi?id=153852 and to make the background scrollable again
    const htmlRootElement = document.getElementsByTagName('html')[0];
    const offsetY = Math.abs(parseInt(document.body.style.top || 0, 10));
    htmlRootElement.classList.remove('modal-open');
    htmlRootElement.style.removeProperty('padding-right');
    document.body.style.removeProperty('top');
    window.scrollTo(0, offsetY || 0);
}

export const getShortLink = (shortCode) => {
    if (!shortCode) {
        return null;
    }
    return `${window.SHORTENER_BASE_URL}/link/${shortCode}`;
}

export const callShortenerLinkForTracking = (url) => {
    fetch(url, {
        method: 'GET',
        headers: services.auth.getAuthorizationHeader(),
        redirect: 'manual'
    });
}

export const getLanguageBubbleCSS = (selectedTechnology) => {
    return {
        'bg-python': selectedTechnology.language.toLocaleLowerCase() === 'python',
        'bg-dockerfile': selectedTechnology.language.toLocaleLowerCase() === 'docker',
        'bg-java': selectedTechnology.language.toLocaleLowerCase() === 'java',
        'bg-javascript': selectedTechnology.language.toLocaleLowerCase() === 'javascript',
        'bg-csharp': selectedTechnology.language.toLocaleLowerCase() === 'csharp',
        'bg-cpp': selectedTechnology.language.toLocaleLowerCase() === 'cpp',
        'bg-php': selectedTechnology.language.toLocaleLowerCase() === 'php',
        'bg-abap': selectedTechnology.language.toLocaleLowerCase() === 'abap',
        'bg-kotlin': selectedTechnology.language.toLocaleLowerCase() === 'kotlin',
        'bg-powershell': selectedTechnology.language.toLocaleLowerCase() === 'powershell',
        'bg-rust': selectedTechnology.language.toLocaleLowerCase() === 'rust',
    }
}