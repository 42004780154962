<template>
    <div class="row" style="margin-top: 7rem;">
        <div v-if="!errorWhileForwarding" class="col-lg-12">
            <div class="text-center challenge-error">
                <i class="loading-spinner loading-spinner-big loading-spinner-green"></i>
            </div>
        </div>
        <div v-if="errorWhileForwarding" class="col-lg-12">
            <ErrorComponent message="Die automatische Weiterleitung hat nicht funktioniert."
                            style="text-align: center;">
                <h4>Dann machen wir es eben manuell. Dafür bitte einfach</h4>
                <router-link :to="{name:'login'}">
                    <button style="font-weight: bold; margin-top: 1rem;" class="btn">Hier klicken</button>
                </router-link>
            </ErrorComponent>
            <h3>
            </h3>
        </div>
    </div>
</template>

<script>
    import {services} from '../main.js'
    import ErrorComponent from "./ErrorComponent";

    export default {
        name: "callback",
        components: {ErrorComponent},
        data() {
            return {
                timeout: null,
                errorWhileForwarding: false,
            }
        },
        mounted() {
            // TODO: this is not perfect, when the callback error occures we should catch the error instead of a hard coded timeout
            this.timeout = setTimeout(() => {
                this.errorWhileForwarding = true
            }, 5000);
        },
        destroyed() {
            clearTimeout(this.timeout);
        },
    }
</script>
