import TechnologyServiceNetwork from "./TechnologyServiceNetwork";
import TechnologyServiceStub from "./TechnologyServiceStub";


export default class TechnologyService {

    constructor(auth) {
        this.authService = auth;
    }

    getTechnologies() {
        if (typeof window.TECHNOLOGY_API_URL === "undefined" || window.TECHNOLOGY_API_URL === "") {
            console.info("window.TECHNOLOGY_API_URL is not set! Using Stub data!");
            return new TechnologyServiceStub(this.authService).getTechnologies();
        }
        return new TechnologyServiceNetwork(this.authService).getTechnologies();
    }

    getJobWizardTechnologies() {
        if (typeof window.TECHNOLOGY_API_URL === "undefined" || window.TECHNOLOGY_API_URL === "") {
            console.info("window.TECHNOLOGY_API_URL is not set! Using Stub data!");
            return new TechnologyServiceStub(this.authService).getJobWizardTechnologies();
        }
        return new TechnologyServiceNetwork(this.authService).getJobWizardTechnologies();
    }

    createTechnology(technologyData) {
        if (typeof window.TECHNOLOGY_API_URL === "undefined" || window.TECHNOLOGY_API_URL === "") {
            console.info("window.TECHNOLOGY_API_URL is not set! Using Stub data!");
            return new TechnologyServiceStub(this.authService).createTechnology(technologyData);
        }
        return new TechnologyServiceNetwork(this.authService).createTechnology(technologyData);
    }

    updateUserTechnologies(technologiesData) {
        if (typeof window.TECHNOLOGY_API_URL === "undefined" || window.TECHNOLOGY_API_URL === "") {
            console.info("window.TECHNOLOGY_API_URL is not set! Using Stub data!");
            return new TechnologyServiceStub(this.authService).updateUserTechnologies(technologiesData);
        }
        return new TechnologyServiceNetwork(this.authService).updateUserTechnologies(technologiesData);
    }

    getUserTechnologies(publicUserId) {
        if (typeof window.TECHNOLOGY_API_URL === "undefined" || window.TECHNOLOGY_API_URL === "") {
            console.info("window.TECHNOLOGY_API_URL is not set! Using Stub data!");
            return new TechnologyServiceStub(this.authService).getUserTechnologies(publicUserId);
        }
        return new TechnologyServiceNetwork(this.authService).getUserTechnologies(publicUserId);
    }

    createUserTechnology(technologyId) {
        if (typeof window.TECHNOLOGY_API_URL === "undefined" || window.TECHNOLOGY_API_URL === "") {
            console.info("window.TECHNOLOGY_API_URL is not set! Using Stub data!");
            return new TechnologyServiceStub(this.authService).createUserTechnology(technologyId);
        }
        return new TechnologyServiceNetwork(this.authService).createUserTechnology(technologyId);
    }

    removeUserTechnology(technologyId) {
        if (typeof window.TECHNOLOGY_API_URL === "undefined" || window.TECHNOLOGY_API_URL === "") {
            console.info("window.TECHNOLOGY_API_URL is not set! Using Stub data!");
            return new TechnologyServiceStub(this.authService).removeUserTechnology(technologyId);
        }
        return new TechnologyServiceNetwork(this.authService).removeUserTechnology(technologyId);
    }
}
