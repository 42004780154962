<template>
            <li class="dropdown platform-notification-list" v-if="isAuthenticated">
                <a href="#"
                   class="dropdown-toggle btn btn-secondary navbar-btn"
                   data-toggle="dropdown"
                   role="button"
                   aria-haspopup="true"
                   aria-expanded="false"
                   @click="setNotificationsAsSeen()"
                >
                    <span v-if="!allNotificationsSeen" class="navbar-notification"></span>
                    <i class="dev-notification notification-bell"></i>
                </a>
                    <div class="dropdown-menu navbar-messages" v-bind:style="{top: this.messageOffset + 'px'}">
                        <a
                            v-for="notification in platformNotificationsResult"
                            :href="notification.link"
                            class="navbar-messages__item"
                            v-bind:class="{ unread: !notification.isRead }"
                            @click="setNotificationAsRead(notification)">
                            <div class="navbar-messages__avatar">
                                <img :src="notification.icon">
                            </div>
                            <div class="navbar-messages__body">
                                <h5 class="navbar-messages__sender">
                                    {{notification.subject}}
                                </h5>
                                <p class="navbar-messages__content" v-html="notification.content">
                                </p>
                                <h5><small>{{ getHumanReadableTime(notification.time) }}</small></h5>
                            </div>
                        </a>
    <!--                    <a v-if="platformNotificationsResult.length > 0" class="navbar-messages__view-all" >-->
    <!--                        Alle anzeigen: NotImplementedException :)-->
    <!--                    </a>-->
                        <a v-if="platformNotificationsResult.length === 0" class="navbar-messages__view-all" >
                            Keine Benachrichtigungen :(
                        </a>
                    </div>
            </li>
</template>

<script>
    import moment from "moment"
    import {services} from "../main"
    import {mapState} from 'vuex';

    export default {
        name: "platform-notification-component",
        data() {
            return {
                pageNumber: 1,
                pageSize: 5,
                platformNotificationsResult: [],
                platformNotificationLoading: {loaded: false, error: null},
                platformNotificationSocket: null,
                messageOffset: 61
            }
        },
        computed: {
            ...mapState([
                'isAuthenticated',
            ]),
            allNotificationsSeen() {
                if (this.platformNotificationsResult.length == 0) {
                    return true;
                }

                let result = true;
                for (let notification of this.platformNotificationsResult) {
                    result = result && notification.isSeen;
                }
                return result;
            },
        },
        watch: {
            isAuthenticated() {
                if (!this.isAuthenticated) {
                    return;
                }
                this.platformNotificationsResult = services.notificationService.getPlatformNotifications(this.pageNumber, this.pageSize);
                this.createNotificationConnection();
            },
            "platformNotificationLoading.loaded": function() {
                if (this.platformNotificationLoading.error == null && this.platformNotificationLoading.loaded == true) {
                    this.platformNotificationsResult = this.platformNotificationLoading.apiData;
                }
            },
        },
        mounted() {
            if (this.isAuthenticated) {
                this.platformNotificationLoading = services.notificationService.getPlatformNotifications(this.pageNumber, this.pageSize);
                this.createNotificationConnection();
            }
            document.addEventListener('scroll', this.calcMessageOffset)
        },
        components: {},
        methods: {
            getHumanReadableTime(timestamp) {
                // TODO: Move this local configuration to a global level
                moment.locale('de');
                return moment(timestamp).fromNow();
            },
            setNotificationAsRead(notification) {
                notification.isRead = true;
                services.notificationService.setPlatformNotificationsAsRead(notification.id);
            },
            setNotificationsAsSeen(){
                for(let notification of this.platformNotificationsResult){
                    notification.isSeen = true;
                }
                services.notificationService.setPlatformNotificationsAsSeen();
            },
            createNotificationConnection() {
                const userID = services.auth.getUserID();
                this.platformNotificationSocket = services.notificationsService.getPlatformNotificationsServiceSocket();
                this.platformNotificationSocket.on(`notifications_notification_${userID}_platform-notifications`, () => {
                    this.platformNotificationLoading = services.notificationService.getPlatformNotifications(this.pageNumber, this.pageSize);
                });
            },
            calcMessageOffset(){
                this.messageOffset = window.innerWidth <= 767 ? window.scrollY > 61 ? 0 : 61-window.scrollY : 61;
            }
        },
    }
</script>

<style scoped>
    .navbar-messages__item.unread {
        border-left: 4px solid #08AB99;
    }
    .navbar-messages__item:not(.unread) {
        padding-left: 16px;
    }
    .platform-notification-list {
        margin-right: 15px;
    }
    @media (max-width: 767px) {
        .dropdown-menu.navbar-messages{
            min-width: initial;
        }
    }
    .notification-bell {
      color: #061a2d;
      font-size: 1.8rem;
    }

    .navbar-btn {
      margin-top: 14px;
      margin-bottom: 14px;
    }
    .dropdown-toggle {
      padding: 7px 10px;
    }
    .nav .open > a,
    .nav .open > a:hover,
    .nav .open > a:focus {
      background-color: #cee3f8;
      color: #061a2d;
    }

</style>
