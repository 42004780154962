export class GetTechnologiesResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class CreateTechnologyResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class UpdateUserTechnologiesResult {
     constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
     }
}

export class GetUserTechnologiesResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class CreateUserTechnologyResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class RemoveUserTechnologyResult {
    constructor() {
        this.apiData = {};
        this.error = null;
        this.loaded = false;
    }
}

