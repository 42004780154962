<template>
    <div class="achievement-notification-wrapper">
        <transition name="bounce" mode="out-in"  v-on:after-enter="removeFirstAchievementFromQueue()" :duration="10000">
            <div v-if="achievementToShow" :key="achievementToShow.id" @click="hideNotification()"
                 class="achievement col-lg-3 col-sm-6 col-xs-10 " :class="{bounceOutDown: this.hide}">
                <achievement-svg :title="achievementToShow.title" :icon="achievementToShow.icon" :description="achievementToShow.description" :is-notification="true" :score="achievementToShow.score" />
            </div>
        </transition>
    </div>
</template>

<script>
    import {services} from "../../main.js"
    import {mapState} from 'vuex';
    import AchievementSvg from "./AchievementSvg";

    export default {
        name: "achievement-notification",
        components: {AchievementSvg},
        data() {
            return {
                socket: null,
                achievementNotificationSocket: null,
                achievementQueue: [],
                runAnimation: false,
                achievementIndex: 0,
                hide: false,
            }
        },
        created() {

        },
        computed: {
            achievementToShow: function () {
                if (this.achievementQueue.length > 0) {
                    return this.achievementQueue[0];
                }
            },
            ...mapState([
                'isAuthenticated',
            ]),
        },
        mounted() {
            if (this.isAuthenticated) {
                this.createAchievementConnection();
            }
        },
        watch: {
            isAuthenticated() {
                if (!this.isAuthenticated) {
                    return;
                }
                this.createAchievementConnection();
            },
        },
        methods: {
            createAchievementConnection: function () {
                this.achievementNotificationSocket = services.notificationsService.getAchievementNotificationsServiceSocket();
                const userID = services.auth.getUserID();
                this.achievementNotificationSocket.on(`notifications_gamification_${userID}_achievement-unlocked`, (data) => {
                    this.achievementIndex += 1;
                    let json = JSON.parse(data);
                    let achievement = {
                        title: json.title,
                        icon: json.icon,
                        description: json.description,
                        id: this.achievementIndex
                    };
                    this.achievementQueue.push(achievement);
                });
            },
            hideNotification: function () {
                this.hide = true;
                this.removeFirstAchievementFromQueue();
            },
            removeFirstAchievementFromQueue: function () {
                this.hide = true;
                this.achievementQueue.splice(0, 1);
            }
        },
    }
</script>

<style scoped>
    .achievement {
        position: fixed;
        bottom: 15px;
        right: 10px;
        z-index: 100;
        display: block;
    }

    @keyframes bounceInUp {
        0%, 60%, 75%, 90%, to {
            -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1)
        }
        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0)
        }
        60% {
            opacity: 1;
            -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0)
        }
        75% {
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0)
        }
        90% {
            -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0)
        }
        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0)
        }
    }

    @keyframes bounceOutDown {
        20% {
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0)
        }
        40%, 45% {
            opacity: 1;
            -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0)
        }
        to {
            opacity: 0;
            -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0)
        }
    }

    .bounce-enter-active {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        animation: bounceInUp .75s ;
    }

    .bounce-leave-active {
        animation: bounceOutDown .75s forwards;
    }


    .fade-enter-active {
        transition: opacity .5s;
    }

    .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }
</style>
