import {responseHandler, APIResponse} from "../Utilities";
import {ApplyToJobOfferResponse, CheckIfJobOfferExistsResponse, InteractResult} from "./InteractsServiceResponse";

export default class InteractsServiceNetwork {

    constructor(auth) {
        this.authService = auth;
    }

    checkIfJobOfferExists(vacancyIdFromCompanyService) {
        let result = new CheckIfJobOfferExistsResponse();
        fetch(window.INTERACTS_API_URL + "/api/vacancy/" + vacancyIdFromCompanyService + "/check_if_job_offer_exist/", {
            headers: this.authService.getAuthorizationHeader()
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    applyToJobOffer(vacancyIdFromCompanyService) {
        let result = new ApplyToJobOfferResponse();
        fetch(window.INTERACTS_API_URL + "/api/job_offer/" + vacancyIdFromCompanyService + "/apply/", {
            method: "POST",
            headers: this.authService.getAuthorizationHeader()
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    addWorkSampleToCompanyId(companyId, challengeId) {
        let url = window.INTERACTS_API_URL + "/api/company/" + companyId + "/add_work_sample/";
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let apiFetch = await fetch(url, {
                        method: "POST",
                        headers: this.authService.getAuthorizationHeader(),
                        body: JSON.stringify({challengeId: challengeId}),
                    });

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        );
        return result;
    }

    getSharedWorksamples(companyId) {
        let url = window.INTERACTS_API_URL + "/api/company/" + companyId + "/get_shared_work_samples/";
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let apiFetch = await fetch(url, {
                        method: "GET",
                        headers: this.authService.getAuthorizationHeader(),
                    });

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        );
        return result;
    }

    cachePreChallengeInteract(challengeId, language, userTokenId, region, profession) {
        let result = new InteractResult();
        let url = `${window.INTERACTS_API_URL}/interacts_preview/interact_job_tunnel/${challengeId}/pre_challenge_content/?user_token=${userTokenId}&programming_language=${language}&region=${region}&profession=${profession}`;
        fetch(url, {
            method: "GET",
        })
            .then(function (response) {
                return response.text();
            })
            .then((html) => {
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getInteractResult(challengeId, language, userTokenId, region, profession) {
        let result = new InteractResult();
        let url = `${window.INTERACTS_API_URL}/interacts/${challengeId}/result/?programming_language=${language}`;
        if (userTokenId) {
            url = `${window.INTERACTS_API_URL}/interacts/${challengeId}/result/?user_token=${userTokenId}&programming_language=${language}&region=${region}&profession=${profession}`;
        }
        fetch(url, {
            method: "GET",
        })
            .then(function (response) {
                return response.text();
            })
            .then((html) => {
                // Needs injection to correctly load the image resources from the interacts service
                result.html = html.replace("<head>", `<head><style> html, body{height: 100%; margin: 0px;} </style><base href='${window.INTERACTS_API_URL}'>`);
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getJobOfferDetails(jobOfferId) {
        let url = window.INTERACTS_API_URL + "/api/vacancy/" + jobOfferId + "/";
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let apiFetch = await fetch(url, {
                        headers: this.authService.getAuthorizationHeader(),
                    });

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        );
        return result;
    }

    applyToCompany(companyId) {
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let apiFetch = await fetch(window.INTERACTS_API_URL + `/api/company/${companyId}/apply/`, {
                        headers: this.authService.getAuthorizationHeader(),
                        method: "POST",
                    })

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        );
        return result;
    }

    getJobRequests(companyId) {
        // create query param
        let queryParam = "";
        if (companyId) {
            queryParam = `?company_id=${companyId}`;
        }
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let apiFetch = await fetch(window.INTERACTS_API_URL + `/api/job_request/${queryParam}`, {
                        headers: this.authService.getAuthorizationHeader(),
                        method: "GET",
                    })

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        );
        return result;
    }
}
