<template>
    <div class="feedback-button">Feedback geben</div>
</template>

<script>
    export default {
        name: "FeedbackButton"
    }
</script>

<style scoped>
.feedback-button {
    right: 0;
    bottom: 0;
    border-bottom: none;
    border-right: none;
    background-color: #fff;
    color: #444 !important;
    font-size: 11px;
    padding: 6px;
    -webkit-box-shadow: -2px -2px 20px rgba(0, 0, 0, 0.15);
    box-shadow: -2px -2px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px 0 0 0;
    position: fixed;
    border-top: none;
    font-weight: bold;
    display: block;
    white-space: nowrap;
    text-decoration: none !important;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 1000;
}
.feedback-button:hover {
    cursor: pointer;
}
</style>