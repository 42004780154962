import {APIResponse, Cache, responseHandler} from "../Utilities";
import {
    ApplyForBeingACreatorResult,
    CoinsLeaderBoard,
    GetCvResult,
    MultiplePublicUserProfileDataResult,
    PublicUserProfileResult,
    UserProfileChangeResult,
    UploadCvResult,
    UserProfilesData,
    UserProfileResult,
    FinishOnboarding,
    SubmitConnectionCode,
    ListConnections, XingInformation, BusinessNetworkUserInformation
} from "./UserServiceResponses";

const cache = new Cache();

function getFromCacheIfThere(withoutCache, key, result) {
    if (withoutCache) {
        cache.pushToCache(key, null);
    }

    let valueFromCache = cache.getValueFromCache(key);
    if (valueFromCache) {
        return valueFromCache;
    }

    cache.pushToCache(key, result);
    return null;
}

export class UserServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    getUserProfile() {
        let result = new UserProfileResult();
        fetch(window.USER_API_URL + "/api/user_profile/", {
            headers: this.authService.getAuthorizationHeader(),
            credentials: 'include'
        })
            .then(responseHandler)
            .then((json) => {
                result.user_profile = json;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    changeUserProfile(data) {
        let result = new UserProfileChangeResult();
        fetch(window.USER_API_URL + "/api/user_profile/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify(data)
        })
            .then(responseHandler)
            .then((json) => {
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getProfileDataOfMultipleUsers(user_ids) {
        let result = new UserProfilesData();
        fetch(window.USER_API_URL + "/api/user_profile/get_data_for_user_ids/", {
            headers: this.authService.getAuthorizationHeader(),
            body: JSON.stringify({user_ids: user_ids}),
            method: "POST"
        })
            .then(responseHandler)
            .then((json) => {
                result.userProfilesData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.error = error;
                result.loaded = true;
            });
        return result;
    }

    finishOnboarding(data) {
        let result = new FinishOnboarding();
        fetch(window.USER_API_URL + "/api/user_profile/finish_onboarding/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify(data),
            credentials: 'include',
        })
            .then(responseHandler)
            .then((json) => {
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getCoinsLeaderBoard(pageId = 0) {
        let url = window.USER_API_URL + "/api/coin_leaderboard/" + "?pageID=" + pageId;
        let result = new CoinsLeaderBoard();
        fetch(url, {
            method: 'GET',
            headers: this.authService.getAuthorizationHeader()
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
                if ("pagination" in json) {
                    result.pagination = json.pagination;
                }
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    applyForBeingACreator(creatorInterest) {
        let result = new ApplyForBeingACreatorResult();
        fetch(window.USER_API_URL + "/api/user_profile/apply_for_being_a_creator/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify({creatorInterest: creatorInterest})
        })
            .then(responseHandler)
            .then((json) => {
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }


    getPublicUserProfile(userId) {
        let result = new PublicUserProfileResult();
        fetch(window.USER_API_URL + "/public_api/user_profile_public/" + userId + "/", {
            headers: this.authService.getAuthorizationHeader(),
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getDataForPublicUserIds(userIds) {
        let result = new MultiplePublicUserProfileDataResult();
        fetch(window.USER_API_URL + "/api/user_profile/get_data_for_public_user_ids/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify({"publicUserIds": userIds})
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    uploadCv(cvData) {
        let result = new UploadCvResult();
        fetch(window.USER_API_URL + "/api/cv/upload/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify({data: cvData})
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getCv() {
        let result = new GetCvResult();
        fetch(window.USER_API_URL + "/api/cv/", {
            headers: this.authService.getAuthorizationHeader()
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    saveJsonResume(jsonResume) {

        let result = new UploadCvResult();
        fetch(window.USER_API_URL + "/api/cv/save_json_resume/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify({data: {jsonResume: jsonResume}})
        })
            .then(responseHandler)
            .then((json) => {
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getLogoDrawingsByPublicUserId(publicUserID) {
        let result = new Promise((resolve, reject) => {
            fetch(window.USER_API_URL + `/public_api/user_profile/${publicUserID}/logo/`, {
                method: "GET",
            })
                .then(responseHandler)
                .then((json) => {
                    resolve(json);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
        return result;
    }

    addOrUpdateLogoDrawingInUserProfile(drawingId, code) {
        let result = new Promise((resolve, reject) => {
            fetch(window.USER_API_URL + "/api/logo/add_or_updated_drawing/", {
                headers: this.authService.getAuthorizationHeader(),
                method: "POST",
                body: JSON.stringify({code: code, ...(drawingId && {drawingId: drawingId})})
            })
                .then(responseHandler)
                .then((json) => {
                    resolve(json);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
        return result;
    }

    removeLogoDrawingInUserProfile(drawingId) {
        let result = new Promise((resolve, reject) => {
            fetch(window.USER_API_URL + `/api/logo/${drawingId}/delete/`, {
                headers: this.authService.getAuthorizationHeader(),
                method: "DELETE",
            })
                .then(responseHandler)
                .then((json) => {
                    resolve(json);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
        return result;
    }

    submitConnectionCode(connection, code, redirectUrl) {
        let result = new SubmitConnectionCode();
        fetch(window.USER_API_URL + "/api/connections/submit_connection_code/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify({connection: connection, code: code, redirectUrl: redirectUrl})
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    listConnections() {
        let result = new ListConnections();

        fetch(window.USER_API_URL + "/api/connections/list_connections/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "GET"
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getBusinessNetworkUserInformation(businessNetworkPlattform) {
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let apiFetch = await fetch(window.USER_API_URL + `/api/business_social_network/${businessNetworkPlattform}/information/`, {
                        headers: this.authService.getAuthorizationHeader(),
                        method: "GET",
                    })

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        );
        return result;
    }

    postBusinessNetworkUserInformation(businessNetworkPlattform, publicProfileUrl) {
        let result = new BusinessNetworkUserInformation();
        fetch(window.USER_API_URL + `/api/business_social_network/${businessNetworkPlattform}/information/`, {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify({publicProfileUrl: publicProfileUrl})
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    deleteBusinessNetworkUserInformation(businessNetworkPlattform) {
        let result = new BusinessNetworkUserInformation();
        fetch(window.USER_API_URL + `/api/business_social_network/${businessNetworkPlattform}/information/`, {
            headers: this.authService.getAuthorizationHeader(),
            method: "DELETE",
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getMyDreamCompany() {
        const url = window.USER_API_URL + '/api/my_dream_company/'
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                  try {
                    let apiFetch = await fetch(url, {
                        headers: this.authService.getAuthorizationHeader(),
                        method: "GET"
                    });

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        )
        return result;
    }

    postMyDreamCompany(postData) {
        const url = window.USER_API_URL + '/api/my_dream_company/'
        let result = new APIResponse(
            new Promise(async (resolve, reject) => {
                try {
                    let apiFetch = await fetch(url, {
                        method: 'POST',
                        headers: this.authService.getAuthorizationHeader(),
                        body: JSON.stringify(postData)
                    })

                    let json = await responseHandler(apiFetch);
                    result.apiData = json.data;
                    result.loaded = true;
                    resolve(json.data);
                } catch (error) {
                    console.error(error);
                    result.loaded = true;
                    result.error = error;
                    reject(error);
                }
            })
        )
        return result
    }

}
