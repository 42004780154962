import {responseHandler} from '../Utilities.js';
import {
    GetTechnologiesResult,
    CreateTechnologyResult, GetUserTechnologiesResult, CreateUserTechnologyResult, RemoveUserTechnologyResult,
    UpdateUserTechnologiesResult,
} from "./TechnologyServiceResponses";

export default class TechnologyServiceNetwork {

    constructor(auth) {
        this.authService = auth;
    }

    getTechnologies() {
        let result = new GetTechnologiesResult();
        fetch(window.TECHNOLOGY_API_URL + "/api/technologies/",
            {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getJobWizardTechnologies(userId) {
        let result = new GetTechnologiesResult();
        fetch(window.TECHNOLOGY_API_URL + "/api/jobwizard_technologies/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'GET'
            }
        )
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    createTechnology(technologyData) {
        let result = new CreateTechnologyResult();
        fetch(window.TECHNOLOGY_API_URL + "/api/technologies/create/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
                body: JSON.stringify(technologyData)
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    updateUserTechnologies(technologiesData) {
        let result = new UpdateUserTechnologiesResult();
        fetch(window.TECHNOLOGY_API_URL + "/api/user_technologies/update/",
        {
            headers: this.authService.getAuthorizationHeader(),
            method: 'POST',
            body: JSON.stringify({
              technologiesData: technologiesData,
            })
        })
        .then(responseHandler)
        .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
        })
        .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
        });
        return result;
    }

    getUserTechnologies(publicUserId) {
        let result = new GetUserTechnologiesResult();
        fetch(window.TECHNOLOGY_API_URL + "/public_api/user_technologies/" + publicUserId + "/")
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    createUserTechnology(technologyId) {
        let result = new CreateUserTechnologyResult();
        fetch(window.TECHNOLOGY_API_URL + "/api/user_technologies/create/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
                body: JSON.stringify({id: technologyId})
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    removeUserTechnology(technologyId) {
        let result = new RemoveUserTechnologyResult();
        fetch(window.TECHNOLOGY_API_URL + "/api/user_technologies/remove/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
                body: JSON.stringify({id: technologyId})
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }
}
