import {RewardServiceStub} from "./RewardServiceStub";
import {RewardServiceNetwork} from "./RewardServiceNetwork";

export default class RewardService {

    constructor(auth) {
        this.authService = auth;
    }

    getAvailableRewards() {
        if (typeof window.REWARD_API_URL === "undefined" || window.REWARD_API_URL === "") {
            console.info("window.REWARD_API_URL is not set! Using Stub data!");
            return new RewardServiceStub(this.authService).getAvailableRewards();
        }
        return new RewardServiceNetwork(this.authService).getAvailableRewards();
    }

    getUserCoins(withoutCache = false, valueBefore = 0) {
        if (typeof window.REWARD_API_URL === "undefined" || window.REWARD_API_URL === "") {
            console.info("window.REWARD_API_URL is not set! Using Stub data!");
            return new RewardServiceStub(this.authService).getUserCoins(withoutCache, valueBefore);
        }
        return new RewardServiceNetwork(this.authService).getUserCoins(withoutCache, valueBefore);
    }

    exchangeReward(rewardId, email, userAddress) {
        if (typeof window.REWARD_API_URL === "undefined" || window.REWARD_API_URL === "") {
            console.info("window.REWARD_API_URL is not set! Using Stub data!");
            return new RewardServiceStub(this.authService).exchangeReward(rewardId, email, userAddress);
        }
        return new RewardServiceNetwork(this.authService).exchangeReward(rewardId, email, userAddress);
    }

    redeemCouponCode(couponCode) {
        if (typeof window.REWARD_API_URL === "undefined" || window.REWARD_API_URL === "") {
            console.info("window.REWARD_API_URL is not set! Using Stub data!");
            return new RewardServiceStub(this.authService).redeemCouponCode(couponCode);
        }
        return new RewardServiceNetwork(this.authService).redeemCouponCode(couponCode);
    }

    getDonations() {
        if (typeof window.REWARD_API_URL === "undefined" || window.REWARD_API_URL === "") {
            console.info("window.REWARD_API_URL is not set! Using Stub data!");
            return new RewardServiceStub(this.authService).getDonations();
        }
        return new RewardServiceNetwork(this.authService).getDonations();
    }

    donate(coins) {
        if (typeof window.REWARD_API_URL === "undefined" || window.REWARD_API_URL === "") {
            console.info("window.REWARD_API_URL is not set! Using Stub data!");
            return new RewardServiceStub(this.authService).donate(coins);
        }
        return new RewardServiceNetwork(this.authService).donate(coins);
    }

    getDonationLeaderBoard(pageId=null) {
        if (typeof window.REWARD_API_URL === "undefined" || window.REWARD_API_URL === "") {
            console.info("window.REWARD_API_URL is not set! Using Stub data!");
            return new RewardServiceStub(this.authService).getDonationLeaderBoard(pageId);
        }
        return new RewardServiceNetwork(this.authService).getDonationLeaderBoard(pageId);
    }
}
