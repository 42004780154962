<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60px" height="53px" viewBox="0 0 60 53" version="1.1">
    <!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch -->
    <title>092 - Laptop Home</title>
    <desc>Created with Sketch.</desc>
    <defs/>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="092---Laptop-Home" fill-rule="nonzero">
            <path d="M59,43 L59,46 C59,49.3137085 56.3137085,52 53,52 L7,52 C3.6862915,52 1,49.3137085 1,46 L1,43 C1,42.4477153 1.44771525,42 2,42 L20,42 L20,44 C20,45.6568542 21.3431458,47 23,47 L37,47 C38.6568542,47 40,45.6568542 40,44 L40,42 L58,42 C58.5522847,42 59,42.4477153 59,43 Z" id="Shape" fill="#BFBFBF"/>
            <path d="M58,42 L55,42 C55.5522847,42 56,42.4477153 56,43 L56,46 C56,49.3137085 53.3137085,52 50,52 L53,52 C56.3137085,52 59,49.3137085 59,46 L59,43 C59,42.4477153 58.5522847,42 58,42 Z" id="Shape" fill="#808080"/>
            <path d="M40,42 L40,44 C40,45.6568542 38.6568542,47 37,47 L23,47 C21.3431458,47 20,45.6568542 20,44 L20,42 L40,42 Z" id="Shape" fill="#BFBFBF"/>
            <path d="M37,42 L37,44 C37,45.6568542 35.6568542,47 34,47 L37,47 C38.6568542,47 40,45.6568542 40,44 L40,42 L37,42 Z" id="Shape" fill="#808080"/>
            <path d="M53,1 L7,1 C4.790861,1 3,2.790861 3,5 L3,42 L57,42 L57,5 C57,2.790861 55.209139,1 53,1 Z M41.49,22.34 C40.7473715,23.1575135 39.484887,23.2244297 38.66,22.49 L38,21.9 L38,31 C38,31.5522847 37.5522847,32 37,32 L23,32 C22.4477153,32 22,31.5522847 22,31 L22,21.9 L21.34,22.49 C20.8076743,22.9687359 20.0603825,23.1271137 19.3796221,22.905474 C18.6988618,22.6838344 18.1880567,22.1158496 18.0396221,21.415474 C17.8911876,20.7150985 18.1276743,19.9887359 18.66,19.51 L28.66,10.51 C29.4238629,9.82982281 30.5761371,9.82982281 31.34,10.51 L35,13.8 L35,12 C35,11.4477153 35.4477153,11 36,11 L38,11 C38.5522847,11 39,11.4477153 39,12 L39,17.4 L41.34,19.51 C42.1612478,20.2508521 42.2283308,21.5164847 41.49,22.34 Z" id="Shape" fill="#FFDC00"/>
            <path d="M53,1 L50,1 C52.209139,1 54,2.790861 54,5 L54,42 L57,42 L57,5 C57,2.790861 55.209139,1 53,1 Z" id="Shape" fill="#FF8401"/>
            <path d="M39,12 L39,17.4 L35,13.8 L35,12 C35,11.4477153 35.4477153,11 36,11 L38,11 C38.5522847,11 39,11.4477153 39,12 Z" id="Shape" fill="#FC4012"/>
            <path d="M38,21.9 L38,31 C38,31.5522847 37.5522847,32 37,32 L33,32 L33,26 C32.9967052,24.8967981 32.1032019,24.0032948 31,24 L29,24 C27.8967981,24.0032948 27.0032948,24.8967981 27,26 L27,32 L23,32 C22.4477153,32 22,31.5522847 22,31 L22,21.9 L30,14.69 L38,21.9 Z" id="Shape" fill="#FFFFFF"/>
            <path d="M41.49,22.34 C40.7473715,23.1575135 39.484887,23.2244297 38.66,22.49 L38,21.9 L30,14.69 L22,21.9 L21.34,22.49 C20.8076743,22.9687359 20.0603825,23.1271137 19.3796221,22.905474 C18.6988618,22.6838344 18.1880567,22.1158496 18.0396221,21.415474 C17.8911876,20.7150985 18.1276743,19.9887359 18.66,19.51 L28.66,10.51 C29.4238629,9.82982281 30.5761371,9.82982281 31.34,10.51 L35,13.8 L39,17.4 L41.34,19.51 C42.1612478,20.2508521 42.2283308,21.5164847 41.49,22.34 Z" id="Shape" fill="#FC4012"/>
            <path d="M33,26 L33,32 L27,32 L27,26 C27.0032948,24.8967981 27.8967981,24.0032948 29,24 L31,24 C32.1032019,24.0032948 32.9967052,24.8967981 33,26 Z" id="Shape" fill="#FC4012"/>
            <path d="M4,46 L4,43 C4,42.4477153 4.44771525,42 5,42 L6,42 L6,5 C6,2.790861 7.790861,1 10,1 L7,1 C4.790861,1 3,2.790861 3,5 L3,42 L2,42 C1.44771525,42 1,42.4477153 1,43 L1,46 C1,49.3137085 3.6862915,52 7,52 L10,52 C6.6862915,52 4,49.3137085 4,46 Z" id="Shape" fill="#FFFFFF"/>
            <rect id="Rectangle-path" fill="#FFFFFF" x="2" y="5" width="2" height="8"/>
            <path d="M58,41 L58,5 C57.9966939,2.23994685 55.7600532,0.00330611633 53,0 L7,0 C4.23994685,0.00330611633 2.00330612,2.23994685 2,5 C2.00000001,5.55228474 2.44771526,5.99999998 3,5.99999998 C3.55228474,5.99999998 3.99999999,5.55228474 4,5 C4,3.34314575 5.34314575,2 7,2 L53,2 C54.6568542,2 56,3.34314575 56,5 L56,41 L4,41 L4,13 C4,12.4477153 3.55228475,12 3,12 C2.44771525,12 2,12.4477153 2,13 L2,41 C0.8954305,41 1.3527075e-16,41.8954305 0,43 L0,46 C0.00440863597,49.8641657 3.13583434,52.9955914 7,53 L53,53 C56.8641657,52.9955914 59.9955914,49.8641657 60,46 L60,43 C60,41.8954305 59.1045695,41 58,41 Z M39,43 L39,44 C39,45.1045695 38.1045695,46 37,46 L23,46 C21.8954305,46 21,45.1045695 21,44 L21,43 L39,43 Z M58,46 C57.9966939,48.7600532 55.7600532,50.9966939 53,51 L7,51 C4.23994685,50.9966939 2.00330612,48.7600532 2,46 L2,43 L19,43 L19,44 C19,46.209139 20.790861,48 23,48 L37,48 C39.209139,48 41,46.209139 41,44 L41,43 L58,43 L58,46 Z" id="Shape" fill="#000000"/>
            <circle id="Oval" fill="#000000" cx="3" cy="9" r="1"/>
            <path d="M15,45 L12,45 C11.4477153,45 11,45.4477153 11,46 C11,46.5522847 11.4477153,47 12,47 L15,47 C15.5522847,47 16,46.5522847 16,46 C16,45.4477153 15.5522847,45 15,45 Z" id="Shape" fill="#000000"/>
            <circle id="Oval" fill="#000000" cx="6" cy="46" r="1"/>
            <circle id="Oval" fill="#000000" cx="9" cy="46" r="1"/>
            <path d="M7,8 C7.26519481,7.99994336 7.51950727,7.89454937 7.707,7.707 L9.707,5.707 C10.0859722,5.31462111 10.0805524,4.69091522 9.69481858,4.30518142 C9.30908478,3.91944763 8.68537889,3.91402779 8.293,4.293 L6.293,6.293 C6.00709208,6.57899425 5.92157809,7.00903662 6.07632202,7.38265456 C6.23106595,7.7562725 6.5956041,7.99991365 7,8 Z" id="Shape" fill="#000000"/>
            <path d="M6.293,12.707 C6.68349985,13.0973819 7.31650015,13.0973819 7.707,12.707 L14.707,5.707 C15.0859722,5.31462111 15.0805524,4.69091522 14.6948186,4.30518142 C14.3090848,3.91944763 13.6853789,3.91402779 13.293,4.293 L6.293,11.293 C5.90261806,11.6834999 5.90261806,12.3165001 6.293,12.707 Z" id="Shape" fill="#000000"/>
            <path d="M38,10 L36,10 C35.0494628,9.999806 34.2301074,10.6686675 34.04,11.6 L32.007,9.771 C30.8680133,8.73895395 29.1319867,8.73895395 27.993,9.771 L17.993,18.771 C17.0887791,19.5822754 16.7630758,20.8587849 17.1680676,22.0041079 C17.5730594,23.1494308 18.6287929,23.9374576 19.842,24 C19.9,24 19.95,24 20,24 C20.3410356,23.998989 20.6793368,23.9391096 21,23.823 L21,31 C21,32.1045695 21.8954305,33 23,33 L37,33 C38.1045695,33 39,32.1045695 39,31 L39,23.809 C40.1406847,24.1917514 41.3998153,23.8798924 42.23,23.009 C43.3378506,21.7775719 43.2380233,19.8813004 42.007,18.773 L40,16.964 L40,12 C40,10.8954305 39.1045695,10 38,10 Z M36,12 L38,12 L38,15.164 L36,13.364 L36,12 Z M28,31 L28,26 C28,25.4477153 28.4477153,25 29,25 L31,25 C31.5522847,25 32,25.4477153 32,26 L32,31 L28,31 Z M34,31 L34,26 C34,24.3431458 32.6568542,23 31,23 L29,23 C27.3431458,23 26,24.3431458 26,26 L26,31 L23,31 L23,22.336 L30,16.036 L37,22.336 L37,31 L34,31 Z M41,20.947 C41.0141557,21.2119666 40.922423,21.4716962 40.745,21.669 C40.3683707,22.0649531 39.7479283,22.0974462 39.332,21.743 L30.67,13.943 C30.2896843,13.6006832 29.7123157,13.6006832 29.332,13.943 L20.669,21.743 C20.4045181,21.9894374 20.0276228,22.0739462 19.6832383,21.964031 C19.3388537,21.8541158 19.0806025,21.566891 19.0077876,21.2128005 C18.9349727,20.85871 19.0589318,20.4928878 19.332,20.256 L29.332,11.256 C29.7121633,10.9142829 30.2888367,10.9142829 30.669,11.256 L40.669,20.256 C40.8677884,20.4324337 40.9871049,20.6815204 41,20.947 Z" id="Shape" fill="#000000"/>
        </g>
    </g>
</svg>
</template>
<script>
export default {
    name: "RemoteIcon"
}
</script>