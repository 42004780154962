import {
    GetPlatformNotificationsResult,
    GetSubscriptionStateResult, SetPlatformNotificationAsReadResult, SetPlatformNotificationAsSeenResult,
    SetSubscriptionStateResult
} from "./NotificationServiceResponses";

export class NotificationServiceStub {
    constructor(auth) {
        this.authService = auth;
    }


    setSubscriptionState(unsubscribed) {
        let result = new SetSubscriptionStateResult();

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 100);

        return result;
    }

    getSubscriptionState() {
        let result = new GetSubscriptionStateResult();

        setTimeout(function () {
            result.apiData = {
                "isUnsubscribedFromAllNotifications":false,
                "states": [
                    {"key": "ALL", "name": "Alle", "subscribed": true},
                    {"key": "CHALLENGE_REMINDER", "name": "Erinnerung an offene Challenges", "subscribed": true},
                    {"key": "MONTHLY_SUMMARY", "name": "Monatliche Zusammenfassung", "subscribed": true},
                    {"key": "CHALLENGE_ANALYSIS", "name": "Challenge Auswertung", "subscribed": true},
                    {"key": "UNRESPONDED_EMAIL_REMINDER", "name": "Erinnerung an unbeantwortete E-Mails", "subscribed": true},
                    {"key": "UNRESPONDED_CHAT_MESSAGE_REMINDER", "name": "Erinnerung an unbeantwortete Nachrichten im Chat", "subscribed": true},
                    {"key": "NEW_CHAT_MESSAGE", "name": "Neue Chat-Nachricht", "subscribed": true},
                    {"key": "WEEKLY_JOB_STATISTICS", "name": "Wöchentliche Statistik", "subscribed": true},
                    {"key": "JOIN_CAMPUS_CHALLENGE", "name": "Teilnahme an der Campus Challenge", "subscribed": true},
                    {"key": "CV_UPLOAD_CONFIRMATION", "name": "Lebenslauf erfolgreich hochgeladen", "subscribed": true},
                    {"key": "NEW_SPONSORED_LEAD", "name": "Neuer Lead", "subscribed": true},
                    {"key": "INITIAL_JOB_INTEREST", "name": "Erstes Jobinteresse", "subscribed": true},
                    {"key": "NEW_RANK_THE_CODE_RATING", "name": "Neue Challenge Bewertung", "subscribed": false},
                    {"key": "NEW_COMMENT_FOR_CODE_RATING", "name": "Neuer Kommentar zu Challenge Bewertung", "subscribed": false},
                    {"key": "NEW_CODE_COMMENT", "name": "Neuer Kommentar zu Code", "subscribed": false},
                    {"key": "NEW_COMMENT_FOR_CODE_COMMENT", "name": "Neuer Kommentar zu Code Kommentar", "subscribed": false}
                ]
            };
            result.loaded = true;
        }, 100);

        return result;
    }

    getPlatformNotifications(pageNumber, pageSize) {
        let result = new GetPlatformNotificationsResult();

        setTimeout(function () {
            result.apiData = [{
                id: "13050f06-99ae-41c8-bbd3-21a03b406b65",
                icon: "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg",
                subject: "Neues Job Angebot",
                time: "2019-12-14T19:58:21.134614Z",
                content: "Du hast ein neues Jobangebot von Google. Bereit für eine neue Herausforderung?",
                link: "#",
                isRead: false,
                isSeen: false,
            },
            {
                id: "13050f06-99ae-41c8-bbd3-21a03b406b66",
                icon: "/assets/img/comment.png",
                subject: "Neuer Kommentar zur Code Bewertung",
                time: "2019-12-11T19:58:21.134614Z",
                content: "Du hast einen neuen Kommentar zu einer Code Bewertung für die Challenge \"Spaceship validation when entering the motherships\" erhalten",
                link: "/challenge/spaceship-validation-when-entering-the-motherships?technology=java%2Bspringboot&view=otherSolutions&taskId=45644b62-8c23-4320-b1fb-29a9dd6d6a7c&solutionId=60d0d31d-3927-4438-b1d4-10a169073068&solutionRatingId=3a1835c8-3eea-4a49-89ca-5310ac0d6483&ratingCommentId=dd5d4f1b-7f6f-46a7-ac89-814d842bf11d",
                isRead: false,
                isSeen: false,
            },
            {
                id: "16050f06-99ae-41c8-bbd3-21a03b406b66",
                icon: "/assets/img/comment.png",
                subject: "Neuer Kommentar zur Code Bewertung",
                time: "2019-12-11T19:58:21.134614Z",
                content: "Du hast einen neuen Kommentar zu einer Code Bewertung für die Challenge \"Spaceship validation when entering the motherships\" erhalten",
                link: "/challenge/spaceship-validation-when-entering-the-motherships?technology=java%2Bspringboot&view=otherSolutions&taskId=45644b62-8c23-4320-b1fb-29a9dd6d6a7c&solutionId=60d0d31d-3927-4438-b1d4-10a169073068&solutionRatingId=3a1835c8-3eea-4a49-89ca-5310ac0d6483&ratingCommentId=dd5d4f1b-7f6f-46a7-ac89-814d842bf11e",
                isRead: false,
                isSeen: false,
            },
            {
                id: "13050f06-99ae-41c8-bbd3-21a03b406b56",
                icon: "/assets/img/gavel.png",
                subject: "Neue Code Bewertung",
                time: "2019-12-11T19:58:21.134614Z",
                content: "Du hast ein neue Rank the Code Bewertung für die Challenge \"FizzBuzz PHP\" erhalten",
                link: "/challenge/fizzbuzz-php?technology=php&view=otherSolutions&taskId=d5496d33-2471-4381-bd61-52535b275b53&solutionId=b6074ec0-1085-41f3-b38c-fff672db1081&solutionRatingId=8ffce6b8-681a-4f03-8a8a-aa16190c9812",
                isRead: false,
                isSeen: false,
            },
            {
                id: "13050f06-99ae-41c8-bbd3-21a03b406b68",
                icon: "/assets/img/code_comment.png",
                subject: "Neuer Code Kommentar",
                time: "2019-11-14T19:58:21.134614Z",
                content: "Du hast einen neuen Code Kommentar zur Lösung der Challenge \"FizzBuzz PHP\" erhalten",
                link: "/challenge/fizzbuzz-php?technology=php&view=yourSolution&taskId=d5496d33-2471-4381-bd61-52535b275b53&solutionId=b6074ec0-1085-41f3-b38c-fff672db1081&codeCommentId=b6074ec0-1085-41f3-b38c-fff672db1080",
                isRead: false,
                isSeen: false,
            },
            {
                id: "13050f06-99ae-41c8-bbd3-21a03b406b26",
                icon: "/assets/img/gavel.png",
                subject: "Neue Code Bewertung",
                time: "2019-12-11T19:58:21.134614Z",
                content: "Du hast ein neue Rank the Code Bewertung für die Challenge \"FizzBuzz PHP\" erhalten",
                link: "/challenge/fizzbuzz-php?technology=php&view=yourSolution&taskId=d5496d33-2471-4381-bd61-52535b275b53&solutionId=b6074ec0-1085-41f3-b38c-fff672db1081&solutionRatingId=dd5d4f1b-7f6f-46a7-ac89-814d842bf31e",
                isRead: false,
                isSeen: false,
            },
            {
                id: "13050f06-99ae-41c8-bbd3-21a03b406b66",
                icon: "/assets/img/code_comment.png",
                subject: "Neuer Kommentar zur Code Kommentar",
                time: "2019-12-11T19:58:21.134614Z",
                content: "Du hast einen neuen Kommentar zu einer Code Bewertung für die Challenge \"FizzBuzz PHP\" erhalten",
                link: "/challenge/fizzbuzz-php?technology=php&view=yourSolution&taskId=d5496d33-2471-4381-bd61-52535b275b53&solutionId=b6074ec0-1085-41f3-b38c-fff672db1081&codeCommentId=b6074ec0-1085-41f3-b38c-fff672db1080&commentForCodeCommentId=b6074ec0-1085-41f3-b38c-fff672db1081",
                isRead: false,
                isSeen: false,
            }];
            result.pagination = {
                pageNumber: 3,
                numPages: 2,
            };
            result.loaded = true;
        }, 100);

        return result;
    }

    setPlatformNotificationsAsRead(notificationId) {
        let result = new SetPlatformNotificationAsReadResult();

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 100);

        return result;
    }

    setPlatformNotificationsAsSeen() {
        let result = new SetPlatformNotificationAsSeenResult();

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 100);

        return result;
    }

}
