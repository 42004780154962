import {services} from "../../main";

export default class SocketStub {
    constructor(props = {}) {
        this.events = {};
        this.emitted = [];
        Object.assign(this, props);
    }

    on(event, handler) {
        this.events[event] = handler;
    }

    emit(...args) {
        this.emitted = [
            ...this.emitted,
            args
        ];
    }

    simulate(event, payload) {
        this.events[event](payload);
    }

    clear() {
        this.emitted = [];
    }
}

export class NotificationsServiceStub {
    constructor() {
    }

    getAchievementNotificationsServiceSocket() {
        let socketStub = new SocketStub();
        let now = new Date();
        let events = [
            {
                title: "Hello World! 1",
                description: "Willkommen bei EntwickerHeld!",
                icon: "https://polylith-public-files.s3.eu-central-1.amazonaws.com/TrophyIcons_21_t.png",
                success: true,
                trigger: [{
                    description: "Challenges gelöst",
                    limit: 1,
                    operator: "GREATER_THAN_OR_EQUALS",
                    count: 1,
                    finished: false
                }]
            },
             {
                title: "Hello World! 2",
                description: "Willkommen bei EntwickerHeld!",
                icon: "https://polylith-public-files.s3.eu-central-1.amazonaws.com/TrophyIcons_21_t.png",
                success: true,
                trigger: [{
                    description: "Challenges gelöst",
                    limit: 1,
                    operator: "GREATER_THAN_OR_EQUALS",
                    count: 1,
                    finished: false
                }]
            },

        ];
        const userIdStub = services.auth.getUserID();
        setTimeout(function () {
            let randomEvent = events[Math.floor(Math.random() * events.length)];
            // socketStub.simulate(`notifications_gamification_${userIdStub}_achievement-unlocked`, JSON.stringify(randomEvent));
        }, 40);
        return socketStub;
    }

    getLevelUpNotificationsServiceSocket() {
        const userIdStub = services.auth.getUserID();
        let socketStub = new SocketStub();
        let now = new Date();
        let events = [
            {
                "userId": userIdStub,
                "levelBeforeUpdate": 7,
                "levelAfterUpdate": 8,
            },

        ];
        setTimeout(function () {
            let randomEvent = events[Math.floor(Math.random() * events.length)];
            // socketStub.simulate(`notifications_gamification_${userIdStub}_level-up`, JSON.stringify(randomEvent));
        }, 2000);
        return socketStub;
    }

    getExperienceUpdateNotificationsServiceSocket() {
        const userIdStub = services.auth.getUserID();
        let socketStub = new SocketStub();
        let now = new Date();
        let events = [
            {
                "userId": userIdStub,
                "xpBeforeUpdate": 500,
                "xpAfterUpdate": 700,
                "xpNeededRelative": 1000,
                "xpAdded": 200,
            },
        ];
        setTimeout(function () {
            let randomEvent = events[Math.floor(Math.random() * events.length)];
            // socketStub.simulate(`notifications_gamification_${userIdStub}_xp-update`, JSON.stringify(randomEvent));
        }, 2000);
        return socketStub;
    }

    getPlatformNotificationsServiceSocket() {
        let socketStub = new SocketStub();
        const userIdStub = services.auth.getUserID();
        setInterval(function () {
            // socketStub.simulate(`notifications_notification_${userIdStub}_platform-notifications`);
        }, 3000);

        return socketStub;
    }

     getUserServiceSocket() {
        let socketStub = new SocketStub();
        const userIdStub = services.auth.getUserID();
        setInterval(function () {
            //socketStub.simulate(`notifications_user_${userIdStub}_user-logged-in`);
        }, 3000);

        return socketStub;
    }

    getSkillScoreNotificationsServiceSocket() {
        let socketStub = new SocketStub();
        const userIdStub = services.auth.getUserID();
        setInterval(function () {
            // socketStub.simulate(`notifications_gamification_${userIdStub}_skill-score-updated`, JSON.stringify({}));
        }, 3000);

        return socketStub;
    }

    getNewMessageNotificationSocket() {
        let socketStub = new SocketStub();
        const userIdStub = services.auth.getUserID();
        setInterval(function () {
            // socketStub.simulate(`notifications_message_${userIdStub}_new-message`, JSON.stringify({}));
        }, 5000);

        return socketStub;
    }

}