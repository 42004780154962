<template>
    <vue-headful
            :title="title + ' - EntwicklerHeld.de'"
            :url="'https://platform.entwicklerheld.de' + url"
    />
</template>

<script>
    import vueHeadful from 'vue-headful';

    export default {
        name: "MetaDataComponent",
        components: {"vue-headful": vueHeadful},
        props: {
            title: {type: [String], default: ''},
            url: {type: [String], default: ''}
        },
    }
</script>

<style scoped>

</style>
