export class ChallengeDetails {
    constructor() {
        this.challenge_data = {};
        this.error = null;
        this.loaded = false;
    }
}

export class ChallengeList {
    constructor() {
        this.challengeListData = {};
        this.error = null;
        this.loaded = false;
    }
}

export class SubmitTask {
    constructor() {
        this.error = null;
        this.apiData = {};
        this.loaded = false;
    }
}

export class AcceptChallengeResult {
    constructor() {
        this.error = null;
        this.apiData = {};
        this.loaded = false;
    }
}

export class SubmitChallengeResult {
    constructor() {
        this.error = null;
        this.apiData = {};
        this.loaded = false;
    }
}

export class SolvedChallengesResult {
    constructor() {
        this.error = null;
        this.apiData = {};
        this.loaded = false;
    }
}

export class TaskList {
    constructor() {
        this.error = null;
        this.apiData = [];
        this.loaded = false;
    }
}

export class RunnTests {
    constructor() {
        this.error = null;
        this.apiData = {};
        this.loaded = false;
    }
}

export class ChallengeGroupDetails {
    constructor() {
        this.error = null;
        this.apiData = {};
        this.loaded = false;
    }
}

export class ChallengeGroupList {
    constructor() {
        this.apiData = [];
        this.error = null;
        this.loaded = false;
    }
}
