import {CoinExchangeRateResult} from "./EntwicklerHerzServiceResponses";

export class EntwicklerHerzServiceStub {
    constructor(auth) {
        this.authService = auth;
    }

    getCoinExchangeRate() {
        let result = new CoinExchangeRateResult();

        setTimeout(function () {
            result.loaded = true;
            result.apiResult = {
                "exchangeRate": 0.00515
            }
        }, 1000);

        return result;

    }
}
