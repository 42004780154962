import { InteractsServiceStub } from "./InteractsServiceStub";
import InteractsServiceNetwork from "./InteractsServiceNetwork";

export default class InteractsService {

    constructor(auth) {
        this.authService = auth;
    }

    checkIfJobOfferExists(vacancyIdFromCompanyService) {
        if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
            console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
            return new InteractsServiceStub(this.authService).checkIfJobOfferExists(vacancyIdFromCompanyService);
        }
        return new InteractsServiceNetwork(this.authService).checkIfJobOfferExists(vacancyIdFromCompanyService);
    }

    applyToJobOffer(vacancyIdFromCompanyService) {
        if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
            console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
            return new InteractsServiceStub(this.authService).applyToJobOffer(vacancyIdFromCompanyService);
        }
        return new InteractsServiceNetwork(this.authService).applyToJobOffer(vacancyIdFromCompanyService);
    }

    applyToCompany(companyId) {
        if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
            console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
            return new InteractsServiceStub(this.authService).applyToCompany(companyId);
        }
        return new InteractsServiceNetwork(this.authService).applyToCompany(companyId);
    }

    addWorkSampleToCompanyId(companyId, challengeId) {
        if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
            console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
            return new InteractsServiceStub(this.authService).addWorkSampleToCompanyId(companyId, challengeId);
        }
        return new InteractsServiceNetwork(this.authService).addWorkSampleToCompanyId(companyId, challengeId);
    }

    getSharedWorksamples(companyId) {
        if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
            console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
            return new InteractsServiceStub(this.authService).getSharedWorksamples(companyId);
        }
        return new InteractsServiceNetwork(this.authService).getSharedWorksamples(companyId);
    }

    getInteractResult(challengeId, language, userTokenId, region, profession) {
        if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
            console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
            return new InteractsServiceStub(this.authService).getInteractResult(challengeId, language, userTokenId, region, profession);
        }
        return new InteractsServiceNetwork(this.authService).getInteractResult(challengeId, language, userTokenId, region, profession);
    }

    getJobOfferDetails(jobOfferId) {
        if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
          console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
          return new InteractsServiceStub(this.authService).getJobOfferDetails(jobOfferId);
        }
        return new InteractsServiceNetwork(this.authService).getJobOfferDetails(jobOfferId);
      }
    cachePreChallengeInteract(challengeId, language, userTokenId, region, profession) {
        if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
          console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
          return new InteractsServiceStub(this.authService).cachePreChallengeInteract(challengeId, language, userTokenId, region, profession);
        }
        return new InteractsServiceNetwork(this.authService).cachePreChallengeInteract(challengeId, language, userTokenId, region, profession);
    }

    getJobRequests(companyId=null) {
        if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
          console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
          return new InteractsServiceStub(this.authService).getJobRequests(companyId);
        }
        return new InteractsServiceNetwork(this.authService).getJobRequests(companyId);
    }
}
