import {
    ApplyForBeingACreatorResult,
    CoinsLeaderBoard,
    GetCvResult,
    MultiplePublicUserProfileDataResult,
    PublicUserProfileResult,
    UploadCvResult,
    UserProfileChangeResult,
    UserProfilesData,
    UserProfileResult,
    FinishOnboarding,
    SaveJsonResumeResult,
    SubmitConnectionCode,
    ListConnections, BusinessNetworkUserInformation, XingInformation
} from "./UserServiceResponses";
import {APIResponse, MockResponse} from "../Utilities";

export class UserServiceStub {
    constructor(auth) {
        this.authService = auth;
    }

    getUserProfile() {
        let result = new UserProfileResult();

        setTimeout(function () {
            result.loaded = true;
            result.user_profile = {
                display_name: "JuJu4tw",
                anonymizedName: 'Wonderful Engelbart',
                uuid: "8028ca5d1c6165a975d8bc903a8ce61d",
                first_name: "Ju",
                last_name: "Sha",
                address: "Hello 5",
                zipCode: "12345",
                city: "Genf",
                country: "Schweiz",
                contact_email_address: "ju.sha@email.de",
                region: "ANDERE",
                profession: "ST",
                professionType: "STUDENT",
                profession_choices: [
                    {code: "PUPIL", profession: "Schüler*in"},
                    {code: "STUDENT", profession: "Student*in, Azubi"},
                    {code: "PROFESSIONAL", profession: "Professional"},
                ],
                university: "U_134",
                university_choices: [
                    {code: "U_134", university: "Universität Bremen"},
                    {code: "U_135", university: "Uni Leipzig"},
                    {code: "U_136", university: "Uni Dresden"}
                ],
                region_choices: [
                    {'code': 'ANDERE', 'region': 'Andere'},
                    {'code': 'AT', 'region': 'Österreich'},
                    {'code': 'CH', 'region': 'Schweiz'},
                    {'code': 'DE_BW', 'region': 'Baden-Württemberg'},
                    {'code': 'DE_BY', 'region': 'Bayern'},
                    {'code': 'DE_BE', 'region': 'Berlin'},
                    {'code': 'DE_BB', 'region': 'Brandenburg'},
                    {'code': 'DE_HB', 'region': 'Bremen'},
                    {'code': 'DE_HH', 'region': 'Hamburg'},
                    {'code': 'DE_HE', 'region': 'Hessen'},
                    {'code': 'DE_MV', 'region': 'Mecklenburg-Vorpommern'},
                    {'code': 'DE_NI', 'region': 'Niedersachsen'},
                    {'code': 'DE_NW', 'region': 'Nordrhein-Westfalen'},
                    {'code': 'DE_RP', 'region': 'Rheinland-Pfalz'},
                    {'code': 'DE_SL', 'region': 'Saarland'},
                    {'code': 'DE_SN', 'region': 'Sachsen'},
                    {'code': 'DE_ST', 'region': 'Sachsen-Anhalt'},
                    {'code': 'DE_SH', 'region': 'Schleswig-Holstein'},
                    {'code': 'DE_TH', 'region': 'Thüringen'}
                ],
                profile_picture: "https://entwicklerheld.de/static/img/team_ilja_v.jpg",
                newsletter: false,
                data_privacy_confirmation: true,
                is_creator: false,
                encoded_region: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZWdpb24iOiJERV9CRSIsInVzZXJJZCI6ImF1dGgwfDVjODY0MTkyMjlkYjJlMzczNTY1OTkzNCJ9.RHYvLPm4bbOZ8yqnJPVdFoFqEbLX3czvirY_VbApFZc",
                jobInterest: 'NEW_JOB',
                experience: 3,
                location:
                    {
                        city: "Dresden",
                        country: "Germany",
                        latitude: -73.8194305,
                        longitude: 122.984342,
                        countryCode: "DE"
                    },
                completedNewOnboarding: true,
            }
        }, 100);
        return result;
    }

    changeUserProfile(data) {
        let result = new UserProfileChangeResult();

        setTimeout(function () {
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    getProfileDataOfMultipleUsers(user_ids) {
        let result = new UserProfilesData();

        setTimeout(function () {
            result.loaded = true;
            result.userProfilesData = {
                "google-oauth2|107982076963308370959": {"region": "Sachsen", "profession": "Junior Professional"},
                "auth0|5c79045916ba4a2e84dc4265": {"region": "Sachsen", "profession": "Senior Professional"},
                "auth0|5c8912b3880ea13b8e85412b": {"region": "Sachsen", "profession": "Senior Professional"},
                "auth0|5c8e7a2a3cb7a74a5bd57a71": {"region": "Sachsen", "profession": "Student*in, Azubi"},
            }
        }, 100);

        return result;
    }

    finishOnboarding(data) {
        let result = new FinishOnboarding();

        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    applyForBeingACreator(creatorInterest) {
        let result = new ApplyForBeingACreatorResult();
        setTimeout(function () {
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    getCoinsLeaderBoard(pageId = 0) {
        let result = new CoinsLeaderBoard();
        let pos = 22;

        if (pageId < 1)
            pageId = Math.ceil(pos / 10);

        let i = pageId > 1 ? 3 : 0;
        let j = (pageId - 1) * 10;

        setTimeout(function () {
            result.apiData = {
                "currentUserPosition": pos,
                "leaderboard": [
                    {
                        "position": 1,
                        "publicUserId": "1",
                        "isCurrentUser": false,
                        "totalCoinsEarned": 2000
                    },
                    {
                        "position": 2,
                        "publicUserId": "2",
                        "isCurrentUser": false,
                        "totalCoinsEarned": 1000
                    },
                    {
                        "position": 3,
                        "publicUserId": "3",
                        "isCurrentUser": false,
                        "totalCoinsEarned": 1000
                    },
                    {
                        "position": 4,
                        "publicUserId": "4",
                        "isCurrentUser": false,
                        "totalCoinsEarned": 500
                    },
                    {
                        "position": 5,
                        "publicUserId": "5",
                        "isCurrentUser": false,
                        "totalCoinsEarned": 100
                    },
                    {
                        "position": 6,
                        "publicUserId": "6",
                        "isCurrentUser": false,
                        "totalCoinsEarned": 50
                    },
                    {
                        "position": 7,
                        "publicUserId": "7",
                        "isCurrentUser": false,
                        "totalCoinsEarned": 25
                    },
                    {
                        "position": 8,
                        "publicUserId": "8",
                        "isCurrentUser": false,
                        "totalCoinsEarned": 5
                    },
                    {
                        "position": 9,
                        "publicUserId": "9",
                        "isCurrentUser": false,
                        "totalCoinsEarned": 2
                    },
                    {
                        "position": 10,
                        "publicUserId": "10",
                        "isCurrentUser": false,
                        "totalCoinsEarned": 1
                    }
                ]
            };

            if (pageId > 1) {
                while (i < result.apiData.leaderboard.length) {
                    result.apiData.leaderboard[i].position = ++j;
                    result.apiData.leaderboard[i].isCurrentUser = result.apiData.leaderboard[i].position === pos;
                    i++;
                }

                while (result.apiData.leaderboard.length < 13) {
                    result.apiData.leaderboard.push({
                        "position": ++j,
                        "publicUserId": i++,
                        "totalCoinsEarned": 1,
                        "isCurrentUser": false,
                    });
                }
            }

            result.error = null;
            result.loaded = true;
            result.pagination = {"pageID": pageId, "numPages": 45, "totalCount": 450};
        }, 100);

        return result;
    }

    getPublicUserProfile(userId) {
        let result = new PublicUserProfileResult();

        setTimeout(function () {
            result.apiData = {
                "uuid": "cbff9719-8b35-4b6a-a7ff-f4cc0486e6fa",
                "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653522/eh-platform/user_avatars/47-047_Cat_in_A_Mug-Yellow.svg",
                "displayName": "Ilja Boar",
                "isCreator": true,
            };
            result.error = null;
            result.loaded = true;
        }, 100);

        return result;
    }

    getDataForPublicUserIds(userIds) {
        let result = new MultiplePublicUserProfileDataResult();

        setTimeout(function () {
            result.apiData = [
                {
                    "publicUserId": "1",
                    "displayName": "Friedemann",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/70-070_Labrador_Puppy-Flat.svg"
                },
                {
                    "publicUserId": "2",
                    "displayName": "Jakob",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653560/eh-platform/user_avatars/69-069_Poodle-Cartoony.svg"
                },
                {
                    "publicUserId": "3",
                    "displayName": "Felix",
                    "profilePicture": "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5c483da5137421218b5983fd/b28f8b53-8942-4fe3-b739-a998ad99f749/128?size=48&s=48"
                },
                {
                    "publicUserId": "4",
                    "displayName": "Ronny",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653522/eh-platform/user_avatars/47-047_Cat_in_A_Mug-Yellow.svg"
                },
                {
                    "publicUserId": "5",
                    "displayName": "Pi",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/70-070_Labrador_Puppy-Flat.svg"
                },
                {
                    "publicUserId": "6",
                    "displayName": "Ronny",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/70-070_Labrador_Puppy-Flat.svg"
                },
                {
                    "publicUserId": "7",
                    "displayName": "Norma",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/70-070_Labrador_Puppy-Flat.svg"
                },
                {
                    "publicUserId": "8",
                    "displayName": "Steffi",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/70-070_Labrador_Puppy-Flat.svg"
                },
                {
                    "publicUserId": "9",
                    "displayName": "Mirko",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/70-070_Labrador_Puppy-Flat.svg"
                },
                {
                    "publicUserId": "10",
                    "displayName": "Benjamin",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/70-070_Labrador_Puppy-Flat.svg"
                },
                {
                    "publicUserId": "05ba98c88c0a97fe1d504f89843de2ad",
                    "displayName": "Daniel",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/70-070_Labrador_Puppy-Flat.svg"
                },
                {
                    "publicUserId": "2ec2649fd6b3f7cac7546238058a37cb",
                    "displayName": "Michel",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/70-070_Labrador_Puppy-Flat.svg"
                },
                {
                    "publicUserId": "eed30690e9e03453b7b1588ef0f01010",
                    "displayName": "Besten Hero ever",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/70-070_Labrador_Puppy-Flat.svg"
                },
                {
                    "publicUserId": "8028ca5d1c6165a975d8bc903a8ce61d",
                    "displayName": "JuJu4tw",
                    "profilePicture": "https://entwicklerheld.de/static/img/team_ilja_v.jpg"
                },
                {
                    "publicUserId": "ea9b6adc7dce1e4b45a35dc415176e5a",
                    "displayName": "Michael Meyer",
                    "profilePicture": "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653560/eh-platform/user_avatars/69-069_Poodle-Cartoony.svg"
                },
            ];
            for (let i = 11; i < 50; i++) {
                const index = i % 10;
                const j = (i +  7) % 13;
                result.apiData.push({
                    "publicUserId": `${i}`,
                    "displayName": `${result.apiData[index].displayName} ${result.apiData[j].displayName}`,
                    "profilePicture": result.apiData[index].profilePicture,
                });
            }
            result.error = null;
            result.loaded = true;
        }, 100);

        return result;
    }

    uploadCv(cvData) {
        let result = new UploadCvResult();
        setTimeout(function () {
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    getCv() {
        let result = new GetCvResult();

        setTimeout(function () {
            result.loaded = true;
            result.apiData = {
                userId: "auth0|5a99736aae9f9b5ee5acdbd8",
                isInterestedInJobOffer: false,
                hasUploadedPdf: true,
                jsonResume: "{\n    \"basics\": {\n        \"name\": \"Max Mustermann\",\n        \"email\": \"max.mustermann@example.com\"\n    }\n}"
            }
        }, 100);
        return result;
    }

    saveJsonResume(jsonResume) {
        let result = new SaveJsonResumeResult();

        setTimeout(function () {
            result.loaded = true;
            result.error = null;
        }, 100);
        return result;
    }

    getLogoDrawingsByPublicUserId(publicUserID) {
        return new Promise((resolve, reject) => {
            let data = {
                data: [{
                    id: "1", code: `
to square :length
    repeat 4 [ fd :length rt 90 ]
end
to randomcolor
    setcolor pick [ red orange yellow green blue violet ]
end
clearscreen
penup
back 50
lt 90
fd 50
rt 90
pendown
square 100`
                }, {
                    id: "2", code:

                        `to tree :size
   if :size < 5 [forward :size back :size stop]
   forward :size/3
   left 30 tree :size*2/3 right 30
   forward :size/6
   right 25 tree :size/2 left 25
   forward :size/3
   right 25 tree :size/2 left 25
   forward :size/6
   back :size
end
clearscreen
penup
back 125
pendown
tree 150`
                }]
            };
            setTimeout(function () {
                resolve(data);
            }, 100);
        });
    }

    addOrUpdateLogoDrawingInUserProfile(drawingId, code) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (!drawingId) {
                    console.log("Added drawing to user profile with code " + code);
                    resolve();
                }
                console.log("Updated drawing with id " + drawingId + " to user profile with code " + code);
                resolve();
            }, 200);
        })
    }

    removeLogoDrawingInUserProfile(drawingId) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log("Removed drawing from user profile with id " + drawingId);
                resolve();
            }, 200);
        })
    }

    submitConnectionCode(connection, code, redirectUrl) {
        let result = new SubmitConnectionCode();
        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    listConnections() {
        let result = new ListConnections();
        setTimeout(function () {
            result.apiData = {
                "twitch": true
            };
            result.loaded = true;
            result.error = null;
        }, 100);

        return result;
    }

    getBusinessNetworkUserInformation(businessNetworkPlattform) {
        new MockResponse().respond(data);
    }

    postBusinessNetworkUserInformation(publicProfileUrl) {
        let result = new BusinessNetworkUserInformation();
        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
            result.error = null;
        }, 1000);

        return result;
    }

    deleteBusinessNetworkUserInformation(businessNetworkPlattform) {
        let result = new BusinessNetworkUserInformation();
        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
            result.error = null;
        }, 1000);

        return result;
    }

    getMyDreamCompany() {
        let result = new APIResponse()
        setTimeout(() => {
            result.apiData = {
                "salary": 100000,
                "location": "remote",
                "technologies": [
                    "440eaa9a-a217-46b1-b60b-f5496f554331",
                    "24f4b501-f7cd-4ef8-ad41-b7f675db4a3d",
                    "f6054818-7250-4248-a265-0034813172d2",
                    "bc68d9cc-2cca-49dc-a2ca-4b273bb36964"
                ],
                "availableLocations": {
                    "everywhere": "Überall",
                    "remote": "Remote",
                    "homebase": "Homebase"
                },
                "created": "2022-09-23T08:20:28.876111Z",
                "updated": "2022-09-23T08:20:28.876124Z"
            }
            result.error = false
            result.loaded = true
        }, 2000)

        return result
    }

    postMyDreamCompany(postData) {
        let result = new APIResponse()
        setTimeout(() => {
            result.apiData = {}
            result.error = false
            result.loaded = true
        }, 2000)
        return result
    }
}
