import {
    RewardListResult,
    UserCoinsResult,
    RedeemCouponCodeResult,
    ExchangeResult,
    DonationResult, Donate, DonationLeaderBoardResult
} from "./RewardServiceResponses";

export class RewardServiceStub {
    constructor(auth) {
        this.authService = auth;
    }

    getAvailableRewards() {
        let result = new RewardListResult();

        setTimeout(function () {
            result.loaded = true;
            result.rewards = [
                {
                    id: "d84e9392-f004-4f4d-a09a-c333b3d76d00",
                    name: "REWE",
                    title: "REWE",
                    description: "Gültigkeit der Gutscheine beträgt zehn Jahre ab Kauf. Einlösbar für Millionen Produkte bei Amazon.de. Amazon.de Gutscheine sind nur auf Amazon.de und Amazon.at einlösbar.",
                    minPrice: 500,
                    maxPrice: 500,
                    logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1662535346/eh-platform/rewards/ST-01_2017_DE_20.png",
                    rewards: [
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d02",
                            "name": "REWE 50 €",
                            "title": "50 € Guthaben",
                            "price": 500,
                            "amountOfRewards": 6,
                            "remainingRewards": 5,
                        },
                    ]
                },
                {
                    id: "d84e9392-f004-4f4d-a09a-c333b3d76d04",
                    name: "EntwicklerHeld Shirt Grün (Frauen - Fitted Cut)",
                    title: "EntwicklerHeld Shirt Grün (Frauen - Fitted Cut)",
                    description: "Exklusives EntwicklerHeld-Shirt! Versand nur möglich in Deutschland und Österreich.",
                    minPrice: 1000,
                    maxPrice: 1000,
                    logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1662535303/eh-platform/rewards/rewards-shirt-grun-female.png",
                    rewards: [
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d05",
                            "name": "EntwicklerHeld Shirt Grün (Frauen - Fitted Cut)",
                            "title": "Größe XS",
                            "price": 1000,
                            "amountOfRewards": null,
                            "remainingRewards": null,
                        },
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d06",
                            "name": "EntwicklerHeld Shirt Grün (Frauen - Fitted Cut)",
                            "title": "Größe S",
                            "price": 1000,
                            "amountOfRewards": null,
                            "remainingRewards": null,
                        },
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d07",
                            "name": "EntwicklerHeld Shirt Grün (Frauen - Fitted Cut)",
                            "title": "Größe M",
                            "price": 1000,
                            "amountOfRewards": null,
                            "remainingRewards": null,
                        },
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d08",
                            "name": "EntwicklerHeld Shirt Grün (Frauen - Fitted Cut)",
                            "title": "Größe L",
                            "price": 1000,
                            "amountOfRewards": null,
                            "remainingRewards": null,
                        },
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d09",
                            "name": "EntwicklerHeld Shirt Grün (Frauen - Fitted Cut)",
                            "title": "Größe XL",
                            "price": 1000,
                            "amountOfRewards": null,
                            "remainingRewards": null,
                        },
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d10",
                            "name": "EntwicklerHeld Shirt Grün (Frauen - Fitted Cut)",
                            "title": "Größe XXL",
                            "price": 1000,
                            "amountOfRewards": null,
                            "remainingRewards": null,
                        },
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d11",
                            "name": "EntwicklerHeld Shirt Grün (Frauen - Fitted Cut)",
                            "title": "Größe XXXL",
                            "price": 1000,
                            "amountOfRewards": null,
                            "remainingRewards": null,
                        },
                    ]
                },
                {
                    id: "d84e9392-f004-4f4d-a09a-c333b3d76d12",
                    name: "Netflix",
                    title: "Netflix",
                    description: "Netflix hat etwas für jeden Geschmack: Filme, Serien und natürlich eigene Originale. Netflix-Mitglieder können Top-Entertainment direkt im eigenen Wohnzimmer oder unterwegs auf jedem internetfähigen Gerät erleben – ganz ohne Werbung.",
                    minPrice: 225,
                    maxPrice: 500,
                    logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1662535345/eh-platform/rewards/netflix-cr80-0718-nodenom-1500x950_-_july.31.2018.png",
                    rewards: [
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d13",
                            "name": "Netflix",
                            "title": "15 € Guthaben",
                            "price": 225,
                            "amountOfRewards": 5,
                            "remainingRewards": 3,
                        },
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d14",
                            "name": "Netflix",
                            "title": "25 € Guthaben",
                            "price": 375,
                            "amountOfRewards": 0,
                            "remainingRewards": 0,
                        },
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d15",
                            "name": "Netflix",
                            "title": "50 € Guthaben",
                            "price": 5000,
                            "amountOfRewards": 10,
                            "remainingRewards": 10,
                        },
                    ]
                },
                {
                    id: "d84e9392-f004-4f4d-a09a-c333b3d76d01",
                    name: "Amazon",
                    title: "Amazon",
                    description: "Gültigkeit der Gutscheine beträgt zehn Jahre ab Kauf. Einlösbar für Millionen Produkte bei Amazon.de. Amazon.de Gutscheine sind nur auf Amazon.de und Amazon.at einlösbar.",
                    minPrice: 500,
                    maxPrice: 1000,
                    logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1662535346/eh-platform/rewards/ST-01_2017_DE_20.png",
                    rewards: [
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d02",
                            "name": "Amazon 50 €",
                            "title": "50 € Guthaben",
                            "price": 500,
                            "amountOfRewards": 0,
                            "remainingRewards": 0,
                        },
                        {
                            "id": "d84e9392-f004-4f4d-a09a-c333b3d76d03",
                            "name": "Amazon 100 €",
                            "title": "100 € Guthaben",
                            "price": 1000,
                            "amountOfRewards": 0,
                            "remainingRewards": 0,
                        },
                    ]
                },
            ]
        }, 100);

        return result;

    }

    getUserCoins(withoutCache = false, valueBefore = 0) {
        let result = new UserCoinsResult();
        result.coins = valueBefore;

        setTimeout(function () {
            result.loaded = true;
            result.coins = 1000;
        }, 100);

        return result;
    }

    exchangeReward(rewardId, email, userAddress) {
        let result = new ExchangeResult();

        setTimeout(function () {
            result.loaded = true;
        }, 2000);

        return result;
    }


    redeemCouponCode(couponCode) {
        let result = new RedeemCouponCodeResult();

        setTimeout(function () {
            result.apiResult = {
                title: "Easter Challenge",
                type: "EASTER_CHALLENGE",
                message: "Yay you made it, happy easter celebration",
                coins: 100
            };
            result.loaded = true;
        }, 100);

        return result;
    }

    getDonations() {
        let result = new DonationResult();

        setTimeout(function () {
            result.apiResult = {
                "donatedCoins": 10000,
                "donatedCoinsByUser": "1000"
            };
            result.loaded = true;
        }, 1000);

        return result;
    }

    donate(coins) {
        let result = new Donate();
        setTimeout(function () {
            result.apiData = {};
            result.loaded = true;
        }, 2000);
        return result;
    }

    getDonationLeaderBoard(pageId) {
        let result = new DonationLeaderBoardResult();
        let pos = 38;
        
        if (!pageId)
            pageId = pos > 10 ? Math.ceil(pos/10) : 1;
        
        let n = (pageId - 1) * 10;
        
        setTimeout(function () {
                result.apiData = {
                    "currentUserPosition": pos,
                    "leaderboard": [
                        {
                            "position": n + 1,
                            "publicUserId": "1",
                            "donatedCoins": 1200,
                            "isCurrentUser": false,
                        },
                        {
                            "position": n + 2,
                            "publicUserId": "2",
                            "donatedCoins": 1000,
                            "isCurrentUser": false,
                        },
                        {
                            "position": n + 3,
                            "publicUserId": "3",
                            "donatedCoins": 700,
                            "isCurrentUser": false,
                        },
                        {
                            "position": n + 4,
                            "publicUserId": "4",
                            "donatedCoins": 600,
                            "isCurrentUser": false,
                        },
                        {
                            "position": n + 5,
                            "publicUserId": "5",
                            "donatedCoins": 500,
                            "isCurrentUser": false,
                        },
                        {
                            "position": n + 6,
                            "publicUserId": "6",
                            "donatedCoins": 450,
                            "isCurrentUser": false,
                        },
                        {
                            "position": n + 7,
                            "publicUserId": "7",
                            "donatedCoins": 400,
                            "isCurrentUser": false,
                        },
                        {
                            "position": n + 8,
                            "publicUserId": "8",
                            "donatedCoins": 380,
                            "isCurrentUser": false,
                        },
                        {
                            "position": n + 9,
                            "publicUserId": "9",
                            "donatedCoins": 300,
                            "isCurrentUser": false,
                        },
                        {
                            "position": n + 10,
                            "publicUserId": "10",
                            "donatedCoins": 298,
                            "isCurrentUser": false,
                        },
                    ]
                }
                
                for (let item of result.apiData.leaderboard)
                    item.isCurrentUser = item.position === pos;
                
                result.loaded = true;
                result.error = null;
                result.pagination = {"pageID": pageId, "numPages": 10, "totalCount": 100};
            },
            2000
        );
        return result;
    }
}
