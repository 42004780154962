var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row form-row"},[_c('div',{staticClass:"col-xs-12 col-md-offset-3 col-md-6"},[(_vm.selectedProfession !== 'pupil')?_c('div',[_c('h3',{staticClass:"text-center"},[_vm._v("\n                Bist du auf der Suche nach einem Helden-Job, "+_vm._s(_vm.name)+"?\n            ")]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('form',{staticClass:"job-interest-form"},[_c('div',{staticClass:"job-interest-box",class:{'job-interest-selected-animation' : _vm.selectedJobInterest === 'job',
                            'job-interest-other-animation': _vm.selectedJobInterest && _vm.selectedJobInterest !== 'job',
                            'job-interest-focus': _vm.selectedJobInterest === 'job'},on:{"click":function($event){return _vm.selectJobInterest('job')}}},[_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"job-interest-box",class:{'job-interest-selected-animation' : _vm.selectedJobInterest === 'offers',
                            'job-interest-other-animation': _vm.selectedJobInterest && _vm.selectedJobInterest !== 'offers',
                            'job-interest-focus': _vm.selectedJobInterest === 'offers'},on:{"click":function($event){return _vm.selectJobInterest('offers')}}},[_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"job-interest-box",class:{'job-interest-selected-animation' : _vm.selectedJobInterest === 'freelancer',
                            'job-interest-other-animation': _vm.selectedJobInterest && _vm.selectedJobInterest !== 'freelancer',
                            'job-interest-focus': _vm.selectedJobInterest === 'freelancer'},on:{"click":function($event){return _vm.selectJobInterest('freelancer')}}},[_vm._m(2)]),_vm._v(" "),_c('div',{staticClass:"job-interest-box",class:{'job-interest-selected-animation' : _vm.selectedJobInterest === 'no-job',
                            'job-interest-other-animation': _vm.selectedJobInterest && _vm.selectedJobInterest !== 'no-job',
                            'job-interest-focus': _vm.selectedJobInterest === 'no-job'},on:{"click":function($event){return _vm.selectJobInterest('no-job')}}},[_vm._m(3)])])])]):_vm._e(),_vm._v(" "),(_vm.selectedProfession === 'pupil')?_c('div',[_c('h3',{staticClass:"text-center"},[_vm._v("\n                Bist du interessiert an einer Ausbildung oder einem Studium in der IT, "+_vm._s(_vm.name)+"?\n            ")]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('form',{staticClass:"job-interest-form"},[_c('div',{staticClass:"pupil-box",class:{'job-interest-selected-animation' : _vm.selectedJobInterest === 'studies',
                            'job-interest-other-animation': _vm.selectedJobInterest && _vm.selectedJobInterest !== 'studies',
                            'job-interest-focus': _vm.selectedJobInterest === 'studies'},on:{"click":function($event){return _vm.selectJobInterest('studies')}}},[_vm._m(4)]),_vm._v(" "),_c('div',{staticClass:"pupil-box",class:{'job-interest-selected-animation' : _vm.selectedJobInterest === 'maybe',
                            'job-interest-other-animation': _vm.selectedJobInterest && _vm.selectedJobInterest !== 'maybe',
                            'job-interest-focus': _vm.selectedJobInterest === 'maybe'},on:{"click":function($event){return _vm.selectJobInterest('maybe')}}},[_vm._m(5)]),_vm._v(" "),_c('div',{staticClass:"pupil-box",class:{'job-interest-selected-animation' : _vm.selectedJobInterest === 'no-studies',
                            'job-interest-other-animation': _vm.selectedJobInterest && _vm.selectedJobInterest !== 'no-studies',
                            'job-interest-focus': _vm.selectedJobInterest === 'no-studies'},on:{"click":function($event){return _vm.selectJobInterest('no-studies')}}},[_vm._m(6)])])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row job-interest-row"},[_c('div',{staticClass:"col-xs-3 col-lg-6"},[_c('img',{staticClass:"job-icon",attrs:{"src":"https://res.cloudinary.com/dspi5zyaa/image/upload/v1598604845/eh-platform/icons/002_Job_Search_-_Retro.svg","alt":"Job Search Icon"}}),_vm._v(" "),_c('p',[_c('input',{staticClass:"input",attrs:{"type":"radio","id":"job-interest-choice-1","name":"radiobutton"}})])]),_vm._v(" "),_c('div',{staticClass:"col-xs-9 col-lg-12"},[_c('p',[_vm._v("\n                                    Ja, ich suche einen Job oder Praktikum.\n                                ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row job-interest-row"},[_c('div',{staticClass:"col-xs-3 col-lg-6"},[_c('img',{staticClass:"job-icon",attrs:{"src":"https://res.cloudinary.com/dspi5zyaa/image/upload/v1598604673/eh-platform/icons/096_Emails_-_Retro.svg","alt":"Job Offer Icon"}}),_vm._v(" "),_c('input',{staticClass:"job-interest-input input",attrs:{"type":"radio","id":"job-interest-choice-2","name":"radiobutton"}})]),_vm._v(" "),_c('div',{staticClass:"col-xs-9 col-lg-12"},[_c('p',[_vm._v("\n                                    Nein, aber an Angeboten bin ich interessiert.\n                                ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row job-interest-row"},[_c('div',{staticClass:"col-xs-3 col-lg-6"},[_c('img',{staticClass:"job-icon",attrs:{"src":"https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605040/eh-platform/icons/029_3D_Model_-_Retro.svg","alt":"Freelancer Icon"}}),_vm._v(" "),_c('input',{staticClass:"job-interest-input input",attrs:{"type":"radio","id":"job-interest-choice-3","name":"radiobutton"}})]),_vm._v(" "),_c('div',{staticClass:"col-xs-9 col-lg-12"},[_c('p',[_vm._v("\n                                    Nein, aber als Freelancer suche ich neue Projekte.\n                                ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row job-interest-row"},[_c('div',{staticClass:"col-xs-3 col-lg-6"},[_c('img',{staticClass:"job-icon",attrs:{"src":"https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605043/eh-platform/icons/032_No_Waiting_-_Retro.svg","alt":"No Interest Icon"}}),_vm._v(" "),_c('input',{staticClass:"job-interest-input input",attrs:{"type":"radio","id":"job-interest-choice-4","name":"radiobutton"}})]),_vm._v(" "),_c('div',{staticClass:"col-xs-9 col-lg-12"},[_c('p',[_vm._v("Das ist geheim!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row job-interest-row"},[_c('div',{staticClass:"col-xs-3 col-lg-4"},[_c('img',{staticClass:"job-icon",attrs:{"src":"https://res.cloudinary.com/dspi5zyaa/image/upload/v1598606549/eh-platform/icons/047_Programmer_-_Retro.svg","alt":"Job Search Icon"}}),_vm._v(" "),_c('p',[_c('input',{staticClass:"input",attrs:{"type":"radio","id":"job-interest-choice-5","name":"radiobutton"}})])]),_vm._v(" "),_c('div',{staticClass:"col-xs-9 col-lg-12"},[_c('p',[_vm._v("\n                                    Ja, ich möchte später Informatik (o.Ä.) studieren oder eine Ausbildung in der Informatik machen.\n                                ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row job-interest-row"},[_c('div',{staticClass:"col-xs-3 col-lg-4"},[_c('img',{staticClass:"job-icon",attrs:{"src":"https://res.cloudinary.com/dspi5zyaa/image/upload/v1598606549/eh-platform/icons/043_Browser_-_Retro.svg","alt":"Job Offer Icon"}}),_vm._v(" "),_c('input',{staticClass:"job-interest-input input",attrs:{"type":"radio","id":"job-interest-choice-6","name":"radiobutton"}})]),_vm._v(" "),_c('div',{staticClass:"col-xs-9 col-lg-12"},[_c('p',[_vm._v("\n                                    Ich muss erstmal EntwicklerHeld werden und herausfinden, ob die Informatik etwas für mich ist.\n                                ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row job-interest-row"},[_c('div',{staticClass:"col-xs-3 col-lg-4"},[_c('img',{staticClass:"job-icon",attrs:{"src":"https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605043/eh-platform/icons/032_No_Waiting_-_Retro.svg","alt":"Freelancer Icon"}}),_vm._v(" "),_c('input',{staticClass:"job-interest-input input",attrs:{"type":"radio","id":"job-interest-choice-7","name":"radiobutton"}})]),_vm._v(" "),_c('div',{staticClass:"col-xs-9 col-lg-12"},[_c('p',[_vm._v("\n                                    Nein, auf keinen Fall bzw. keine Ahnung.\n                                ")])])])
}]

export { render, staticRenderFns }