<template>
   <div class="modal" :class="{'in': isVisible, 'open': isVisible}" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">

            <p class="text-center"><img style="max-width: 120px; margin: 2rem 0;" src="/assets/img/cookie.png" /></p>
              <h1 class="text-center" style="margin-bottom: 2rem;"><strong>Privacy</strong> first!</h1>
            <p>Wir verwenden für den Seitenbetrieb notwendige Cookies und optionale zur Erhebung anonymisierter
                Statistiken. Du kannst selbst entscheiden, ob Du diese nutzen möchtest. Deine Einstellungen kannst
                Du jederzeit <a class="" href="https://entwicklerheld.de/cookie_policy/" target="_blank">hier</a>
                ändern.<br>
                Weitere Informationen findest Du auch in unseren
                <a class="" href="https://entwicklerheld.de/data_privacy/" target="_blank">Datenschutzschutzbestimmungen.</a></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="acceptConsent">Akzeptieren</button>
            <button type="button" class="btn btn-secondary" v-on:click="declineConsent">Ablehnen</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    import {services} from "../main";
    import {CookieAcceptResult} from "../services/cookie-consent/CookieConsentServiceResponses"
    import {eventBus} from "../main";

    export default {
        name: "CookieBarComponent",
        props: {
            isVisible: {type: [Boolean], default: {}},
        },
        data() {
            return {
                cookieConsentApiRequest: new CookieAcceptResult(),
            }
        },
        methods: {
            declineConsent() {
                this.cookieConsentApiRequest = services.cookieConsentService.decline();
            },
            acceptConsent() {
                this.cookieConsentApiRequest = services.cookieConsentService.accept();
            }
        },
        watch: {
            "cookieConsentApiRequest.loaded": function () {
                eventBus.$emit("finishedCookieSettings")
            }
        }


    }
</script>

<style scoped>
    .open {
        display: block;
    }

    .modal.open {
        z-index: 2300;
        background-color: rgba(0,0,0,0.8);
    }

    .modal-content a{
        color: #08AB99;
        cursor: pointer;
    }
</style>
