<template>
	<div>
		<div style="position: relative;">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" class="achievement panel"
				 :class="{notification: this.isNotification, inProgress: !this.success}"
				 viewBox="5 5 860 213" xml:space="preserve">
				<g>

						<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="435.5" y1="223.0787" x2="435.5" y2="5.0787"
										gradientTransform="matrix(1 0 0 -1 0 226.0788)">
						<stop offset="0" style="stop-color:#F5F5F5"/>
							<stop offset="1" style="stop-color:#FFFDFD"/>
					</linearGradient>
					<path class="st0" d="M23,3h825c10.5,0,19,8.5,19,19v180c0,10.5-8.5,19-19,19H23c-10.5,0-19-8.5-19-19V22C4,11.5,12.5,3,23,3z"/>
				</g>
				<path class="st1" d="M212.5,3l3.4,141v77H17.1c-8.3,0-15-6.8-15-15.1L2.8,16.1c0-8.3,6.8-15,15.1-14.9L212.5,3z"/>
				<path :class="{normal_achievement: score !== 0, heroic_achievement: score === 0}" d="M828,221L766,76l46.4-73H852c8.3,0,15,6.7,15,15v188c0,8.3-6.7,15-15,15H828z"/>
				<path class="st3" d="M43,31h131c8.3,0,15,6.7,15,15v131c0,8.3-6.7,15-15,15H43c-8.3,0-15-6.7-15-15V46C28,37.7,34.7,31,43,31z"/>
				<path class="st4" d="M43,31h131c8.3,0,15,6.7,15,15v131c0,8.3-6.7,15-15,15H43c-8.3,0-15-6.7-15-15V46C28,37.7,34.7,31,43,31z"/>

                <text transform="matrix(1 0 0 1 788 87.0787)" class="st8 st6 st9" v-if="score !== 0">{{ score }}<title
                        data-toggle="tooltip">Erhaltene Achievement Punkte</title></text>
                <text data-tooltip="test" transform="matrix(1 0 0 1 788 87.0787)" class="st8 st6 st9" v-if="score == 0">&starf;<title
                        data-toggle="tooltip">Das hat nicht jeder - ein besonderes Achievement.</title></text>
				<rect x="240" y="83.1" class="st3" width="511" height="65"/>

				<rect x="240" y="165.1" class="st3" width="511" height="23"/>

				<image :href="this.icon" x="22" y="22" height="180px" width="180px" class="visual"></image>
			</svg>
			<div style="position: absolute; top: 0; left: 27%; width: 58%">
				<h2 class="st5 st6 st7 achievement-title">{{ title }}</h2>
				<p>{{ description }}</p>
				<ul class="achievement-trigger">
					<li v-for="triggerElement of this.trigger" :class="{finished: triggerElement.finished, inProgress: !triggerElement.finished}">
						{{getProgressString(triggerElement)}} {{ triggerElement.description }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: "AchievementSvg",
        props: {
            title: {type: String, required: true},
            icon: {type: String, required: true},
            description: {type: String, required: true},
            isNotification: {type: Boolean, required: false, default: false},
            success: {type: Boolean, required: false, default: true},
            trigger: {type: Array, required: false, default: () => []},
            score: {type: Number, required: false, default: 0},
        },
        updated() {
            this.$nextTick(function () {
                let tooltips = $('[data-toggle="tooltip"]');
                tooltips.tooltip('destroy');
                tooltips.tooltip();
            })
        },
        methods: {
			getProgressString: function (trigger) {
				let triggerCount = trigger.count;
				if (trigger.operator === "GREATER_THAN_OR_EQUALS" || trigger.operator === "GREATER_THAN") {
					triggerCount = ((trigger.count < trigger.limit) ? trigger.count : trigger.limit);
				}
				if (trigger.operator === "LOWER_THAN_OR_EQUALS" || trigger.operator === "LOWER_THAN") {
					triggerCount = ((trigger.count > trigger.limit) ? trigger.count : trigger.limit);
				}
				return `${triggerCount}/${trigger.limit}`;
			}
		}
    }
</script>

<style scoped>
	.st0{fill:url(#SVGID_1_);}
	.st1{fill:#F5F5F5;}
	.normal_achievement{fill:#DE9E32;}
	.heroic_achievement{fill:#de6e32;}
	.st3{fill:none;}
	.st4{fill:none;stroke:#F3F3F3;}
	.st5{fill:#383F49;}

	.st6 {
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: bold
	}
	.st7{font-size:41px;}
	.st8{fill:#FFFFFF;}
	.st9{font-size:60px;}
    .achievement.notification {
        /* notification specific styles */
    }


	.st10{font-family:'Source Sans Pro', "Source Code Pro", sans-serif;}
	.st11{font-size:26px;}
	.st12{font-size:24px;}
	.visual {
		border-radius: 5px;
	}

    .inProgress {
        opacity: .5;
        filter: grayscale(1);
    }

	.achievement-title {
		font-size: 22px;
		margin-top: 5%;
		margin-bottom: 5px;
	}

	.achievement-trigger > li.finished:before {
		position:absolute;
		left: 0;
		width: 14px;
		content: url(https://polylith-public-files.s3.eu-central-1.amazonaws.com/check-circle.svg) " ";
		margin-top: 1px;
	}

	.achievement-trigger > li.inProgress:before {
		position:absolute;
		left: -17px;
		width: 14px;
		content: url(https://polylith-public-files.s3.eu-central-1.amazonaws.com/times-circle.svg) " ";
		margin-top: 1px;
	}

	.achievement-trigger {
        margin-top: 4%;
		font-size: 11px;
		padding-left: 17px;
		list-style: none;
	}
	@media(max-width:767px){
		.achievement-title {
				font-size: 14px !important;
		}
		p {
			margin-bottom: 0;
			font-size: 10px;
		}

		.achievement-trigger {
			font-size: 10px;
		}
	}

	@media(max-width:435px){
		.achievement-trigger {
			display: none;
		}
	}
</style>