import {NotificationsServiceNetwork} from "./NotificationsServiceNetwork";
import {NotificationsServiceStub} from "./NotificationsServiceStub";

export default class NotificationsService {
  constructor() {
  }

  getPlatformNotificationsServiceSocket() {
    if (typeof window.NOTIFICATIONS_API_URL === "undefined" || window.NOTIFICATIONS_API_URL === "") {
      console.info("Using Stub data for NotificationsService!");
      return new NotificationsServiceStub().getPlatformNotificationsServiceSocket();
    }
    return new NotificationsServiceNetwork().getPlatformNotificationsServiceSocket();
  }

  getUserServiceSocket() {
    if (typeof window.NOTIFICATIONS_API_URL === "undefined" || window.NOTIFICATIONS_API_URL === "") {
      console.info("Using Stub data for NotificationsService!");
      return new NotificationsServiceStub().getUserServiceSocket();
    }
    return new NotificationsServiceNetwork().getUserServiceSocket();
  }

  getAchievementNotificationsServiceSocket() {
    if (typeof window.NOTIFICATIONS_API_URL === "undefined" || window.NOTIFICATIONS_API_URL === "") {
      console.info("Using Stub data for NotificationsService!");
      return new NotificationsServiceStub().getAchievementNotificationsServiceSocket();
    }
    return new NotificationsServiceNetwork().getAchievementNotificationsServiceSocket();
  }

  getLevelUpNotificationsServiceSocket() {
    if (typeof window.NOTIFICATIONS_API_URL === "undefined" || window.NOTIFICATIONS_API_URL === "") {
      console.info("Using Stub data for NotificationsService!");
      return new NotificationsServiceStub().getLevelUpNotificationsServiceSocket();
    }
    return new NotificationsServiceNetwork().getLevelUpNotificationsServiceSocket();
  }

  getExperienceUpdateNotificationsServiceSocket() {
    if (typeof window.NOTIFICATIONS_API_URL === "undefined" || window.NOTIFICATIONS_API_URL === "") {
      console.info("Using Stub data for NotificationsService!");
      return new NotificationsServiceStub().getExperienceUpdateNotificationsServiceSocket();
    }
    return new NotificationsServiceNetwork().getExperienceUpdateNotificationsServiceSocket();
  }

  getSkillScoreNotificationsServiceSocket() {
    if (typeof window.NOTIFICATIONS_API_URL === "undefined" || window.NOTIFICATIONS_API_URL === "") {
      console.info("Using Stub data for NotificationsService!");
      return new NotificationsServiceStub().getSkillScoreNotificationsServiceSocket();
    }
    return new NotificationsServiceNetwork().getSkillScoreNotificationsServiceSocket();
  }

  getNewMessageNotificationSocket() {
    if (typeof window.NOTIFICATIONS_API_URL === "undefined" || window.NOTIFICATIONS_API_URL === "") {
      console.info("Using Stub data for NotificationsService!");
        return new NotificationsServiceStub().getNewMessageNotificationSocket();
    }
      return new NotificationsServiceNetwork().getNewMessageNotificationSocket();
  }

}
