import {CompanyServiceStub} from "./CompanyServiceStub";
import {CompanyServiceNetwork} from "./CompanyServiceNetwork";

export default class CompanyService {
    constructor(auth) {
        this.authService = auth;
    }

    getAllCompanies(
        search = "",
        hasChallenge=false,
        hasJobs=false,
        has100PercentRemoteOption=false,
        technologies=[],
        languages=[],
        values = [],
        latitude = 0,
        longitude = 0,
        searchRadius = 0,
        sortIndex = 0
    ) {

      if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
        console.info("window.COMPANY_API_URL is not set! Using Stub data!");
        return new CompanyServiceStub(this.authService).getAllCompanies(
            search,
            hasChallenge,
            hasJobs,
            has100PercentRemoteOption,
            technologies,
            languages,
            values,
            latitude,
            longitude,
            searchRadius,
            sortIndex
        );
      }
      return new CompanyServiceNetwork(this.authService).getAllCompanies(
          search,
          hasChallenge,
          hasJobs,
          has100PercentRemoteOption,
          technologies,
          languages,
          values,
          latitude,
          longitude,
          searchRadius,
          sortIndex
      );
    }

    getCompanyList(
        pageID = 1,
        search = "",
        hasChallenge=false,
        hasJobs=false,
        has100PercentRemoteOption=false,
        technologies=[],
        languages=[],
        values = [],
        latitude = 0,
        longitude = 0,
        searchRadius = 0,
        sortIndex = 0
    ) {

      if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
        console.info("window.COMPANY_API_URL is not set! Using Stub data!");
        return new CompanyServiceStub(this.authService).getCompanyList(
            pageID,
            search,
            hasChallenge,
            hasJobs,
            has100PercentRemoteOption,
            technologies,
            languages,
            values,
            latitude,
            longitude,
            searchRadius,
            sortIndex
        );
      }
      return new CompanyServiceNetwork(this.authService).getCompanyList(
          pageID,
          search,
          hasChallenge,
          hasJobs,
          has100PercentRemoteOption,
          technologies,
          languages,
          values,
          latitude,
          longitude,
          searchRadius,
          sortIndex
      );
    }

    getPublicSponsoringInformation(challengeIds, tracking=false) {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
            console.info("window.COMPANY_API_URL is not set! Using Stub data!");
            return new CompanyServiceStub(this.authService).getPublicSponsoringInformation(challengeIds, tracking);
        }
        return new CompanyServiceNetwork(this.authService).getPublicSponsoringInformation(challengeIds, tracking);
    }

    getSponsoringInformationDetails(challengeId, tracking=false) {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
            console.info("window.COMPANY_API_URL is not set! Using Stub data!");
            return new CompanyServiceStub(this.authService).getSponsoringInformationDetails(challengeId, tracking);
        }
        return new CompanyServiceNetwork(this.authService).getSponsoringInformationDetails(challengeId, tracking);
    }

    getSubmissionState(challengeId) {
        if (typeof COMPANY_API_URL === "undefined" || COMPANY_API_URL === "") {
            console.info("COMPANY_API_URL is not set! Using Stub data!");
            return new CompanyServiceStub(this.authService).getSubmissionState(challengeId);
        }
        return new CompanyServiceNetwork(this.authService).getSubmissionState(challengeId);
    }

    getCompanyProfile(companyId, tracking = false) {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
          console.info("window.COMPANY_API_URL is not set! Using Stub data!");
          return new CompanyServiceStub(this.authService).getCompanyProfile(companyId, tracking);
        }
        return new CompanyServiceNetwork(this.authService).getCompanyProfile(companyId, tracking);
    }

    getVacancyList(
        pageID = 1,
        search = "",
        hasChallenge=false,
        hasRemote=false,
        technologies=[],
        languages=[],
        latitude = 0,
        longitude = 0,
        searchRadius = 0,
        ratingIndex = 0,
        sortIndex = 0
    ) {

      if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
        console.info("window.COMPANY_API_URL is not set! Using Stub data!");
        return new CompanyServiceStub(this.authService).getVacancyList(
            pageID,
            search,
            hasChallenge,
            hasRemote,
            technologies,
            languages,
            latitude,
            longitude,
            searchRadius,
            ratingIndex,
            sortIndex
        );
      }
      return new CompanyServiceNetwork(this.authService).getVacancyList(
          pageID,
          search,
          hasChallenge,
          hasRemote,
          technologies,
          languages,
          latitude,
          longitude,
          searchRadius,
          ratingIndex,
          sortIndex
      );
    }


    getCompanyVacanciesByLocations(
        search = "",
        hasChallenge=false,
        hasRemote=false,
        technologies=[],
        languages=[],
        latitude = 0,
        longitude = 0,
        searchRadius = 0,
        ratingIndex = 0,
        sortIndex = 0
    ) {

      if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
        console.info("window.COMPANY_API_URL is not set! Using Stub data!");
        return new CompanyServiceStub(this.authService).getCompanyVacanciesByLocations(
            search,
            hasChallenge,
            hasRemote,
            technologies,
            languages,
            latitude,
            longitude,
            searchRadius,
            ratingIndex,
            sortIndex
        );
      }
      return new CompanyServiceNetwork(this.authService).getCompanyVacanciesByLocations(
          search,
          hasChallenge,
          hasRemote,
          technologies,
          languages,
          latitude,
          longitude,
          searchRadius,
          ratingIndex,
          sortIndex
      );
    }

    getVacancy(vacancySlugOrId) {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
          console.info("window.COMPANY_API_URL is not set! Using Stub data!");
          return new CompanyServiceStub(this.authService).getVacancy(vacancySlugOrId);
        }
        return new CompanyServiceNetwork(this.authService).getVacancy(vacancySlugOrId);
    }

    getVacancyGoogleForJobsJson(vacancyId) {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
          console.info("window.COMPANY_API_URL is not set! Using Stub data!");
          return new CompanyServiceStub(this.authService).getVacancyGoogleForJobsJson(vacancyId);
        }
        return new CompanyServiceNetwork(this.authService).getVacancyGoogleForJobsJson(vacancyId);
    }

    getVacancies(companyId, pageID, jobsPerPage) {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
          console.info("window.COMPANY_API_URL is not set! Using Stub data!");
          return new CompanyServiceStub(this.authService).getVacancies(companyId, pageID, jobsPerPage);
        }
        return new CompanyServiceNetwork(this.authService).getVacancies(companyId, pageID, jobsPerPage);
    }

    getTechnologyList() {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
            console.info("window.COMPANY_API_URL is not set! Using Stub data!");
            return new CompanyServiceStub(this.authService).getTechnologyList();
        }
        return new CompanyServiceNetwork(this.authService).getTechnologyList();
    }

    getVacanciesTechnologyList() {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
            console.info("window.COMPANY_API_URL is not set! Using Stub data!");
            return new CompanyServiceStub(this.authService).getVacanciesTechnologyList();
        }
        return new CompanyServiceNetwork(this.authService).getVacanciesTechnologyList();
    }

    getActiveValues() {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
            console.info("window.COMPANY_API_URL is not set! Using Stub data!");
            return new CompanyServiceStub(this.authService).getActiveValues();
        }
        return new CompanyServiceNetwork(this.authService).getActiveValues();
    }

    getVacancyRating(vacancyId) {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
            console.info("window.COMPANY_API_URL is not set! Using Stub data!");
            return new CompanyServiceStub(this.authService).getVacancyRating(vacancyId);
        }
        return new CompanyServiceNetwork(this.authService).getVacancyRating(vacancyId);
    }

    getVacancyRatingComments(vacancyId) {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
            console.info("window.COMPANY_API_URL is not set! Using Stub data!");
            return new CompanyServiceStub(this.authService).getVacancyRatingComments(vacancyId);
        }
        return new CompanyServiceNetwork(this.authService).getVacancyRatingComments(vacancyId);
    }

    postVacancyRating(vacancyId, ratingData) {
        if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
            console.info("window.COMPANY_API_URL is not set! Using Stub data!");
            return new CompanyServiceStub(this.authService).postVacancyRating(vacancyId, ratingData);
        }
        return new CompanyServiceNetwork(this.authService).postVacancyRating(vacancyId, ratingData);
    }
}

