import ResumeServiceNetwork from "./ResumeServiceNetwork";
import ResumeServiceStub from "./ResumeServiceStub";


export default class ResumeService {

    constructor(auth) {
        this.authService = auth;
    }

    getResume() {
         if (typeof window.RESUME_API_URL === "undefined" || window.RESUME_API_URL === "") {
            console.info("window.RESUME_API_URL is not set! Using Stub data!");
            return new ResumeServiceStub(this.authService).getResume();
        }
        return new ResumeServiceNetwork(this.authService).getResume();
    }

    createWork(workData) {
         if (typeof window.RESUME_API_URL === "undefined" || window.RESUME_API_URL === "") {
            console.info("window.RESUME_API_URL is not set! Using Stub data!");
            return new ResumeServiceStub(this.authService).createWork(workData);
        }
        return new ResumeServiceNetwork(this.authService).createWork(workData);
    }

    createEducation(educationData) {
         if (typeof window.RESUME_API_URL === "undefined" || window.RESUME_API_URL === "") {
            console.info("window.RESUME_API_URL is not set! Using Stub data!");
            return new ResumeServiceStub(this.authService).createEducation(educationData);
        }
        return new ResumeServiceNetwork(this.authService).createEducation(educationData);
    }

    updateWork(workId, workData) {
         if (typeof window.RESUME_API_URL === "undefined" || window.RESUME_API_URL === "") {
            console.info("window.RESUME_API_URL is not set! Using Stub data!");
            return new ResumeServiceStub(this.authService).updateWork(workId, workData);
        }
        return new ResumeServiceNetwork(this.authService).updateWork(workId, workData);
    }

    updateEducation(educationId, educationData) {
         if (typeof window.RESUME_API_URL === "undefined" || window.RESUME_API_URL === "") {
            console.info("window.RESUME_API_URL is not set! Using Stub data!");
            return new ResumeServiceStub(this.authService).updateEducation(educationId, educationData);
        }
        return new ResumeServiceNetwork(this.authService).updateEducation(educationId, educationData);
    }

    deleteWork(workId) {
         if (typeof window.RESUME_API_URL === "undefined" || window.RESUME_API_URL === "") {
            console.info("window.RESUME_API_URL is not set! Using Stub data!");
            return new ResumeServiceStub(this.authService).deleteWork(workId);
        }
        return new ResumeServiceNetwork(this.authService).deleteWork(workId);
    }

    deleteEducation(educationId) {
         if (typeof window.RESUME_API_URL === "undefined" || window.RESUME_API_URL === "") {
            console.info("window.RESUME_API_URL is not set! Using Stub data!");
            return new ResumeServiceStub(this.authService).deleteEducation(educationId);
        }
        return new ResumeServiceNetwork(this.authService).deleteEducation(educationId);
    }

}