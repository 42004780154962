import {responseHandler} from '../Utilities.js';
import {
    CloseChatResult,
    GetChannelDetailsResult,
    GetChannelsResult, SaveDraftMessageResult, SendMessageResult,
} from "./MessageServiceResponses";


export default class MessageServiceNetwork {

    constructor(auth) {
        this.authService = auth;
    }

    getChannels() {
        let result = new GetChannelsResult();
        fetch(window.MESSAGE_API_URL + "/api/channel/",{
            headers: this.authService.getAuthorizationHeader()
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    getChannelDetails(channelId) {
        let result = new GetChannelDetailsResult();
        fetch(window.MESSAGE_API_URL + "/api/channel/" + channelId + "/", {
            headers: this.authService.getAuthorizationHeader()
        })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    sendMessage(channelId, content) {
        let result = new SendMessageResult();
        fetch(window.MESSAGE_API_URL + "/api/channel/" + channelId + "/send_message/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify({ content: content }),
        })
          .then(responseHandler)
          .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
          })
          .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
          });
        return result;
      }

      saveDraftMessage(channelId, content) {
        let result = new SaveDraftMessageResult();
        fetch(window.MESSAGE_API_URL + "/api/channel/" + channelId + "/send_draft_message/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
            body: JSON.stringify({ content: content }),
        })
          .then(responseHandler)
          .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
          })
          .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
          });
        return result;
      }

      closeChat(channelId) {
        let result = new CloseChatResult();
        fetch(window.MESSAGE_API_URL + "/api/channel/" + channelId + "/close/", {
            headers: this.authService.getAuthorizationHeader(),
            method: "POST",
        })
          .then(responseHandler)
          .then((json) => {
            result.apiData = json.data;
            result.loaded = true;
          })
          .catch((error) => {
            console.error(error);
            result.loaded = true;
            result.error = error;
          });
        return result;
      }
}