<template>
    <div class="row form-row">
        <div class="col-xs-12 col-md-offset-3 col-md-6">
            <div v-if="selectedProfession !== 'pupil'">
                <h3 class="text-center">
                    Bist du auf der Suche nach einem Helden-Job, {{name}}?
                </h3>
                <div class="content">
                    <form class="job-interest-form">
                        <div class="job-interest-box" @click="selectJobInterest('job')"
                             :class="{'job-interest-selected-animation' : selectedJobInterest === 'job',
                                'job-interest-other-animation': selectedJobInterest && selectedJobInterest !== 'job',
                                'job-interest-focus': selectedJobInterest === 'job'}">
                            <div class="row job-interest-row">
                                <div class="col-xs-3 col-lg-6">
                                    <img class="job-icon"
                                         src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1598604845/eh-platform/icons/002_Job_Search_-_Retro.svg"
                                         alt="Job Search Icon">
                                    <p>
                                        <input
                                                class=" input"
                                                type="radio"
                                                id="job-interest-choice-1"
                                                name="radiobutton"
                                        />
                                    </p>
                                </div>
                                <div class="col-xs-9 col-lg-12">
                                    <p>
                                        Ja, ich suche einen Job oder Praktikum.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="job-interest-box" @click="selectJobInterest('offers')"
                             :class="{'job-interest-selected-animation' : selectedJobInterest === 'offers',
                                'job-interest-other-animation': selectedJobInterest && selectedJobInterest !== 'offers',
                                'job-interest-focus': selectedJobInterest === 'offers'}">
                            <div class="row job-interest-row">
                                <div class="col-xs-3 col-lg-6">
                                    <img class="job-icon"
                                         src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1598604673/eh-platform/icons/096_Emails_-_Retro.svg"
                                         alt="Job Offer Icon">
                                    <input
                                            class="job-interest-input input"
                                            type="radio"
                                            id="job-interest-choice-2"
                                            name="radiobutton"
                                    />
                                </div>
                                <div class="col-xs-9 col-lg-12">
                                    <p>
                                        Nein, aber an Angeboten bin ich interessiert.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="job-interest-box" @click="selectJobInterest('freelancer')"
                             :class="{'job-interest-selected-animation' : selectedJobInterest === 'freelancer',
                                'job-interest-other-animation': selectedJobInterest && selectedJobInterest !== 'freelancer',
                                'job-interest-focus': selectedJobInterest === 'freelancer'}">
                            <div class="row job-interest-row">
                                <div class="col-xs-3 col-lg-6">
                                    <img class="job-icon"
                                         src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605040/eh-platform/icons/029_3D_Model_-_Retro.svg"
                                         alt="Freelancer Icon">
                                    <input
                                            class="job-interest-input input"
                                            type="radio"
                                            id="job-interest-choice-3"
                                            name="radiobutton"
                                    />
                                </div>
                                <div class="col-xs-9 col-lg-12">
                                    <p>
                                        Nein, aber als Freelancer suche ich neue Projekte.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="job-interest-box" @click="selectJobInterest('no-job')"
                             :class="{'job-interest-selected-animation' : selectedJobInterest === 'no-job',
                                'job-interest-other-animation': selectedJobInterest && selectedJobInterest !== 'no-job',
                                'job-interest-focus': selectedJobInterest === 'no-job'}">
                            <div class="row job-interest-row">
                                <div class="col-xs-3 col-lg-6">
                                    <img class="job-icon"
                                         src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605043/eh-platform/icons/032_No_Waiting_-_Retro.svg"
                                         alt="No Interest Icon">
                                    <input
                                            class="job-interest-input input"
                                            type="radio"
                                            id="job-interest-choice-4"
                                            name="radiobutton"
                                    />
                                </div>
                                <div class="col-xs-9 col-lg-12">
                                    <p>Das ist geheim!</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div v-if="selectedProfession === 'pupil'">
                <h3 class="text-center">
                    Bist du interessiert an einer Ausbildung oder einem Studium in der IT, {{name}}?
                </h3>
                <div class="content">
                    <form class="job-interest-form">
                        <div class="pupil-box" @click="selectJobInterest('studies')"
                             :class="{'job-interest-selected-animation' : selectedJobInterest === 'studies',
                                'job-interest-other-animation': selectedJobInterest && selectedJobInterest !== 'studies',
                                'job-interest-focus': selectedJobInterest === 'studies'}">
                            <div class="row job-interest-row">
                                <div class="col-xs-3 col-lg-4">
                                    <img class="job-icon"
                                         src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1598606549/eh-platform/icons/047_Programmer_-_Retro.svg"
                                         alt="Job Search Icon">
                                    <p>
                                        <input
                                                class=" input"
                                                type="radio"
                                                id="job-interest-choice-5"
                                                name="radiobutton"
                                        />
                                    </p>
                                </div>
                                <div class="col-xs-9 col-lg-12">
                                    <p>
                                        Ja, ich möchte später Informatik (o.Ä.) studieren oder eine Ausbildung in der Informatik machen.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="pupil-box" @click="selectJobInterest('maybe')"
                             :class="{'job-interest-selected-animation' : selectedJobInterest === 'maybe',
                                'job-interest-other-animation': selectedJobInterest && selectedJobInterest !== 'maybe',
                                'job-interest-focus': selectedJobInterest === 'maybe'}">
                            <div class="row job-interest-row">
                                <div class="col-xs-3 col-lg-4">
                                    <img class="job-icon"
                                         src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1598606549/eh-platform/icons/043_Browser_-_Retro.svg"
                                         alt="Job Offer Icon">
                                    <input
                                            class="job-interest-input input"
                                            type="radio"
                                            id="job-interest-choice-6"
                                            name="radiobutton"
                                    />
                                </div>
                                <div class="col-xs-9 col-lg-12">
                                    <p>
                                        Ich muss erstmal EntwicklerHeld werden und herausfinden, ob die Informatik etwas für mich ist.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="pupil-box" @click="selectJobInterest('no-studies')"
                             :class="{'job-interest-selected-animation' : selectedJobInterest === 'no-studies',
                                'job-interest-other-animation': selectedJobInterest && selectedJobInterest !== 'no-studies',
                                'job-interest-focus': selectedJobInterest === 'no-studies'}">
                            <div class="row job-interest-row">
                                <div class="col-xs-3 col-lg-4">
                                    <img class="job-icon"
                                         src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1598605043/eh-platform/icons/032_No_Waiting_-_Retro.svg"
                                         alt="Freelancer Icon">
                                    <input
                                            class="job-interest-input input"
                                            type="radio"
                                            id="job-interest-choice-7"
                                            name="radiobutton"
                                    />
                                </div>
                                <div class="col-xs-9 col-lg-12">
                                    <p>
                                        Nein, auf keinen Fall bzw. keine Ahnung.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "JobInterestForm",
        props: {
            name: {
                type: String,
                default: "EntwicklerHeld",
            },
            selectedProfession: {
                type: String,
                default: "professional/academics",
            },
            nextFunction: {},
        },
        data() {
            return {
                selectedJobInterest: null,
            }
        },
        validations: {
            selectedJobInterest: {
                required,
            },
            form: ["selectedJobInterest"],
        },
        methods: {
            validate() {
                this.$v.form.$touch();
                let isValid = !this.$v.form.$invalid;
                this.$emit("on-validate", this.$data, isValid);
                return isValid;
            },
            selectJobInterest (selectedJobInterest) {
                this.selectedJobInterest = selectedJobInterest;
                setTimeout(this.nextFunction, 500);
            },
        },
    }
</script>

<style scoped>
    .content {
        width: 100%;
    }

    .input {
        width: 100%;
    }

    hr {
        border-top: 1px solid white;
    }

    p {
        margin-top: 1rem;
        font-size: 15px;
        color: #4c4c4c;
    }

    h3 {
        color: #4c4c4c;
        margin: 1.5rem 0;
        line-height: 1.4em;
    }

    form input[type="radio"] + label:before {
        content: none;
    }

    .job-icon {
        width: auto;
        display: block;
        margin: 1rem auto;
        filter: grayscale(80%);
        max-width: 75px;
        opacity: 0.9;
    }

    .job-interest-row {
        display: flex;
        align-items: center;
    }

    .job-interest-form {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        margin-top: 5rem;
    }

    .job-interest-box {
        align-self: center;
        margin: 0.75rem 0;
        width: 100%;
        padding: 1rem;
        flex-direction: row;
        border-radius: 10px;
        background-color: #F2F7FD;
        box-sizing: border-box;
        border: 2px solid transparent;
        transition: all 0.3s ease-in-out;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .pupil-box {
        flex-direction: row;
        border-radius: 10px;
        background-color: #08AB991A;
        box-sizing: border-box;
        border: 2px solid transparent;
        transition: all 0.3s ease-in-out;
        align-self: center;
        margin: 0.75rem 0;
        width: 100%;
        padding: 1rem;
    }

    .job-interest-box:hover {
        cursor: pointer;
        background-color: #08AB9933;
    }

    .job-interest-box:focus, .job-interest-focus {
        border: solid 2px #08AB99;
        background-color: #08AB9933;
    }

    .job-interest-box:hover img, .job-interest-focus img{
        filter: grayscale(0%);
        opacity: 1;
    }

    .pupil-box:hover img, .job-interest-focus img{
        filter: grayscale(0%);
        opacity: 1;
    }

     .pupil-box:hover {
        cursor: pointer;
        background-color: #08AB9933;
    }

    .pupil-box:focus, .job-interest-focus {
        border: solid 2px #08AB99;
        background-color: #08AB9933;
    }

    .job-interest-selected-animation {
        animation-name: selected-job-interest-get-big;
        animation-fill-mode: forwards;
        animation-duration: 0.8s;
    }

    .job-interest-other-animation {
        animation-name: selected-job-interest-fade-out;
        animation-duration: 0.5s;
    }

    .job-icon {
        max-width: 50px;
    }

    @keyframes selected-job-interest-get-big {
        to {
            transform: scale(1.05);
        }
    }

    @keyframes selected-job-interest-fade-out {
        to {
            opacity: 0;
        }
    }

    @media (max-width: 767px) {
        .job-interest-form {
          margin-top: 2rem;
        }
    }

    @media (min-width: 768px){
        p {
          font-size: 120%;
        }

        .job-interest-box {
          width: 80%;
        }
    }

    @media (min-width: 992px){
        .job-interest-box {
          width: 100%;
        }
    }
</style>