import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from 'vuex'

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueGtag from "vue-gtag";
import {Keyframes} from 'vue-keyframes';
import VueScrollTo from "vue-scrollto"

import App from './App.vue'
import store from './store/Store.js'
import Callback from './components/Callback.vue'
import MetaDataComponent from './components/MetaDataComponent'

import AuthenticationService from './services/Authentication.js';
import RewardService from "./services/reward/RewardService";
import UserService from "./services/user/UserService";
import TaskManagementService from "./services/task-management/TaskManagementService";
import QualityService from "./services/quality/QualityService";
import FlowService from "./services/flow/FlowService";
import WorkspaceService from "./services/workspace/WorkspaceService";
import ProvisionerService from "./services/provisioner/ProvisionerService";
import NewsService from "./services/news/NewsService";
import CompanyService from "./services/company/CompanyService";
import GamificationService from "./services/gamification/GamificationService";
import GoogleAnalyticsService from "./services/tracking/GoogleAnalytics";
import FaceBookAnalyticsService from "./services/tracking/FacebookAnalytics";
import RedditAnalyticsService from "./services/tracking/RedditAnalytics";
import RankTheCodeService from "./services/rank-the-code/RankTheCodeService";
import NotificationService from "./services/notification/NotificationService";
import CampusChallengeService from "./services/campus-challenge/CampusChallengeService";
import EntwicklerHerzService from "./services/entwicklerherz/EntwicklerHerz";
import NotificationsService from "./services/notifications/NotificationsService";
import VueGooglePlaces from 'vue-google-places'
import CookieConsentService from "./services/cookie-consent/CookieConsentService";
import TechnologyService from "./services/technology/TechnologyService";
import MessageService from "./services/message/MessageService";
import ResumeService from "./services/resume/ResumeService";
import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";
import "jquery.cookie/jquery.cookie";

// LOAD CSS
import "./assets/css/styles.css";
import "./assets/css/global.css";


import "./assets/css/animate.min.css";
import "./assets/css/devfont.css";

import {isCookieConsentSet, getCookieConsent} from "./services/Utilities";
import VoerroTagsInput from '@voerro/vue-tagsinput';
import Vuelidate from "vuelidate/src";
import TwitterAnalyticsService from "./services/tracking/TwitterAnalytics";
import LinkedInAnalyticsService from "./services/tracking/LinkedInAnalytics";
import SnapChatAnalytics from "./services/tracking/SnapChatAnalytics";
import InteractsService from "./services/interacts/InteractsService";

Vue.component('tags-input', VoerroTagsInput);
Vue.component("MetaDataComponent", MetaDataComponent);
Vue.use(VueRouter);

Vue.use(Keyframes);

Vue.use(VueScrollTo);

if (!window.DEBUG) {
  Sentry.init({
    dsn: window.SENTRY_API_URL,
    environment: window.ENVIRONMENT,
    release: "frontend@" + window.VERSION,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true
      }),
      new Integrations.CaptureConsole({
        levels: ["error", "warn"]
      })
    ]
  });
}

Vue.use(Vuex);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCVWu7dN6ToxD8ksp17hJ9DOtVkN19jbxY",
      libraries: "places",
  }
});
Vue.use(Vuelidate);
Vue.use(VueGooglePlaces);

//IMPORTANT: Views should always be lazy-loaded with ()=>import() syntax
const routes = [
    {
        path: '/campaign',
        name: "campaign-list",
        props: {public: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "ChallengeGroupListView" */ './views/ChallengeGroupListView.vue')
    },
    {
        path: '/dashboard',
        name: "dashboard",
        props: {public: false},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "DashboardView" */ './views/DashboardView.vue')
    },
    {
        path: '/challenge',
        name: "challenge-list",
        props: {public: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "ChallengeGroupListView" */ './views/ChallengeGroupListView.vue')
    },
    {
        path: '/login',
        name: "login",
        props: {public: true, whitelabel: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "Login" */ "./views/Login.vue")
    },
    {
        path: '/campaign/:slug',
        name: "campaign-details",
        props: {public: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "CampaignDetails", webpackPrefetch: true */ './views/CampaignDetailsView.vue')
    },
    {
        path: '/challenge/:slug',
        name: "challenge-details",
        props: {public: true, whitelabel: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "CampaignDetails", webpackPrefetch: true */ './views/CampaignDetailsView.vue')
    },
    {
        path: '/challenge/:challengeId/accept-and-edit',
        name: "direct-link-challenge-edit",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "DirectLinkChallengeEditView" */ './views/DirectLinkChallengeEditView')
    },
    {
        path: '/challenge/:challengeId/analysis',
        name: "challenge-analysis",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "ChallengeAnalysis" */ './views/ChallengeAnalysis')
    },
    {
        path: '/challenge/:challengeId/task/:taskId/edit',
        name: "challenge-edit-view",
        props: {whitelabel: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "ChallengeEditView" */ './views/ChallengeEditView.vue')
    },
    {
        path: '/company/',
        name: "company-list",
        props: {public: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "CompanyList" */ './views/CompanyListView.vue')
    },
    {
        path: '/company/:slug',
        name: "company-profile",
        props: {public: true, whitelabel: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "NewCompanyProfileView" */ './views/NewCompanyProfileView.vue')
    },
    {
        path: '/vacancy/',
        name: "vacancy-list",
        props: {public: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "VacancyListView" */ './views/VacancyListView')
    },
    {
        path: '/vacancy/:slugOrId',
        name: "vacancy-details",
        props: {public: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "VacancyView" */ './views/VacancyView')
    },
    {
        path: '/reward',
        name: "reward-list",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "RewardGroupListView" */ './views/RewardGroupListView')
    },
    {
        path: '/reward/:id',
        name: "reward-exchange",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "RewardExchangeView" */ './components/rewards/RewardExchangeComponent')
    },
    {path: '/callback', name: "callback", component: Callback, props: {whitelabel: true}},
    {
        path: '/show_file',
        name: "show-file",
        props: {whitelabel: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "ReadOnlyEditorView" */ './views/ReadOnlyEditorView')
    },
    {
        path: '/profile',
        name: "user-profile",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "UserProfileView" */ './views/UserProfileView')
    },
    {
        path: '/resume',
        name: "resume",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "ResumeView" */ './views/ResumeView')
    },
    {
        path: '/publicprofile/:userId',
        name: "public-user-profile",
        props: {public: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "PublicUserProfile" */ './views/PublicUserProfileView')
    },
    {
        path: '/leader-board',
        name: "leader-board",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "LeaderboardView" */ './views/LeaderboardView.vue')
    },
    {
        path: '/news',
        name: "news",
        props: {public: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "NewsListView" */ './views/NewsListView')
    },
    {
        path: "/news/:slug",
        name: "news-post",
        props: {public: true},
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "NewsPostView" */ './views/NewsPostView')
    },
    {
        path: '/creatorslounge',
        name: "creators-lounge",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "CreatorsLounge" */ './views/CreatorsLoungeIndexView')
    },
    {
        path: '/campuschallenge',
        name: "campus-challenge",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "CampusChallengeView" */ './views/CampusChallengeView')
    },
    {
        path: '/chat',
        name: "chat",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "ChatView" */ './views/ChatView')
    },
    {
        path: '/chat/:channelId',
        name: "chat",
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "ChatView" */ './views/ChatView')
    },
    {
        path: '*', redirect: to => {
            return {
                name: "challenge-list",
                query: to.query,
            }
        },
    },
];

class EntwicklerHeldVueRouter extends VueRouter {
    constructor(props) {
        super(props);
        this.manuallySavedPosition=null;
    }
}

const router = new EntwicklerHeldVueRouter({
    routes: routes,
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if (this.manuallySavedPosition) {
            let jumpingPosition = this.manuallySavedPosition;
            this.manuallySavedPosition = null;
            return jumpingPosition;
        }
        return { x: 0, y: 0 };
    }
});

// Creates a mock object that has all methods of the given class but does nothing
function mock(cls)  {
    function dummy() {}
    let mockObject = {};
    for (let prop of Object.getOwnPropertyNames( cls.prototype )) {
        Object.defineProperty(mockObject, prop, {value: dummy})
    }
    return mockObject
}

let authenticationService = new AuthenticationService(router, store);
let googleAnalyticsService = mock(GoogleAnalyticsService);
let facebookAnalyticsService = mock(FaceBookAnalyticsService);
let redditAnalyticsService = mock(RedditAnalyticsService);
let twitterAnalyticsService = mock(TwitterAnalyticsService);
let linkedInAnaylticsService = mock(LinkedInAnalyticsService);
let snapChatAnaylticsService = mock(SnapChatAnalytics);

export const services = new Vue({
  data: {
    auth: authenticationService,
    rewardService: new RewardService(authenticationService),
    userService: new UserService(authenticationService),
    taskManagementService: new TaskManagementService(authenticationService),
    qualityService: new QualityService(authenticationService),
    flowService: new FlowService(authenticationService),
    workspace: new WorkspaceService(authenticationService),
    provisioner: new ProvisionerService(authenticationService),
    newsService: new NewsService(authenticationService),
    cookieConsentService: new CookieConsentService(authenticationService),
    companyService: new CompanyService(authenticationService),
    gamificationService: new GamificationService(authenticationService),
    notificationService: new NotificationService(authenticationService),
    notificationsService: new NotificationsService(authenticationService),
    rankTheCodeService: new RankTheCodeService(authenticationService),
    campusChallengeService: new CampusChallengeService(authenticationService),
    entwicklerHerzService: new EntwicklerHerzService(authenticationService),
    technologyService: new TechnologyService(authenticationService),
    messageService: new MessageService(authenticationService),
    resumeService: new ResumeService(authenticationService),
    interactsService: new InteractsService(authenticationService),
    googleAnalytics: googleAnalyticsService,
    facebookAnalytics: facebookAnalyticsService,
    redditAnalytics: redditAnalyticsService,
    twitterAnalytics: twitterAnalyticsService,
    linkedInAnalytics: linkedInAnaylticsService,
    snapChatAnalytics: snapChatAnaylticsService,
  },
    methods: {
        initializeTracking: function () {
            if (isCookieConsentSet() && getCookieConsent()) {
                if (window.GOOGLE_ANALYTICS_ID) {
                    Vue.use(VueGtag, {
                        config: {
                            id: window.GOOGLE_ANALYTICS_ID,
                            params: {
                                send_page_view: false,
                                anonymize_ip: true,
                                linker: {
                                    domains: ['stayhomeandcode.de'],
                                    accept_incoming: true,
                                }
                            }
                        },
                    }, router,);
                }
                this.googleAnalytics = new GoogleAnalyticsService(Vue.$gtag);
                this.facebookAnalytics = new FaceBookAnalyticsService();
                this.redditAnalytics = new RedditAnalyticsService();
                this.twitterAnalytics = new TwitterAnalyticsService();
                this.linkedInAnalytics = new LinkedInAnalyticsService();
                this.snapChatAnalytics = new SnapChatAnalytics();
            }
        }
    }
});

services.initializeTracking();

export const eventBus = new Vue();

const publicRouteNames = getPublicRoutes();

function getPublicRoutes() {
  const publicRoutes = [];
  for (const route of routes) {
    if (typeof route.props !== "undefined" && route.props.public === true) {
      publicRoutes.push(route.name);
    }
  }
  return publicRoutes;
}

const whiteLabelRouteNames = getWhitelabelRoutes();

function getWhitelabelRoutes() {
    const whitelabelRoutes = [];
    for (const route of routes) {
        if (typeof route.props !== "undefined" && route.props.whitelabel === true) {
            whitelabelRoutes.push(route.name);
        }
    }
    return whitelabelRoutes;
}

function fillFeedbackModalWithData(isAuthenticated, to) {
    let userID = "anonymous";
    if (isAuthenticated) {
        userID = services.auth.getUserID();
    }
    let info = "UserID: " + userID + "; Path: " + to.path;
    if (typeof window.ATL_JQ_PAGE_PROPS === "undefined") {
        window.ATL_JQ_PAGE_PROPS = {
            "fieldValues": {}, "triggerFunction": function (showCollectorDialog) {
                Vue.prototype.$showJiraIssueCollector = showCollectorDialog;
            }
        }
    }
    window.ATL_JQ_PAGE_PROPS["fieldValues"]["customfield_10421"] = info;
}

router.beforeEach((to, from, next) => {
  // always check if user is authenticated (this method also set the vuex isAuthenticated variable)
  let isAuthenticated = services.auth.isAuthenticated();

  fillFeedbackModalWithData(isAuthenticated, to);

  if (FEATURE_FLAGS.WHITELABEL && !whiteLabelRouteNames.includes(to.name)) {
      services.auth.login();
      return;
  }

  if (to.name === "callback") {
    next();
    return;
  }

  if (publicRouteNames.includes(to.name)) {
    next();
    return;
  }

  if (!isAuthenticated) {
    services.auth.login(to);
    return;
  }
  next();

});

/** global variables vue */
const FEATURE_FLAGS = {
    // here you can set a challengegroup id as challenge of the week (it is used for the challenge details view)
    CHALLENGE_OF_THE_WEEK: null, // "f2c4aab2-9296-4517-9e80-5f1e1151834c",
    WHITELABEL: window.WHITELABEL,
    LIVE_STREAM_CODE: window.LIVE_STREAM_CODE_FEATURE_FLAG
}

Vue.prototype.$features = FEATURE_FLAGS;

// SETTINGS FOR THE WHITELABEL SETUP
if (window.WHITELABEL) {
    if (!window.WHITELABEL_AUTH0_CLIENT_ID) {
        // TODO: maybe we move this to the build process
        throw new Error("WHITELABEL_AUTH0_CLIENT_ID is not set. This is required for the whitelabel setup. Each client needs its own auth0 client id.");
    }
    Vue.prototype.$whitelabelConfig = {
        name: window.WHITELABEL_NAME,
        logo: window.WHITELABEL_LOGO,
        highlightColor: window.WHITELABEL_HIGHLIGHT_COLOR,
        loginImage: window.WHITELABEL_LOGIN_IMAGE,
    }
    // set css variables in :root with javascript
    // TODO: make if configurable with env variable
    const whiteLabelColor = window.WHITELABEL_COLOR;
    if (whiteLabelColor) {
        document.documentElement.style.setProperty('--sidebar-background', window.WHITELABEL_COLOR);
        document.documentElement.style.setProperty('--sidebar-second-background', window.WHITELABEL_COLOR);
    }


}

new Vue({
  el: "#app",
  router: router,
  store: store,
  render: h => h(App),
});
