import NewsServiceNetwork from "./NewsServiceNetwork";
import NewsServiceStub from "./NewsServiceStub";

export default class NewsService {

    constructor(auth) {
        this.authService = auth;
    }

    getNewsList() {
        if (typeof window.NEWS_API_URL === "undefined" || window.NEWS_API_URL === "") {
            console.info("window.NEWS_API_URL is not set! Using Stub data!");
            return new NewsServiceStub(this.authService).getNewsList();
        }
        return new NewsServiceNetwork(this.authService).getNewsList();
    }

    getPost(postID) {
        if (typeof window.NEWS_API_URL === "undefined" || window.NEWS_API_URL === "") {
            console.info("window.NEWS_API_URL is not set! Using Stub data!");
            return new NewsServiceStub(this.authService).getPost(postID);
        }
        return new NewsServiceNetwork(this.authService).getPost(postID);
    }

    getUnreadPosts() {
        if (typeof window.NEWS_API_URL === "undefined" || window.NEWS_API_URL === "") {
            console.info("window.NEWS_API_URL is not set! Using Stub data!");
            return new NewsServiceStub(this.authService).getUnreadPosts();
        }
        return new NewsServiceNetwork(this.authService).getUnreadPosts();
    }

    setAllPostsRead() {
        if (typeof window.NEWS_API_URL === "undefined" || window.NEWS_API_URL === "") {
            console.info("window.NEWS_API_URL is not set! Using Stub data!");
            return new NewsServiceStub(this.authService).setAllPostsRead();
        }
        return new NewsServiceNetwork(this.authService).setAllPostsRead();
    }

    getVotedPosts() {
        if (typeof window.NEWS_API_URL === "undefined" || window.NEWS_API_URL === "") {
            console.info("window.NEWS_API_URL is not set! Using Stub data!");
            return new NewsServiceStub(this.authService).getVotedPosts();
        }
        return new NewsServiceNetwork(this.authService).getVotedPosts();
    }

    votePost(postId) {
        if (typeof window.NEWS_API_URL === "undefined" || window.NEWS_API_URL === "") {
            console.info("window.NEWS_API_URL is not set! Using Stub data!");
            return new NewsServiceStub(this.authService).votePost(postId);
        }
        return new NewsServiceNetwork(this.authService).votePost(postId);
    }
}
