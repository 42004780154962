<template>
    <div class="user-coins">
        <router-link :to="{ name: 'reward-list'}">
            <img src="/assets/img/coins.png" alt="An icon with coins" style="height: 2.0rem" v-if="with_icon"/>
            <strong>
                <VueOdometer :value="apiResult.coins" :duration="1000"></VueOdometer>
            </strong>
        </router-link>
        <span v-if="!apiResult.loaded" class="loading-spinner" style="color: rgba(0,0,0,0.4);"></span>
        <span v-if="apiResult.loaded && apiResult.error" class="fa fa-exclamation-circle" title="Guthaben konnte nicht geladen werden" data-toggle="tooltip"></span>
    </div>
</template>

<script>
    import {services} from "../../main.js"
    import {eventBus} from "../../main.js"
    import VueOdometer from 'v-odometer/src'
    import {mapState} from "vuex";

    export default {
        name: "RewardComponent",
        components: {VueOdometer},
        props: {
            with_icon: {type: [Boolean], default: true},
        },
        computed: {
            ...mapState(['isAuthenticated']),
        },
        updated() {
          this.$nextTick(function () {
              // TODO: Check if this can be done without jquery with vue
              $('[data-toggle="tooltip"]').tooltip();
            })
        },
        mounted() {
            let this_ = this;
            eventBus.$on("user-coins-changed", function () {
                this_.apiResult = services.rewardService.getUserCoins(true, this_.apiResult.coins);
            })
            if (this.isAuthenticated) {
                this.apiResult = services.rewardService.getUserCoins();
            }
        },
        data() {
            return {
                apiResult: {loaded: false, error: null},
            }
        },
    }
</script>

<style scoped>
    strong {
        font-weight: 400;
    }

    .user-coins {
        display: inline-block;
    }

    .coins a {
        color: rgba(255, 255, 255, 0.7) !important;
    }

    .coins a:hover {
        color: rgba(255, 217, 71, 0.7) !important;
    }

    .users-coins-overlay-container a {
        pointer-events: none;
    }
</style>
