import {responseHandler} from "../Utilities";
import {
    CreateEducationResult,
    CreateWorkResult,
    DeleteEducationResult,
    DeleteWorkResult,
    ResumeResult,
    UpdateEducationResult,
    UpdateWorkResult
} from "./ResumeServiceResponses";


export default class ResumeServiceNetwork {
    constructor(auth) {
        this.authService = auth;
    }

    getResume() {
        let result = new ResumeResult();
        fetch(window.RESUME_API_URL + "/api/resume/",
            {headers: this.authService.getAuthorizationHeader()})
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    createWork(workData) {
        let result = new CreateWorkResult();
        fetch(window.RESUME_API_URL + "/api/resume/work/create/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
                body: JSON.stringify(workData)
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    createEducation(educationData) {
        let result = new CreateEducationResult();
        fetch(window.RESUME_API_URL + "/api/resume/education/create/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'POST',
                body: JSON.stringify(educationData)
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    updateWork(workId, workData) {
        let result = new UpdateWorkResult();
        fetch(window.RESUME_API_URL + "/api/resume/work/" + workId + "/update/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'PUT',
                body: JSON.stringify(workData)
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    updateEducation(educationId, educationData) {
        let result = new UpdateEducationResult();
        fetch(window.RESUME_API_URL + "/api/resume/education/" + educationId + "/update/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'PUT',
                body: JSON.stringify(educationData)
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    deleteWork(workId) {
        let result = new DeleteWorkResult();
        fetch(window.RESUME_API_URL + "/api/resume/work/" + workId + "/delete/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'DELETE',
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }

    deleteEducation(educationId) {
        let result = new DeleteEducationResult();
        fetch(window.RESUME_API_URL + "/api/resume/education/" + educationId + "/delete/",
            {
                headers: this.authService.getAuthorizationHeader(),
                method: 'DELETE',
            })
            .then(responseHandler)
            .then((json) => {
                result.apiData = json.data;
                result.loaded = true;
            })
            .catch((error) => {
                console.error(error);
                result.loaded = true;
                result.error = error;
            });
        return result;
    }
}
